import { Row } from 'antd'
import styled from 'styled-components'

import colors from '~/styles/colors'

export const LegColor = styled(Row)`
  background-color: ${(props) => props.color};
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  border-radius: 6px;
  align-items: center;
`

export const Label = styled.span`
  height: 20px;
  font-size: 11px;
  margin-left: 2px;
  margin-bottom: 2px;
  whitespace: nowrap;
  font-weight: bold;
  align-items: center;
`

export const Count = styled.span`
  color: ${colors.blue};
  font-size: 11px;
  height: 20px;
  margin-bottom: 2px;
`
export const TableRow = styled(Row)`
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
  width: 100%;
`

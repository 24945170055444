import t from 'prop-types'

export const PermissionPropTypes = t.objectOf(
  t.shape({
    permissions: t.arrayOf([t.string]),
  })
).isRequired

export const FilterPropTypes = t.shape({
  form: t.objectOf(),
  onReset: t.func,
  statuses: t.arrayOf(t.shape({ label: t.string, value: t.number })).isRequired,
  submitFilter: t.func,
})

export const StatiticPropTypes = t.shape({
  budget: t.string,
  budget_credit: t.string,
  budget_debit: t.string,
  missions_actived_count: t.string,
  missions_count: t.string,
  missions_desactive_count: t.string,
  missions_not_used: t.string,
  missions_open: t.string,
  missions_started: t.string,
  registers_analyze_count: t.string,
  registers_approved_count: t.string,
  registers_count: t.string,
  registers_pendent_count: t.string,
  registers_reproved_count: t.string,
})

export const UserRatePropTypes = t.shape({
  score_all: t.oneOfType([t.number, t.any]),
  score_customer: t.oneOfType([t.number, t.any]),
})

export const RegisterPropTypes = t.shape({
  approved_by: t.string,
  category_id: t.string,
  category_name: t.string,
  check_in_check_out: t.bool,
  city: t.string,
  confirm_bonus: t.string,
  country: t.string,
  created_at: t.string,
  created_by: t.string,
  custom_status_description: t.string,
  custom_status_id: t.string,
  customer_id: t.string,
  customer_name: t.string,
  customer_value: t.string,
  customer_value_bonus: t.string,
  customer_value_variable: t.string,
  description: t.string,
  description_variable: t.string,
  has_approved: t.string,
  has_attachment: t.bool,
  has_bonus: t.bool,
  has_custom_status: t.bool,
  has_forms: t.bool,
  has_import_document: t.bool,
  has_import_image: t.bool,
  has_notified_via_email: t.bool,
  has_photo: t.bool,
  has_points: t.bool,
  has_points_value: t.bool,
  has_pos: t.bool,
  has_products: t.string,
  has_reproved: t.string,
  has_signature: t.bool,
  has_value_percentage: t.bool,
  has_value_variable: t.bool,
  id: t.number,
  invoice_value: t.string,
  label_value: t.string,
  lat: t.number,
  lng: t.number,
  message: t.string,
  missionName: t.string,
  mission_id: t.string,
  mission_key: t.string,
  mission_value: t.string,
  missions_main_id: t.string,
  missions_users_id: t.string,
  mu_key: t.string,
  neighborhood: t.string,
  number: t.string,
  photos_count: t.string,
  place: t.string,
  postal_code: t.string,
  r_customer_value: t.string,
  r_user_value: t.string,
  rate: t.string,
  status: t.number,
  street: t.string,
  subcategory: t.string,
  total_rows: t.string,
  uf: t.string,
  uid: t.string,
  updated_at: t.string,
  updated_by: t.string,
  url_customer_mission_receipt: t.string,
  url_mission_report: t.string,
  url_user_mission_receipt: t.string,
  userAvatar: t.string,
  userName: t.string,
  user_id: t.string,
  user_point_value: t.string,
  user_value: t.string,
  user_value_bonus: t.string,
  user_value_variable: t.string,
})

export const RegisterDefaultProps = {
  approved_by: null,
  city: null,
  country: null,
  created_by: null,
  custom_status_description: null,
  custom_status_id: null,
  customer_value_bonus: null,
  customer_value_variable: null,
  description_variable: null,
  has_approved: null,
  has_products: null,
  has_reproved: null,
  invoice_value: null,
  message: null,
  neighborhood: null,
  number: null,
  postal_code: null,
  rate: null,
  street: null,
  uf: null,
  updated_by: null,
  url_customer_mission_receipt: null,
  url_mission_report: null,
  url_user_mission_receipt: null,
  userAvatar: null,
  user_point_value: null,
  user_value_bonus: null,
  user_value_variable: null,
}

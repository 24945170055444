import React from 'react'

import {
  PlayCircleOutlined,
  CameraOutlined,
  FormOutlined,
  CheckCircleOutlined,
  FileAddFilled,
} from '@ant-design/icons'

const fontSize = {
  fontSize: '16px',
}

export const actionIcon = {
  start: <PlayCircleOutlined style={fontSize} />,
  photos: <CameraOutlined style={fontSize} />,
  'open-web': <FileAddFilled style={fontSize} />,
  'form-sended': <FormOutlined style={fontSize} />,
  finish: <CheckCircleOutlined style={fontSize} />,
}

export const actionLabel = {
  start: 'Iniciou',
  photos: 'Tirou Foto',
  'open-web': 'Abriu Arquivo',
  'form-sended': 'Enviou Respostas',
  'send-point-of-sale': 'Enviou PDV',
  finish: 'Finalizou',
}

export const actionColor = {
  start: '#089EFD',
  photos: '#0B35FA',
  'open-web': '#C5C5C5',
  'form-sended': '#F1B950',
  'send-point-of-sale': '#6D5ACF',
  finish: '#669275',
}

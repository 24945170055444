import React from 'react'

import { Form, Input } from 'antd'
import t from 'prop-types'

export function Search({ onChange }) {
  return (
    <Form.Item name="search">
      <Input
        placeholder="Nome / COD. / ID"
        onBlur={({ target }) => onChange('mission', target.value)}
      />
    </Form.Item>
  )
}

Search.propTypes = {
  onChange: t.func,
}

import { responseData } from '~/utils/reducer'

const FORMS_ITEMS_OPTIONAL_CREATE = 'FORMS_ITEMS_OPTIONAL_CREATE'
const FORMS_ITEMS_OPTIONAL_GET = 'FORMS_ITEMS_OPTIONAL_GET'
const FORMS_ITEMS_OPTIONAL_REMOVE = 'FORMS_ITEMS_OPTIONAL_REMOVE'

const FORMS_ITEMS_MULTIPLES_CREATE = 'FORMS_ITEMS_MULTIPLES_CREATE'
const FORMS_ITEMS_MULTIPLES_REMOVE = 'FORMS_ITEMS_MULTIPLES_REMOVE'
const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER = 'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER'

const formsMultiplesOptionalGet = responseData(
  FORMS_ITEMS_OPTIONAL_GET,
  'array'
)
const formsMultiplesOptionalCreate = responseData(
  FORMS_ITEMS_OPTIONAL_CREATE,
  'object'
)
const formsMultiplesOptionalRemove = responseData(
  FORMS_ITEMS_OPTIONAL_REMOVE,
  'object'
)

const formsItemsMultiplesCreate = responseData(
  FORMS_ITEMS_MULTIPLES_CREATE,
  'object'
)
const formsItemsMultiplesRemove = responseData(
  FORMS_ITEMS_MULTIPLES_REMOVE,
  'object'
)
const formsItemsMultiplesOrder = responseData(
  FORMS_ITEMS_MULTIPLES_UPDATE_ORDER,
  'array'
)

export default {
  formsItemsMultiplesOrder,
  formsItemsMultiplesCreate,
  formsItemsMultiplesRemove,
  formsMultiplesOptionalGet,
  formsMultiplesOptionalRemove,
  formsMultiplesOptionalCreate,
}

import React, { useState } from 'react'

import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Space, Popover, Skeleton } from 'antd'
import t from 'prop-types'

const PreviewImage = ({ item, loading, handleRemove }) => {
  const [visible, setVisible] = useState(false)

  const handleOnOpenChange = (isVisible) => {
    if (isVisible) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  return (
    <Popover
      content={
        <Space direction="vertical" size="small">
          {loading ? (
            <Skeleton.Avatar
              size="large"
              shape="square"
              active={loading}
              style={{ width: 150, height: 150 }}
            />
          ) : (
            <img
              alt="preview"
              src={item.url_image}
              style={{ width: 150, height: 150, backgroundColor: '#f1f2f3' }}
            />
          )}
          <Button
            shape="round"
            type="danger"
            onClick={() => handleRemove(item)}
            icon={<DeleteOutlined />}
          >
            Remover
          </Button>
        </Space>
      }
      title="Preview"
      trigger="click"
      open={visible}
      onOpenChange={handleOnOpenChange}
    >
      <Button shape="link" style={{ height: 30 }} icon={<EyeOutlined />} />
    </Popover>
  )
}

PreviewImage.defaultTypes = {
  loading: false,
}

PreviewImage.propTypes = {
  item: t.objectOf(t.string).isRequired,
  loading: t.bool.isRequired,
  handleRemove: t.func.isRequired,
}

export default PreviewImage

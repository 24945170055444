import { Drawer } from 'antd'
import styled from 'styled-components'

export const Container = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 65% !important;
  }

  .ant-row {
    height: 100%;
  }

  @media (max-width: 1400px) {
    .ant-drawer-content-wrapper {
      width: 75% !important;
    }
  }

  @media (max-width: 1100px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }

    .ant-col {
      min-height: 200px;
    }
  }
`

export const MapSection = styled.div`
  height: 100%;
`

export const MapContainer = styled.div`
  min-height: 530px;

  @media (max-width: 991px) {
    min-height: 400px;
  }
`

export const ProductsSection = styled.div`
  overflow-y: auto;
  .ant-card + .ant-card {
    margin-top: 8px;
  }

  @media (max-width: 991px) {
    margin-top: 8px;
  }

  @media (min-width: 991px) {
    max-height: calc(100vh - 110px);

    .ant-card:last-child {
      margin-bottom: 24px;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

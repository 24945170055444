import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`

import {
  WALLET_CONSOLIDATE,
  WALLET_TRANSACTION,
  WALLET_CONSOLIDATE_UPDATE,
  WALLET_FETCH_CONSOLIDATED,
  WALLET_LIST_CONSOLIDATED,
} from '~/services/api/endpoints'
import { get, put, post } from '~/utils/actionsRequests'

const getAllSalesConsolidated = () =>
  get({
    url: WALLET_FETCH_CONSOLIDATED,
    typeFetch: 'WALLET_FETCH_CONSOLIDATED',
    typeError: 'WALLET_FETCH_CONSOLIDATED_FAIL',
    typeResponse: 'WALLET_FETCH_CONSOLIDATED_OK',
  })

const getSaleById = (id) =>
  get({
    url: `${WALLET_LIST_CONSOLIDATED}?user_id=${id}`,
    typeFetch: 'WALLET_LIST_CONSOLIDATED',
    typeError: 'WALLET_LIST_CONSOLIDATED_FAIL',
    typeResponse: 'WALLET_LIST_CONSOLIDATED_OK',
  })

const consolidateValue = (id, data) =>
  post({
    data,
    url: WALLET_CONSOLIDATE(id),
    typeFetch: 'WALLET_CONSOLIDATE',
    typeError: 'WALLET_CONSOLIDATE_FAIL',
    typeResponse: 'WALLET_CONSOLIDATE_OK',
  })

const updateConsolidateValue = (id) =>
  put({
    url: WALLET_CONSOLIDATE_UPDATE(id),
    typeFetch: 'WALLET_CONSOLIDATE_UPDATE',
    typeError: 'WALLET_CONSOLIDATE_UPDATE_FAIL',
    typeResponse: 'WALLET_CONSOLIDATE_UPDATE_OK',
  })

const walletTransaction = (userId, data) =>
  post({
    data,
    url: WALLET_TRANSACTION(userId),
    typeFetch: 'WALLET_TRANSACTION',
    typeError: 'WALLET_TRANSACTION_FAIL',
    typeResponse: 'WALLET_TRANSACTION_OK',
  })

export {
  getSaleById,
  consolidateValue,
  walletTransaction,
  updateConsolidateValue,
  getAllSalesConsolidated,
}

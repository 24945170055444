import React from 'react'

import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  DatabaseOutlined,
  GroupOutlined,
} from '@ant-design/icons'
import { Row, Space, Button } from 'antd'
import t from 'prop-types'

const HeaderActions = ({
  start,
  selectedRowKeys,
  loading,
  openModalSchedule,
  archiveMissions,
  openModalMissionGrouping,
}) => (
  <Row justify="space-between">
    <div>
      {selectedRowKeys.length > 0 && (
        <Space>
          <Button
            icon={<CheckOutlined />}
            shape="round"
            type="primary"
            onClick={() => start(true)}
            loading={loading}
            disabled={loading}
          >
            Ativar
          </Button>
          <Button
            icon={<CloseOutlined />}
            shape="round"
            type="danger"
            onClick={() => start(false)}
            loading={loading}
            disabled={loading}
          >
            Inativar
          </Button>
          <Button
            icon={<CalendarOutlined />}
            shape="round"
            color="orange"
            loading={loading}
            disabled={loading}
            onClick={() => openModalSchedule()}
          >
            Agendar
          </Button>
          <Button
            icon={<DatabaseOutlined />}
            shape="round"
            type="ghost"
            loading={loading}
            disabled={loading}
            onClick={() => archiveMissions()}
          >
            Arquivar
          </Button>
          <Button
            icon={<GroupOutlined />}
            shape="round"
            type="ghost"
            loading={loading}
            disabled={loading}
            onClick={() => openModalMissionGrouping()}
          >
            Agrupar
          </Button>
        </Space>
      )}
      <span style={{ marginLeft: 8 }}>
        {selectedRowKeys.length > 0 &&
          `Itens selecionados ${selectedRowKeys.length}`}
      </span>
    </div>
  </Row>
)

HeaderActions.propTypes = {
  start: t.func.isRequired,
  selectedRowKeys: t.func.isRequired,
  loading: t.bool.isRequired,
  openModalSchedule: t.func.isRequired,
  archiveMissions: t.func.isRequired,
  openModalMissionGrouping: t.func.isRequired,
}

export default HeaderActions

/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Form, Modal, Empty } from 'antd'
import { isNil } from 'ramda'

import Images from '~/assets/images'
import ListCards from '~/components/organisms/ListCards'
import { useAuth } from '~/contexts/auth'
import useProductCustomers from '~/hooks/useProductsCustomers'

import Header from './components/Header'
import ProductForm from './components/ProductForm'

const { confirm } = Modal

function Products() {
  const navigate = useNavigate()
  const { data } = useAuth()
  const [form] = Form.useForm()

  const id = data?.clientId

  const [product, setProduct] = useState({})
  const [open, setOpen] = useState({ visible: false, type: null })

  const {
    getProducts,
    productsCustomers,
    createProduct,
    loadingProductsCustomers,
    itemsList,
    updateProduct,
    removeProduct,
    countProducts,
  } = useProductCustomers(id)

  function handleSubmit(values, type) {
    const productData = { ...product, ...values }

    delete productData.total_rows
    delete productData.content

    if (productData.has_spotlight && isNil(productData.description_spotlight)) {
      return message.error('Descrição do destaque não pode estar vazia!')
    }

    setOpen({ ...open, visible: false })

    if (type === 'update') {
      return updateProduct(productData)
    }

    return createProduct(productData)
  }

  function remove(productCategory) {
    confirm({
      title: 'Alerta',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja remover essa categoria?',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        removeProduct(productCategory.uid)
      },
    })
  }

  function handlePagination() {
    getProducts()
  }

  function handleDetail(item, type) {
    form.setFieldsValue(item)
    setProduct({ ...product, ...item })
    setOpen({ visible: true, type })
  }

  useEffect(() => {
    getProducts()
  }, []) //eslint-disable-line

  return (
    <>
      <Header
        openForm={() => {
          form.resetFields()
          setProduct({})
          setOpen({ visible: true, type: 'create' })
        }}
      />

      {!loadingProductsCustomers && productsCustomers.length === 0 && (
        <Empty
          image={Images.Products}
          description="Nenhuma categoria de produto até o momento..."
        />
      )}

      <ListCards
        hasEdit
        hasDetail
        hasRemove
        data={itemsList}
        count={countProducts}
        loading={loadingProductsCustomers}
        onClickEdit={handleDetail}
        onClickRemove={remove}
        handlePagination={handlePagination}
        onClickDetail={(productId) => navigate(`/products/${productId}`)}
      />

      <ProductForm
        form={form}
        item={product}
        loading={loadingProductsCustomers}
        open={open}
        handleSubmit={handleSubmit}
        close={() => {
          setProduct({})
          form.resetFields([
            'title',
            'description',
            'has_spotlight',
            'description_spotlight',
          ])
          setOpen({ ...open, visible: false })
        }}
      />
    </>
  )
}

export default Products

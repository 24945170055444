import React from 'react'
import ReactMapGl from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import t from 'prop-types'

import { MAPBOX_TOKEN } from '~/config/env'

import CurrentMarker from './CurrentMarker'
import Item from './Item'

const Feeds = ({
  children,
  markers,
  viewport,
  onChange,
  selected,
  currentMarker,
}) => (
  <AutoSizer>
    {({ width }) => (
      <ReactMapGl
        viewState={viewport}
        scrollZoom={false}
        doubleClickZoom={false}
        maxZoom={12}
        touchRotate={false}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onViewportChange={onChange}
        mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
        width={width}
        height={540}
      >
        {currentMarker && (
          <CurrentMarker
            lat={currentMarker.lat}
            lng={currentMarker.lng}
            place={currentMarker.place}
          />
        )}

        {markers?.map((i, index) => (
          <Item
            item={i}
            index={index}
            selected={selected}
            key={String(`m-${index}`)}
          />
        ))}
        {children}
      </ReactMapGl>
    )}
  </AutoSizer>
)

Feeds.propTypes = {
  children: t.node,
  onChange: t.func.isRequired,
  selected: t.number.isRequired,
  markers: t.arrayOf(t.any).isRequired,
  currentMarker: t.objectOf(t.any),
  viewport: t.objectOf(t.any).isRequired,
}

export default Feeds

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'
import {
  PRODUCTS_MISSIONS,
  PRODUCTS_MISSIONS_CREATE,
  PRODUCTS_MISSIONS_REMOVE,
} from '~/services/api/endpoints'

function useProductsMissions(missionId) {
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [products, setProducts] = useState([])

  const getProductsMissions = useCallback(async () => {
    try {
      setLoadingProducts(true)
      const res = await api.get(PRODUCTS_MISSIONS(missionId), {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProducts(false)
      return setProducts(res.data)
    } catch (error) {
      setLoadingProducts(false)
      return message.error(error.message)
    }
  }, [missionId])

  async function onCreate(data) {
    try {
      setLoadingProducts(true)
      const res = await api.post(PRODUCTS_MISSIONS_CREATE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProducts(false)
      getProductsMissions()
      return message.success('Categoria de Produto foi vinculado com sucesso!')
    } catch (error) {
      setLoadingProducts(false)
      return message.error(error.message)
    }
  }

  async function onRemove(item) {
    try {
      setLoadingProducts(true)
      const res = await api.delete(PRODUCTS_MISSIONS_REMOVE(item.uid), {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProducts(false)
      getProductsMissions()
      return message.success(
        'Categoria de Produto foi desvinculado com sucesso!'
      )
    } catch (error) {
      setLoadingProducts(false)
      return message.error(error.message)
    }
  }

  return {
    products,
    onCreate,
    onRemove,
    loadingProducts,
    getProductsMissions,
  }
}

export default useProductsMissions

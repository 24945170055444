export default {
  paddingBottom: {
    paddingBottom: 80,
  },
  marginRight: {
    marginRight: 8,
  },
  textAlign: {
    textAlign: 'right',
  },
  block: {
    width: '100%',
  },
  descHeight: {
    maxHeight: 250,
  },
}

/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import { FunnelPlotOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Popover, Button, Alert } from 'antd'

import Table from '~/components/organisms/Table'
import { useAuth } from '~/contexts/auth'
import useReports from '~/hooks/useReports'

import ExportForm from './components/ExportForm'
import ZipLinkModal from './components/ZipLinkModal'
import { columns } from './helpers'

export default function Report() {
  const { data } = useAuth()
  const {
    getAll,
    reports,
    loadingReports,
    pagination,
    removeReport: removeOneReport,
    generateZipSignedUrls,
    reportSelected,
    setReportSelected,
  } = useReports()

  const [visible, setVisible] = useState(false)
  const [query, setQuery] = useState({ limit: 15, offset: 0 })
  const [isOpenZipLinkModal, setIsOpenZipLinkModal] = useState()

  const customerId = data?.clientId

  function handlerUserReceipt(url) {
    return window.open(url, '_self')
  }

  async function removeReport(item) {
    await removeOneReport(item)
    getAll(query)
  }

  function handlePagination(page) {
    const pageLimit = page * query.limit
    const currentOffset = page === 1 ? 0 : pageLimit - query.limit
    setQuery({ ...query, offset: currentOffset })

    return getAll({ customerId, ...query, all: true, offset: currentOffset })
  }

  function openZipLinkModal(item) {
    setIsOpenZipLinkModal(true)
    generateZipSignedUrls(item)
  }

  function closeZipLinkModal() {
    setIsOpenZipLinkModal(false)
    setReportSelected(null)
  }

  useEffect(() => {
    getAll({ customerId, ...query })
  }, [customerId]) //eslint-disable-line

  console.log({ reports })

  return (
    <>
      <ZipLinkModal
        isOpenZipLinkModal={isOpenZipLinkModal}
        closeZipLinkModal={closeZipLinkModal}
        reportSelected={reportSelected}
      />
      <PageHeader
        extra={[
          <Popover
            key={1}
            title="Filtros"
            trigger="click"
            visible={visible}
            placement="leftTop"
            content={
              <ExportForm
                loadNewRegisters={handlePagination}
                customerId={customerId}
              />
            }
            onOpenChange={() => setVisible((prev) => !prev)}
          >
            <Button shape="round" icon={<FunnelPlotOutlined />}>
              Exportar
            </Button>
          </Popover>,
        ]}
      />
      <Table
        size="small"
        data={reports}
        loading={loadingReports}
        total={pagination.total}
        current={pagination.current}
        pageSize={pagination.pageSize}
        handlePagination={handlePagination}
        columns={columns({
          openZipLinkModal,
          handlerUserReceipt,
          removeReport,
        })}
      />

      <Alert
        message="Exportações ficaram disponível por no máximo 5 dias para Download."
        type="warning"
        showIcon
        style={{ marginTop: 20 }}
      />
    </>
  )
}

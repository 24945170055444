import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCustomersTransactions() {
  const [transactions, setTransactions] = useState(false)
  const [transactionsCount, setTransactionsCount] = useState(0)
  const [loadingTransactions, setLoadingTransactions] = useState(false)

  const getTransactions = useCallback(async (params) => {
    try {
      setLoadingTransactions(true)
      const res = await api.get('customers-transactions', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTransactionsCount(res.data.count)
      return setTransactions(res.data.transactions)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTransactions(false)
    }
  }, [])

  // REFATORAR API DEPRECATED
  async function onSubmitReport({ params, customerId }) {
    try {
      setLoadingTransactions(true)
      const res = await api.get(`customers/${customerId}/transactions/report`, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      return window.open(res.data, '_self')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTransactions(false)
    }
  }

  return {
    transactions,
    onSubmitReport,
    getTransactions,
    transactionsCount,
    loadingTransactions,
  }
}

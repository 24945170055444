import React from 'react'

import { Col, Typography, Table as TableAnt } from 'antd'
import t from 'prop-types'

const { Text } = Typography

export function Table({ chartsItems }) {
  const total = chartsItems
    ?.map((i) => i.count || i.total)
    .reduce((a, b) => a + b)

  return (
    <Col span={6}>
      <TableAnt
        size="small"
        scroll={{ y: 280 }}
        dataSource={chartsItems.map((i) => ({
          label: i?.uf || i?.answer || 'Não Localizado',
          count: i?.count || 0,
        }))}
        pagination={{ defaultPageSize: [100], position: ['none'] }}
        columns={[
          {
            title: 'Descrição',
            dataIndex: 'label',
            key: 'label',
          },
          {
            title: 'Qtd',
            dataIndex: 'count',
            key: 'count',
          },
          {
            title: '%',
            dataIndex: 'count',
            key: 'percent',
            render: (count) => `${Number(100 * (count / total)).toFixed(0)}%`,
          },
        ]}
        summary={() => (
          <TableAnt.Summary.Row>
            <TableAnt.Summary.Cell>Total</TableAnt.Summary.Cell>
            <TableAnt.Summary.Cell>
              <Text type="success">{total}</Text>
            </TableAnt.Summary.Cell>
            <TableAnt.Summary.Cell>
              <Text type="success">100%</Text>
            </TableAnt.Summary.Cell>
          </TableAnt.Summary.Row>
        )}
      />
    </Col>
  )
}

Table.propTypes = {
  chartsItems: t.arrayOf(),
  total: t.number,
}

import React, { useMemo, useRef, useState } from 'react'

import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import t from 'prop-types'

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current

      setOptions([])
      setFetching(true)

      const newOptions = await fetchOptions(value)

      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }

      setOptions(newOptions)
      setFetching(false)
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      {...props}
      labelInValue
      options={options}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
    />
  )
}

DebounceSelect.propTypes = {
  fetchOptions: t.arrayOf(t.objectOf(t.any)),
  debounceTimeout: t.func,
}

export { DebounceSelect }

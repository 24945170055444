import React from 'react'

import { Modal } from 'antd'
import t from 'prop-types'

import { StyledBox, Title, SubTitle, Space, HeaderBox } from './style'

const ModalMobile = ({ open, setOpen }) => (
  <>
    <Modal
      footer={false}
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
      bodyStyle={{
        backgroundImage:
          'url(https://www.missionbrasil.com.br/wp-content/uploads/2021/01/Passo-03-1.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50vw 15vh',
      }}
      okText="Vamos para o cadastro!"
    >
      <HeaderBox>
        <Title>MISSION BRASIL</Title>
        <SubTitle>REALIZE MISSÕES POR TODO O BRASIL!</SubTitle>
        <Title>GANHE DINHEIRO!</Title>
        <Space />
      </HeaderBox>
      <StyledBox>Torne-se Missionário</StyledBox>
      <StyledBox>Realize Missões</StyledBox>
      <StyledBox>GANHE DINHEIRO!</StyledBox>
      <StyledBox onClick={() => setOpen(false)}>CADASTRE-SE AGORA!</StyledBox>
    </Modal>
  </>
)

ModalMobile.propTypes = {
  open: t.bool,
  setOpen: t.func,
}

export default ModalMobile

import React, { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Col, Space, Row, Input, Button } from 'antd'
import t from 'prop-types'

export default function SelectItem({ items, removeItem, onChangeSelectItem }) {
  const [selectValue, setSelectValue] = useState('')

  function handleOnPressEnter(event) {
    onChangeSelectItem(event, 'selectItem')
    setSelectValue('')
  }

  return (
    <Space direction="vertical">
      <Row gutter={16} title="Adicionar itens">
        <Col span={24}>
          <Input
            onPressEnter={(event) => handleOnPressEnter(event)}
            placeholder="Adicionar respostas...(Pressione ENTER para Salvar)"
            onChange={({ target }) => setSelectValue(target.value)}
            value={selectValue}
          />
        </Col>
      </Row>
      {items.map((item, index) => (
        <Row gutter={16} key={Number(index)}>
          <Col span={24}>
            <Input
              value={item.title}
              addonAfter={
                <Button
                  block
                  shape="round"
                  icon={<DeleteOutlined />}
                  onClick={() => removeItem(index)}
                />
              }
            />
          </Col>
        </Row>
      ))}
    </Space>
  )
}

SelectItem.propTypes = {
  items: t.arrayOf(t.objectOf(t.any)).isRequired,
  removeItem: t.func.isRequired,
  onChangeSelectItem: t.func.isRequired,
}

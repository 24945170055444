export default {
  container: {
    height: 114,
    minWidth: 120,
  },
  pageContainer: {
    padding: 0,
    marginBottom: 10,
  },
}

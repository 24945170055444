import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`

export const ContainerBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

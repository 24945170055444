import styled from 'styled-components'

export const PointsSpan = styled.span`
  font-size: 10px;
  color: gray;
  text-align: left;
`
export const CardStyle = {
  textAlign: 'center',
  maxWidth: 150,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}

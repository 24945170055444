import React from 'react'

import { Modal, Transfer } from 'antd'
import t from 'prop-types'

const Opcional = ({ items, cancel, onChange, visible, targetKeys }) => {
  const values = items?.map((i) => ({
    key: String(i.uid),
    title: `${i.order} - ${i.question}`,
    chosen: false,
  }))

  return (
    <Modal
      open={visible}
      okText="Ok"
      onOk={cancel}
      onCancel={cancel}
      title="Opcionais"
      cancelText="Cancelar"
      style={{ zIndex: 999999999 }}
    >
      <p>
        Selecione as perguntas que devem estar escondidas ao iniciar o
        formulário, caso o usuário escolha está resposta as perguntas abaixo
        aparecerão.
      </p>

      <Transfer
        onChange={onChange}
        dataSource={values}
        targetKeys={targetKeys}
        render={(i) => <span>{i.title}</span>}
      />
    </Modal>
  )
}

Opcional.defaultTypes = {
  visible: false,
}

Opcional.propTypes = {
  items: t.arrayOf(t.objectOf(t.string)).isRequired,
  cancel: t.func.isRequired,
  onChange: t.func.isRequired,
  visible: t.bool.isRequired,
  targetKeys: t.arrayOf(t.string).isRequired,
}

export default Opcional

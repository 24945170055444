import React, { useCallback, useEffect, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { message, Spin, Table } from 'antd'

import api from '~/services/api'

import { columns } from './components/columns'
import { HeaderActions } from './components/HeaderActions'

export function StoresRequests() {
  const [requests, setRequests] = useState(null)
  const [loadingRequests, setLoadingRequests] = useState(false)

  const getRequests = useCallback(async () => {
    setLoadingRequests(true)

    try {
      const res = await api.get(`workforce-management-store/request`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRequests(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRequests(false)
    }
  }, [])

  useEffect(() => {
    getRequests()
  }, [getRequests])

  return (
    <div style={{ marginTop: '10px' }}>
      <HeaderActions />
      {loadingRequests ? (
        <Spin
          style={{ marginTop: '10px' }}
          indicator={<LoadingOutlined spin />}
        />
      ) : (
        <div style={{ borderTop: 'solid 1px #ddd', marginTop: '20px' }}>
          <Table columns={columns} dataSource={requests} />
        </div>
      )}
    </div>
  )
}

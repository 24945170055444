import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useMissionsFeeds() {
  const [loadingFeeds, setLoadingFeeds] = useState(false)
  const [loadingCurrentFeeds, setLoadingCurrentFeeds] = useState(false)
  const [missionsFeeds, setMissionsFeeds] = useState([])
  const [currentMissionsFeeds, setCurrentMissionsFeeds] = useState([])

  const getMissionsFeedsByMissionsUsersId = useCallback(
    async (missionsUsersId) => {
      setLoadingFeeds(true)
      try {
        const res = await api.get(
          `missions-feeds/customers/${missionsUsersId}`,
          {
            validateStatus() {
              return true
            },
          }
        )

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        return setMissionsFeeds(res.data)
      } catch (error) {
        return message.error(error.message)
      } finally {
        setLoadingFeeds(false)
      }
    },
    []
  )

  const getMissionsFeeds = useCallback(async (params) => {
    try {
      setLoadingCurrentFeeds(true)
      const res = await api.get('missions-feeds/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCurrentMissionsFeeds(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCurrentFeeds(false)
    }
  }, [])

  return {
    loadingFeeds,
    missionsFeeds,
    currentMissionsFeeds,
    loadingCurrentFeeds,
    getMissionsFeeds,
    getMissionsFeedsByMissionsUsersId,
  }
}

export default useMissionsFeeds

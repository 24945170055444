import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogoutOutlined } from '@ant-design/icons'
import { Menu, Skeleton } from 'antd'
import t from 'prop-types'

import { useAuth } from '~/contexts/auth'
import { items } from '~/utils/routesAuthenticated'

function SideBar({ permissions }) {
  const navigate = useNavigate()
  const [loadingBar, setLoadingBar] = useState(true)
  const { signOut, data, loadingUser } = useAuth()

  let userPermissions

  if (permissions) {
    userPermissions = permissions
  }

  const routesPermissions = data?.admin
    ? items
    : items.filter((i) => userPermissions?.includes(i.validation))

  const [selectedKey, setSelectedKey] = useState(null)

  const menuItemsRender = routesPermissions.map((navItem, index) => {
    if (navItem.validation === 'private_accounts' && !data.is_private) {
      routesPermissions.splice(index, 1)
    }

    return navItem
  })

  function onClick(item) {
    if (item.key === 'signout') {
      return signOut()
    }

    if (item.key.includes('sub')) {
      const findChildren = items.find(
        (i) => i.key === item.keyPath[item.keyPath.length - 1]
      )

      const findItem = findChildren.children.find((i) => i.key === item.key)

      setSelectedKey(item.key)

      return navigate(findItem.path)
    }

    const find = menuItemsRender.find((i) => i.key === item.key)

    setSelectedKey(item.key)

    return navigate(find.path)
  }

  useEffect(() => {
    setSelectedKey(selectedKey || 0)
  }, [selectedKey])

  useEffect(() => {
    const sub = setTimeout(() => {
      setLoadingBar(false)
    }, 3000)

    return () => sub
  }, [loadingBar, data])

  if (loadingBar) {
    return (
      <div style={{ padding: 20 }}>
        <Skeleton style={{ width: 150, color: 'white' }} />
      </div>
    )
  }

  return (
    !loadingUser &&
    selectedKey !== null && (
      <Menu
        theme="dark"
        mode="inline"
        onSelect={(e) => onClick(e)}
        defaultSelectedKeys={[selectedKey || '1']}
        items={[
          ...menuItemsRender,
          { key: 'signout', label: 'Sair', icon: <LogoutOutlined /> },
        ]}
      />
    )
  )
}

SideBar.propTypes = {
  permissions: t.arrayOf(t.string),
}

export default SideBar

import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useImport() {
  const [loadingImport, setLoading] = useState(false)

  async function onSubmitImport({ rows, filename }) {
    try {
      setLoading(true)
      let lines = []
      let importId = null

      for (let index = 0; index < rows.length; index += 1) {
        lines.push({ ...rows[index], line: index })

        if (lines.length === 1000) {
          const response = api.post('/import-files/customers', {
            name: filename,
            importId,
            lines,
            totalLines: rows.length,
          })

          importId = response.data.importId
          lines = []
        }
      }

      if (lines.length > 0) {
        await api.post('/import-files/customers', {
          name: filename,
          importId,
          lines,
          totalLines: rows.length,
        })
      }
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loadingImport,
    onSubmitImport,
  }
}

export default useImport

import React from 'react'

import { Button, Result } from 'antd'

const Success = () => (
  <Result
    status="success"
    title="Cadastro realizado com sucesso!"
    subTitle="Acesse o nosso App"
    extra={[
      <Button
        type="primary"
        key="console"
        onClick={() =>
          window.open(
            'https://apps.apple.com/us/app/mission-brasil/id1200317448',
            '_self'
          )
        }
      >
        Apple Store
      </Button>,
      <Button
        key="buy"
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=br.com.missionbrasil',
            '_self'
          )
        }
      >
        Google Play
      </Button>,
    ]}
  />
)

export default Success

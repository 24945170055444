import React from 'react'

import { Form, Button, Input, Card } from 'antd'
import t from 'prop-types'

const Password = ({ form, loading, handleSubmit, handleFailed }) => (
  <Card title="Atualizar Senha">
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleFailed}
    >
      <Form.Item
        name="password"
        label="Nova Senha"
        rules={[
          {
            required: true,
            message: 'Digite seu senha por favor!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirmar Senha"
        rules={[
          {
            required: true,
            message: 'Digite sua senha por favor!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }

              return Promise.reject(new Error('As senhas devem estar iguais!'))
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Atualizar
        </Button>
      </Form.Item>
    </Form>
  </Card>
)

Password.defaultTypes = {
  loading: false,
}

Password.propTypes = {
  form: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
  handleFailed: t.func.isRequired,
}

export default Password

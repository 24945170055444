export const styles = {
  center: {
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 8,
  },
  pageHeader: {
    padding: 0,
    marginBottom: 10,
  },
}

import React, { useState, useEffect } from 'react'

import {
  Input,
  Checkbox,
  Form,
  Drawer,
  Skeleton,
  Button,
  DatePicker,
  TimePicker,
  Row,
} from 'antd'
import t from 'prop-types'

const Detail = ({ form, item, open, close, loading, handleSubmit }) => {
  const [daysOfTheWeek, setDaysOfTheWeek] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  })

  function handleChangeDOTW(e) {
    setDaysOfTheWeek((dotw) => ({
      ...dotw,
      [e.target.value]: e.target.checked,
    }))
  }

  useEffect(() => {
    if (item && item.days) {
      const dotw = {}
      item.days.forEach((day) => {
        dotw[day] = true
      })
      setDaysOfTheWeek((oldDotw) => ({ ...oldDotw, ...dotw }))
    }
  }, [item])

  return (
    <Drawer
      width={720}
      onClose={close}
      destroyOnClose="true"
      visible={open.visible}
      title={item?.title || 'Novo Agendamento'}
    >
      <Skeleton loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={item}
          onFinish={(values) => handleSubmit(values, open.type)}
        >
          <Form.Item label="Título" name="title" required>
            <Input />
          </Form.Item>

          <Form.Item label="Descrição" name="description">
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="days" label="Dias da semana" required>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Checkbox
                  value="monday"
                  checked={daysOfTheWeek.monday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Segunda-feira
                </Checkbox>
                {daysOfTheWeek.monday && (
                  <Form.Item name="mondayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="tuesday"
                  checked={daysOfTheWeek.tuesday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Terça-feira
                </Checkbox>
                {daysOfTheWeek.tuesday && (
                  <Form.Item name="tuesdayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="wednesday"
                  checked={daysOfTheWeek.wednesday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Quarta-feira
                </Checkbox>
                {daysOfTheWeek.wednesday && (
                  <Form.Item name="wednesdayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="thursday"
                  checked={daysOfTheWeek.thursday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Quinta-feira
                </Checkbox>
                {daysOfTheWeek.thursday && (
                  <Form.Item name="thursdayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="friday"
                  checked={daysOfTheWeek.friday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Sexta-feira
                </Checkbox>
                {daysOfTheWeek.friday && (
                  <Form.Item name="fridayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="saturday"
                  checked={daysOfTheWeek.saturday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Sábado
                </Checkbox>
                {daysOfTheWeek.saturday && (
                  <Form.Item name="saturdayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
              <Row>
                <Checkbox
                  value="sunday"
                  checked={daysOfTheWeek.sunday}
                  onChange={handleChangeDOTW}
                  style={{ lineHeight: '32px' }}
                >
                  Domingo
                </Checkbox>
                {daysOfTheWeek.sunday && (
                  <Form.Item name="sundayPeriod">
                    <TimePicker.RangePicker />
                  </Form.Item>
                )}
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name="period" label="Período do agendamento">
            <DatePicker.RangePicker showTime />
          </Form.Item>

          <Form.Item>
            <Button shape="round" type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  )
}

Detail.defaultTypes = {
  loading: false,
}

Detail.propTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  open: t.bool.isRequired,
  close: t.func.isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
}

export default Detail

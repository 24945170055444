import React from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Select, Row, Col } from 'antd'
import t from 'prop-types'

import Count from '../Count'

const { Option } = Select

const styles = {
  selectStyle: {
    minWidth: 180,
  },
  pageStyle: {
    padding: 0,
    marginTop: 6,
    marginBottom: 10,
  },
}

const filters = [
  {
    value: 1,
    description: 'Hoje',
  },
  {
    value: 7,
    description: 'Últimos 7 dias',
  },
  {
    value: 15,
    description: 'Últimos 15 dias',
  },
  {
    value: 30,
    description: 'Últimos 30 dias',
  },
  {
    value: 90,
    description: 'Últimos 90 dias',
  },
  {
    value: 180,
    description: 'Últimos 180 dias',
  },
  {
    value: null,
    description: 'Período Customizado',
  },
]

export default function Header({ onChange, counts, loading }) {
  return (
    <PageHeader style={styles.pageStyle}>
      <Row style={{ marginBottom: 10 }}>
        <Col key="c2">
          <Select
            loading={loading}
            onChange={onChange}
            style={styles.selectStyle}
            placeholder="Selecione o Período"
          >
            {filters.map((i) => (
              <Option value={i.value} key="1">
                {i.description}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row justify="space-between" align="bottom">
        <Col key="c1">
          <Count statistic={counts} loading={loading} />
        </Col>
      </Row>
    </PageHeader>
  )
}

Header.defaultProps = {
  counts: 0,
  loading: false,
}

Header.propTypes = {
  counts: t.number,
  loading: t.bool,
  onChange: t.func.isRequired,
}

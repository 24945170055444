import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  FileExcelOutlined,
  SearchOutlined,
  ShoppingOutlined,
} from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import t from 'prop-types'

import useGeocode from '~/hooks/useGeocode'

const { Option } = Select
const { RangePicker } = DatePicker

function Filter({
  form,
  onSubmit,
  activeMissions,
  categories,
  onExportReport,
}) {
  const [showCustom, setShowCustom] = useState(null)
  const { ufsOptions, onChangeUFs, citiesOptions } = useGeocode()
  const navigate = useNavigate()

  const periodOptions = [
    { text: '1 dia', value: 1 },
    { text: '7 dias', value: 7 },
    { text: '15 dias', value: 15 },
    { text: '30 dias', value: 30 },
    { text: 'Custom', value: 'custom' },
  ]

  function handleChangePeriod(value) {
    if (value === 'custom') {
      return setShowCustom(true)
    }

    return setShowCustom(false)
  }

  function handleClickReset() {
    return setShowCustom(false)
  }

  const BaseCol = ({ children }) => (
    <Col xs={24} xl={4} sm={12} md={6}>
      {children}
    </Col>
  )

  BaseCol.propTypes = {
    children: t.element,
  }

  return (
    <Card id="filter">
      <Form onFinish={onSubmit} form={form}>
        <Row gutter={[8, 8]}>
          <BaseCol>
            <Form.Item name="missionId">
              <Select
                placeholder="Missão"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  )
                }
                options={activeMissions?.map((mission) => ({
                  value: mission.uid,
                  label: mission.name,
                }))}
              />
            </Form.Item>
          </BaseCol>

          <BaseCol>
            <Form.Item name="search">
              <Input
                placeholder="Identificador ou MB Key"
                suffix={<SearchOutlined />}
              />
            </Form.Item>
          </BaseCol>
          <BaseCol>
            <Form.Item name="uf">
              <Select
                placeholder="Estados"
                mode="multiple"
                onChange={(values) => onChangeUFs(values)}
              >
                {ufsOptions?.map((state) => (
                  <Option key={state.value} value={state.value}>
                    {state.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </BaseCol>
          <BaseCol>
            <Form.Item name="cities">
              <Select
                placeholder="Cidades"
                disabled={citiesOptions?.length === 0}
                mode="multiple"
              >
                {citiesOptions?.map((c) => (
                  <Option key={c.value} value={c.value}>
                    {c.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </BaseCol>

          <BaseCol>
            <Form.Item name="missionsMainId">
              <Select placeholder="Categoria" disabled={!categories?.length}>
                {categories?.map((categorie) => (
                  <Option
                    key={`${categorie?.id}`}
                    value={categorie?.mission_main_id}
                  >
                    {categorie?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </BaseCol>

          <BaseCol>
            <Form.Item name="period">
              <Select
                placeholder="Periodo"
                defaultValue={7}
                onChange={(value) => handleChangePeriod(value)}
              >
                {periodOptions.map((period) => (
                  <Option key={period.value} value={period.value}>
                    {period.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </BaseCol>

          {showCustom && (
            <BaseCol>
              <Form.Item name="customDate">
                <RangePicker />
              </Form.Item>
            </BaseCol>
          )}
        </Row>

        <Form.Item style={{ marginTop: 8 }}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Filtrar
          </Button>
          <Button
            htmlType="reset"
            style={{ marginRight: 8 }}
            onClick={() => handleClickReset()}
          >
            Resetar Filtro
          </Button>
          <Button
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => navigate('/workforce/managament/stores')}
          >
            <ShoppingOutlined />
            Gerenciar Demandas
          </Button>
          <Button
            htmlType="export"
            style={{ marginRight: 8 }}
            onClick={() => onExportReport()}
          >
            <FileExcelOutlined />
            Exportar relatório
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

Filter.propTypes = {
  onSubmit: t.func,
  states: t.arrayOf(t.objectOf(t.string)),
  cities: t.arrayOf(t.objectOf(t.string)),
  categories: t.arrayOf(t.objectOf(t.any)),
  activeMissions: t.arrayOf(t.objectOf(t.string)),
  onSelectState: t.func,
  form: t.objectOf({ submit: t.func }),
  onExportReport: t.func,
}

export default Filter

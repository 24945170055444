import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCustomersNotifications() {
  const [loading, setLoading] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [customersNotifications, setCustomersNotifications] = useState([])
  const [customersNotificationsType, setCustomersNotificationsType] = useState(
    []
  )
  const [senders, setSenders] = useState([])

  async function getCustomersNotifications() {
    try {
      setLoading(true)

      const res = await api.get('customers-notifications', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setSenders(
        res.data.customersNotifications.map((notifications) => {
          const sendersList =
            notifications.senders.length > 0
              ? notifications.senders.split(',')
              : []

          return sendersList.map((email) => ({
            notification_type: notifications.notification_type,
            value: email,
            label: email,
          }))
        })
      )

      setCustomersNotificationsType(res.data.customersNotificationsType)
      return setCustomersNotifications(res.data.customersNotifications)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onCreateCustomersNotifications(params) {
    try {
      setLoading(true)

      const res = await api.post('customers-notifications', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success(
        `Notificações ${params?.status ? 'Ativas' : 'Inativas'}`
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onUpdateCustomersNotifications(params) {
    try {
      setLoadingUpdate(true)

      const res = await api.put('customers-notifications', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return true
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingUpdate(false)
    }
  }

  return {
    getCustomersNotifications,
    loading,
    loadingUpdate,
    customersNotifications,
    setCustomersNotifications,
    customersNotificationsType,
    onUpdateCustomersNotifications,
    onCreateCustomersNotifications,
    senders,
    setSenders,
  }
}

import React, { useState } from 'react'

import { DownloadOutlined, FileSearchOutlined } from '@ant-design/icons'
import { List, Skeleton, Tooltip, Button } from 'antd'
import t from 'prop-types'

import { PreviewModal } from './PreviewModal'

export function PDF({ files, loadMore, initLoading }) {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [selectedPDF, setSelectedPDF] = useState(null)

  const handleCancel = () => {
    setIsPreviewModalOpen(false)
  }

  const handleOpenPreview = (file) => {
    setSelectedPDF(file)
    setIsPreviewModalOpen(true)
  }

  return (
    <>
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={files}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Tooltip title="Preview">
                <Button
                  shape="round"
                  icon={<FileSearchOutlined />}
                  onClick={() => handleOpenPreview(item.file_path)}
                />
              </Tooltip>,
              <Tooltip title="Baixar">
                <Button
                  shape="round"
                  icon={<DownloadOutlined />}
                  onClick={() => window.open(item.file_path, '_self')}
                />
              </Tooltip>,
            ]}
          >
            <Skeleton avatar title={false} loading={initLoading} active>
              <List.Item.Meta title={item.key.split('/')[3]} />
            </Skeleton>
          </List.Item>
        )}
      />
      <PreviewModal
        open={isPreviewModalOpen}
        onCancel={handleCancel}
        file={selectedPDF}
        setSelectedPDF={setSelectedPDF}
      />
    </>
  )
}

PDF.defaultTypes = {
  loadMore: false,
  initLoading: false,
}

PDF.propTypes = {
  files: t.arrayOf(t.any).isRequired,
  loadMore: t.bool.isRequired,
  initLoading: t.bool.isRequired,
}

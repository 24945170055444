import React from 'react'

import { UsergroupAddOutlined, SketchOutlined } from '@ant-design/icons'
import { Col, Card, Row, Statistic } from 'antd'
import t from 'prop-types'

const Statistics = ({ totalUsers, totalPoints }) => {
  return (
    <Row gutter={8}>
      <Col span={4}>
        <Card>
          <Statistic
            value={totalUsers || 0}
            title="Total Usuário(s)"
            prefix={<UsergroupAddOutlined />}
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card>
          <Statistic
            title="Total Ponto(s)"
            value={totalPoints || 0}
            prefix={<SketchOutlined />}
          />
        </Card>
      </Col>
    </Row>
  )
}

Statistics.propTypes = {
  totalUsers: t.number,
  totalPoints: t.number,
}

export { Statistics }

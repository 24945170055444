// NAO MIGRADO ARQUIVOS ANEXOS PDF / PHOTOS
import { useState, useCallback } from 'react'

import api from '~/services/api'

export default function useLogs() {
  const [logs, setLogs] = useState([])
  const [loadingLogs, setLoading] = useState(true)

  const getLogsById = useCallback(async (registerId) => {
    try {
      setLoading(true)
      const res = await api.get(`audit-logs/${registerId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLogs(res.data)
    } catch (error) {
      return setLogs([])
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    logs,
    getLogsById,
    loadingLogs,
  }
}

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons'
import { Input, Button, Popover, Form, Upload, Image } from 'antd'
import t from 'prop-types'

import { ContainerImage } from './styles'

const { TextArea } = Input

const ContentFormExample = ({
  value,
  exampleData,
  onChange,
  onChangeUploadImage,
  preview,
}) => {
  return (
    <Form layout="vertical">
      <Form.Item label="Imagem">
        <ContainerImage>
          <Image
            width={100}
            height={100}
            style={{ marginBottom: 10 }}
            fallback="https://via.placeholder.com/100"
            src={
              exampleData?.example?.image ||
              preview ||
              'https://via.placeholder.com/100'
            }
          />

          <Upload
            showUploadList={false}
            style={{ marginTop: 10 }}
            accept=".png, .jpg, .jpeg"
            onChange={({ file }) =>
              onChangeUploadImage({ formsItemsId: value.uid, file })
            }
          >
            <Button style={{ marginTop: 10 }} icon={<UploadOutlined />}>
              Upload
            </Button>
          </Upload>
        </ContainerImage>
      </Form.Item>
      <Form.Item label="Título">
        <Input
          type="text"
          value={exampleData?.example?.title}
          onChange={({ target }) => onChange('title', target.value)}
        />
      </Form.Item>
      <Form.Item label="Descrição">
        <TextArea
          defaultValue={value?.example?.description}
          value={exampleData?.example?.description}
          onChange={({ target }) => onChange('description', target.value)}
        />
      </Form.Item>
    </Form>
  )
}

ContentFormExample.propTypes = {
  onChange: t.func.isRequired,
  value: t.objectOf(t.string).isRequired,
  exampleData: t.objectOf(t.string),
  onChangeUploadImage: t.func,
  preview: t.string,
}

const FormExample = ({
  value,
  onSubmitExample,
  onChangeUploadImage,
  onChangeStatusButton,
  preview,
}) => {
  const [exampleData, setExampleData] = useState({})

  const handleOnChange = (key, exampleValue) => {
    setExampleData((prevState) => ({
      example: {
        ...prevState.example,
        [key]: exampleValue,
      },
    }))
  }

  const handleOnOpenChange = (visible) => {
    if (!visible) {
      if (Object.keys(exampleData).length > 0) {
        const formsItemsId = value?.uid
        onSubmitExample({ formsItemsId, ...exampleData })
      }
      onChangeStatusButton(false)
    } else {
      onChangeStatusButton(true)
    }
  }

  useEffect(() => {
    if (Object.keys(value).length > 0) {
      setExampleData({ example: { ...value?.example } })
    }
  }, []) // eslint-disable-line

  return (
    <Popover
      trigger="click"
      placement="left"
      title="Descreva um exemplo."
      content={
        <ContentFormExample
          value={value}
          onChange={handleOnChange}
          exampleData={exampleData}
          onChangeUploadImage={onChangeUploadImage}
          preview={preview}
        />
      }
      onOpenChange={handleOnOpenChange}
    >
      <Button
        type="primary"
        shape="round"
        icon={<InfoCircleFilled />}
        style={{ marginLeft: 5 }}
      >
        Adicionar Exemplo
      </Button>
    </Popover>
  )
}

FormExample.defaultTypes = {
  value: {},
}

FormExample.propTypes = {
  value: t.objectOf(t.string).isRequired,
  onSubmitExample: t.func,
  onChangeUploadImage: t.func,
  onChangeStatusButton: t.bool,
  preview: t.string,
}

export default FormExample

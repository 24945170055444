import React, { useEffect } from 'react'

import { Form, Select } from 'antd'
import t from 'prop-types'

import useMissionsGroups from '~/hooks/useMissionsGroups'

export function Groups({ onChange }) {
  const { getMissionsGroups, groups } = useMissionsGroups()
  const params = new URLSearchParams(document.location.search)
  const groupId = params.get('group')

  useEffect(() => {
    getMissionsGroups()
  }, []) //eslint-disable-line

  return (
    <Form.Item name="groups">
      <Select
        placeholder="Grupos"
        defaultValue={groupId || null}
        onChange={(values) => {
          if (values) {
            return onChange('group', values)
          }

          return onChange('group', '')
        }}
      >
        {groups?.map((group) => (
          <Select.Option key={String(group.uid)} value={group.uid}>
            {group.description}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

Groups.propTypes = {
  onChange: t.func,
}

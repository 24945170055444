import React, { useEffect, useState } from 'react'

import { ControlOutlined } from '@ant-design/icons'
import { Space, Dropdown, Button } from 'antd'
import t from 'prop-types'

import useCustomStatus from '~/hooks/useCustomStatus'

import { RegisterPropTypes } from '../lib'

export function ActionButtons({ onClickCustomStatus, onClickModal, item }) {
  const [items, setItems] = useState([])

  const { getCustomStatus, customStatus, loadingCustomStatus } =
    useCustomStatus()

  function onClick(option) {
    if (option.key === 'ap') {
      return onClickModal({ currentStatus: 1 })
    }

    const findCustom = items.find((i) => i.key === Number(option.key))

    return onClickCustomStatus({
      missionsUsersId: item.missions_users_id,
      customStatusId: findCustom.value,
    })
  }

  useEffect(() => {
    getCustomStatus({ id: item.missions_main_id, status: item.status })
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingCustomStatus) {
      const newItems = customStatus.map((i, key) => ({
        key,
        value: i.uid,
        label: i.description,
      }))

      setItems([...newItems, { key: 'ap', label: 'Aprovar' }])
    }
  }, [loadingCustomStatus]) //eslint-disable-line

  return (
    <Space direction="horizontal">
      {item.has_custom_status ? (
        <Dropdown.Button
          type="primary"
          menu={{ items, onClick }}
          icon={<ControlOutlined />}
        >
          {item.custom_status_description || 'Alterar Status'}
        </Dropdown.Button>
      ) : (
        <Button
          key="1"
          type="primary"
          shape="round"
          onClick={() => onClickModal({ currentStatus: 1, currentItem: item })}
        >
          Aprovar
        </Button>
      )}
      <Button
        danger
        key="2"
        shape="round"
        onClick={() => onClickModal({ currentStatus: 4, currentItem: item })}
      >
        Recusar Missão
      </Button>
    </Space>
  )
}

ActionButtons.defaultTypes = {}

ActionButtons.propTypes = {
  onClickCustomStatus: t.func.isRequired,
  onClickModal: t.func.isRequired,
  item: t.objectOf(RegisterPropTypes).isRequired,
}

import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useFormsItemsSections() {
  const [loadingSection, setLoadingSection] = useState(false)
  const [sectionsName, setSectionsName] = useState([])

  async function getSectionsName(formsId) {
    try {
      setLoadingSection(true)
      const res = await api.get(
        'forms-items-sections',
        {
          params: {
            forms_id: formsId,
          },
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setSectionsName(res.data)
    } catch (error) {
      return message.error('Houve um erro ao buscar os nomes das seções')
    } finally {
      setLoadingSection(false)
    }
  }

  async function create(values) {
    try {
      setLoadingSection(true)

      const res = await api.post('forms-items-sections', values, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getSectionsName(values.forms_id)

      return message.success('Seção nomeada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSection(false)
    }
  }
  async function removeSectionName(values) {
    try {
      setLoadingSection(true)

      const res = await api.delete('forms-items-sections', {
        params: values,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getSectionsName(values.forms_id)

      return message.success('Seção removida com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSection(false)
    }
  }

  return {
    loadingSection,
    create,
    getSectionsName,
    sectionsName,
    removeSectionName,
  }
}

export default useFormsItemsSections

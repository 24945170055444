/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Button, Form, Input } from 'antd'
import queryString from 'query-string'

import useCustomer from '~/hooks/useCustomer'

export default function Activation() {
  const location = useLocation()
  const { onActivation, loadingCustomer } = useCustomer()

  const [values] = useState(queryString.parse(location.search))

  const [form] = Form.useForm()

  async function handleSubmit(data) {
    return onActivation({ ...data, ...values })
  }

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <div className="content-login">
      <div className="content-login-header">
        <h3>Alterar Senha</h3>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Digite seu email por favor!',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Nova Senha"
          name="password"
          rules={[
            {
              required: true,
              message: 'Digite sua senha por favor!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmar Senha"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Digite sua senha por favor!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error('As senhas devem estar iguais!')
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loadingCustomer}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

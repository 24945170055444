export const APPROVATION_BATCH_STATUS = {
  QUEUED: 'Na fila',
  PROCESSING: 'Processando',
  DONE: 'Completo',
}

export const QUEUE_APPROVATION_STATUS = {
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
}

export const OPERATION_LABEL = {
  update: 'Atualizado',
  insert: 'Criado',
  delete: 'Removido',
}

export const STATUS_COLOR = {
  0: '#fbbf24',
  1: '#84cc16',
  2: '#dc2626',
  3: '#f97316',
  4: '#0284c7',
}

export const STATUS_LABEL = {
  0: 'Aguardando',
  1: 'Aprovado',
  2: 'Reprovado',
  3: 'Analise',
  4: 'Missão Recusada para Correção',
}

export const INIT_PAGE = 1

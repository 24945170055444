import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ShoppingOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export function HeaderActions() {
  const navigate = useNavigate()

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, display: 'flex' }}>
          <Button
            htmlType="button"
            type="text"
            style={{ marginRight: 8 }}
            onClick={() => navigate('/workforce/managament/stores')}
          >
            <ShoppingOutlined />
            Minhas Lojas
          </Button>
          <Button
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => navigate('/workforce/managament/stores-requests')}
          >
            <ShoppingOutlined />
            Histórico de Demandas
          </Button>
        </div>
      </div>
    </>
  )
}

export const PENDING = 0
export const APPROVED = 1
export const REPROVED = 2
export const CORRECTION = 4

export const statusList = [
  {
    key: 0,
    value: 0,
    text: 'Aguardando Avaliação',
  },
  {
    key: 1,
    value: 1,
    text: 'Aprovado',
  },
  {
    key: 2,
    value: 2,
    text: 'Reprovado',
  },
]

export const orderList = [
  {
    key: 1,
    value: 'asc',
    text: 'Crescente',
  },
  {
    key: 2,
    value: 'desc',
    text: 'Decrescente',
  },
]

export const statusLabel = {
  0: 'Aguardando',
  1: 'Aprovado(s)',
  2: 'Reprovado(s)',
}

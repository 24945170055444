import React from 'react'
import Gallery from 'react-grid-gallery'

import { Empty, Button, Divider } from 'antd'
import t from 'prop-types'

import EmptyImage from '~/assets/images/empty-images.svg'

export function Images({ files, countImages, loadingMoreFile }) {
  if (files.length === 0) {
    return <Empty image={EmptyImage} />
  }

  return (
    <div style={{ flexDirection: 'column' }}>
      <Gallery images={files} />

      {countImages > files.length && (
        <>
          <Divider />
          <Button onClick={loadingMoreFile} shape="round">
            Carregar mais...
          </Button>
        </>
      )}
    </div>
  )
}

Images.defaultTypes = {
  countImages: 0,
}

Images.propTypes = {
  files: t.arrayOf(t.objectOf({})).isRequired,
  countImages: t.number.isRequired,
  loadingMoreFile: t.func.isRequired,
}

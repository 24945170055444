export const styles = {
  heightRow: {
    maxHeight: 580,
  },
  card: {
    flex: 1,
    height: 580,
  },
  cardList: {
    zIndex: 9999,
    height: 580,
    maxHeight: 580,
    overflowY: 'auto',
  },
  center: {
    alignItems: 'center',
  },
  colorText: (color) => ({
    color,
  }),
}

import { useState, useCallback } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useAttachments() {
  const [files, setFiles] = useState([])
  const [photos, setPhotos] = useState([])
  const [loadingFile, setLoadingFile] = useState(false)
  const [loadingPhotos, setLoadingPhotos] = useState(false)

  const getFilesByMissionId = useCallback(async (id) => {
    try {
      setLoadingFile(true)
      const res = await api.get(`attachments/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFiles(
        res.data.data.map((i) => ({
          uid: i.uid,
          url: i.path,
          name: i.name,
          status: 'done',
        }))
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFile(false)
    }
  }, [])

  const getPhotosByMissionsUsersId = useCallback(
    async ({ missionsUsersId, limit }) => {
      setLoadingPhotos(true)
      try {
        const res = await api.get(`photos/${missionsUsersId}`, {
          params: {
            limit,
          },
          validateStatus() {
            return true
          },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        setLoadingPhotos(false)

        return setPhotos(res.data.data)
      } catch (error) {
        setLoadingPhotos(false)
        return message.error(error.message)
      }
    },
    []
  )

  async function onUploadData(data) {
    setLoadingFile(true)
    try {
      const res = await api.post('attachments', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Upload realizado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFile(false)
    }
  }

  async function onRemoveFile(id) {
    await api.delete(`attachments/${id}`)

    return message.success('Arquivo removido com sucesso!')
  }

  return {
    files,
    photos,
    loadingFile,
    loadingPhotos,
    onRemoveFile,
    onUploadData,
    getFilesByMissionId,
    getPhotosByMissionsUsersId,
  }
}

import React, { useState, useEffect } from 'react'

import { Button, Form, Select } from 'antd'
import t from 'prop-types'

import useForms from '~/hooks/useForms'
import useReports from '~/hooks/useReports'

const { Option } = Select
const ExportForm = ({ status, registersId }) => {
  const [filters, setFilters] = useState({ formsId: null })

  const { getAll, loadingForms, forms } = useForms()
  const { onGenerateReports, loadingGenerate } = useReports()

  useEffect(() => {
    getAll()
  }, []) //eslint-disable-line

  return (
    <Form>
      <Form.Item>
        <Select
          loading={loadingForms}
          optionFilterProp="children"
          placeholder="Selecione o formulário"
          onChange={(value) => setFilters({ ...filters, formsId: value })}
        >
          {forms.map((i) => (
            <Option key={i.uid} value={i.uid}>
              {i.id} - {i.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          shape="round"
          type="primary"
          loading={loadingGenerate}
          disabled={loadingGenerate}
          onClick={() => onGenerateReports({ ...filters, status, registersId })}
        >
          Aplicar
        </Button>
      </Form.Item>
    </Form>
  )
}

ExportForm.propTypes = {
  status: t.number,
  registersId: t.arrayOf(t.any),
}

export default ExportForm

import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useSignUpUser() {
  const [userStep, setUserStep] = useState(0)
  const [userData, setUserData] = useState(null)
  const [loadingSign, setLoadingSign] = useState(false)
  const [userDataByHostHash, setUserDataByHostHash] = useState({})

  async function onSignUp(data) {
    try {
      setLoadingSign(true)

      const res = await api.post('sessions/users/create', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      setUserData(res.data)
      return setUserStep(userStep + 1)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSign(false)
    }
  }

  async function onSubmitMessage({ value, userId }) {
    try {
      const res = await api.post(
        'sessions/users/send-code-message',
        {
          userId,
          type: 'sms',
          value,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setUserStep(userStep + 1)

      return message.success('Seus código foi enviado por SMS.')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSign(false)
    }
  }

  async function onConfirmCode({ value, userId }) {
    setLoadingSign(true)
    try {
      const res = await api.post(
        'sessions/users/confirm-code',
        {
          userId,
          type: 'sms',
          value,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return setUserStep(userStep + 1)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSign(false)
    }
  }

  async function getUserByHostHash(params) {
    try {
      setLoadingSign(true)

      const res = await api.get('member-get-member/host-hash', {
        params: {
          hostHash: params,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setUserDataByHostHash(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSign(false)
    }
  }

  return {
    userStep,
    onSignUp,
    userData,
    loadingSign,
    onConfirmCode,
    onSubmitMessage,
    getUserByHostHash,
    userDataByHostHash,
  }
}

export default useSignUpUser

import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

import { Button, Modal } from 'antd'
import t from 'prop-types'

import { Pagination } from './styles'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

export function PreviewModal({ open, onCancel, file, setSelectedPDF }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  // eslint-disable-next-line camelcase
  function onDocumentLoadSuccess({ numPages: num_pages }) {
    setNumPages(num_pages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function handleOnCancel() {
    setNumPages(null)
    setPageNumber(1)
    setSelectedPDF(null)
    onCancel()
  }

  return (
    <Modal width={643} visible={open} footer={null} onCancel={handleOnCancel}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <Pagination>
        <p>
          Pagina {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
        </p>
        <div>
          <Button
            size="middle"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Anterior
          </Button>
          <Button
            size="middle"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Próxima
          </Button>
        </div>
      </Pagination>
    </Modal>
  )
}

PreviewModal.defaultTypes = {
  open: false,
}

PreviewModal.propTypes = {
  open: t.bool.isRequired,
  onCancel: t.func.isRequired,
  file: t.string.isRequired,
  setSelectedPDF: t.func.isRequired,
}

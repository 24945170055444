import React from 'react'
import { Navigate } from 'react-router-dom'

import t from 'prop-types'

import PrivateLayout from '~/components/templates/PrivateLayout'

export default function PrivateRoute({ children }) {
  const token = localStorage.getItem('@Mission:token')

  if (token === null) {
    return <Navigate to="/login" />
  }

  return <PrivateLayout>{children}</PrivateLayout>
}

PrivateRoute.propTypes = {
  children: t.node,
}

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import t from 'prop-types'

import Layout from '~/components/templates/Layout'

const isNotPaths = ['/activation', '/recovery', '/signup', '/referral']

export default function PublicRoute({ children }) {
  const location = useLocation()
  const token = localStorage.getItem('@Mission:token')
  const hasPath = isNotPaths.includes(location.pathname)

  if (token !== null && !hasPath) {
    return <Navigate to="/dashboard" />
  }

  return <Layout>{children}</Layout>
}

PublicRoute.propTypes = {
  children: t.node,
}

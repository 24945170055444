import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { message, Carousel } from 'antd'
import axios from 'axios'
import t from 'prop-types'
import queryString from 'query-string'

import ImageDashboard from '~/assets/images/dash.svg'
import LogoMission from '~/assets/images/logo-name.png'
import { EnvStatus } from '~/components/molecules/EnvStatus'
import { API_BASE_URL } from '~/config/env'
import useSignUpUser from '~/hooks/useSignUpUser'

import {
  Container,
  Logo,
  LogoImage,
  Sidebar,
  ContainerContent,
  Title,
  Subtitle,
  BoxSlides,
  Description,
  Highlight,
  Text,
  CarouselContent,
  Image,
} from './styles'

function AuthLayout({ children }) {
  const location = useLocation()
  const { getUserByHostHash, userDataByHostHash } = useSignUpUser()

  const [customer, setCustomer] = useState(null)
  const [values] = useState(queryString.parse(location.search))

  const [loading, setLoading] = useState(false)

  const isSignUpPage = location.pathname === '/signup'
  const isReferralPage = location.pathname === '/referral'

  const getCustomer = useCallback(async () => {
    setLoading(true)
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${values?.token}`,
        },
      }

      const res = await axios.get(
        `${API_BASE_URL}/v1/customers/profile`,
        config
      )

      return setCustomer(res.data)
    } catch (error) {
      return message.error('Token Inválido')
    } finally {
      setLoading(false)
    }
  }, [values])

  const showLayoutCustomer = !loading && customer && isSignUpPage

  const showLayoutReferral =
    !loading && userDataByHostHash && isReferralPage && values?.referer

  const showLayoutDefault = !showLayoutCustomer && !showLayoutReferral

  function LayoutCustomer() {
    return (
      <Container
        backgroundColor={customer?.appearence?.backgroundColor || '#033259'}
      >
        <Logo src={customer.avatar} alt="logo" />
        <Title color={customer?.appearence?.textColor || '#FFFFFF'}>
          {customer.name}
        </Title>
        <Subtitle color={customer?.appearence?.textColor || '#FFFFFF'}>
          Página para cadastro de usuários
        </Subtitle>
      </Container>
    )
  }

  function LayoutDefault() {
    return (
      <Container backgroundColor="#033259">
        <LogoImage src={LogoMission} alt="logo" />
        <ContainerContent>
          <Title color="#FFFFFF">
            Crie missões pelo Brasil a qualquer hora, em qualquer lugar.
          </Title>
          <img src={ImageDashboard} width={350} alt="DashImage" />
        </ContainerContent>
      </Container>
    )
  }

  function LayoutReferral() {
    return (
      <Container backgroundColor="#033259">
        <Carousel
          style={{ width: '30vw', alignItems: 'center' }}
          autoplay
          autoplaySpeed="60"
          effect="fade"
          dots={false}
        >
          <CarouselContent>
            <BoxSlides>
              <LogoImage src={LogoMission} alt="logo" />
              <LogoImage src={userDataByHostHash?.avatar} alt="avatar" />
            </BoxSlides>
            <Highlight color="#033259" backgroundColor="#FFFFFF">
              NOVA PARCERIA
            </Highlight>
            <Highlight color="#033259" backgroundColor="#FFFFFF">
              Mission e {userDataByHostHash?.name}
            </Highlight>
            <Image
              src="https://devmissionbr.s3.amazonaws.com/link-files/png/bbd0bf38-c2c1-4661-b213-84c36e06df26/1658440822444-garota.png"
              alt="cel"
            />
            <Description>
              <Text color="#FFFFFF" fontSize="2rem" textAlign="center">
                Ei, você da {userDataByHostHash?.name}, vem com a MISSION e
                ganhe uma renda extra!
              </Text>
            </Description>
          </CarouselContent>

          <CarouselContent>
            <BoxSlides>
              <LogoImage src={LogoMission} alt="logo" />
              <LogoImage src={userDataByHostHash?.avatar} alt="avatar" />
            </BoxSlides>
            <BoxSlides>
              <Description>
                <Text
                  color="#FFFFFF"
                  fontSize="1.3rem"
                  textAlign="left"
                  style={{
                    margin: '20px',
                  }}
                >
                  A mission é uma plataforma digital que conecta empresas e os
                  usuários que estão dispostos a realizar serviços com qualidade
                  e ganhar uma renda extra.
                </Text>
              </Description>
              <Image
                src="https://devmissionbr.s3.amazonaws.com/link-files/png/0f64f5dc-c4e7-49ef-b087-1e17a3d032aa/1658413659774-celular.png"
                alt="cel"
              />
            </BoxSlides>
            <Text color="#FFFFFF" textAlign="center">
              #GIGWOKERS #TRABALHO40 #JOBONDEMAND
            </Text>
          </CarouselContent>
        </Carousel>
      </Container>
    )
  }

  useEffect(() => {
    if (values?.referer && isReferralPage) {
      getUserByHostHash(values?.referer)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (values?.token && isSignUpPage) {
      getCustomer()
    }
  }, []) //eslint-disable-line

  return (
    <div>
      {process.env.NODE_ENV !== 'production' ? <EnvStatus /> : null}
      <div className="page-login">
        <Sidebar>
          {showLayoutReferral ? <LayoutReferral /> : null}
          {showLayoutCustomer ? <LayoutCustomer /> : null}
          {showLayoutDefault ? <LayoutDefault /> : null}
        </Sidebar>
        <div className="page-login-container">{children}</div>
      </div>
    </div>
  )
}

AuthLayout.defaultTypes = {
  children: React.createElement('div'),
}

AuthLayout.propTypes = {
  children: t.element.isRequired,
}

export default AuthLayout

import React, { useState } from 'react'

import { Layout, Avatar } from 'antd'
import t from 'prop-types'

import Logo from '~/assets/images/logo-name-color.svg'
import LogoWhite from '~/assets/images/logo-white.png'
import Loading from '~/components/atoms/Loading'
import { EnvStatus } from '~/components/molecules/EnvStatus'
import HeaderCustom from '~/components/molecules/HeaderCustom'
import SideBar from '~/components/organisms/SideBar'
import { useAuth } from '~/contexts/auth'
import NotPermission from '~/pages/NotPermission'

import {
  styles,
  ContainerImage,
  Title,
  Footer,
  ContainerFooter,
  LabelVersion,
} from './styles'

const { Content, Sider } = Layout

function PrivateLayout({ children }) {
  const { data, loadingUser } = useAuth()
  const [collapsed, setCollapsed] = useState(false)

  const perms = data?.permissions?.permissions

  if (loadingUser) {
    return <Loading />
  }

  return (
    <>
      {process.env.NODE_ENV !== 'production' ? <EnvStatus /> : null}
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="80"
          collapsed={collapsed}
          style={styles.sideBar}
          defaultCollapsed={collapsed}
          onCollapse={(coll) => setCollapsed(coll)}
        >
          <ContainerImage isCollapsable={collapsed}>
            <div>
              <Avatar src={data?.client_avatar}>{data?.client_name}</Avatar>
            </div>
            {!collapsed && <Title>{data?.client_name}</Title>}
          </ContainerImage>
          <SideBar permissions={perms} />
          <Footer>
            <ContainerFooter>
              <img
                alt="logo"
                width={collapsed ? 80 : 160}
                src={collapsed ? LogoWhite : Logo}
              />
              <LabelVersion>v{process.env.REACT_APP_VERSION}</LabelVersion>
            </ContainerFooter>
          </Footer>
        </Sider>
        <Layout style={styles.layoutMarginLeft(collapsed)}>
          <HeaderCustom collapsed={collapsed} setCollapsed={setCollapsed} />
          <Content style={styles.content}>
            {!perms ? <NotPermission /> : React.cloneElement(children)}
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

PrivateLayout.defaultTypes = {
  children: React.createElement('div'),
}

PrivateLayout.propTypes = {
  children: t.element.isRequired,
}

export default PrivateLayout

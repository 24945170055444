// NAO MIGRADO ARQUIVOS ANEXOS PDF / PHOTOS
import { useState, useCallback } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useFiles() {
  const [files, setFiles] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(true)

  const [images, setImages] = useState([])
  const [countImages, setCountImages] = useState(0)
  const [loadingImages, setLoadingImages] = useState(true)

  const getFiles = useCallback(async (missionsUsersId) => {
    try {
      setLoadingFiles(true)
      const res = await api.get(`files/customers/${missionsUsersId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFiles(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFiles(false)
    }
  }, [])

  const getImages = useCallback(async (missionsUsersId, params) => {
    try {
      setLoadingImages(true)
      const res = await api.get(`photos/${missionsUsersId}`, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountImages(Number(res.data.count))

      return setImages(
        res.data?.data.map((i) => ({
          src: i.path,
          thumbnail: i.path,
          caption: `Ordem ${i.order}`,
        }))
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingImages(false)
    }
  }, [])

  return {
    files,
    loadingFiles,
    getFiles,
    images,
    countImages,
    getImages,
    loadingImages,
  }
}

export default useFiles

import React from 'react'

import { Alert } from 'antd'

const result = ({
  isValid,
  operation,
  valueRef,
  labelError,
  labelSuccess,
  colorSuccess,
  colorError,
}) => {
  return (
    <Alert
      showIcon
      message={`Ref ${operation} ${valueRef} - Resultado: ${
        isValid ? labelSuccess || 'Apto' : labelError || 'Não Apto'
      }`}
      type={isValid ? colorSuccess || 'success' : colorError || 'error'}
    />
  )
}

const validateOperation = ({ valueRef, valueResponded, operation }) => {
  const numberOperations = {
    '=': Number(valueRef) === Number(valueResponded),
    '>': Number(valueResponded, 10) > Number(valueRef, 10),
    '>=': Number(valueResponded, 10) >= Number(valueRef, 10),
    '<=': Number(valueResponded, 10) <= Number(valueRef, 10),
    '<': Number(valueResponded, 10) < Number(valueRef, 10),
  }

  return numberOperations[operation]
}

export const operatorValue = (params) => {
  const {
    type,
    operation,
    valueRef,
    valueResponded,
    labelError,
    colorError,
    labelSuccess,
    colorSuccess,
  } = params

  if (
    type === 'string' &&
    String(valueRef?.trim()) === String(valueResponded?.trim())
  ) {
    return result({ isValid: true, valueRef, operation })
  }

  if (['comma', 'dot_comma', 'number'].includes(type)) {
    return result({
      operation,
      valueRef,
      labelError,
      colorError,
      labelSuccess,
      colorSuccess,
      isValid: validateOperation({ valueRef, valueResponded, operation }),
    })
  }

  return result({ isValid: false })
}

export const isOperator = (operator, data) => {
  let value = null

  if (operator?.type === 'comma') {
    value = data.split(',').length
  }

  if (operator?.type === 'dot_comma') {
    value = data.split(';').length
  }

  if (operator?.type === 'text') {
    value = data
  }

  if (operator?.type === 'number') {
    value = Number(data)
  }

  return operatorValue({
    type: operator?.type,
    operation: operator.operator,
    valueRef: operator.value,
    colorError: operator?.color_error,
    valueResponded: value,
    colorSuccess: operator?.color_success,
    labelSuccess: operator?.label_success,
    labelError: operator?.label_error,
  })
}

import React from 'react'

import {
  PlusCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import { Card, List, Button, Popover } from 'antd'
import { format } from 'date-fns'
import t from 'prop-types'

import colors from '~/styles/colors'

import PenaltyForm from '../PenaltyForm'

const cardStyles = {
  height: 580,
  maxHeight: 680,
  overflowX: 'auto',
}

const Detail = ({ items, form, loading, submit }) => (
  <Card
    bordered={false}
    loading={loading}
    title="Vendas"
    style={cardStyles}
    extra={[
      !loading && !items[0].is_consolidated && (
        <Popover
          title="Adicionar Penalidade"
          trigger="click"
          placement="leftTop"
          content={
            <PenaltyForm form={form} submit={submit} loadingButton={loading} />
          }
        >
          <Button shape="round" icon={<PlusCircleOutlined />}>
            Adicionar Transação
          </Button>
        </Popover>
      ),
    ]}
  >
    <List
      dataSource={items}
      itemLayout="horizontal"
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={item.description_transaction.toUpperCase()}
            description={format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
          />
          <span>
            {item.type === 'CREDIT' ? (
              <PlusOutlined style={{ color: colors.greenLight }} />
            ) : (
              <MinusOutlined style={{ color: colors.redSecondary }} />
            )}{' '}
            R$ {item.value}
          </span>
        </List.Item>
      )}
    />
  </Card>
)

Detail.defaultTypes = {
  loading: false,
}

Detail.propTypes = {
  items: t.arrayOf(t.any).isRequired,
  form: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  submit: t.func.isRequired,
}

export default Detail

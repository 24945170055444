import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, message, Modal, Empty } from 'antd'
import { isArray } from 'lodash'
import moment from 'moment'

import Images from '~/assets/images'
import ListCards from '~/components/organisms/ListCards'
import { useAuth } from '~/contexts/auth'
import api from '~/services/api'
import { queryStringToObject } from '~/utils/queryStringHelper'

import Detail from './components/Detail'
import Header from './components/Header'

const { confirm } = Modal

const daysAbv = {
  monday: 'Seg',
  tuesday: 'Ter',
  wednesday: 'Qua',
  thursday: 'Qui',
  friday: 'Sex',
  saturday: 'Sáb',
  sunday: 'Dom',
}

export default function MissionSchedule() {
  const { search } = useLocation()
  const { data } = useAuth()

  const [count, setCount] = useState(0)
  const [open, setOpen] = useState({ visible: false, type: null })
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)

  const customerId = data?.clientId

  const [form] = Form.useForm()

  const getSchedules = useCallback(async () => {
    try {
      const res = await api.get('schedule')
      return setItems(
        res.data.map((i) => {
          const dayPeriods = {}
          const days = []

          i.config.days.forEach((day) => {
            days.push(day.dayOfTheWeek)
            dayPeriods[`${day.dayOfTheWeek}Period`] = [
              moment(day.startTime, 'HH:mm'),
              moment(day.endTime, 'HH:mm'),
            ]
          })

          return {
            ...i,
            content: days.map((d) => daysAbv[d]).join(', '),
            description: i.description || 'Sem descrição',
            period: [
              moment(i.started_at, 'YYYY-MM-DD HH:mm:ss'),
              moment(i.finished_at, 'YYYY-MM-DD HH:mm:ss'),
            ],
            days,
            ...dayPeriods,
          }
        })
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, []) //eslint-disable-line

  const onRefresh = useCallback(() => {
    setLoading(true)
    getSchedules()
  }, [getSchedules])

  async function handleSubmit(values, type) {
    const itemData = { ...item, ...values }

    if (!itemData.title) {
      message.error('Título do agendamento não pode estar vazio!')
      return false
    }

    if (!itemData.days) {
      message.error('Escolha ao menos um dia para agendamento!')
      return false
    }

    setLoading(true)

    const days = itemData.days.map((day) => {
      const dayPeriod = itemData[`${day}Period`]
      const startTime = isArray(dayPeriod)
        ? dayPeriod[0].format('HH:mm')
        : '00:00'
      const endTime = isArray(dayPeriod)
        ? dayPeriod[1].format('HH:mm')
        : '23:59'

      return { dayOfTheWeek: day, startTime, endTime }
    })

    const clientData = {
      title: itemData.title,
      customerId: customerId.uid,
      description: itemData.description || '',
      status: true,
      days,
      period: itemData.period || [
        moment(0).format('YYYY-MM-DD HH:mm:ss'),
        moment(8640000000000000).format('YYYY-MM-DD HH:mm:ss'),
      ],
    }

    if (type === 'update') {
      try {
        await api.put('schedule', {
          ...clientData,
          scheduleId: itemData.uid,
        })
        setOpen({ ...open, visible: false })
        onRefresh()
        return message.success('Atualizado com sucesso!')
      } catch (error) {
        return message.error('Falha na atualização...')
      } finally {
        setLoading(false)
      }
    } else {
      try {
        const res = await api.post('schedule', clientData)
        setCount(res.data.count)
        setOpen({ ...open, visible: false })
        onRefresh()
        return message.success('Criado com sucesso!')
      } catch (error) {
        return message.error('Falha na criação...')
      } finally {
        setLoading(false)
      }
    }
  }

  function turnOnOff(itemParam) {
    const onOff = itemParam.status ? 'inativar' : 'ativar'
    confirm({
      title: 'Alerta',
      icon: <ExclamationCircleOutlined />,
      content: `Você tem certeza que deseja ${onOff} esse agendamento?`,
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          await api.put('schedule/status', {
            scheduleId: itemParam.uid,
            status: !itemParam.status,
          })
          const idx = items.findIndex((it) => it.uid === itemParam.uid)
          const newItems = [...items]
          newItems[idx].status = !itemParam.status
          setItems(newItems)
          message.success('Alterado com sucesso!')
        } catch (error) {
          message.error('Algo deu errado :(')
        }
      },
    })
  }

  function handleDetail(itemParam, type) {
    form.setFieldsValue(itemParam)
    setItem({ ...item, ...itemParam })
    setOpen({ visible: true, type })
  }

  function openForm() {
    form.resetFields()
    setItem({})
    setOpen({ visible: true, type: 'create' })
  }

  function handlePagination() {
    onRefresh()
  }

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useEffect(() => {
    if (search && items && items.length > 0) {
      const { editScheduleId } = queryStringToObject(search)
      if (editScheduleId) {
        const sched = items.find((it) => it.uid === editScheduleId)
        form.setFieldsValue(sched)
        setItem((oldItem) => ({ ...oldItem, ...sched }))
        setOpen({ visible: true, type: 'update' })
      }
    }
  }, [search, form, items])

  return (
    <>
      <Header openForm={openForm} />

      <ListCards
        hasEdit
        hasTurnOnOff
        data={items}
        count={count}
        loading={loading}
        onClickEdit={handleDetail}
        onClickTurnOnOff={turnOnOff}
        handlePagination={handlePagination}
      />

      {!loading && items.length === 0 && (
        <Empty
          image={Images.Schedule}
          description="Nenhum agendamento adicionado até o momento..."
        />
      )}

      <Detail
        open={open}
        form={form}
        item={item}
        loading={loading}
        handleSubmit={handleSubmit}
        close={() => {
          setItem({})
          form.resetFields([
            'title',
            'description',
            'has_spotlight',
            'description_spotlight',
          ])
          setOpen({ ...open, visible: false })
        }}
      />
    </>
  )
}

import React, { useEffect, useState, useCallback } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import { ReloadOutlined } from '@ant-design/icons'
import {
  Row,
  List,
  Button,
  Skeleton,
  Col,
  Card,
  Avatar,
  Empty,
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import * as d3 from 'd3'

import Profile from '~/assets/images/profile.svg'
import Rocket from '~/assets/images/rocket.svg'
import MapFeeds from '~/components/organisms/Maps/Feeds'
import Popup from '~/components/organisms/Maps/Popup'
import useMissionsFeeds from '~/hooks/useMissionsFeeds'
import { feedLabel, feedColor } from '~/utils/feedsActions'
import { dateParse } from '~/utils/helpers'

import { styles } from './styles'

import 'mapbox-gl/dist/mapbox-gl.css'

const position = {
  latitude: -8.269214,
  longitude: -55.441382,
}

const animationViewport = {
  zoom: 14,
  transitionDuration: 5000,
  transitionEasing: d3.easeCubic,
  transitionInterpolator: new FlyToInterpolator(),
}

export default function UsersActivities() {
  const {
    getMissionsFeeds,
    currentMissionsFeeds,
    loadingCurrentFeeds,
  } = useMissionsFeeds()
  const [limit] = useState(30)
  const [items, setItems] = useState([])
  const [selected, setSelected] = useState({})
  const [viewport, setViewport] = useState({
    zoom: 0,
    height: 560,
    ...position,
    width: window.innerWidth,
  })

  function ContentMission(i) {
    return (
      <div>
        <b style={styles.colorText(feedColor[i.action])}>
          {feedLabel(i.action)}
        </b>{' '}
        {i.name} ({i.mission_key})
      </div>
    )
  }

  function onClick(item, index) {
    setSelected(index)
    return setViewport({
      ...viewport,
      latitude: item?.lat || position.latitude,
      longitude: item?.lng || position.longitude,
      ...animationViewport,
    })
  }

  function renderPopup(item) {
    const { lat, lng, place } = item
    return <Popup lat={lat} lng={lng} place={place} />
  }

  const onRefresh = useCallback(() => {
    getMissionsFeeds({ limit })
  }, []) //eslint-disable-line

  useEffect(() => {
    onRefresh()
  }, []) //eslint-disable-line

  useEffect(() => {
    setItems(currentMissionsFeeds)
    onClick(currentMissionsFeeds[0], 0)
  }, [currentMissionsFeeds]) //eslint-disable-line

  return (
    <>
      <PageHeader
        className="site-page-header"
        extra={[
          <Button
            shape="round"
            loading={loadingCurrentFeeds}
            key={String(`update-key`)}
            onClick={() => onRefresh()}
            icon={<ReloadOutlined spin={loadingCurrentFeeds} />}
          >
            Atualizar
          </Button>,
        ]}
      />
      {items.length > 0 && (
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Card style={styles.card}>
              <Skeleton loading={loadingCurrentFeeds} active>
                {!loadingCurrentFeeds && items.length > 0 && (
                  <MapFeeds
                    markers={items}
                    selected={selected}
                    viewport={viewport}
                    renderPopUp={renderPopup}
                    onChange={(item) => setViewport(item)}
                  />
                )}
              </Skeleton>
            </Card>
          </Col>
          <Col span={12}>
            <Card style={styles.cardList} loading={loadingCurrentFeeds}>
              {items.length > 0 && (
                <List
                  loading={loadingCurrentFeeds}
                  dataSource={items}
                  itemLayout="horizontal"
                  renderItem={(item, index) => (
                    <List.Item
                      key={String(index)}
                      onClick={() => onClick(item, index)}
                    >
                      <Skeleton loading={loadingCurrentFeeds} active avatar>
                        <List.Item.Meta
                          description={ContentMission(item)}
                          title={item.userName.split(' ')[0].toUpperCase()}
                          avatar={<Avatar src={item.userAvatar || Profile} />}
                        />
                        <p>{dateParse(item.created_at)}</p>
                      </Skeleton>
                    </List.Item>
                  )}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}

      {!loadingCurrentFeeds && items.length === 0 && (
        <Empty
          image={Rocket}
          description="Nenhuma missão lançada até o momento..."
        />
      )}
    </>
  )
}

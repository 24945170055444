import React, { useEffect, useState } from 'react'

import {
  CopyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  BookOutlined,
} from '@ant-design/icons'
import { Button, Input, Card, Alert, Tooltip, Form, Space } from 'antd'

import useApiCredentials from '~/hooks/useApiCredentials'

export default function ApiForm() {
  const [show, setShow] = useState()
  const {
    getCredentials,
    credentials,
    generateCredentials,
    isLoading,
    hasGeneratedApiCredentials,
  } = useApiCredentials()

  const preventChangeInputValue = () => {}

  useEffect(() => {
    getCredentials()
  }, []) //eslint-disable-line

  return (
    <Card title="Credenciais da API">
      {!hasGeneratedApiCredentials() && (
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={generateCredentials}
        >
          Criar Credenciais
        </Button>
      )}
      {hasGeneratedApiCredentials() && (
        <>
          <Alert
            showIcon
            message="Chaves Restritas"
            type="info"
            description="Para maior segurança, guarde e não compartilhe as chaves API."
          />
          <br />
          <p>
            Acesse a documentação da API e entenda quais são os recursos
            disponíveis.
          </p>
          <p>
            <Space>
              <BookOutlined style={{ fontSize: 16 }} />

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_URL_API_DOCUMENTATION}
              >
                Documentação API
              </a>
            </Space>
          </p>

          <br />
          <Form layout="vertical">
            <Form.Item label="Client ID" style={{ fontWeight: 'bold' }}>
              <Input.Group compact>
                <Input
                  style={{ width: 'calc(100% - 200px)' }}
                  value={credentials.client_id}
                  disabled
                  onChange={preventChangeInputValue}
                />
                <Tooltip title="Copiar Client ID">
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() =>
                      navigator.clipboard.writeText(credentials.client_id)
                    }
                  />
                </Tooltip>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Client Secret" style={{ fontWeight: 'bold' }}>
              <Input.Group compact>
                <Input
                  disabled
                  style={{ width: 'calc(100% - 200px)' }}
                  value={credentials.client_secret}
                  type={show ? 'text' : 'password'}
                  onChange={preventChangeInputValue}
                />
                <Tooltip title="Copiar Client Secret">
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() =>
                      navigator.clipboard.writeText(credentials.client_secret)
                    }
                  />
                </Tooltip>
                <Button
                  icon={show ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  onClick={() => setShow((prevState) => !prevState)}
                />
              </Input.Group>
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  )
}

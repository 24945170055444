/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import { Col, Form, Select } from 'antd'
import t from 'prop-types'

import api from '~/services/api'

const ColDefault = ({ children }) => (
  <Col xs={24} xl={4} sm={12} md={6}>
    {children}
  </Col>
)

ColDefault.propTypes = {
  children: t.element,
}

export function UfsAndCities({ onChange }) {
  const [ufs, setUfs] = useState([])
  const [cities, setCities] = useState([])

  async function getCities(query) {
    if (query.length === 0) {
      setCities([])
      return;
    }

    const {
      data: { data },
    } = await api.get('geocode/customers/search', {
      params: {
        ufs: query.join(', '),
      },
    })

    setCities(data)
  }

  function onChangeUfs(values) {
    getCities(values)
    onChange('ufs[]', values)
  }

  async function getUfs() {
    const {
      data: { data },
    } = await api.get('geocode/customers/search')
    return setUfs(data)
  }

  useEffect(() => {
    getUfs()
  }, [])

  return (
    <>
      <ColDefault>
        <Form.Item name="ufs">
          <Select
            placeholder="UF"
            mode="multiple"
            onChange={onChangeUfs}
            options={ufs?.map((i) => ({ label: i.name, value: i.uf }))}
          />
        </Form.Item>
      </ColDefault>
      <ColDefault>
        <Form.Item name="cities">
          <Select
            disabled={!cities.length}
            placeholder="Cidades"
            mode="multiple"
            onChange={(values) => onChange('cities[]', values)}
            options={cities?.map((city) => ({ label: city, value: city }))}
          />
        </Form.Item>
      </ColDefault>
    </>
  )
}

UfsAndCities.propTypes = {
  onChange: t.func,
}

import React from 'react'

import { Form, Button, DatePicker, Select, Checkbox } from 'antd'
import t from 'prop-types'

const { Option } = Select

const options = [
  { key: '0', text: 'PDF', value: 'pdf', icon: 'file-pdf' },
  { key: '1', text: 'XLS', value: 'xls', icon: 'file-excel' },
]

const ExportForm = ({ form, loadingButton, submitFilter }) => (
  <Form form={form} onFinish={submitFilter}>
    <Form.Item name="initial">
      <DatePicker placeholder="Data Inicial" />
    </Form.Item>
    <Form.Item name="final">
      <DatePicker placeholder="Data Final" />
    </Form.Item>
    <Form.Item name="type">
      <Select placeholder="Selecione o arquivo" optionFilterProp="children">
        {options.map((i) => (
          <Option value={i.value}>{i.text}</Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item name="type_report">
      <Checkbox.Group
        options={[
          {
            value: 'DEBIT',
            label: 'Débito',
          },
          {
            value: 'CREDIT',
            label: 'Crédito',
          },
        ]}
      />
    </Form.Item>
    <Form.Item>
      <Button
        shape="round"
        type="primary"
        htmlType="submit"
        loading={loadingButton}
      >
        Exportar
      </Button>
    </Form.Item>
  </Form>
)

ExportForm.propTypes = {
  form: t.objectOf(t.any).isRequired,
  loadingButton: t.bool.isRequired,
  submitFilter: t.func.isRequired,
}

export default ExportForm

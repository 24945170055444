import React, { useState } from 'react'

import { Layout, Form, Tabs } from 'antd'

import { useAuth } from '~/contexts/auth'
import useCustomer from '~/hooks/useCustomer'
import useCustomersAccounts from '~/hooks/useCustomersAccounts'

import ApiForm from './components/Api'
import Appearence from './components/Appearence'
import Notifications from './components/Notifications'
import Password from './components/Password'
import UserData from './components/UserData'

const { TabPane } = Tabs

function Settings() {
  const [form] = Form.useForm()
  const { data } = useAuth()

  const [values] = useState(data?.user)
  const { onChangeCustomer, loadingCustomer } = useCustomer()
  const { onUpdate, loadingCustomersAccounts } = useCustomersAccounts()
  const [customAppearence, setCustomAppearence] = useState({ ...values })

  function onSubmit(dataCustomer) {
    delete dataCustomer.confirmPassword

    if (values.role === 0 || values.role === null) {
      return onChangeCustomer(dataCustomer)
    }

    let dataCustomerAccount = {
      ...dataCustomer,
      id: data?.uid,
      username: dataCustomer.name,
      permissions: data?.permissions,
    }

    if (dataCustomer.password) {
      dataCustomerAccount = {
        ...dataCustomerAccount,
        id: data?.uid,
        username: dataCustomer.name,
        permissions: data?.permissions,
      }
    }

    delete dataCustomerAccount.name

    return onUpdate({
      ...dataCustomerAccount,
      id: data?.uid,
      permissions: data?.permissions,
    })
  }

  function onChangeTabs(key) {
    return key
  }

  function onUploadFile(event) {
    setCustomAppearence((prevState) => ({
      ...prevState,
      preview: event.result,
    }))
  }

  return (
    <Layout>
      <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
        <TabPane tab="Perfil" key="1">
          <UserData
            form={form}
            values={values}
            handleSubmit={onSubmit}
            loading={loadingCustomer || loadingCustomersAccounts}
          />
        </TabPane>
        <TabPane tab="Senha" key="2">
          <Password
            form={form}
            handleSubmit={onSubmit}
            loading={loadingCustomer || loadingCustomersAccounts}
          />
        </TabPane>
        <TabPane tab="Aparência" key="6">
          <Appearence
            form={form}
            values={customAppearence}
            handleSubmit={onSubmit}
            onUploadFile={onUploadFile}
            loading={loadingCustomer || loadingCustomersAccounts}
          />
        </TabPane>

        <TabPane tab="API" key="5">
          <ApiForm />
        </TabPane>
        <TabPane tab="Notificações" key="3">
          <Notifications />
        </TabPane>

        <TabPane tab="Integrações (Em Breve)" key="4" disabled />
      </Tabs>
    </Layout>
  )
}

export default Settings

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

const filtersDefault = {
  limit: 10,
  offset: 0,
  search: '',
}

function useCustomersAccounts() {
  const [countData, setCountData] = useState(0)
  const [loadingCustomersAccounts, setLoadingCustomersAccounts] =
    useState(false)
  const [loadingResendEmail, setLoadingResendEmail] = useState(false)
  const [customersAccounts, setCustomersAccounts] = useState([])
  const [customersAccountsOptions, setCustomersAccountsOptions] = useState([])

  const getCustomersAccounts = useCallback(
    async ({ search, limit, offset }) => {
      try {
        setLoadingCustomersAccounts(true)
        const res = await api.get('customers-accounts', {
          params: {
            search,
            limit,
            offset,
          },
          validateStatus() {
            return true
          },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        const emailList = res.data?.accounts
          .filter((i) => i.status)
          .map((i) => ({ value: i?.email, label: i.username }))

        setCustomersAccountsOptions(emailList)

        setCountData(Number(res.data.count))
        return setCustomersAccounts(res.data.accounts)
      } catch (error) {
        return message.error(error.message)
      } finally {
        setLoadingCustomersAccounts(false)
      }
    },
    []
  )

  async function onRemove(id) {
    setLoadingCustomersAccounts(true)
    try {
      const res = await api.delete(`sessions/customers-accounts/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Conta removida com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      getCustomersAccounts(filtersDefault)
      setLoadingCustomersAccounts(true)
    }
  }

  async function onCreate(customersAccountsData) {
    try {
      const res = await api.post(
        'sessions/customers/customers-accounts',
        customersAccountsData,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      getCustomersAccounts(filtersDefault)

      return message.success('Sub-Conta foi criada com sucesso!')
    } catch (error) {
      return message.error(error.message)
    }
  }

  async function onUpdate(customersAccountsData) {
    setLoadingCustomersAccounts(true)
    try {
      const res = await api.patch('customers-accounts', customersAccountsData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Os dados foram atualizados com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCustomersAccounts(false)
    }
  }

  async function onResendEmail(customersAccountData) {
    setLoadingResendEmail(true)
    try {
      const res = await api.patch(
        'sessions/customers-accounts/resend-email',
        customersAccountData,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      return message.success('Seu convite foi encaminhado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingResendEmail(false)
    }
  }

  return {
    onRemove,
    onUpdate,
    onCreate,
    countData,
    onResendEmail,
    customersAccounts,
    loadingResendEmail,
    getCustomersAccounts,
    loadingCustomersAccounts,
    customersAccountsOptions,
  }
}

export default useCustomersAccounts

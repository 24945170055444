import React from 'react'
import NumberFormat from 'react-number-format'

import { Modal, Button, Input } from 'antd'
import t from 'prop-types'

const ModalPromotionalBonus = ({
  open,
  onSubmit,
  onCancel,
  loading,
  onChange,
}) => (
  <Modal
    visible={open}
    title="Adicionar Bônus Promocional"
    onOk={onSubmit}
    onCancel={onCancel}
    footer={[
      <Button key="back" onClick={onCancel}>
        Cancelar
      </Button>,
      <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
        Enviar
      </Button>,
    ]}
  >
    <NumberFormat
      prefix="R$"
      customInput={Input}
      decimalSeparator=","
      placeholder="R$0,00"
      onValueChange={onChange}
      thousandsGroupStyle="thousand"
    />
  </Modal>
)

ModalPromotionalBonus.propTypes = {
  open: t.bool,
  onSubmit: t.func,
  onCancel: t.func,
  loading: t.bool,
  onChange: t.func,
}

export default ModalPromotionalBonus

import React from 'react'

import { Result } from 'antd'

const Invalid = () => (
  <Result
    status="error"
    title="Token Expirado"
    subTitle="Fale com o responsável da empresa e gere um novo link de cadastro."
  />
)

export default Invalid

export default {
  redLight: '#F3615F',
  redStrong: '#d3455a',
  redSecondary: '#dc2b5f',
  blue: '#089EFD',
  blueStrong: '#0B35FA',
  greenSolid: '#669275',
  greenLight: '#1bae9f',
  greenLightSecondary: '#51ccd4',
  greenShock: '#52c41a',
  grey: '#C5C5C5',
  yellow: '#F1B950',
}

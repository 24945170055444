import React, { memo, useState } from 'react'

import {
  RedoOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Tooltip, Popconfirm } from 'antd'
import t from 'prop-types'

import ContentFormExample from '../../ContentFormExample'
import OperatorAlert from '../../OperatorAlert'
import { Container } from './styles'

const ModalEditQuestion = ({
  info,
  isOpen,
  closeModal,
  children,
  submitUpdate,
  removeItem,
  onChangeValue,
  onSubmitOperatorWarning,
  onSubmitExample,
  onChangeUploadImage,
  preview,
}) => {
  const [buttonUpdateDisabled, setButtonUpdateDisabled] = useState(false)
  return (
    <Container
      title={`${info?.order}. ${info?.question}`}
      onCancel={closeModal}
      visible={isOpen}
      footer={[
        <Button
          key={1}
          shape="round"
          type="primary"
          onClick={() => submitUpdate(info)}
          icon={<RedoOutlined />}
          style={{ marginRight: 5 }}
          disabled={buttonUpdateDisabled}
        >
          Atualizar
        </Button>,
        <Popconfirm
          key={2}
          okText="Confirmar"
          cancelText="Cancelar"
          onConfirm={() => removeItem(info?.uid)}
          title="Tem certeza que deseja remover essa pergunta？"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Tooltip key="0" title="Remover">
            <Button
              shape="round"
              icon={<DeleteOutlined />}
              type="danger"
              style={{ marginRight: 5 }}
            >
              Remover
            </Button>
          </Tooltip>
        </Popconfirm>,
        <OperatorAlert
          key={3}
          value={info}
          onSubmitOperatorWarning={onSubmitOperatorWarning}
          onChangeStatusButton={(value) => setButtonUpdateDisabled(value)}
        />,
        <ContentFormExample
          key={4}
          onChange={onChangeValue}
          value={info}
          onSubmitExample={onSubmitExample}
          onChangeUploadImage={onChangeUploadImage}
          onChangeStatusButton={(value) => setButtonUpdateDisabled(value)}
          preview={preview}
        />,
      ]}
      centered
      destroyOnClose
      width="80%"
    >
      {children}
    </Container>
  )
}

ModalEditQuestion.propTypes = {
  info: t.objectOf(t.string),
  isOpen: t.bool,
  closeModal: t.func,
  children: t.node,
  submitUpdate: t.func,
  removeItem: t.func,
  onChangeValue: t.func,
  onSubmitOperatorWarning: t.func,
  onSubmitExample: t.func,
  onChangeUploadImage: t.func,
  preview: t.string,
}

export default memo(ModalEditQuestion)

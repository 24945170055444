import React from 'react'

import { Button, Form, Input } from 'antd'
import t from 'prop-types'

const ConfirmCode = ({ form, handleConfirmCode, loading }) => (
  <>
    <div className="content-login-header">
      <h3>Confirmar Código</h3>
    </div>

    <Form form={form} layout="vertical" onFinish={handleConfirmCode}>
      <Form.Item
        label="Código"
        name="code"
        rules={[
          {
            required: true,
            message: 'Código',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Confirmar Código
        </Button>
      </Form.Item>
    </Form>
  </>
)

ConfirmCode.propTypes = {
  form: t.objectOf(t.any),
  handleConfirmCode: t.func,
  loading: t.bool,
}

export default ConfirmCode

import React, { useEffect } from 'react'

import { Form, Button, Input, Card, Row, Col } from 'antd'
import t from 'prop-types'

import UploadImage from '~/components/molecules/UploadImage'

export default function Appearence({
  values,
  loading,
  handleSubmit,
  handleFailed,
  onUploadFile,
}) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <Card title="Customização de Aparência">
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleFailed}
      >
        <Row gutter={[40, 0]}>
          <Form.Item name="preview">
            <div
              style={{
                marginLeft: '10px',
                width: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UploadImage
                id={values.uid}
                loading={false}
                onUploadFile={onUploadFile}
                imagePath={values.preview || values.avatar}
              />
            </div>
          </Form.Item>

          <Col span={4} style={{ marginLeft: '30px' }}>
            <Form.Item
              name={['appearence', 'backgroundColor']}
              label="Cor padrão de fundo"
            >
              <Input
                defaultValue={values.appearence?.backgroundColor || '#002e5d'}
                type="color"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name={['appearence', 'textColor']}
              label="Cor padrão do texto"
            >
              <Input
                defaultValue={values.appearence?.textColor || '#FFFFFF'}
                type="color"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

Appearence.defaultTypes = {
  loading: false,
}

Appearence.propTypes = {
  values: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
  handleFailed: t.func.isRequired,
  onUploadFile: t.func.isRequired,
}

import React from 'react'
import { Line } from 'react-chartjs-2'

import { Empty, Skeleton, Card } from 'antd'
import {
  ArcElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { format } from 'date-fns'
import t from 'prop-types'

import Dash2 from '~/assets/images/dash-2.svg'

import { Container } from './styles'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
)

const Conversion = ({ data, loading }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }
  const pointOptions = {
    tension: 0.1,
    pointRotation: 1,
    pointColor: 'transparent',
    pointBorderColor: 'transparent',
    pointBorderWidth: 10,
    borderWidth: 5,
    radius: 2,
    capBezierPoints: false,
    stepped: false,
  }
  const dataSets = {
    labels: data.map((i) => format(new Date(i.period), 'dd/MM')),
    datasets: [
      {
        ...pointOptions,
        label: 'Iniciadas',
        borderColor: '#2a86ff',
        data: data.map((i) => Number(i.started)),
      },
      {
        ...pointOptions,
        label: 'Finalizadas',
        borderColor: 'rgb(81, 204, 212, 1)',
        data: data.map((i) => Number(i.finished)),
      },
      {
        ...pointOptions,
        label: 'Desistiu',
        borderColor: 'rgb(211, 69, 90, 1)',
        data: data.map((i) => Number(i.quit)),
      },
    ],
  }

  return (
    <Card title="Desempenho" className="card-content">
      <Skeleton loading={loading} active>
        {data.length === 0 ? (
          <Empty image={Dash2} />
        ) : (
          <Container>
            <Line height={400} options={options} data={dataSets} />
          </Container>
        )}
      </Skeleton>
    </Card>
  )
}

Conversion.defaultTypes = {
  loading: false,
}

Conversion.propTypes = {
  data: t.arrayOf(t.any).isRequired,
  loading: t.bool.isRequired,
}

export default Conversion

export const backgroundColors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#30b2ff',
  '#6497ef',
  '#8879d4',
  '#9f59ae',
  '#a83781',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#30b2ff',
  '#6497ef',
  '#8879d4',
  '#9f59ae',
  '#a83781',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#30b2ff',
  '#6497ef',
  '#8879d4',
  '#9f59ae',
  '#a83781',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#30b2ff',
  '#6497ef',
  '#8879d4',
  '#9f59ae',
  '#a83781',
]

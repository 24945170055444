import React from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload, message, Skeleton } from 'antd'
import t from 'prop-types'

function getBase64(file, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(file)
}

function beforeUpload(file) {
  const is10MB = file.size / 1024 / 1024 < 10

  if (!is10MB) {
    message.error('Arquivo deve ter no máximo 10MB')
  }

  return is10MB
}

function onChange(info, onUploadFile, id) {
  const { status } = info.file
  if (status === 'done') {
    getBase64(info.file.originFileObj, (file) =>
      onUploadFile({
        productId: id,
        result: file,
        info: info.file.originFileObj,
      })
    )
    message.info(`${info.file.name} iniciando upload...`)
  } else if (status === 'error') {
    message.error(`${info.file.name} falha no envio do arquivo!`)
  }
}

const uploadButton = ({ loading }) => (
  <div>
    {loading ? <LoadingOutlined /> : <PlusOutlined />}
    <div className="ant-upload-text">Upload</div>
  </div>
)

const UploadImage = ({ id, onUploadFile, imagePath, loading }) => {
  const draggerProps = {
    name: 'avatar',
    accept: 'image/png',
    beforeUpload,
    onChange: (info) => onChange(info, onUploadFile, id),
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => onSuccess(`${file} ok`), 0),
  }

  return (
    <div className="clearfix">
      {loading && <Skeleton active />}

      {!loading && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Upload className="upload-image" {...draggerProps}>
          {imagePath ? (
            <img src={imagePath} style={{ width: '100%' }} alt="avatar" />
          ) : (
            uploadButton(loading)
          )}
        </Upload>
      )}
    </div>
  )
}

UploadImage.defaultTypes = {
  loading: false,
}

UploadImage.propTypes = {
  id: t.string.isRequired,
  loading: t.bool.isRequired,
  imagePath: t.string.isRequired,
  onUploadFile: t.func.isRequired,
}

uploadButton.propTypes = {
  loading: t.bool.isRequired,
}

export default UploadImage

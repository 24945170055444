import React, { useState, useEffect, memo } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useParams, useNavigate } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'

import { DragOutlined, EditOutlined } from '@ant-design/icons'
import { message, Affix, Divider, Form, Button } from 'antd'
import t from 'prop-types'
import { isEmpty } from 'ramda'

import useForms from '~/hooks/useForms'
import useFormsItems from '~/hooks/useFormsItems'
import useFormsItemsParents from '~/hooks/useFormsItemsParents'
import useFormsItemsQuestionsMultiples from '~/hooks/useFormsItemsQuestionsMultiples'

import CreateItem from './components/CreateItem'
import Header from './components/Header'
import Item from './components/Item'
import ModalCreate from './components/Modals/ModalCreateQuestion'
import ModalEdit from './components/Modals/ModalEditQuestion'
import Opcional from './components/Opcional'
import SectionName from './components/SectionName'
import SelectItemUpdate from './components/SelectItemUpdate'
import { Card, Title, ContainerButton } from './styles'

const grid = 2

const getItemStyle = ({ provided, style, isDragging }) => {
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  }

  const marginBottom = 8
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
    userSelect: 'none',
  }

  return withSpacing
}

const getListStyle = ({ isDraggingOver }) => ({
  width: '100%',
  padding: grid,
  background: isDraggingOver ? 'lightblue' : '#f1f2f3',
})

function Detail() {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const { forms, loadingForms } = useForms()

  const {
    exportFormsItemsTemplate,
    importFormsItemsTemplate,
    loadingForms: loadingFormsItems,
    getFormsItems,
    formsItems: formsItemsData,
    createFormsItems,
    changeOrder,
    removeFormsItems,
    updateFormsItems,
    uploadImage,
    urlImage,
    loadingImport,
  } = useFormsItems()

  const {
    getFormsItemsQuestionsMultiples,
    questionsMultiples,
    createFormsItemsQuestionsMultiples,
    deleteFormsItemsQuestionsMultiples,
    changeOrderQuestionsMultiples,
    updateFormsItemsQuestionsMultiples,
    uploadImageQuestionsMultiples,
    loadingMultiples,
  } = useFormsItemsQuestionsMultiples()

  const { createParent, getParents, parents, removeParents } =
    useFormsItemsParents()

  const initialForms = {
    type: null,
    question: '',
    example: null,
    length: null,
    section: null,
    items: [],
    operator_warning: null,
  }

  const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false)
  const [isOpenModalCreateQuestion, setIsOpenModalCreateQuestion] =
    useState(false)
  const [title, setTitle] = useState('')
  const [values, setValues] = useState({})

  const [opcional, setOpcional] = useState({})
  const [visible, setVisible] = useState(false)
  const [targetKeys, setTargetKeys] = useState([])
  const [loadingFile, setLoadingFile] = useState(false)
  const [formsItems, setFormsItems] = useState(initialForms)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [backgroundHeader, setBackgroundHeader] = useState(null)
  const [visibleFilterOrder, setVisibleFilterOrder] = useState(false)
  const [openSectionName, setOpenSectionName] = useState(false)
  const [visibleActionsTemplate, setVisibleActionsTemplate] = useState(false)

  const { id } = useParams()

  const uploadProps = (item) => {
    return {
      showUploadList: false,
      accept: '.png, .jpg, .jpeg',
      onChange: (info) => {
        uploadImageQuestionsMultiples({ fiqmId: item.uid, ...info })
      },
    }
  }

  const handleRemovePreviewImageItem = (params) => {
    updateFormsItemsQuestionsMultiples({
      uid: params.uid,
      url_image: null,
    })

    return setLoadingFile(false)
  }

  function reorderMultiples(list, formIndex, direction) {
    if (direction === 'down') {
      list.splice(formIndex + 1, 0, list.splice(formIndex, 1)[0])
    }

    if (direction === 'up') {
      list.splice(formIndex - 1, 0, list.splice(formIndex, 1)[0])
    }

    changeOrderQuestionsMultiples({ list: list.map((item) => item.id) })
  }

  const reorderList = (list, source, destination) => {
    const newOrderList = Array.from(list)
    const [itemMoved] = newOrderList.splice(source, 1)
    newOrderList.splice(destination, 0, itemMoved)

    return newOrderList
  }

  const submitOrder = async ({ start, to }) => {
    const endIndex = formsItemsData.findIndex((i) => i.order === start)
    const startIndex = formsItemsData.findIndex((i) => i.order === to)

    if (endIndex === -1 || startIndex === -1) {
      message.error('Essa ordenação não existe!')
      return false
    }

    const newList = reorderList(formsItemsData, startIndex, endIndex)

    await changeOrder(newList)
    return setVisibleFilterOrder(false)
  }

  const onDragEnd = async (params) => {
    if (!params.destination) {
      return
    }

    const { source, destination } = params
    const newList = reorderList(formsItemsData, source.index, destination.index)

    await changeOrder(newList)
  }

  const onChangeValue = (item, value, key) => {
    setValues((prevState) => ({
      ...prevState,
      formsItemsId: item.uid,
      [key]: value,
    }))

    if (value === 3 || value === 4) {
      getFormsItemsQuestionsMultiples(item.uid)

      setSelectedQuestion((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }
  }

  const submitUpdate = () => {
    updateFormsItems(values)
  }

  function handleOnBlurQuestionMultiple(valuesQuestionsMultiples) {
    if (Object.keys(valuesQuestionsMultiples).length > 0) {
      updateFormsItemsQuestionsMultiples(valuesQuestionsMultiples)
    }
  }

  function handleOnPressEnterQuestionMultiple(
    formsItemsId,
    titleQuestionsMultiples
  ) {
    createFormsItemsQuestionsMultiples({
      uid: formsItemsId,
      items: titleQuestionsMultiples,
    })
  }

  const handleRemoveItem = (itemId) => {
    removeFormsItems({ formItemUid: itemId })

    setTimeout(() => {
      setIsOpenModalEditQuestion(false)
    }, 500)
  }

  function onChangeFormsItems(key, value) {
    return setFormsItems((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  function incrementItem(e) {
    const item = {
      forms_items_id: id,
      title: e.target.value,
      order: formsItems.items.length + 1,
    }

    setFormsItems((prevState) => ({
      ...prevState,
      items: [...prevState.items, item],
    }))
  }

  function decrementItem(index) {
    return setFormsItems({
      ...formsItems,
      items: formsItems.items.filter((i, idx) => idx !== index),
    })
  }

  const onClickOpcional = (item) => {
    getParents({ parentId: item.uid })

    setOpcional(item)
    setVisible(true)
  }

  useEffect(() => {
    if (parents.length > 0) {
      setTargetKeys(parents.map((parent) => parent.forms_items_id))
    } else {
      setTargetKeys([])
    }
  }, [parents])

  const onChangeOptional = (targetKeysList, direction, moveKeys) => {
    setTargetKeys(targetKeysList)

    const oldParents = parents?.map((parent) => parent.forms_items_id)
    const oldParentsFromTargetKeysList = targetKeysList.filter(
      (i) => i !== moveKeys[0]
    )

    if (direction === 'right') {
      console.log({
        parentId: opcional.uid,
        newParents: moveKeys,
        oldParents: [...oldParents, ...oldParentsFromTargetKeysList],
      })
      return createParent({
        parentId: opcional.uid,
        newParents: moveKeys,
        oldParents: [...oldParents, ...oldParentsFromTargetKeysList],
      })
    }
    console.log({
      parentId: opcional.uid,
      newParents: moveKeys,
      oldParents: [...oldParentsFromTargetKeysList],
    })

    return removeParents({
      parentId: opcional.uid,
      deleteParents: moveKeys,
      oldParents: [...oldParentsFromTargetKeysList],
    })
  }

  useEffect(() => {
    if (!loadingForms && forms.length > 0) {
      setTitle(forms[0].name)
    }
  }, [title, loadingForms, forms]) // eslint-disable-line

  useEffect(() => {
    getFormsItems({
      formId: id,
    })
  }, []) // eslint-disable-line

  function handleOpenFormItemModal(item) {
    if (item.type === 4 || item.type === 3) {
      getFormsItemsQuestionsMultiples(item.uid)
    }

    setSelectedQuestion(item)
    setIsOpenModalEditQuestion(true)
  }

  const RenderItem = memo(function RenderItem({
    provided,
    item,
    style = {},
    isDragging,
  }) {
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`item ${isDragging ? 'is-dragging' : ''}`}
        style={getItemStyle({ provided, style, isDragging })}
      >
        <Card className="form-item">
          <Title>
            {item?.order} {item?.question}
          </Title>
          <ContainerButton className="form-item-buttons">
            <Button
              shape="round"
              type="primary"
              onClick={() => handleOpenFormItemModal(item)}
              icon={<EditOutlined />}
              style={{ marginRight: 5 }}
            >
              Editar
            </Button>
            <div>
              <DragOutlined />
            </div>
          </ContainerButton>
        </Card>
      </div>
    )
  })

  RenderItem.propTypes = {
    provided: t.objectOf(t.any),
    item: t.objectOf(t.any),
    style: t.shape({
      left: t.number,
      height: t.number,
      position: t.string,
      right: t.number,
      top: t.number,
      width: t.string,
    }),
    isDragging: t.bool,
  }

  const RenderList = memo(function RenderList(props) {
    const { data: itemsList, index, style } = props
    const item = itemsList[index]

    if (!item) {
      return null
    }

    return (
      <Draggable key={String(index)} index={index} draggableId={String(index)}>
        {(providedDrag) => (
          <RenderItem provided={providedDrag} item={item} style={style} />
        )}
      </Draggable>
    )
  })

  RenderList.propTypes = {
    data: t.any,
    index: t.number,
    style: t.shape({
      left: t.number,
      height: t.number,
      position: t.string,
      right: t.number,
      top: t.number,
      width: t.string,
    }),
  }

  function handleExportFormsItemsTemplate() {
    exportFormsItemsTemplate(id)
    setVisibleActionsTemplate(false)
  }

  function handleRemoveQuestionMultiple(value) {
    deleteFormsItemsQuestionsMultiples({
      fiqmId: value.uid,
    })
  }

  async function handleImportFormsItemsTemplate({ file }) {
    await importFormsItemsTemplate({ file, id })

    setVisibleActionsTemplate(false)
  }

  const handleSubmitCreateFormsItems = () => {
    return createFormsItems({ ...formsItems, forms_id: id })
  }

  const handleChangeWarningOrExample = (valueWarningOrExample) => {
    setValues((prevState) => ({
      formsItemsId: valueWarningOrExample.formsItemsId,
      ...prevState,
      operator_warning: {
        ...prevState.operator_warning,
        ...valueWarningOrExample.operator_warning,
      },
      example: {
        ...prevState.example,
        ...valueWarningOrExample.example,
        ...(urlImage && { image: urlImage }),
      },
    }))
  }

  const handleOnChangeUploadImage = (params) => {
    uploadImage(params)
  }

  const handleCloseModalEdit = () => {
    setIsOpenModalEditQuestion(false)
  }

  return (
    <>
      <SectionName
        open={openSectionName}
        close={() => setOpenSectionName(false)}
        formsId={id}
      />
      <Affix
        onChange={(hasAffix) =>
          setBackgroundHeader(hasAffix ? '#fff' : 'transparent')
        }
      >
        <Header
          key="header-1"
          form={form}
          loading={loadingFormsItems}
          title={title}
          submitOrder={submitOrder}
          visible={visibleFilterOrder}
          background={backgroundHeader}
          goBack={() => navigate(-1)}
          handleVisibleChange={() => setVisibleFilterOrder((prev) => !prev)}
          setIsOpenModalCreateQuestion={setIsOpenModalCreateQuestion}
          openSectionName={() => setOpenSectionName(true)}
          handleExportFormsItemsTemplate={() =>
            handleExportFormsItemsTemplate()
          }
          handleImportFormsItemsTemplate={(params) =>
            handleImportFormsItemsTemplate(params)
          }
          handleVisibleChangeActionsTemplate={() =>
            setVisibleActionsTemplate(!visibleActionsTemplate)
          }
          visibleActionsTemplate={visibleActionsTemplate}
          loadingActionsTemplate={loadingImport}
        />
      </Affix>
      {formsItemsData.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
              <RenderItem
                provided={provided}
                isDragging={snapshot.isDragging}
                item={formsItemsData[rubric.source.index]}
              />
            )}
          >
            {(provided, snapshot) => {
              return (
                <div
                  style={getListStyle({
                    isDraggingOver: snapshot.isDraggingOver,
                  })}
                >
                  <List
                    height={
                      formsItemsData.length * 60 > 600
                        ? 600
                        : formsItemsData.length * 60
                    }
                    width="calc(100% - 1px)"
                    itemCount={formsItemsData.length}
                    outerRef={provided.innerRef}
                    itemData={formsItemsData}
                    itemSize={60}
                  >
                    {!isEmpty(formsItemsData) && RenderList}
                  </List>
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>
      )}

      <ModalCreate
        isOpen={isOpenModalCreateQuestion}
        closeModal={() => setIsOpenModalCreateQuestion(false)}
      >
        <CreateItem
          forms={formsItems}
          submit={handleSubmitCreateFormsItems}
          loading={loadingFormsItems}
          onChange={(key, value) => onChangeFormsItems(key, value)}
          decrementItem={(value) => decrementItem(value)}
          incrementItem={(value) => incrementItem(value)}
        />
      </ModalCreate>

      {selectedQuestion !== null && (
        <ModalEdit
          info={selectedQuestion}
          isOpen={isOpenModalEditQuestion}
          removeItem={handleRemoveItem}
          submitUpdate={submitUpdate}
          closeModal={handleCloseModalEdit}
          onSubmitOperatorWarning={handleChangeWarningOrExample}
          onSubmitExample={handleChangeWarningOrExample}
          onChangeUploadImage={handleOnChangeUploadImage}
          isLoading={loadingFormsItems}
          preview={urlImage}
        >
          <Item item={selectedQuestion} onChangeValue={onChangeValue}>
            {(selectedQuestion?.type === 3 || selectedQuestion?.type === 4) && (
              <>
                <Divider />
                <SelectItemUpdate
                  loadingMultiples={loadingMultiples}
                  items={questionsMultiples}
                  removeItem={(value) => handleRemoveQuestionMultiple(value)}
                  onClickOpcional={onClickOpcional}
                  onPressEnterQuestionMultiple={(
                    formsItemsId,
                    titleQuestionsMultiples
                  ) =>
                    handleOnPressEnterQuestionMultiple(
                      formsItemsId,
                      titleQuestionsMultiples
                    )
                  }
                  handleOnBlurQuestionMultiple={(valuesQuestionsMultiples) =>
                    handleOnBlurQuestionMultiple(valuesQuestionsMultiples)
                  }
                  loadingFile={loadingFile}
                  uploadProps={uploadProps}
                  reorderMultiples={(list, formIndex, direction) =>
                    reorderMultiples(list, formIndex, direction)
                  }
                  handleRemoveImage={handleRemovePreviewImageItem}
                />
              </>
            )}
          </Item>

          <Opcional
            items={formsItemsData}
            visible={visible}
            opcional={opcional}
            targetKeys={targetKeys}
            onChange={onChangeOptional}
            cancel={() => setVisible(false)}
          />
        </ModalEdit>
      )}
    </>
  )
}

export default Detail

import React from 'react'

import { Form, Button, Select, Input } from 'antd'
import t from 'prop-types'

import types from './helpers/types'

const { TextArea } = Input
const { Option } = Select

const PenaltyForm = ({ form, loadingButton, submit }) => (
  <Form form={form} onFinish={submit}>
    <Form.Item name="value">
      <Input prefix="R$" placeholder="Valor" type="number" />
    </Form.Item>
    <Form.Item name="type">
      <Select placeholder="Tipo de Transação">
        {types.map((i) => (
          <Option key={i.label} value={i.value}>
            {i.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item name="description_transaction">
      <TextArea placeholder="Descrever transação..." rows={2} />
    </Form.Item>
    <Form.Item>
      <Button
        shape="round"
        type="primary"
        htmlType="submit"
        loading={loadingButton}
      >
        Enviar
      </Button>
    </Form.Item>
  </Form>
)

PenaltyForm.defaultTypes = {
  loadingButton: false,
}

PenaltyForm.propTypes = {
  form: t.objectOf(t.any).isRequired,
  loadingButton: t.bool.isRequired,
  submit: t.func.isRequired,
}

export default PenaltyForm

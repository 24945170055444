import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Space, Button, Affix, Form, message } from 'antd'

import Table from '~/components/organisms/Table'
import {
  getSaleById,
  consolidateValue,
  walletTransaction,
  getAllSalesConsolidated,
} from '~/store/ducks/sales/actions'

import Detail from './components/Detail'
import Header from './components/Header'
import columns from './helpers/columns'

export default function ProductSales() {
  const dispatch = useDispatch()

  const sales = useSelector((state) => state.sales)
  const getSaled = useSelector((state) => state.getSale)
  const consolidateWallet = useSelector((state) => state.consolidateWallet)
  const walletTransactioned = useSelector((state) => state.walletTransactioned)
  const [form] = Form.useForm()
  const [formFilter] = Form.useForm()
  const [item, setItem] = useState({})
  const [count, setCount] = useState(0)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [loadingDetail, setLoadingDetail] = useState(false)
  const [consolidates, setConsolidates] = useState([])
  const [filters, setFilters] = useState({ offset: 0, limit: 15 })
  const [pagination, setPagination] = useState({
    total: 0,
    current: 0,
    pageSize: 0,
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const getSales = useCallback(() => {
    dispatch(getAllSalesConsolidated())
  }, [dispatch])

  function handlePagination(page) {
    const pageLimit = page * filters.limit
    const currentOffset = page === 1 ? 0 : pageLimit - filters.limit
    setFilters({
      ...filters,
      offset: currentOffset,
    })
  }

  const findSales = useCallback(
    (id) => {
      dispatch(getSaleById(id))
    },
    [dispatch]
  )

  function onClick(detail) {
    setLoadingDetail(true)
    setItem(detail)
    findSales(detail.user_id)
  }

  function onFinishConsolidate() {
    setLoading(true)
    selectedRowKeys.forEach((i) => {
      dispatch(
        consolidateValue(items[i].consolidated_id, {
          value: items[i].total,
        })
      )
    })
  }

  function submit(values) {
    if (!values.value) {
      message.error('O valor da transação é obrigatório!')
      return false
    }

    if (Number(values.value) <= 0) {
      message.error('O valor deve ser maior que zero!')
      return false
    }

    if (!values.type) {
      message.error('Tipo de transação é obrigatório')
      return false
    }

    if (!values.description_transaction) {
      message.error('Descrição da transação é obrigatória')
      return false
    }

    return dispatch(
      walletTransaction(item.user_id, {
        value: values.value,
        category_transaction: values.type,
        consolidated_wallet_id: item.consolidated_id,
        type: values.type === 2 ? 'DEBIT' : 'CREDIT',
        description_transaction: values.description_transaction,
      })
    )
  }

  function onSelectChange(selectedItems) {
    return setSelectedRowKeys(selectedItems)
  }

  useEffect(() => {
    getSales()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (sales.success) {
      const { offset, limit } = filters
      const pageLimit = offset * limit
      setPagination({
        total: Number(sales.count),
        pageSize: sales.data.length,
        current: pagination.current > 0 ? pageLimit - limit : 1,
      })
      setItems(sales.data)
      setCount(sales.count)
      if (sales.data.length > 0) {
        onClick(sales.data[0])
      }
      setLoading(false)
      sales.success = false
    }

    if (getSaled.success) {
      setConsolidates(getSaled.data)
      setLoadingDetail(false)
      getSaled.success = false
    }

    if (walletTransactioned.success) {
      getSales()
      message.success('Transação foi realizada com sucesso!')
      walletTransactioned.success = false
    }

    if (walletTransactioned.error) {
      getSales()
      message.error(walletTransactioned.message)
      walletTransactioned.error = false
    }

    if (consolidateWallet.success) {
      getSales()
      setLoading(false)
      message.success('Valores consolidados com sucesso!')
      consolidateWallet.success = false
    }

    if (consolidateWallet.error) {
      setLoading(false)
      message.error(consolidateWallet.message)
      consolidateWallet.error = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sales,
    filters,
    pagination,
    getSaled,
    consolidateWallet,
    walletTransactioned,
  ])

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  function submitFilter(values) {
    return values
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col lg={items.length > 0 ? 12 : 24}>
          <Space style={{ marginBottom: 10 }}>
            <Header
              form={formFilter}
              visible={visible}
              submitFilter={submitFilter}
              onReset={() => formFilter.resetFields()}
              onVisible={() => setVisible((prev) => !prev)}
            />
            {selectedRowKeys.length > 0 && (
              <Button
                key="1"
                shape="round"
                type="primary"
                loading={loading}
                disabled={loading}
                onClick={onFinishConsolidate}
              >
                Consolidar
              </Button>
            )}
            <span key="2">
              {selectedRowKeys.length > 0 &&
                `Itens selecionados ${selectedRowKeys.length}`}
            </span>
          </Space>
          <Table
            data={items}
            count={count}
            loading={loading}
            columns={columns}
            onClick={onClick}
            total={pagination.total}
            rowSelection={rowSelection}
            current={pagination.current}
            pageSize={pagination.pageSize}
            handlePagination={handlePagination}
          />
        </Col>
        <Col lg={12} md={12} xl={12}>
          {items.length > 0 && (
            <Affix offsetTop={10}>
              <Detail
                form={form}
                submit={submit}
                items={consolidates}
                loading={loadingDetail}
              />
            </Affix>
          )}
        </Col>
      </Row>
    </>
  )
}

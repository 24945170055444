import axios from 'axios'

import { API_BASE_URL } from '~/config/env'

export const baseURL = `${API_BASE_URL}/v1/`

const token = localStorage.getItem('@Mission:token')

const api = axios.create({
  baseURL,
})

if (token !== null) {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

function success(response) {
  if (response.status === 401) {
    localStorage.removeItem('@Mission:token')
  }
  return response
}

function fail(error) {
  if (error.status === 401) {
    localStorage.removeItem('@Mission:token')
  }

  return Promise.reject(error)
}

api.interceptors.response.use(success, fail)

export default api

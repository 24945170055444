import React from 'react'

import {
  FormOutlined,
  RocketOutlined,
  UserAddOutlined,
  ShoppingOutlined,
  DashboardOutlined,
  LineChartOutlined,
  DownCircleOutlined,
  LockOutlined,
  DollarCircleOutlined,
  ThunderboltOutlined,
  LinkOutlined,
} from '@ant-design/icons'

export const items = [
  {
    icon: <DashboardOutlined />,
    label: 'Dashboard',
    key: '1',
    path: '/dashboard',
    validation: 'dashboard',
  },
  {
    icon: <LockOutlined />,
    key: '2',
    label: 'Contas Privadas',
    path: '/accounts-private',
    validation: 'private_accounts',
  },
  {
    icon: <LineChartOutlined />,
    key: '3',
    label: 'BI - Analytics',
    path: '/analytics',
    validation: 'analytics',
  },
  {
    icon: <ThunderboltOutlined />,
    key: '4',
    label: 'Workforce Manager',
    path: '/workforce',
    validation: 'workforce',
  },
  {
    key: 'sub-4',
    icon: <DollarCircleOutlined />,
    label: 'Financeiro',
    validation: 'payments',
    children: [
      {
        key: 'sub-4-1',
        label: 'Transações',
        path: '/transactions',
      },
      {
        key: 'sub-4-2',
        label: 'Pagamentos',
        path: '/payments',
      },
    ],
  },
  {
    key: 'sub-5',
    icon: <RocketOutlined />,
    label: 'Missões',
    validation: 'missions',
    children: [
      {
        key: 'sub51',
        label: 'Lançar',
        path: '/missions/launch',
      },
      {
        key: 'sub52',
        label: 'Atividades',
        path: '/missions/feeds',
      },
      // {
      //   key: 'sub53',
      //   label: 'Agendamentos',
      //   path: '/missions/schedules',
      // },
      {
        key: 'sub54',
        label: 'Lançadas',
        path: '/missions',
      },
      {
        key: 'sub55',
        label: 'Finalizadas',
        path: '/missions/registers',
      },
      {
        key: 'sub56',
        label: 'Solicitações',
        path: '/missions/requested',
      },
    ],
  },
  {
    icon: <DownCircleOutlined />,
    key: '6',
    label: 'Exportação',
    path: '/reports',
    validation: 'reports',
  },
  {
    icon: <ShoppingOutlined />,
    key: '7',
    label: 'Produtos',
    validation: 'products',
    path: '/products',
  },
  {
    key: '8',
    icon: <FormOutlined />,
    label: 'Formulários',
    path: '/forms',
    validation: 'forms',
  },
  {
    key: '9',
    icon: <UserAddOutlined />,
    label: 'Sub-contas',
    path: '/accounts',
    validation: 'accounts',
  },
  {
    key: '10',
    icon: <LinkOutlined />,
    label: 'Webhook',
    path: '/webhook',
    validation: 'webhook',
  },
]

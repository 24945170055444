import React, { useEffect, useState } from 'react'

import { Select, Space, Button } from 'antd'
import t from 'prop-types'

import ListCards from '~/components/organisms/ListCards'
import useProductsCustomers from '~/hooks/useProductsCustomers'
import useProductsMissions from '~/hooks/useProductsMissions'

import 'react-quill/dist/quill.snow.css'

const { Option } = Select

function Products({ missionId }) {
  const [productSelected, setProductSelected] = useState(null)

  const {
    products,
    onRemove,
    onCreate,
    loadingProducts,
    getProductsMissions,
  } = useProductsMissions(missionId)
  const {
    getProducts,
    productsCustomers,
    loadingProductsCustomers,
  } = useProductsCustomers()

  function onChange(value) {
    setProductSelected(value)
  }

  function onSubmit() {
    return onCreate({
      missionId,
      productsCustomersId: productSelected,
    })
  }

  useEffect(() => {
    getProducts()
  }, []) //eslint-disable-line

  useEffect(() => {
    getProductsMissions()
  }, []) //eslint-disable-line

  return (
    <>
      <Space style={{ marginBottom: 10 }}>
        <Select
          showSearch
          onChange={onChange}
          style={{ width: 200 }}
          optionFilterProp="children"
          loading={loadingProductsCustomers}
          placeholder="Adicione uma Categoria de Produto"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {productsCustomers.map((i) => (
            <Option value={i.uid}>{i.title}</Option>
          ))}
        </Select>
        <Button type="primary" onClick={onSubmit} loading={loadingProducts}>
          Adicionar
        </Button>
      </Space>
      <ListCards
        hasRemove
        data={products}
        onClickRemove={onRemove}
        loading={loadingProducts}
      />
    </>
  )
}

Products.propTypes = {
  missionId: t.string,
}

export default Products

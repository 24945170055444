import React, { useEffect, useState } from 'react'

import { List, Select, Switch, message } from 'antd'

import useCustomersAccounts from '~/hooks/useCustomersAccounts'
import useCustomersNotifications from '~/hooks/useCustomersNotifications'
import { onConvertArrayToObject } from '~/utils/helpers'

function Notifications() {
  const [emails, setEmails] = useState(null)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [showSwitch, setShowSwitch] = useState(true)
  const {
    loading,
    getCustomersNotifications,
    customersNotifications,
    customersNotificationsType,
    onUpdateCustomersNotifications,
  } = useCustomersNotifications()

  const { getCustomersAccounts, customersAccountsOptions } =
    useCustomersAccounts()

  function onSwitch({ id, status }) {
    const emailExist = emails[id]

    setCurrentStatus((prev) => ({ ...prev, [id]: status }))

    if (emails === null || !emailExist) {
      setCurrentStatus((prev) => ({ ...prev, [id]: false }))
      return message.error('Ative apenas se tiver algum e-mail adicionado')
    }

    currentStatus[id] = status

    return onUpdateCustomersNotifications({
      status,
      notification_type: id,
      senders: emails[id].split(','),
    })
  }

  function isNotEmpty(type) {
    return emails !== null && emails[type] !== null && emails[type]?.length > 0
  }

  function onChange({ value, id }) {
    const status = currentStatus ? currentStatus[id] : false

    if (value.length === 0 && status) {
      return message.error(
        'É necessário inativar o push para remover este e-mail'
      )
    }

    const updateData = {
      notification_type: id,
      senders: value,
      status: currentStatus ? currentStatus[id] : false,
    }

    if (value.length === 0) {
      delete emails[id]
      setEmails((prev) => ({ ...prev, ...emails }))
    }

    onUpdateCustomersNotifications(updateData)

    return setEmails((prev) => ({ ...prev, [id]: `${value?.join(',')}` }))
  }

  useEffect(() => {
    getCustomersAccounts({ limit: 1000, offset: 0, search: '' })
  }, []) // eslint-disable-line

  useEffect(() => {
    let sub = false

    if (!sub) {
      getCustomersNotifications()
    }

    return () => {
      sub = true
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    let sub = false

    if (!loading && !sub) {
      ;(async () => {
        if (customersNotifications.length > 0) {
          const emailsItems = onConvertArrayToObject(
            customersNotifications,
            'notification_type',
            'senders'
          )
          const currentStatusItems = onConvertArrayToObject(
            customersNotifications,
            'notification_type',
            'status'
          )
          setCurrentStatus((prev) => ({ ...prev, ...currentStatusItems }))
          setEmails((prev) => ({ ...prev, ...emailsItems }))
        }

        setTimeout(() => setShowSwitch(false), 500)
      })()
    }

    return () => {
      sub = true
    }
  }, [loading]) //eslint-disable-line

  return (
    <List
      bordered
      size="large"
      loading={loading}
      dataSource={customersNotificationsType}
      renderItem={(item) => (
        <>
          <List.Item
            key={item.title}
            extra={
              !showSwitch ? (
                <Switch
                  loading={loading}
                  disabled={loading}
                  checked={
                    currentStatus !== null ? currentStatus[item?.id] : false
                  }
                  defaultChecked={
                    currentStatus !== null ? currentStatus[item?.id] : false
                  }
                  onChange={(status) => onSwitch({ id: item.id, status })}
                />
              ) : null
            }
          >
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>

          <List.Item key={`${item.id}-${item.title}`}>
            <Select
              mode="tags"
              tokenSeparators={[',']}
              style={{ width: '100%' }}
              placeholder="Selecione os emails"
              options={customersAccountsOptions || []}
              onChange={(value) => onChange({ value, id: item.id })}
              value={ isNotEmpty(item.id) ? emails?.[item.id]?.split(',') : []}
            />
          </List.Item>
        </>
      )}
    />
  )
}

export default Notifications

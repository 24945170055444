import React from 'react'

import { Result } from 'antd'

const Invalid = () => (
  <Result
    status="error"
    title="Referenciador não encontrado"
    subTitle="Gentileza solicitar link válido ao responsável pelo site de origem"
  />
)

export default Invalid

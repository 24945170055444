import styled from 'styled-components'

export const MarkerIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  cursor: pointer;
  background-color: red;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-end-end-radius: 5px;
`

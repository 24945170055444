import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Input, Row, Button } from 'antd'
import t from 'prop-types'

const Header = ({ onSearch, handleCleanForm }) => (
  <PageHeader style={{ marginBottom: 10 }}>
    <Row justify="space-between">
      <div>
        <Input.Search onChange={onSearch} placeholder="Buscar..." />
      </div>
      <Button
        key="0"
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleCleanForm}
      >
        Novo Formulário
      </Button>
    </Row>
  </PageHeader>
)

Header.defaultTypes = {
  onSearch: t.func.isRequired,
  handleCleanForm: t.func.isRequired,
}

Header.propTypes = {
  onSearch: t.func.isRequired,
  handleCleanForm: t.func.isRequired,
}

export default Header

import React from 'react'
import { Bar, Doughnut } from 'react-chartjs-2'

import { Card, Row, Col } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import { ContainerChart } from './styles'

function Charts({ adherenceGraphicBar, adherenceGraphicPizza }) {
  const customOptionsToBar = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: '#FFFFFF',
        labels: {
          title: {
            font: {
              size: 16,
              weight: 'bold',
            },
          },
        },
        formatter(value) {
          return `${value}%`
        },
      },
    },
  }

  const dataBar = {
    labels: adherenceGraphicBar?.labels.map((i) =>
      dayjs(i).format('DD/MM/YYYY')
    ),
    datasets: [
      {
        label: 'Aderência %',
        data: adherenceGraphicBar?.data,
        backgroundColor: '#3ad089',
      },
    ],
  }

  const dataPizza = {
    labels: adherenceGraphicPizza?.labels,
    datasets: [
      {
        data: adherenceGraphicPizza?.data,
        backgroundColor: ['#3ad089', '#fe5565'],
      },
    ],
  }

  return (
    <Row gutter={16}>
      <Col sm={24} xl={12}>
        <Card>
          <ContainerChart>
            <Bar
              options={{ ...options, ...customOptionsToBar }}
              data={dataBar}
            />
          </ContainerChart>
        </Card>
      </Col>
      <Col sm={24} xl={12}>
        <Card>
          <ContainerChart>
            <Doughnut options={options} data={dataPizza} />
          </ContainerChart>
        </Card>
      </Col>
    </Row>
  )
}

Charts.propTypes = {
  adherenceGraphicBar: t.objectOf(t.arrayOf(t.string)),
  adherenceGraphicPizza: t.objectOf(t.arrayOf(t.string)),
}

export default Charts

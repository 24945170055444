/* eslint-disable camelcase */
import React, { useEffect } from 'react'

import { StarFilled } from '@ant-design/icons'
import { Row, Col, Statistic } from 'antd'
import t from 'prop-types'

import useRateUser from '~/hooks/useRateUser'

const hasScore = ({ title, score }) => (
  <Statistic
    title={title}
    suffix={score ? ' / 5' : null}
    value={score ? Number(score).toFixed(1) : 'Sem Nota'}
    prefix={score ? <StarFilled style={{ color: 'gold' }} /> : null}
  />
)

export function UserRate({ userId }) {
  const { getRateUser, rateScore } = useRateUser()

  useEffect(() => {
    if (userId) {
      getRateUser({ userId })
    }
  }, []) //eslint-disable-line

  return (
    <Row>
      <Col span={12}>
        {hasScore({ score: rateScore?.score_all, title: 'Mission Brasil' })}
      </Col>
      <Col span={12}>
        {hasScore({ score: rateScore?.score_customer, title: 'Cliente' })}
      </Col>
    </Row>
  )
}

UserRate.propTypes = {
  userId: t.string,
}

hasScore.propTypes = {
  title: t.string.isRequired,
  score: t.shape,
}

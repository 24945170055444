/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import { Calendar, Steps, Row, Col, Card } from 'antd'
import * as d3 from 'd3'
import dayjs from 'dayjs'

import useMissions from '~/hooks/useMissions'
import useSearchLocation from '~/hooks/useSearchLocation'

import {
  Categories,
  Confirm,
  Footer,
  Forms,
  Info,
  Local,
  Attachment,
  Products,
} from './components'

const position = {
  latitude: -8.269214,
  longitude: -55.441382,
}

const animationViewport = {
  zoom: 10,
  transitionDuration: 5000,
  transitionEasing: d3.easeCubic,
  transitionInterpolator: new FlyToInterpolator(),
}

const initialSteps = [
  {
    title: 'Categoria',
    description: 'Escolha uma categoria',
    key: 'category',
  },
  {
    title: 'Data de Expiração',
    description: 'Qual é o prazo de finalização?',
    key: 'calendar',
  },
  {
    title: 'Info',
    description: 'Detalhes da missão',
    key: 'info',
  },
  {
    title: 'Local',
    description: 'Caso for localizada, digite o endereço corretamente.',
    key: 'location',
  },
  {
    title: 'Finalizar',
    description: 'Por favor, confirme os dados da missão.',
    key: 'done',
  },
]

export default function MissionLaunch() {
  const { onGeocodeFormat } = useSearchLocation()
  const [current, setCurrent] = useState(0)
  const [categoryName, setCategoryName] = useState('')
  const [hasLocation, setHasLocation] = useState(false)
  const [viewport, setViewport] = useState({
    zoom: 0,
    height: 560,
    ...position,
    width: window.innerWidth,
  })
  const [mission, setMission] = useState({
    name: null,
    lat: null,
    lng: null,
    place: null,
    identifier: null,
    description: null,
    custom_distance: null,
    missions_main_id: null,
    expired_at: dayjs().add(3, 'days'),
  })

  const {
    customFields,
    setCustomFields,
    onCreateMission,
    loadingMissionCreate,
    onChangeInputValueCustomFields,
  } = useMissions()

  const [items, setItems] = useState(initialSteps)

  function handleChange(key, value) {
    return setMission((prev) => ({ ...prev, [key]: value }))
  }

  function onUploadFile(files) {
    return handleChange('attachments', files)
  }

  async function onClickForm(formId) {
    handleChange('forms_id', formId)
    return setCurrent((prev) => prev + 1)
  }

  async function onClickProduct(products) {
    return handleChange('products', products)
  }

  async function onClick(value) {
    if (current === 0) {
      setHasLocation(value.no_location)

      setMission((prev) => ({
        ...prev,
        name: value.title,
        description: value.description,
        missions_main_id: value.mission_main_id,
      }))

      if (value.custom_fields) {
        setCustomFields(value.custom_fields.fields)
      }

      if (value.has_forms) {
        items.splice(1, 0, {
          title: 'Formulário',
          description: 'Escolha um formulário',
          key: 'forms',
        })
      }

      if (value.has_products) {
        items.splice(2, 0, {
          title: 'Produtos',
          description: 'Escolha os produtos',
          key: 'products',
        })
      }

      if (value.has_attachment) {
        items.splice(3, 0, {
          title: 'Anexo',
          description: 'Adicione pelo menos um anexo',
          key: 'attach',
        })
      }
    }

    setTimeout(() => {
      setCategoryName(value?.title)
    }, 1000)

    return setCurrent((prev) => prev + 1)
  }

  function handleChangeInfoForms(values) {
    return values.map((i) => handleChange(i.name, i.value))
  }

  async function handleAddress(value) {
    setViewport({
      ...viewport,
      ...animationViewport,
      zoom: 14,
      latitude: value.geometry.location.lat,
      longitude: value.geometry.location.lng,
    })

    const result = await onGeocodeFormat(value.address_components)

    return setMission((prev) => ({
      ...prev,
      ...value.geometry.location,
      city: result.city || null,
      uf: result.state || null,
      place: value.formatted_address,
    }))
  }

  const childrenComponents = {
    forms: <Forms onClick={(formId) => onClickForm(formId)} />,
    category: <Categories onClick={(value) => onClick(value)} />,
    products: <Products onClick={(product) => onClickProduct(product)} />,
    calendar: (
      <Calendar
        onSelect={(value) =>
          handleChange('expired_at', value.format('YYYY-MM-DD 18:00'))
        }
      />
    ),
    info: (
      <Info
        customFields={customFields}
        onChangeInputValueCustomFields={onChangeInputValueCustomFields}
        mission={mission}
        onChange={(values) => handleChangeInfoForms(values)}
        onChangeDescription={(value) => handleChange('description', value)}
      />
    ),
    location: (
      <Local
        mission={mission}
        viewport={viewport}
        hasLocation={hasLocation}
        onClick={(value) => handleAddress(value)}
        onChangeViewport={(newViewPort) => setViewport(newViewPort)}
      />
    ),
    attach: <Attachment mission={mission} onUploadFile={onUploadFile} />,
    done: <Confirm mission={mission} category={categoryName} />,
  }

  return (
    <Row>
      <Col lg={6} md={6}>
        <Steps current={current} direction="vertical" items={items} />
      </Col>
      <Col lg={18} md={18}>
        <Card
          title={`${items[current].title} - ${items[current].description}`}
          style={{ height: 580, maxHeight: 580, overflowX: 'auto' }}
          extra={
            <Footer
              items={items}
              current={current}
              setCurrent={setCurrent}
              loading={loadingMissionCreate}
              onInitial={() => setItems(initialSteps)}
              onCreate={() =>
                onCreateMission({ ...mission, custom_fields: customFields })
              }
              isDisable={mission.missions_main_id === null}
            />
          }
        >
          {childrenComponents[items[current].key]}
        </Card>
      </Col>
    </Row>
  )
}

import React from 'react'
import NumberFormat from 'react-number-format'

import { Modal, Button, Input, Checkbox } from 'antd'
import t from 'prop-types'

const ModalConvertPoints = ({
  open,
  onSubmit,
  onCancel,
  loading,
  onChange,
  onChangeBonus,
  valuePerPoints,
}) => (
  <Modal
    visible={open}
    title="Conversão de Valor em Pontos"
    onOk={onSubmit}
    onCancel={onCancel}
    footer={[
      <Button key="back" onClick={onCancel}>
        Cancelar
      </Button>,
      <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
        Enviar
      </Button>,
    ]}
  >
    <NumberFormat
      prefix="R$"
      disabled={loading}
      customInput={Input}
      decimalSeparator=","
      onValueChange={onChange}
      value={valuePerPoints || ''}
      thousandsGroupStyle="thousand"
      placeholder="Valor Convertido em Pontos (Ex: R$5*10=R$50)"
    />

    <Checkbox
      style={{ marginTop: 20 }}
      onChange={(e) => onChangeBonus({ hasBonus: Boolean(e.target.checked) })}
    >
      Adicionar Bônus na Conversão
    </Checkbox>
  </Modal>
)

ModalConvertPoints.propTypes = {
  open: t.bool,
  onSubmit: t.func,
  onCancel: t.func,
  loading: t.bool,
  onChange: t.func,
  onChangeBonus: t.func,
  valuePerPoints: t.number,
}

export default ModalConvertPoints

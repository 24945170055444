import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useApiCredentials() {
  const [credentials, setCredentials] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const hasGeneratedApiCredentials = () => credentials !== null

  const getCredentials = async () => {
    try {
      setIsLoading(true)
      const response = await api.get('customers/api-credentials')
      setCredentials(response.data)
    } catch (error) {
      setCredentials(null)
    } finally {
      setIsLoading(false)
    }
  }

  const generateCredentials = async () => {
    try {
      setIsLoading(true)
      const response = await api.post('customers/api-credentials')
      setCredentials(response.data)
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Ocorreu um erro inesperado.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getCredentials,
    generateCredentials,
    isLoading,
    credentials,
    hasGeneratedApiCredentials,
  }
}

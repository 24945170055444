import React from 'react'

import { Button, Form, Input, Select } from 'antd'
import t from 'prop-types'

const UserData = ({ form, tags, loading, handleSubmit }) => {
  console.log({ tags })
  return (
    <>
      <div className="content-login-header">
        <h3>Cadastre-se</h3>
      </div>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome Completo"
          name="name"
          rules={[
            {
              required: true,
              message: 'Digite seu nome completo',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Digite seu email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nova Senha"
          name="password"
          rules={[
            {
              required: true,
              message: 'Digite sua senha',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmar Senha"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Digite sua senha',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error('As senhas devem estar iguais!')
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        {tags !== null && (
          <Form.Item
            label={tags.name}
            name="customers_users_tags_id"
            rules={[{ required: true, message: `Selecione um ${tags.name}` }]}
          >
            <Select
              showSearch
              placeholder={tags.name}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) => {
                return optionA.label.localeCompare(optionB.label, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              }}
              options={tags?.tags?.items.map((i) => ({
                value: i.id,
                label: i.value,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Cadastre-se
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

UserData.propTypes = {
  form: t.objectOf(t.any),
  loading: t.bool,
  tags: t.arrayOf(t.any),
  handleSubmit: t.func,
}

export default UserData

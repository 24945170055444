import React from 'react'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import t from 'prop-types'

import { MAPBOX_TOKEN } from '~/config/env'

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

const pinStyle = {
  cursor: 'pointer',
  fill: '#d00',
  stroke: '#000',
  strokeWidth: 2,
}

const Map = ({
  missions,
  viewport,
  onChange,
  selectedMission,
  handleClosePopUp,
}) => (
  <AutoSizer>
    {({ width }) => (
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onViewportChange={onChange}
        mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
        width={width}
        height={600}
      >
        {missions.map((mission, index) => (
          <Marker
            offsetTop={0}
            offsetLeft={0}
            latitude={mission.lat}
            longitude={mission.lng}
            key={Number(index)}
            className={selectedMission.name === mission.name ? 'active' : ''}
          >
            <svg
              height={24}
              viewBox="-1.5 -1.5 27 27"
              style={{
                ...pinStyle,
                transform: `translate(${-24 / 2}px,${-24}px)`,
              }}
            >
              <path d={ICON} />
            </svg>
          </Marker>
        ))}

        {!!selectedMission.name && (
          <Popup
            offsetTop={-28}
            latitude={selectedMission.lat}
            longitude={selectedMission.lng}
            onClose={handleClosePopUp}
          >
            <div style={{ marginTop: 10 }}>{selectedMission.name}</div>
          </Popup>
        )}
      </ReactMapGL>
    )}
  </AutoSizer>
)

Map.propTypes = {
  data: t.arrayOf(t.any),
  missions: t.arrayOf(t.any),
  viewport: t.objectOf(t.any),
  selectedMission: t.objectOf(t.any),
  handleClosePopUp: t.func,
  onChange: t.func,
}

export default Map

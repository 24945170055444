import React, { useEffect, useState } from 'react'

import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MoreOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from '@ant-design/icons'
import { Col, Row, Input, Space, Upload, Button, Tooltip, Collapse } from 'antd'
import t from 'prop-types'
import { isNil } from 'ramda'

import Loading from '~/components/atoms/Loading'

import PreviewImage from '../PreviewImage'
import styles from './styles'

const { Panel } = Collapse

const SelectItemUpdate = ({
  items,
  removeItem,
  uploadProps,
  loadingFile,
  onClickOpcional,
  handleRemoveImage,
  reorderMultiples,
  onPressEnterQuestionMultiple,
  handleOnBlurQuestionMultiple,
  loadingMultiples,
}) => {
  const [titleQuestionsMultiples, setTitleQuestionsMultiples] = useState('')
  const [valuesQuestionsMultiples, setValuesQuestionsMultiples] = useState([])
  const [updateTitle, setUpdateTitle] = useState({})

  function handleOnChange(event) {
    const { value } = event.target
    setTitleQuestionsMultiples(value)
  }

  function handleOnPressEnterQuestionMultiple(formsItemsId, title) {
    onPressEnterQuestionMultiple(formsItemsId, title)

    setTitleQuestionsMultiples('')
  }

  function handleOnChangeQuestionsMultiples(item, value) {
    const indexOfMultiple = valuesQuestionsMultiples.findIndex(
      (multiple) => multiple.id === item.id
    )

    setValuesQuestionsMultiples((prevState) =>
      prevState.map((obj, index) =>
        index === indexOfMultiple ? { ...obj, title: value } : obj
      )
    )

    setUpdateTitle((prevState) => ({
      ...prevState,
      uid: item.uid,
      title: value,
    }))
  }

  function handleOnBlur() {
    handleOnBlurQuestionMultiple(updateTitle)
    setUpdateTitle({})
  }

  useEffect(() => {
    setValuesQuestionsMultiples(items)
  }, [items])

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header={`${items.length} Respostas`} key="1">
        <Space direction="vertical" style={styles.widthFlex}>
          <Row gutter={16} title="Adicionar itens">
            <Col span={24}>
              <Input
                value={titleQuestionsMultiples}
                onChange={(event) => handleOnChange(event)}
                onPressEnter={() =>
                  handleOnPressEnterQuestionMultiple(
                    items[0].forms_items_id,
                    titleQuestionsMultiples
                  )
                }
                placeholder="Adicionar respostas...(Pressione ENTER para Salvar)"
              />
            </Col>
          </Row>
          {loadingMultiples ? (
            <Loading />
          ) : (
            <>
              {valuesQuestionsMultiples?.map((item, key) => (
                <Row gutter={16} key={`ind-${item.uid}`}>
                  <Col span={24}>
                    <Input
                      onBlur={() => handleOnBlur()}
                      value={item.title}
                      onChange={(e) =>
                        handleOnChangeQuestionsMultiples(item, e.target.value)
                      }
                      addonAfter={
                        <Button.Group>
                          <Tooltip title="Remover">
                            <Button
                              shape="link"
                              icon={<DeleteOutlined />}
                              onClick={() => removeItem(item)}
                            />
                          </Tooltip>

                          <Tooltip title="Opcional">
                            <Button
                              shape="link"
                              icon={<MoreOutlined />}
                              onClick={() => onClickOpcional(item)}
                            />
                          </Tooltip>

                          <Upload {...uploadProps(item)}>
                            <Tooltip title="Adicionar Imagem">
                              <Button
                                shape="link"
                                icon={<FileImageOutlined />}
                              />
                            </Tooltip>
                          </Upload>

                          {!isNil(item.url_image) && (
                            <Tooltip title="Preview Imagem">
                              <PreviewImage
                                item={item}
                                loading={loadingFile}
                                handleRemove={handleRemoveImage}
                              />
                            </Tooltip>
                          )}
                          {items[0] !== item && (
                            <Tooltip title="Mover para cima">
                              <Button
                                shape="link"
                                icon={<ArrowUpOutlined />}
                                onClick={() =>
                                  reorderMultiples(items, key, 'up')
                                }
                              />
                            </Tooltip>
                          )}
                          {items[items.length - 1] !== item && (
                            <Tooltip title="Mover para baixo">
                              <Button
                                shape="link"
                                icon={<ArrowDownOutlined />}
                                onClick={() =>
                                  reorderMultiples(items, key, 'down')
                                }
                              />
                            </Tooltip>
                          )}
                        </Button.Group>
                      }
                    />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Space>
      </Panel>
    </Collapse>
  )
}

SelectItemUpdate.propTypes = {
  items: t.arrayOf(t.objectOf(t.string)).isRequired,
  removeItem: t.func.isRequired,
  uploadProps: t.func.isRequired,
  loadingFile: t.bool.isRequired,
  onClickOpcional: t.func.isRequired,
  handleRemoveImage: t.func.isRequired,
  reorderMultiples: t.func.isRequired,
  onPressEnterQuestionMultiple: t.func.isRequired,
  handleOnBlurQuestionMultiple: t.func.isRequired,
  loadingMultiples: t.bool,
}

export default SelectItemUpdate

import React, { useState } from 'react'
import { Marker, Popup } from 'react-map-gl'

import { PushpinOutlined } from '@ant-design/icons'
import t from 'prop-types'

import { MarkerIcon } from './styles'

const CurrentMarker = ({ lat, lng, place }) => {
  const [toggle, setToggle] = useState(false)

  return (
    <>
      <Popup
        latitude={lat}
        longitude={lng}
        closeButton
        offsetTop={50}
        offsetLeft={25}
        closeOnClick={toggle}
        onClose={() => setToggle(false)}
        anchor="top"
      >
        <div>{place}</div>
      </Popup>
      <Marker
        offsetTop={0}
        offsetLeft={0}
        latitude={lat}
        longitude={lng}
        className="marker"
        key="current-place-01"
      >
        <MarkerIcon>
          <PushpinOutlined style={{ color: 'white', fontSize: 15 }} />
        </MarkerIcon>
      </Marker>
    </>
  )
}

CurrentMarker.propTypes = {
  lat: t.number.isRequired,
  lng: t.number.isRequired,
  place: t.number.isRequired,
}

export default CurrentMarker

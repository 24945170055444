import React, { useEffect } from 'react'

import { Form, Button, Input, Card } from 'antd'
import t from 'prop-types'

export default function UserDataForm({
  values,
  loading,
  handleSubmit,
  handleFailed,
}) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <Card title="Dados da Conta">
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleFailed}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: 'Digite o Nome por favor!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ required: true, message: 'Digite seu E-mail por favor!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

UserDataForm.defaultTypes = {
  loading: false,
}

UserDataForm.propTypes = {
  values: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
  handleFailed: t.func.isRequired,
}

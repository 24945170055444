import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Row, Col, Modal, Form, DatePicker } from 'antd'
import { format, subDays } from 'date-fns'

import useMetrics from '~/hooks/useMetrics'
import useRegisters from '~/hooks/useRegisters'
import Conversion from '~/pages/Dashboard/components/Charts/Conversion'
import MarketShare from '~/pages/Dashboard/components/Charts/MarketShare'

import HeaderFilter from './components/Header'
import WaitingReview from './components/WaitingReview'

export default function Dashboard() {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [openFilter, setOpenFilter] = useState(false)

  const [filters, setFilters] = useState({
    initial: format(new Date(subDays(new Date(), 180)), 'yyyy-MM-dd'),
    final: format(new Date(), 'yyyy-MM-dd'),
  })
  const { missions, loadingMissions, registersCount, getMissions } =
    useRegisters()
  const {
    loadingDashboard,
    dashboard,
    missionsMain,
    getMetricsDashboard,
    getAverageMarketShareMissionsMain,
    getAverageMarketShareApprovedByState,
    approvedByState,
    getAverageMarketShareLaunchByState,
    launchByState,
    getRegisterDataPeriod,
    registerDataPeriod,
    loadingMissionsFeed,
  } = useMetrics()

  const [limit, setLimit] = useState(15)

  function loadMore() {
    const newLimit = limit + 15
    setLimit(newLimit)
    return getMissions({ status: ['0'], limit: newLimit })
  }

  const getMissionsCompleted = useCallback(() => {
    getMissions({ status: ['0'], limit })
  }, [getMissions, limit])

  function onChangeFilters(values) {
    return setFilters({ ...filters, ...values })
  }

  function onSubmitFilters() {
    const initial = format(new Date(filters.initial), 'yyyy-MM-dd')
    const final = format(new Date(filters.final), 'yyyy-MM-dd')

    getMetricsDashboard({ initial, final })

    setOpenFilter(false)

    return getRegisterDataPeriod({ initial, final })
  }

  function onChange(value) {
    if (value === null) {
      return setOpenFilter(true)
    }
    const initial = format(subDays(new Date(), value), 'yyyy-MM-dd')
    const final = format(new Date(), 'yyyy-MM-dd')

    getMetricsDashboard({ initial, final })

    return setTimeout(() => getRegisterDataPeriod({ initial, final }), 500)
  }

  function getDataOfMarketShare() {
    return Promise.all([
      getAverageMarketShareMissionsMain(),
      getAverageMarketShareApprovedByState(),
      getAverageMarketShareLaunchByState(),
      getRegisterDataPeriod({ ...filters }),
    ])
  }

  useEffect(() => {
    const init = Promise.all([
      getMetricsDashboard(filters),
      getMissionsCompleted(),
      getDataOfMarketShare(),
    ])

    return () => init
  }, []) //eslint-disable-line

  return (
    <>
      <HeaderFilter
        key="header"
        counts={dashboard}
        onChange={(value) => onChange(value)}
        loading={loadingMissionsFeed}
      />

      <Row gutter={[8, 8]} key="1">
        <Col xl={16} md={14} sm={24} xs={24}>
          <Conversion loading={loadingMissionsFeed} data={registerDataPeriod} />
        </Col>
        <Col xl={8} md={10} sm={24} xs={24}>
          <WaitingReview
            limit={limit}
            items={missions}
            count={Number(registersCount)}
            loading={loadingMissions}
            loadMore={() => loadMore()}
            onClick={() => navigate('/missions/registers')}
          />
        </Col>
      </Row>

      <Row gutter={[8, 8]} style={{ marginTop: 10 }} key="2">
        <Col xl={8} md={24} sm={24} xs={24} key="col-1">
          <MarketShare
            values={launchByState}
            title="Criadas"
            loading={loadingDashboard}
          />
        </Col>
        <Col xl={8} md={24} sm={24} xs={24} key="col-2">
          <MarketShare
            values={approvedByState}
            title="Aprovações"
            loading={loadingDashboard}
          />
        </Col>
        <Col xl={8} md={24} sm={24} xs={24} key="col-3">
          <MarketShare
            values={missionsMain}
            title="Categorias"
            loading={loadingDashboard}
          />
        </Col>
      </Row>
      <Modal
        width={400}
        open={openFilter}
        onOk={() => onSubmitFilters()}
        confirmLoading={loadingDashboard}
        title="Período de Customizado"
        onCancel={() => setOpenFilter(false)}
      >
        <Form
          form={form}
          layout="inline"
          onValuesChange={(values) => onChangeFilters(values)}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Form.Item name="initial" required={filters.initial}>
            <DatePicker placeholder="Data Inicial" />
          </Form.Item>
          <Form.Item name="final" required={filters.final}>
            <DatePicker placeholder="Data Final" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import { FunctionOutlined } from '@ant-design/icons'
import { Select, Input, Button, Popover, Form } from 'antd'
import t from 'prop-types'

const { Option } = Select

const ContentOperator = ({ value, warningData, onChange }) => {
  const [_operator, setOperator] = useState(value?.operator_warning?.operator)

  return (
    <Form layout="vertical">
      <Form.Item label="Operador">
        <Select
          value={warningData?.operator_warning?.operator}
          onChange={(op) => {
            setOperator(op)
            onChange('operator', op)
          }}
        >
          <Option value=">">Maior que</Option>
          <Option value=">=">Maior ou Igual que</Option>
          <Option value="=">Igual que</Option>
          <Option value="<">Menor que</Option>
          <Option value="<=">Menor ou Igual que</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Valor">
        <Input
          type="text"
          value={warningData?.operator_warning?.value}
          onChange={({ target }) => onChange('value', target.value)}
        />
      </Form.Item>
      <Form.Item label="Tipo">
        <Select
          value={warningData?.operator_warning?.type}
          onChange={(type) => onChange('type', type)}
        >
          <Option value="text">Texto</Option>
          <Option value="number">Número</Option>
          <Option value="comma">Soma Itens 1,2</Option>
          <Option value="dot_comma">Soma Itens 1;2</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Nome da Coluna">
        <Input
          type="text"
          placeholder="Ex: Resultado"
          value={warningData?.operator_warning?.label_column}
          onChange={({ target }) => onChange('label_column', target.value)}
        />
      </Form.Item>
      <Form.Item label="Caso Verdadeiro">
        <Input
          type="text"
          placeholder="Ex: Apto"
          value={warningData?.operator_warning?.label_success}
          onChange={({ target }) => onChange('label_success', target.value)}
        />
      </Form.Item>
      <Form.Item label="Caso Falso">
        <Input
          type="text"
          placeholder="Ex: Não Apto"
          value={warningData?.operator_warning?.label_error}
          onChange={({ target }) => onChange('label_error', target.value)}
        />
      </Form.Item>
      <Form.Item label="Cor Verdadeiro">
        <Select
          value={warningData?.operator_warning?.color_success}
          onChange={(colorSuccess) => onChange('color_success', colorSuccess)}
        >
          <Option value="error">Erro</Option>
          <Option value="success">Sucesso</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Cor Falso">
        <Select
          value={warningData?.operator_warning?.color_error}
          onChange={(colorError) => onChange('color_error', colorError)}
        >
          <Option value="error">Erro</Option>
          <Option value="success">Sucesso</Option>
        </Select>
      </Form.Item>
    </Form>
  )
}

ContentOperator.propTypes = {
  value: t.objectOf(t.string).isRequired,
  onChange: t.func.isRequired,
  warningData: t.objectOf(t.string),
}

const OperatorAlert = ({
  value,
  onSubmitOperatorWarning,
  onChangeStatusButton,
}) => {
  const [warningData, setWarningData] = useState({})

  function onChange(key, valueData) {
    return setWarningData((prev) => ({
      ...prev,
      operator_warning: { ...prev.operator_warning, [key]: valueData },
    }))
  }

  const handleOnOpenChange = (visible) => {
    if (!visible) {
      if (Object.keys(warningData).length > 0) {
        const formsItemsId = value?.uid
        onSubmitOperatorWarning({ formsItemsId, ...warningData })
      }

      onChangeStatusButton(false)
    } else {
      onChangeStatusButton(true)
    }
  }

  useEffect(() => {
    if (Object.keys(value).length > 0) {
      setWarningData({ operator_warning: { ...value?.operator_warning } })
    }
  }, []) // eslint-disable-line

  return (
    <Popover
      trigger="click"
      placement="topLeft"
      title="Operador de Alerta"
      content={
        <ContentOperator
          value={value}
          warningData={warningData}
          onChange={(key, valueData) => onChange(key, valueData)}
        />
      }
      onOpenChange={handleOnOpenChange}
    >
      <Button shape="round" icon={<FunctionOutlined />} type="ghost">
        Operador de Alerta
      </Button>
    </Popover>
  )
}

OperatorAlert.propTypes = {
  value: t.objectOf(t.string).isRequired,
  onSubmitOperatorWarning: t.func.isRequired,
  onChangeStatusButton: t.bool,
}

export default OperatorAlert

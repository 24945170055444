import React from 'react'

import { LoginOutlined, LogoutOutlined } from '@ant-design/icons'
import { Card, Avatar } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import {
  FlexContainerWithSpaceBetween,
  CheckLabel,
  StatusLabel,
  ContainerRight,
  UserContainer,
  UserLabels,
  Title,
  Subtitle,
  DividerCustom,
} from '../CardDays/styles'
import { Counter } from '../Counter'

const CartItem = (props) => {
  const { date } = props
  const { ratings } = props
  const user = props.users[props.index]

  function hasRating(ratingsData, date, user) {
    return ratingsData?.ratingsMissionsOfUsersPerDay?.[date]?.[user?.uid]
  }

  return (
    <Card key={user.uid} style={{ ...props.style, marginBottom: 6 }}>
      <FlexContainerWithSpaceBetween>
        <UserContainer>
          <Avatar size={25} src={user.avatar}>
            {user?.name.split('')[0]}
          </Avatar>
          <UserLabels>
            <Title>
              {user?.name}{' '}
              {hasRating(ratings, date, user) && (
                <Counter
                  iconName="avgRating"
                  iconColor="#F3D500"
                  description="Média das Avaliações"
                  text={
                    ratings?.ratingsMissionsOfUsersPerDay?.[date]?.[user?.uid]
                  }
                />
              )}
            </Title>
            <Subtitle>{user.mission}</Subtitle>
            {user.has_night_time && <Subtitle>Turno noturno</Subtitle>}
          </UserLabels>
        </UserContainer>
        <ContainerRight>
          <StatusLabel isChecked={user.checkIn || user.checkOut} />
        </ContainerRight>
      </FlexContainerWithSpaceBetween>
      <DividerCustom />

      <FlexContainerWithSpaceBetween>
        <CheckLabel>
          <LoginOutlined /> Check-In{' '}
          {user.checkIn && dayjs(user.checkIn).format('DD/MM/YYYY HH:mm')}
        </CheckLabel>
        <CheckLabel>
          <LogoutOutlined /> Check-Out{' '}
          {user.checkOut && dayjs(user.checkOut).format('DD/MM/YYYY HH:mm')}
        </CheckLabel>
      </FlexContainerWithSpaceBetween>
    </Card>
  )
}

CartItem.propTypes = {
  date: t.string,
  ratings: t.arrayOf(t.objectOf(t.any)),
  users: t.arrayOf(t.objectOf(t.any)),
  index: t.number,
  style: t.objectOf(t.any),
}

export default CartItem

import React, { useCallback, useState } from 'react'

import { Card, Col, Row } from 'antd'
import Meta from 'antd/lib/card/Meta'
import t from 'prop-types'

import { position } from '~/utils/initialPosition'

import Map from './components/Map'
import { Container, MapSection, ProductsSection } from './styles'

function DrawerMissionsMap({ missions, ...props }) {
  const [selectedMission, setSelectedMission] = useState({})
  const [viewport, setViewport] = useState({
    zoom: 4,
    height: 800,
    ...position,
    width: window.innerWidth,
  })

  const handleClosePopUp = useCallback(() => {
    setSelectedMission({})
  }, [])

  return (
    <Container {...props} width={1200}>
      <Row gutter={{ xs: 8, sm: 10, md: 12, lg: 16 }}>
        <Col xs={24} lg={18}>
          <MapSection>
            <Map
              missions={missions.filter((i) => i.lat !== null)}
              viewport={viewport}
              onChange={(item) => setViewport(item)}
              selectedMission={selectedMission}
              handleClosePopUp={handleClosePopUp}
            />
          </MapSection>
        </Col>
        <Col xs={24} lg={6}>
          <ProductsSection>
            {!!missions.length &&
              missions
                ?.filter((i) => i.lat !== null)
                .map((mission) => (
                  <Card
                    key={`${mission.name}-${mission.missions_users_id}`}
                    onClick={() => setSelectedMission(mission)}
                  >
                    <Meta title={mission.name} description={mission.city} />
                  </Card>
                ))}
          </ProductsSection>
        </Col>
      </Row>
    </Container>
  )
}

DrawerMissionsMap.propTypes = {
  missions: t.arrayOf(t.any),
}

export default DrawerMissionsMap

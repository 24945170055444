import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Result } from 'antd'

export default function NotPermission() {
  const navigate = useNavigate()
  return (
    <Result
      title="403"
      status="403"
      subTitle="Desculpe, você não tem permissão de acesso..."
      extra={
        <Button type="primary" shape="round" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      }
    />
  )
}

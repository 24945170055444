import { responseData } from '~/utils/reducer'

const REGISTERS = 'REGISTERS'
const REGISTERS_FEEDS = 'REGISTERS_FEEDS'
const REGISTERS_FILTER = 'REGISTERS_FILTER'
const UPDATE_REGISTER_STATUS = 'UPDATE_REGISTER_STATUS'
const UPDATE_ALL_REGISTERS_STATUS = 'UPDATE_ALL_REGISTERS_STATUS'

const registerDetail = responseData(REGISTERS, 'object')
const registersFeeds = responseData(REGISTERS_FEEDS, 'array')
const registerFilter = responseData(REGISTERS_FILTER, 'array')
const registersUpdate = responseData(UPDATE_REGISTER_STATUS, 'array')
const registersUpdateAll = responseData(UPDATE_ALL_REGISTERS_STATUS, 'array')

export default {
  registerDetail,
  registerFilter,
  registersFeeds,
  registersUpdate,
  registersUpdateAll,
}

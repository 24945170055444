import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useRateUser() {
  const [loadingRate, setLoadingRate] = useState(false)
  const [rateScore, setRate] = useState({})

  const getRateUser = useCallback(async ({ userId, customerId }) => {
    try {
      const res = await api.get(
        'rate-users/average',
        {
          params: {
            user_id: userId,
            customer_id: customerId,
          },
          validateStatus() {
            return true
          },
        },
        [userId, customerId]
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRate(res.data)
    } catch (error) {
      return message.error(error.message)
    }
  }, [])

  async function onSubmitRate(data) {
    try {
      setLoadingRate(true)
      const res = await api.post('rate-users/create', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingRate(false)

      return message.success('Avaliação enviada com sucesso!')
    } catch (error) {
      setLoadingRate(false)
      return message.error(error.message)
    }
  }

  return {
    rateScore,
    loadingRate,
    getRateUser,
    onSubmitRate,
  }
}

export default useRateUser

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { Form, Select } from 'antd'
import t from 'prop-types'

import useCategories from '~/hooks/useCategories'

export function Categories({ onChange }) {
  const { getCategories, categories } = useCategories()

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Form.Item name="missionsMainId">
      <Select
        placeholder="Categorias"
        onChange={(values) => onChange('category', values)}
        options={categories.map((i) => ({
          label: i.title,
          value: i.mission_main_id,
        }))}
      />
    </Form.Item>
  )
}

Categories.propTypes = {
  onChange: t.func,
}

import React from 'react'

import { Form, Row, Col, Button, Card } from 'antd'
import t from 'prop-types'

import { Search } from './Search'
import useQueryFilters from './useQueryFilters'

const ColDefault = ({ children }) => (
  <Col xs={24} xl={4} sm={12} md={6}>
    {children}
  </Col>
)

export function Filter({
  form,
  onSubmit,
  loading,
  onReset,
}) {
  const { getUrlQuery, onUpdateQuery, onResetQuery } = useQueryFilters()

  return (
    <Card size="small" id="filter">
      <Form
        form={form}
        size="small"
        layout="vertical"
        onFinish={(_) => onSubmit(getUrlQuery())}
      >
        <Row gutter={[8, 8]}>
          <ColDefault>
            <Search onChange={onUpdateQuery} />
          </ColDefault>
          <ColDefault>
            <Button type="primary" htmlType="submit" loading={loading} style={{marginRight: 8}}>
              Filtrar
            </Button>
            <Button
              type="default"
              htmlType="reset"
              onClick={(event) => {
                onResetQuery(event)
                onReset(event)
              }}
            >
              Resetar
            </Button>
          </ColDefault>
        </Row>
      </Form>
    </Card>
  )
}

Filter.propTypes = {
  form: t.objectOf(),
  onReset: t.func,
  onSubmit: t.func,
  hasOrder: t.bool,
  loading: t.bool,
  isPrivate: t.bool,
  children: t.element,
}

ColDefault.propTypes = {
  children: t.element,
}

import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { Layout, Button, Dropdown } from 'antd'
import t from 'prop-types'

import { useAuth } from '~/contexts/auth'

import { Container, ContainerLeft, ContainerRight } from './styles'

const { Header } = Layout

function HeaderCustom({ setCollapsed, collapsed }) {
  const { data, signOut } = useAuth()
  const navigate = useNavigate()
  const icon = collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />

  const items = [
    {
      key: '1',
      label: (
        <button
          type="button"
          className="button-link"
          onClick={() => navigate('/missions/launch')}
        >
          Lançar Missão
        </button>
      ),
    },
    {
      key: '2',
      label: (
        <button
          type="button"
          className="button-link"
          onClick={() => navigate('/settings')}
        >
          Configurações
        </button>
      ),
    },
    {
      key: '3',
      danger: true,
      label: (
        <button type="button" className="button-link" onClick={() => signOut()}>
          Sair
        </button>
      ),
    },
  ]

  return (
    <Header style={{ backgroundColor: 'white', alignItems: 'center' }}>
      <Container>
        <ContainerLeft>
          <Button
            type="link"
            icon={icon}
            onClick={() => setCollapsed((prev) => !prev)}
          />
        </ContainerLeft>
        <ContainerRight>
          <Button
            key="1"
            onClick={() => navigate('/missions/launch')}
            style={{
              backgroundColor: 'orange',
              color: 'white',
              border: 'none',
            }}
          >
            <PlusCircleOutlined />
            Lançar Missão
          </Button>
          <Dropdown.Button
            menu={{ items }}
            type="link"
            placement="bottomLeft"
            icon={<UserOutlined />}
          >
            {data?.user?.name}
          </Dropdown.Button>
        </ContainerRight>
      </Container>
    </Header>
  )
}

HeaderCustom.defaultTypes = {
  collapsed: false,
}

HeaderCustom.propTypes = {
  title: t.string,
  collapsed: t.bool.isRequired,
  setCollapsed: t.func.isRequired,
}

export default HeaderCustom

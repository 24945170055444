import React from 'react'

import { Descriptions } from 'antd'
import { format } from 'date-fns'
import t from 'prop-types'

import { MissionsPropsTypes } from './lib'

export function Confirm({ mission, category }) {
  return (
    <Descriptions title="Dados da Missão" bordered>
      <Descriptions.Item label="Nome" span={3}>
        {mission.name}
      </Descriptions.Item>
      <Descriptions.Item label="Categoria" span={3}>
        {category}
      </Descriptions.Item>
      <Descriptions.Item label="Expira Em" span={3}>
        {format(new Date(mission.expired_at), 'dd/MM/yyyy')}
      </Descriptions.Item>
      <Descriptions.Item label="Local" span={3}>
        {mission.place}
      </Descriptions.Item>
    </Descriptions>
  )
}

Confirm.propTypes = {
  mission: t.objectOf(MissionsPropsTypes).isRequired,
  category: t.string.isRequired,
}

export default Confirm

import React, { useState } from 'react'

import { FileExcelOutlined } from '@ant-design/icons'
import { Button, Row, Space, Popover } from 'antd'
import t from 'prop-types'

import ExportForm from '../ExportForm'

export function ActionButtons({ selectedRowKeys, status }) {
  const [visible, setVisible] = useState(false)

  return (
    <Row justify="space-between">
      <div>
        {selectedRowKeys.length > 0 && (
          <Space direction="horizontal" size="small">
            <Popover
              key={3}
              title="Filtros"
              trigger="click"
              placement="right"
              open={visible}
              content={
                <ExportForm
                  status={status}
                  registersId={selectedRowKeys.map((i) => i.uid)}
                />
              }
            >
              <Button
                shape="round"
                color="green"
                icon={<FileExcelOutlined />}
                onClick={() => setVisible((prevState) => !prevState)}
              >
                Respostas Formulário{' '}
                {selectedRowKeys.length ? `(${selectedRowKeys.length})` : ''}
              </Button>
            </Popover>
          </Space>
        )}
      </div>
    </Row>
  )
}

ActionButtons.defaultTypes = {
  status: null,
  loading: false,
}

ActionButtons.propTypes = {
  status: t.number.isRequired,
  loading: t.bool.isRequired,
  loadingRegister: t.bool,
  onClickApprove: t.func.isRequired,
  selectedRowKeys: t.arrayOf(t.string).isRequired,
}

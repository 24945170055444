import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { message } from 'antd'
import JwtDecode from 'jwt-decode'
import t from 'prop-types'
import * as Yup from 'yup'

import api from '~/services/api'

const AuthContext = createContext({})

const isNotPaths = ['/activation', '/recovery', '/signup', '/referral']

function AuthProvider({ children }) {
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingSign, setLoadingSign] = useState(false)
  const hasPath = isNotPaths.includes(location.pathname)

  const signOut = useCallback(() => {
    localStorage.removeItem('@Mission:token')

    setData({ user: null })

    return navigate('/login')
  }, []) //eslint-disable-line

  const getUserMetadata = useCallback(async () => {
    try {
      const response = await api.get('customers/profile', {
        validateStatus() {
          return true
        },
      })

      if (response.status !== 200) {
        throw new Error(response.data.message)
      }

      const token = localStorage.getItem('@Mission:token')

      const decode = JwtDecode(token)

      return setData((prevState) => ({
        ...prevState,
        ...response.data,
        user: response.data,
        userId: decode.sub,
        clientId: decode.client_id,
        admin: decode.admin,
        permissions: decode.permissions,
      }))
    } catch (error) {
      navigate('/login')
      return message.error('Sessão Expirada')
    } finally {
      setLoadingUser(false)
    }
  }, []) // eslint-disable-line

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const params = { email, password }
      setLoadingSign(true)

      const schema = Yup.object().shape({
        email: Yup.string().email().required('Email não pode estar vazio!'),
        password: Yup.string()
          .min(6)
          .required('Senha deve conter no mínimo 6 caracteres!'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error(validate)
      }

      const response = await api.post('sessions/customers/auth', params, {
        validateStatus() {
          return true
        },
      })

      if (response.status !== 201) {
        throw new Error(response.data.message)
      }

      if (!response.data?.is_secure && response.data.email) {
        setLoadingSign(false)
        return navigate(
          `/activation?email=${response.data.email}&token=${response.data.token}`
        )
      }

      api.defaults.headers.Authorization = `Bearer ${response.data.token}`

      localStorage.setItem('@Mission:token', response.data.token)

      await getUserMetadata()

      return navigate('/dashboard')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSign(false)
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!hasPath) {
      getUserMetadata()
    } else {
      setLoadingUser(false)
    }
  }, []) //eslint-disable-line

  return (
    <AuthContext.Provider
      value={{
        data,
        signIn,
        signOut,
        loadingUser,
        loadingSign,
        getUserMetadata,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: t.node.isRequired,
}

function useAuth() {
  const context = useContext(AuthContext)

  return context
}

export { AuthProvider, useAuth }

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

function useCustomer() {
  const navigate = useNavigate()
  const [loadingCustomer, setLoadingCustomer] = useState(false)

  async function onActivation(customerData) {
    try {
      setLoadingCustomer(true)

      const res = await api.patch(
        'sessions/customers/activation',
        customerData,
        {
          headers: {
            Authorization: `Bearer ${customerData.token}`,
          },
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      setLoadingCustomer(false)

      message.success('Sua conta foi alterada com sucesso!')

      return navigate('/')
    } catch (error) {
      setLoadingCustomer(false)
      return message.error(error.message)
    }
  }

  async function onRecovery(customerData) {
    try {
      setLoadingCustomer(true)

      const res = await api.post('sessions/customers/recovery', customerData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Confira o seu e-mail para recuperação de senha')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCustomer(false)
    }
  }

  async function onChangeCustomer(customerData) {
    try {
      setLoadingCustomer(true)

      const res = await api.put('customers', customerData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Seus dados foram atualizados com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCustomer(false)
    }
  }

  return {
    loadingCustomer,
    onActivation,
    onChangeCustomer,
    onRecovery,
  }
}

export default useCustomer

import { useCallback, useState } from 'react'

import { message } from 'antd'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import api from '~/services/api'

function useFormsData() {
  const [loadingFormData, setLoadingFormData] = useState(false)
  const [loadingExtract, setLoadingExtract] = useState(false)
  const [formsData, setFormsData] = useState([])

  const getFormsDataByMissionsUsersId = useCallback(async (missionsUsersId) => {
    try {
      setLoadingFormData(true)
      const res = await api.get(
        `forms-data/customers/forms-data-by-mission/${missionsUsersId}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFormsData(res.data.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFormData(false)
    }
  }, [])

  async function onExportFormsData({ items }) {
    try {
      setLoadingFormData(true)
      const registersUids = items.map((i) => i.uid)
      const data = { registersUids }

      const res = await api.post('forms-data/export', data, {
        responseType: 'arraybuffer',
      })

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      return FileSaver.saveAs(blob, `form_${new Date().getTime()}`)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFormData(false)
    }
  }

  async function onExtractLocal({ data, fileName }) {
    try {
      setLoadingExtract(true)
      const results = []
      const resultObject = {}
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

      for (let i = 0; i < data.length; i += 1) {
        results.push(data[i].items.map((d) => d))
      }

      const resultsMapping = results.flatMap((i) => i)

      const headers = resultsMapping.map(
        (i) => `${i.order}_${i.question}_${i.section}`
      )

      const result = resultsMapping.map((i) => [
        `${i.order}_${i.question}_${i.section}`,
        i.answer,
      ])

      for (let k = 0; k < result.length; k += 1) {
        const [key, value] = result[k]

        resultObject[key] = value
      }

      const dataJsonSheets = XLSX.utils.json_to_sheet([resultObject], {
        header: headers,
      })
      const excelBuffer = XLSX.write(
        {
          Sheets: { data: dataJsonSheets },
          SheetNames: ['data'],
        },
        {
          bookType: 'xlsx',
          type: 'array',
        }
      )
      const fileExport = new Blob([excelBuffer], { type: fileType })

      return FileSaver.saveAs(fileExport, `${fileName}.xlsx`)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingExtract(false)
    }
  }

  return {
    formsData,
    loadingFormData,
    onExtractLocal,
    loadingExtract,
    onExportFormsData,
    getFormsDataByMissionsUsersId,
  }
}

export default useFormsData

import React, { useEffect, useState } from 'react'
import Gallery from 'react-grid-gallery'

import { Descriptions, List } from 'antd'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import usePointOfSales from '~/hooks/usePointOfSales'
import { formatPrice } from '~/utils/format'

import { REASONS, POSITIONS_TYPES } from './helpers/translates'
import { Container, TitleContainer, ItemContainerImages } from './styles'

const captionKey = {
  products_images: 'Ordem',
  extra_point_images: 'Ponto Extra',
  glorifier_images: 'Glorifier',
  gondola_images: 'Gondola',
  present_section_images: 'Presente na seção',
  stopper_images: 'Stopper',
  wobbler_images: 'Wobbler',
}

const ContainerImages = ({ images }) => {
  return <Gallery images={images} />
}

ContainerImages.propTypes = {
  images: t.arrayOf(t.any),
}

export function PointOfSale({ id }) {
  const [pointSaleData, setPointSaleData] = useState(null)
  const { getPointOfSales, loadingPos, pointOfSale } = usePointOfSales()

  const formDataItem = ({ caption, item, index }) => ({
    src: item.path,
    thumbnail: item.path,
    caption: `${caption} ${index}`,
    thumbnailWidth: 120,
    thumbnailHeight: 180,
  })

  const mapItem = (item, index) => (caption) =>
    formDataItem({ caption, item, index })

  for (let i = 0; pointOfSale.length > i; i += 1) {
    Object.keys(pointOfSale[i].data).forEach((key) => {
      pointOfSale[i].data[key] = pointOfSale[i].data[key].map((item, index) =>
        mapItem(item, index)(captionKey[key])
      )
    })
  }

  useEffect(() => {
    getPointOfSales(id)
  }, []) //eslint-disable-line

  useEffect(() => {
    setPointSaleData((prev) => ({ ...prev, ...pointOfSale }))
  }, [pointOfSale])

  if (loadingPos) {
    return <Loading />
  }

  return (
    <Container
      size="large"
      itemLayout="vertical"
      dataSource={pointSaleData}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <TitleContainer>
            <h1>{item.description}</h1>
          </TitleContainer>
          {item.data.products_images && (
            <ItemContainerImages>
              <Gallery images={item.data.products_images} />
            </ItemContainerImages>
          )}
          <Descriptions
            bordered
            column={1}
            size="small"
            style={{ marginTop: '12px' }}
            title="Detalhes da Gondola"
          >
            <Descriptions.Item label="Produto Disponível?">
              {item.data?.exist ? 'Sim' : 'Não'}
            </Descriptions.Item>
            {!item.data?.exist && (
              <Descriptions.Item label="Motivo.">
                {REASONS[item.data?.ruptura_reason]}
              </Descriptions.Item>
            )}
            {item.data?.price_exist && (
              <Descriptions.Item label="Preço:">
                {formatPrice(item.data.price)}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Posição:">
              {POSITIONS_TYPES[item.data?.position]}
            </Descriptions.Item>
            <Descriptions.Item label="Qtd. de Produtos.">
              {item.data?.quantity_product}
            </Descriptions.Item>
            <Descriptions.Item label="Existe Pontos Extra:">
              {item.data?.exist_sales_point ? 'Sim' : 'Não'}
            </Descriptions.Item>
          </Descriptions>

          {item.data?.exist_sales_point && (
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ marginTop: '12px' }}
              title="Tipos de Pontos"
            >
              <Descriptions.Item label="Presente na Seção:">
                {item.data?.present_section ? 'Sim' : 'Não'}
              </Descriptions.Item>
              {item.data.present_section && (
                <Descriptions.Item>
                  <ItemContainerImages>
                    <ContainerImages
                      images={item.data?.present_section_images}
                    />
                  </ItemContainerImages>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Ponta de Gondola:">
                {item.data?.gondola ? 'Sim' : 'Não'}
              </Descriptions.Item>
              {item.data.gondola && (
                <Descriptions.Item>
                  <ItemContainerImages>
                    <ContainerImages images={item.data?.gondola_images} />
                  </ItemContainerImages>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Ponto Extra:">
                {item.data?.extra_point ? 'Sim' : 'Não'}
              </Descriptions.Item>
              {item.data.extra_point && (
                <Descriptions.Item>
                  <ItemContainerImages>
                    <ContainerImages images={item.data?.extra_point_images} />
                  </ItemContainerImages>
                </Descriptions.Item>
              )}
            </Descriptions>
          )}

          <Descriptions
            bordered
            column={1}
            size="small"
            style={{ marginTop: '12px' }}
            title="Midia Kit"
          >
            <Descriptions.Item label="Existe Wobbler:">
              {item.data?.wobbler ? 'Sim' : 'Não'}
            </Descriptions.Item>
            {item.data?.wobbler && (
              <Descriptions.Item>
                <ItemContainerImages>
                  <ContainerImages images={item.data?.wobbler_images} />
                </ItemContainerImages>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Existe Stopper:">
              {item.data?.stopper ? 'Sim' : 'Não'}
            </Descriptions.Item>
            {item.data?.stopper && (
              <Descriptions.Item>
                <ItemContainerImages>
                  <ContainerImages images={item.data?.stopper_images} />
                </ItemContainerImages>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Existe Glorifier:">
              {item.data?.glorifyer ? 'Sim' : 'Não'}
            </Descriptions.Item>
            {item.data?.glorifyer && (
              <Descriptions.Item>
                <ItemContainerImages>
                  <ContainerImages images={item.data?.glorifyer_images} />
                </ItemContainerImages>
              </Descriptions.Item>
            )}
          </Descriptions>
        </List.Item>
      )}
    />
  )
}

PointOfSale.propTypes = {
  id: t.string.isRequired,
}

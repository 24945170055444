import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useWorkforce() {
  const [users, setUsers] = useState({})
  const [dateList, setDateList] = useState([])
  const [adherence, setAdherence] = useState({})
  const [loadingWorkforce, setLoadingWorkforce] = useState(false)
  const [adherenceGraphicBar, setAdherenceGraphicBar] = useState({})
  const [adherenceGraphicPizza, setAdherenceGraphicPizza] = useState({})
  const [totalRegisters, setTotalRegisters] = useState({})
  const [ratings, setRatings] = useState({})

  const exportReport = async (params) => {
    try {
      setLoadingWorkforce(true)

      const res = await api.get('missions-users/workforce-manager', {
        params,
        responseType: 'blob',
      })

      const href = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = href
      link.click()
    } catch (error) {
      if (error.response && error.response.status === 409) {
        message.error('Não há registros para serem exportados.')
      } else {
        message.error(error.message || 'Oops algo inesperado aconteceu!')
      }
    } finally {
      setLoadingWorkforce(false)
    }
  }

  const getUsers = useCallback(async (params) => {
    try {
      setLoadingWorkforce(true)

      const res = await api.get('missions-users/workforce-manager', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setDateList(Object.keys(res.data.missionsUsers))
      setAdherence(res.data.adherence)
      setAdherenceGraphicBar(res.data.adherenceGraphicBar)
      setAdherenceGraphicPizza(res.data.adherenceGraphicPizza)
      setTotalRegisters({
        totalRegistersPerDay: res.data.totalRegistersPerDay,
        totalRegistersInProgressPerDay: res.data.totalRegistersInProgressPerDay,
        totalRegistersMissedPerDay: res.data.totalRegistersMissedPerDay,
      })
      setRatings({
        ratingsPerDay: res.data.ratingsPerDay,
        ratingsMissionsOfUsersPerDay: res.data.ratingsMissionsOfUsersPerDay,
      })

      return setUsers(res.data.missionsUsers)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingWorkforce(false)
    }
  }, [])

  return {
    getUsers,
    exportReport,
    users,
    loadingWorkforce,
    dateList,
    adherence,
    adherenceGraphicBar,
    adherenceGraphicPizza,
    totalRegisters,
    ratings,
  }
}

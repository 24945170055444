import React, { useState } from 'react'

import { Checkbox, Divider, Input, List, Modal } from 'antd'
import t from 'prop-types'

import { DynamicHeaderOptions } from '../DynamicHeaderOptions'

export default function CreateWebhook({
  events,
  onSubmitCreate,
  open,
  onCancel,
}) {
  const [headers, setHeaders] = useState([])
  const [callbackURL, setCallbackURL] = useState('')
  const [eventsIds, setEventsIds] = useState([])

  const addHeader = () =>
    setHeaders((prevState) => [...prevState, { header: 'value' }])

  const handleOnChangeHeaders = (key, value, index) => {
    if (key === 'header') {
      setHeaders((prevState) =>
        prevState.map((prev, idx) => {
          const [, valueHeader] = Object.keys(prev)

          return idx === index ? { [value]: valueHeader } : prev
        })
      )
    } else {
      setHeaders((prevState) =>
        prevState.map((prev, idx) => {
          const [header] = Object.keys(prev)
          return idx === index ? { [header]: value } : prev
        })
      )
    }
  }

  function handleOnChange(key, value, checked) {
    if (key === 'callbackURL') {
      setCallbackURL(value)
    }

    if (key === 'eventsIds') {
      if (checked) {
        setEventsIds((prevState) => [...prevState, value])
      } else {
        setEventsIds((prevState) => prevState.filter((prev) => prev !== value))
      }
    }
  }

  function handleOnClickCreate() {
    const headersObject = {}

    headers.forEach((item) => {
      const shouldContinue = !Object.keys(item).includes('header')

      if (shouldContinue) {
        Object.assign(headersObject, item)
      }
    })

    const createObject = {
      authorization: headersObject,
      callback_url: callbackURL,
      events: eventsIds,
    }

    onSubmitCreate(createObject)

    onCancel()
    setCallbackURL('')
    setEventsIds([])
    setHeaders([])
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Novo Webhook"
      cancelText="Cancelar"
      okText="Criar"
      onOk={() => handleOnClickCreate()}
    >
      <Input
        placeholder="https://"
        onChange={({ target }) => handleOnChange('callbackURL', target.value)}
      />
      <Divider />

      <DynamicHeaderOptions
        headers={headers}
        addHeader={addHeader}
        handleOnChange={handleOnChangeHeaders}
      />

      <Divider />
      <List>
        {events?.map((eventWebhook) => (
          <List.Item>
            <Checkbox
              onChange={({ target }) =>
                handleOnChange('eventsIds', eventWebhook.id, target.checked)
              }
            >
              {eventWebhook.event_name}
            </Checkbox>
          </List.Item>
        ))}
      </List>
    </Modal>
  )
}

CreateWebhook.propTypes = {
  events: t.arrayOf(
    t.shape({
      id: t.string,
      event_name: t.string,
    })
  ).isRequired,
  onSubmitCreate: t.func,
  open: t.bool,
  onCancel: t.func,
}

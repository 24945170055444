import React from 'react'

import { StarOutlined } from '@ant-design/icons'
import { Card, Button, Skeleton, Tabs, Divider, Space, Tag } from 'antd'
import t from 'prop-types'

import {
  ModalDescription,
  Header,
  Answers,
  Feeds,
  Invoice,
  Files,
  PointOfSale,
  SaleBadge,
} from './components'
import { RegisterPropTypes } from './lib'

const statusLabel = {
  0: 'Aguardando',
  1: 'Aprovado',
  2: 'Reprovado',
}

const statusColor = {
  0: 'gold',
  1: 'green',
  2: 'red',
}

export function Detail({
  item,
  loading,
  invoice,
  handleOk,
  onCancel,
  openModal,
  onClickModal,
  onClickLogs,
  onClickApiLogs,
  onClickView,
  onClickRate,
  onClickCustomStatus,
  approveDescription,
  reproveDescription,
  onChangeConfirmDescription,
  openModalRate,
}) {
  const items = [
    {
      key: '1',
      label: 'Respostas',
      children: null,
    },
    {
      key: '2',
      label: 'Arquivos',
      children: null,
    },
    {
      key: '3',
      label: 'Vendas',
      children: null,
    },
    {
      key: '4',
      label: 'Atividades',
      children: <Feeds register={item} />,
    },
    {
      key: '5',
      label: 'Ponto de Vendas',
      children: null,
    },
  ]

  const id = item.missions_users_id
  const isFile = item.has_photo || item.has_attachment || item.has_import_image

  return (
    <Card
      bordered={false}
      style={{ height: '90vh', overflowX: 'auto' }}
      loading={loading}
      title={!loading && `${item.mu_key} - ${item.missionName}`}
      extra={
        <Space>
          {item.custom_status_description !== null ? (
            <Tag>{item.custom_status_description}</Tag>
          ) : (
            <Tag color={statusColor[item.status]}>
              {statusLabel[item.status]}
            </Tag>
          )}
          {item.rate === null && item.status > 0 && (
            <Button
              size="middle"
              type="default"
              shape="round"
              icon={<StarOutlined />}
              onClick={openModalRate}
            >
              Avaliar
            </Button>
          )}
          {item.has_products ? <SaleBadge count={invoice?.totalSales} /> : null}
          <Button
            key="3"
            shape="round"
            size="small"
            onClick={() => onClickLogs()}
          >
            Histórico de Logs
          </Button>
          <Button
            key="4"
            shape="round"
            size="small"
            onClick={() => onClickApiLogs()}
          >
            Integração de Logs
          </Button>
        </Space>
      }
    >
      <Skeleton loading={loading} avatar active>
        <Header
          item={item}
          userId={item.user_id}
          onClickLogs={onClickLogs}
          onClickRate={onClickRate}
          onClickModal={onClickModal}
          onClickCustomStatus={onClickCustomStatus}
        />

        <Divider />

        <Tabs
          defaultActiveKey="1"
          items={items
            .map((i) => {
              if (i.key === '1' && item.has_forms) {
                i.children = <Answers id={id} />
              }

              if (i.key === '2' && isFile) {
                i.children = <Files id={id} onClickView={onClickView} />
              }

              if (i.key === '3' && item.has_products) {
                i.children = <Invoice id={id} />
              }

              if (i.key === '5' && item.has_pos) {
                i.children = <PointOfSale id={id} />
              }

              return i
            })
            .filter((v) => v.children !== null)}
        />
      </Skeleton>
      <ModalDescription
        loading={loading}
        onCancel={onCancel}
        visible={openModal.visible}
        status={openModal.currentStatus}
        onChange={onChangeConfirmDescription}
        approveDescription={approveDescription}
        reproveDescription={reproveDescription}
        handleOk={() =>
          handleOk({
            registerId: item.uid,
            currentStatus: openModal.currentStatus,
          })
        }
      />
    </Card>
  )
}

Detail.propTypes = {
  item: t.objectOf(RegisterPropTypes).isRequired,
  loading: t.bool.isRequired,
  invoice: t.objectOf(t.objectOf({})),
  handleOk: t.func.isRequired,
  onCancel: t.func.isRequired,
  onClickModal: t.func.isRequired,
  onClickView: t.func,
  onClickLogs: t.func,
  onClickCustomStatus: t.func,
  onClickRate: t.func.isRequired,
  currentStatus: t.string,
  approveDescription: t.string,
  reproveDescription: t.string,
  onChangeConfirmDescription: t.func.isRequired,
  openModalRate: t.func.isRequired,
  openModal: t.objectOf({}),
}

import { responseData } from '~/utils/reducer'

const PRODUCTS_ITEMS = 'PRODUCTS_ITEMS'
const PRODUCTS_ITEMS_CREATE = 'PRODUCTS_ITEMS_CREATE'
const PRODUCTS_ITEMS_REMOVE = 'PRODUCTS_ITEMS_REMOVE'
const PRODUCTS_ITEMS_UPDATE = 'PRODUCTS_ITEMS_UPDATE'

const productItems = responseData(PRODUCTS_ITEMS, 'array')
const productItemsCreated = responseData(PRODUCTS_ITEMS_CREATE, 'object')
const productItemsUpdated = responseData(PRODUCTS_ITEMS_UPDATE, 'object')
const productItemsRemoved = responseData(PRODUCTS_ITEMS_REMOVE, 'object')

export default {
  productItems,
  productItemsCreated,
  productItemsUpdated,
  productItemsRemoved,
}

import React from 'react'

import { Row, Col } from 'antd'
import t from 'prop-types'

import { TableRow, LegColor, Label, Count } from './styles'

const List = ({ items }) => {
  const sum = items.datasets[0].data.reduce((a, b) => a + b, 0)
  const percent = (count) =>
    Number((Number(count) / Number(sum)) * 100).toFixed(1)

  const colors = items.datasets[0].backgroundColor.map((color, index) => {
    const key = index + 1

    return <LegColor color={color} key={`${key}_${color}_${Date.now()}`} />
  })

  const labels = items.labels.map((label, index) => {
    const key = index + 1

    return (
      <Row span={8} key={`${key}_${label}}`}>
        <Label className="bold">
          {label === 'null' ? 'Não Localizada' : label}
        </Label>
      </Row>
    )
  })

  const counts = items.datasets[0].data.map((count, k) => {
    const key = k + 1
    return (
      <Row justify="end" key={`${key}_${count}`}>
        <Count className="bold">
          {count} ({percent(count)}%)
        </Count>
      </Row>
    )
  })

  return (
    <TableRow>
      <Col span={2}>{colors}</Col>
      <Col span={16}>{labels}</Col>
      <Col span={6}>{counts}</Col>
    </TableRow>
  )
}

List.propTypes = {
  items: t.arrayOf(t.objectOf(t.any)).isRequired,
}

export default List

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Result } from 'antd'

export default function NotFound() {
  const navigate = useNavigate()
  return (
    <Result
      title="404"
      status="404"
      subTitle="Desculpe, Página não encontrada..."
      extra={
        <Button type="primary" shape="round" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      }
    />
  )
}

import { Col, Row } from 'antd'
import styled from 'styled-components'

export const AntRow = styled(Row)``

export const AntCol = styled(Col)`
  @media (max-width: 991px) {
    height: 100%;
    margin-bottom: 15px;
  }
`
export const MainAntCol = styled(Col)`
  @media (min-width: 992px) {
    overflow-y: auto; */
    height: calc(100vh - 216px);
  }
`

export const Container = styled.div`
  background-color: ${(props) => props.background || 'transparent'};
`

export const MapContainer = styled.div`
  min-height: 400px;
  height: 100%;

  @media (max-width: 991px) {
    min-height: 400px;
  }
`

export const MapSection = styled.section`
  padding: 16px;
  height: 100%;

  .form-container {
    margin-bottom: 0;
  }
`

export const EmptyCard = styled.div`
  height: calc(100vh - 216px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-empty {
    margin: 16px 8px;

    .ant-empty-image {
      height: 150px;
      margin-bottom: 16px;
    }
  }
`

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useInvoices() {
  const [loadingInvoices, setLoading] = useState(false)
  const [invoices, setInvoices] = useState([])

  const getInvoiceByMissionsUsersId = useCallback(async (id) => {
    setLoading(true)
    try {
      const res = await api.get(`invoices/customers/${id}`, {
        params: {
          status: 'approved',
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setInvoices(res.data.invoices)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    invoices,
    loadingInvoices,
    getInvoiceByMissionsUsersId,
  }
}

export default useInvoices

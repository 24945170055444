/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import {
  PlusOutlined,
  FileExcelOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import { Row, Button, Space, Upload } from 'antd'
import t from 'prop-types'

import styles from './styles'

const Header = ({
  goBack,
  loading,
  uploadProps,
  removeAll,
  updateStatusAll,
  selectedRowKeys,
  handleCleanForm,
}) => (
  <Row justify="start" style={styles.container}>
    <Space>
      <Button
        key="-1"
        onClick={goBack}
        shape="circle-outline"
        icon={<ArrowLeftOutlined />}
      />
      {selectedRowKeys.length > 0 && (
        <>
          <Button
            shape="round"
            type="danger"
            loading={loading}
            disabled={loading}
            onClick={() => removeAll()}
          >
            Remover
          </Button>
          <Button
            shape="round"
            type="primary"
            loading={loading}
            disabled={loading}
            onClick={() => updateStatusAll(true)}
          >
            Ativar
          </Button>
          <Button
            shape="round"
            type="ghost"
            loading={loading}
            disabled={loading}
            onClick={() => updateStatusAll(false)}
          >
            Inativar
          </Button>
        </>
      )}
      <span>
        {selectedRowKeys.length > 0 &&
          `Itens selecionados ${selectedRowKeys.length}`}
      </span>
      <Button
        key="0"
        type="primary"
        shape="round"
        disabled={loading}
        icon={<PlusOutlined />}
        onClick={() => handleCleanForm()}
      >
        Novo Produto
      </Button>
      <Upload {...uploadProps}>
        <Button shape="round" loading={loading} disabled={loading}>
          <FileExcelOutlined /> Importar Produtos
        </Button>
      </Upload>
    </Space>
  </Row>
)

Header.defaultTypes = {
  loading: false,
}

Header.propTypes = {
  goBack: t.func.isRequired,
  loading: t.bool.isRequired,
  uploadProps: t.objectOf(t.any).isRequired,
  removeAll: t.func.isRequired,
  selectedRowKeys: t.arrayOf(t.any).isRequired,
  updateStatusAll: t.func.isRequired,
  handleCleanForm: t.func.isRequired,
}

export default Header

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import ReactQuill from 'react-quill'

import {
  List,
  Skeleton,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  message,
} from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import useCategories from '~/hooks/useCategories'
import useSearchLocation from '~/hooks/useSearchLocation'
import CustomFields from '~/pages/Missions/components/CustomFields'
import api from '~/services/api'
import { getPhoneRaw } from '~/utils/helpers'

import styles from './styles'

import 'react-quill/dist/quill.snow.css'

const { Search } = Input
const { Option } = Select

const Info = ({
  item,
  form,
  handleUpdate,
  onChange,
  loading,
  onChangeAddress,
  customFields,
  customFieldsAnswers,
  handleUpdateCustomFieldAnswers,
  onChangeMissionMainId,
}) => {
  const phones = item?.checkin_phone ? item?.checkin_phone.split(', ') : []

  const { categories, getCategories, loadingCategories } = useCategories()
  const { locations, loadingSearch, onSearchAddress, onClear } =
    useSearchLocation()

  const [phone, setPhone] = useState('')
  const [changeLocal, setChangeLocal] = useState(false)
  const [checkinPhones, setCheckinPhones] = useState(phones)
  const [isNeedUpdateCustomFields, setIsNeedUpdateCustomFields] =
    useState(false)
  const [
    isAlreadyProcessedCustomFieldsToRender,
    setIsAlreadyProcessedCustomFieldsToRender,
  ] = useState(false)
  const [customFieldsAnswersToRender, setCustomFieldsAnswersToRender] =
    useState([])

  function processCustomFieldsAnswersToRender() {
    const answers = {}
    for (
      let index = 0;
      index < (customFieldsAnswers || []).length;
      index += 1
    ) {
      const field = customFieldsAnswers[index]
      answers[field.id] = field.value
    }

    const registersToRender = [...customFields]

    for (let index = 0; index < registersToRender.length; index += 1) {
      const register = registersToRender[index]
      registersToRender[index].value = answers[register.id] || ''
    }

    setCustomFieldsAnswersToRender([...registersToRender])
  }

  function onChangeInputValueCustomField(index, value) {
    const registers = [...customFieldsAnswersToRender]
    registers[index].value = value
    setCustomFieldsAnswersToRender([...registers])
    handleUpdateCustomFieldAnswers(registers)
  }

  async function onChangePhone(value, event) {
    value = getPhoneRaw(value)
    let phonesValues = ''
    const alreadyExist = checkinPhones.includes(value)

    if (alreadyExist && event === 'create') {
      return message.error('Número já existe')
    }

    if (event === 'create') {
      setCheckinPhones((prev) => [...prev, value])
      phonesValues = [...checkinPhones, value]
    } else {
      setCheckinPhones(checkinPhones.filter((i) => i !== value))
      phonesValues = checkinPhones.filter((i) => i !== value)
    }

    onChange('checkin_phone', phonesValues.join(', '))

    return api.patch('missions/customers', {
      uid: item?.uid,
      name: item?.name,
      description: item.description,
      expired_at: item?.expired_at,
      missions_main_id: item?.missions_main_id,
      checkin_phone: phonesValues.join(', '),
    })
  }

  useEffect(() => {
    getCategories()

    return () => {
      setIsAlreadyProcessedCustomFieldsToRender(false)
      setCustomFieldsAnswersToRender([])
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (isNeedUpdateCustomFields) {
      processCustomFieldsAnswersToRender()
      setIsNeedUpdateCustomFields(false)
    }

    if (
      (customFields || []).length > 0 &&
      !isAlreadyProcessedCustomFieldsToRender
    ) {
      processCustomFieldsAnswersToRender()
      setIsAlreadyProcessedCustomFieldsToRender(true)
    }
  }, [customFields]) //eslint-disable-line

  const modules = {
    clipboard: {
      matchVisual: false,
    },
  }

  return (
    <Form layout="vertical" form={form} onFinish={handleUpdate}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Nome da Missão" name="name">
            <Input defaultValue={item.name} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="ID (Opcional)" name="identifier">
            <Input placeholder="Identificador" defaultValue={item.identifier} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Categoria" name="missions_main_id">
            <Select
              onChange={(value) => {
                setIsNeedUpdateCustomFields(true)
                setCustomFieldsAnswersToRender([])
                onChangeMissionMainId(value)
              }}
              allowClear="true"
              defaultValue={item.title}
              loading={loadingCategories}
              placeholder="Escolha Categoria"
            >
              {categories.map((value) => (
                <Option
                  key={value.mission_main_id}
                  value={value.mission_main_id}
                >
                  {value.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Expira Em">
            <DatePicker
              style={styles.block}
              placeholder="Expira em"
              defaultValue={dayjs(item.expired_at, 'YYYY-MM-DD')}
              onChange={(_, dateString) => onChange('expired_at', dateString)}
            />
          </Form.Item>
        </Col>
      </Row>
      {item.place !== null && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="custom_distance"
              label="Distância Customizada (Km)"
            >
              <Input
                type="number"
                defaultValue={item.custom_distance}
                placeholder="Ex: 1 (Missão vai aparecer a 1Km de distância do usuário)"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Input
                disabled
                value={item.place}
                style={{ width: 'calc(100% - 200px)' }}
              />
              <Button
                type="primary"
                onClick={() => setChangeLocal((prev) => !prev)}
              >
                Alterar Local
              </Button>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      {changeLocal && (
        <Row gutter={16}>
          <Col span={24}>
            <Search
              allowClear
              loading={loadingSearch}
              onSearch={onSearchAddress}
              placeholder="Digite Endereço e Pressione 'Enter'"
            />

            {locations.length > 0 && (
              <List itemLayout="horizontal" size="small">
                {locations?.map((i) => (
                  <List.Item
                    key={i.place_id}
                    onClick={() => {
                      onChangeAddress(i)
                      onClear()
                      return setChangeLocal(false)
                    }}
                  >
                    <Skeleton loading={loadingSearch} active>
                      <List.Item.Meta title={i.formatted_address} />
                    </Skeleton>
                  </List.Item>
                ))}
              </List>
            )}
          </Col>
        </Row>
      )}
      <CustomFields
        data={customFieldsAnswersToRender}
        onChange={onChangeInputValueCustomField}
      />
      {item?.checkin_has_sms_confirm ? (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Números para Confirmar SMS ">
              <Input.Group compact>
                <InputMask
                  value={phone}
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  mask="(99) 99999-9999"
                  placeholder="Ex: (48) 9999-1234"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type="tel"
                      style={{ width: 'calc(100% - 200px)' }}
                    />
                  )}
                </InputMask>

                <Button
                  type="primary"
                  disabled={phone.length <= 14}
                  onClick={() => onChangePhone(phone, 'create')}
                >
                  Add
                </Button>
              </Input.Group>
            </Form.Item>

            {checkinPhones.map((i) => (
              <Form.Item name="checkin_phone">
                <Input.Group compact>
                  <InputMask
                    value={i}
                    disabled
                    defaultValue={i}
                    mask="(99) 99999-9999"
                    placeholder="Ex: (48) 9999-1234"
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="tel"
                        style={{ width: 'calc(100% - 200px)' }}
                      />
                    )}
                  </InputMask>
                  <Button
                    type="default"
                    color="red"
                    onClick={() => onChangePhone(i, 'remove')}
                  >
                    Remover
                  </Button>
                </Input.Group>
              </Form.Item>
            ))}
          </Col>
        </Row>
      ) : null}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Descrição">
            <ReactQuill
              value={item?.description}
              defaultValue={item.description}
              modules={modules}
              onChangeSelection={(_, _editor, value) => {
                onChange('description', value.getHTML())
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Atualizar
        </Button>
      </Form.Item>
    </Form>
  )
}

Info.propTypes = {
  item: t.objectOf({}),
  form: t.objectOf({}),
  loading: t.bool,
  handleUpdate: t.func,
  onChange: t.func,
  onChangeAddress: t.func,
  customFields: t.arrayOf([]),
  customFieldsAnswers: t.arrayOf([]),
  handleUpdateCustomFieldAnswers: t.func,
  onChangeMissionMainId: t.func,
}

export default Info

import React, { useEffect } from 'react'

import { List, Skeleton, Button, Empty } from 'antd'
import t from 'prop-types'

import useForms from '~/hooks/useForms'

export function Forms({ onClick, isSelected }) {
  const { forms, loadingForms, getAll } = useForms()

  const isActive = (formId) => isSelected === formId

  useEffect(() => {
    getAll()
  }, []) //eslint-disable-line

  return (
    <List itemLayout="horizontal" size="small">
      {forms.length === 0 && <Empty description="Nenhum Formulário" />}

      {forms.length > 0 &&
        forms.map((i) => (
          <List.Item
            key={i.uid}
            onClick={() => onClick(i.uid)}
            style={{ cursor: 'pointer' }}
          >
            <Skeleton loading={loadingForms} active avatar>
              <List.Item.Meta
                description={i.description}
                title={`${i.id} - ${i.name}`}
              />
              <Button type="primary" disabled={isActive(i.uid)}>
                {isActive(i.uid) ? 'Ativo' : 'Escolher'}
              </Button>
            </Skeleton>
          </List.Item>
        ))}
    </List>
  )
}

Forms.defaultTypes = {
  loading: false,
}

Forms.propTypes = {
  onClick: t.func.isRequired,
  isSelected: t.string,
}

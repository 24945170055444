import React from 'react'

import t from 'prop-types'

import { BarAvg } from './BarAvg'
import { BarCount } from './BarCount'
import { DoughnutAvg } from './DoughnutAvg'
import { LinearCount } from './LinearCount'

export function ChartTypes({ type, data }) {
  const chartElements = {
    linear: <LinearCount data={data} />,
    bar_avg: <BarAvg data={data} />,
    doughnut: <DoughnutAvg data={data} />,
  }

  return chartElements[type] || <BarCount data={data} />
}

ChartTypes.propTypes = {
  type: t.string,
  data: t.oneOfType([t.objectOf({}), t.arrayOf([])]),
}

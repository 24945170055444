import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Form, Input, Tag } from 'antd'

import { useAuth } from '~/contexts/auth'

export default function Login() {
  const navigate = useNavigate()
  const { signIn, loadingSign } = useAuth()

  const [form] = Form.useForm()

  return (
    <div className="content-login">
      <div className="content-login-header">
        <h3>Entrar</h3>
      </div>
      <Form form={form} layout="vertical" onFinish={signIn}>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Digite seu email por favor!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Senha"
          name="password"
          rules={[
            {
              required: true,
              message: 'Digite sua senha por favor!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loadingSign}
          >
            Entrar
          </Button>
          <Button type="link" onClick={() => navigate('/recovery')}>
            Esqueci minha senha
          </Button>
        </Form.Item>
        <Tag>® Mission Brasil ({process.env.REACT_APP_VERSION})</Tag>
      </Form>
    </div>
  )
}

import React from 'react'
// eslint-disable-next-line import/no-unresolved
import { Doughnut } from 'react-chartjs-2'

import { Empty, Skeleton, Card, Row } from 'antd'
import t from 'prop-types'

import Dash1 from '~/assets/images/pie.svg'
import { backgroundColors } from '~/pages/Analytics/components/Charts/Colors'

import List from './List'
import { Container } from './styles'

const listStyle = {
  marginTop: 20,
}

const MarketShare = ({ values, title, loading }) => {
  const series = values?.map((i) => Number(i.count))
  const colors = values?.map((i, idx) => backgroundColors[idx])
  const data = {
    type: 'pie',
    labels: values?.map((i) => String(i.title)),
    datasets: [
      {
        data: series,
        backgroundColor: colors,
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      labels: {
        render: 'label',
      },
      datalabels: {
        display: true,
        color: 'white',
      },
    },
  }

  return (
    <Card title={`Market Share - ${title}`} className="card-content">
      <Skeleton loading={loading} active>
        {values.length === 0 ? (
          <Empty image={Dash1} />
        ) : (
          <>
            <Container>
              <Doughnut data={data} options={options} />
            </Container>
            <Row style={listStyle}>
              <List items={data} />
            </Row>
          </>
        )}
      </Skeleton>
    </Card>
  )
}

MarketShare.propTypes = {
  loading: false,
}

MarketShare.propTypes = {
  values: t.arrayOf(t.objectOf(t.any)).isRequired,
  title: t.string.isRequired,
  loading: t.bool.isRequired,
}

export default MarketShare

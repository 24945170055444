/* eslint-disable react/no-array-index-key */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Form, Button, Empty } from 'antd'
import dayjs from 'dayjs'

import EmptyImg from '~/assets/images/onboarding-02.svg'
import { Filter } from '~/components/molecules/Filter'
import { useAuth } from '~/contexts/auth'
import useAnalytics from '~/hooks/useAnalytics'

import { Charts } from './components/Charts'
import { CreateChart } from './components/Drawers/CreateChart'
import DrawerMissionsMap from './components/Drawers/DrawerMissionsMap'
import Header from './components/Header'
import { EmptyCard } from './styles'

export default function Analytics() {
  const [form] = Form.useForm()
  const { data } = useAuth()
  const {
    loadingAnalytics,
    getMissionsUsersIds,
    missionsUsersIds,
    getUserCharts,
    totalCount,
    loadingChart,
    charts,
  } = useAnalytics()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ limit: 10, offset: 0 })
  const [openCreate, setOpenCreate] = useState(false)
  const [isOpenDrawerMissionsMap, setIsOpenDrawerMissionsMap] = useState(false)
  const start = dayjs().subtract(3, 'month').format('YYYY-MM-YY')
  const final = dayjs().format('YYYY-MM-YY')
  const [datesFilter, setDatesFilter] = useState({ start, final })

  function getMissions(params) {
    getMissionsUsersIds({ ...datesFilter, ...params })
  }

  function handleResetFilters() {
    form.resetFields()

    return getMissions({ ...datesFilter })
  }

  async function onHandleLimit() {
    const currentQuery = Object.fromEntries([...searchParams])
    setLoading(true)
    setPagination((prev) => ({ ...prev, limit: pagination.limit + 10 }))

    await getUserCharts({
      params: {
        ...currentQuery,
        ...datesFilter,
        limit: pagination.limit + 10,
      },
      ids: missionsUsersIds,
    })

    return setLoading(false)
  }

  async function onSubmitFilter(params) {
    // setChartsData([])
    await getMissions(params)

    setDatesFilter((prev) => ({
      ...prev,
      start: params.start || datesFilter?.start,
      final: params.final || datesFilter?.final,
    }))

    return getUserCharts({
      params: {
        limit: 10,
        offset: 0,
        ...params,
        ...datesFilter,
      },
      ids: missionsUsersIds,
    })
  }

  async function onRefresh() {
    return getUserCharts({
      params: {
        ...datesFilter,
        limit: pagination.limit,
        offset: pagination.offset,
      },
      ids: missionsUsersIds,
    })
  }

  useEffect(() => {
    getMissions()
  }, []) // eslint-disable-line

  return (
    <>
      <Filter
        form={form}
        loading={loadingAnalytics}
        onSubmit={onSubmitFilter}
        isPrivate={data?.is_private}
        onReset={handleResetFilters}
      />
      <Header
        missionsCount={missionsUsersIds.length}
        onClickAdd={() => setOpenCreate(true)}
        onClickMap={() => setIsOpenDrawerMissionsMap((prev) => !prev)}
      />

      {!loadingChart && charts.length === 0 ? (
        <EmptyCard>
          <Empty image={EmptyImg} description="BI Analytics" />

          <Button
            shape="round"
            type="primary"
            size="middle"
            onClick={() => setOpenCreate(true)}
          >
            Adicionar Gráfico ou Clique em Filtrar
          </Button>
        </EmptyCard>
      ) : null}

      {charts?.length > 0 ? (
        <Charts
          chartsData={charts}
          params={{
            ...datesFilter,
            missions: missionsUsersIds.map((m) => m.missions_users_id),
          }}
        />
      ) : null}

      {totalCount > charts.length && (
        <Button
          loading={loading}
          onClick={onHandleLimit}
          style={{ marginTop: 20 }}
        >
          Carregar mais...
        </Button>
      )}
      <CreateChart
        onRefresh={onRefresh}
        open={openCreate}
        onClose={() => setOpenCreate(false)}
      />
      <DrawerMissionsMap
        missions={missionsUsersIds}
        visible={isOpenDrawerMissionsMap}
        title="Mapa de Missõe(s) Localizada(s)"
        onClose={() => setIsOpenDrawerMissionsMap(false)}
      />
    </>
  )
}

import styled from 'styled-components'

export const Card = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: 6px;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.span`
  width: 80%;
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
  /* border: 1px solid; */
`

export const ContainerButton = styled.div`
  min-width: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    /* border: 1px solid; */
    padding: 4px 8px;
    margin-left: 8px;
  }
`

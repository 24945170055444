import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useBonusPoints() {
  const [loadingBonusPoints, setLoadingBonusPoints] = useState(false)
  const [bonusPoints, setBonusPoints] = useState(0)

  async function onSubmitBonusPoints({ userIds }) {
    setLoadingBonusPoints(true)
    try {
      const res = await api.post(
        'bonus-points-private-users',
        { userIds, bonusPoints },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      setBonusPoints(0)
      return message.success(
        'A bonificação de pontos para usuários selecionados foi feita com sucesso'
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingBonusPoints(false)
    }
  }

  return {
    onSubmitBonusPoints,
    bonusPoints,
    setBonusPoints,
    loadingBonusPoints,
    setLoadingBonusPoints,
  }
}

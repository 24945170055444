import React from 'react'

import { Statistic, Row, Col, Card, Skeleton } from 'antd'
import t from 'prop-types'

import { APPROVED, REPROVED, PENDING } from '~/utils/statusOptions'

import { StatiticPropTypes } from '../Detail/lib'

export function CardValues({ statistic, loading, onClick, currentStatus }) {
  const items = [
    {
      title: 'Aguardando',
      value: statistic?.registers_pendent_count || 0,
      color: '#f7c325',
      status: PENDING,
    },
    {
      title: 'Aprovado(s)',
      value: statistic?.registers_approved_count || 0,
      color: '#1bae9f',
      status: APPROVED,
    },
    {
      title: 'Reprovado(s)',
      value: statistic?.registers_reproved_count || 0,
      color: '#d3455a',
      status: REPROVED,
    },
  ]

  if (statistic?.custom_status !== undefined) {
    items.splice(
      1,
      0,
      ...statistic?.custom_status?.map((item) => ({
        title: item?.description,
        value: item?.count,
        status: 'custom_status',
        color: '#000',
      }))
    )
  }

  return (
    <Row gutter={[6, 6]}>
      {items.map((i) => (
        <Col key={i.title}>
          <Card
            hoverable
            onClick={() => onClick(i.status)}
            style={{
              borderColor: currentStatus === i.status ? '#001628' : '',
              borderWidth: currentStatus === i.status ? 2 : 0,
            }}
          >
            <Skeleton loading={loading}>
              <Statistic
                title={i.title}
                valueStyle={{ color: i.color }}
                value={Number(i.value)}
              />
            </Skeleton>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

CardValues.defaultProps = {
  statistic: {
    budget: '0',
    budget_credit: '0',
    budget_debit: '0',
    missions_actived_count: '0',
    missions_count: '0',
    missions_desactive_count: '0',
    missions_not_used: '0',
    missions_open: '0',
    missions_started: '0',
    registers_analyze_count: '0',
    registers_approved_count: '0',
    registers_count: '0',
    registers_pendent_count: '0',
    registers_reproved_count: '0',
  },
  currentStatus: 0,
  loading: false,
}

CardValues.propTypes = {
  statistic: t.objectOf(StatiticPropTypes),
  loading: t.bool,
  currentStatus: t.number,
  onClick: t.func.isRequired,
}

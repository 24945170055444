export const TYPES = {
  SHORT_ANSWER: 1,
  LONG_ANSWER: 2,
  MULTIPLE: 3,
  SELECT_LIST: 4,
  DATE: 5,
  HOUR: 6,
  NUMBER: 7,
  PRICE: 8,
  CPF: 9,
  CNPJ: 10,
  ZIP_CODE: 11,
  PHONE: 12,
  ADDRESS: 13,
  TEXT: 14,
  EMAIL: 15,
  CAMERA: 16,
}

export const TYPES_LIST = [
  {
    key: 'rc',
    icon: 'file text',
    text: 'Resposta Curta',
    value: 1,
  },
  {
    key: 'rl',
    icon: 'file text',
    text: 'Resposta Longa',
    value: 2,
  },
  {
    key: 'me',
    icon: 'selected radio',
    text: 'Multipla Escolha',
    value: 3,
  },
  {
    key: 'ls',
    icon: 'check square',
    text: 'Lista de Seleção',
    value: 4,
  },
  {
    key: 'dt',
    icon: 'calendar',
    text: 'Data',
    value: 5,
  },
  {
    key: 'hr',
    icon: 'clock',
    text: 'Hora',
    value: 6,
  },
  {
    key: 'num',
    icon: 'sort numeric ascending',
    text: 'Número',
    value: 7,
  },
  {
    key: 'dec',
    icon: 'sort numeric ascending',
    text: 'Preço (R$)',
    value: 8,
  },
  {
    key: 'cpf',
    icon: 'sort numeric ascending',
    text: 'CPF',
    value: 9,
  },
  {
    key: 'cnpj',
    icon: 'sort numeric ascending',
    text: 'CNPJ',
    value: 10,
  },
  {
    key: 'cep',
    icon: 'sort numeric ascending',
    text: 'CEP',
    value: 11,
  },
  {
    key: 'phone',
    icon: 'sort numeric ascending',
    text: 'Telefone',
    value: 12,
  },
  {
    key: 'address',
    icon: 'address',
    text: 'Endereço',
    value: 13,
  },
  {
    key: 'text',
    icon: 'text',
    text: 'Apenas Texto',
    value: 14,
  },
  {
    key: 'email',
    icon: 'email',
    text: 'E-mail',
    value: 15,
  },
  {
    key: 'camera',
    icon: 'camera',
    text: 'Camera (Foto)',
    value: 16,
  },
]

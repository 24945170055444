export const POSITIONS_TYPES = {
  air: 'Aéreo',
  eyes: 'Olhos',
  hands: 'Mãos',
  bottom: 'Rodapé',
}

export const REASONS = {
  prod_missing_and_has_stock:
    'Produto ausente na gôndola, porém esta em estoque.',
  prod_missing_and_stock: 'Produto ausente na gôndola e estoque.',
  not_apply: 'Não se aplica',
}

export const EXTRA_POINTS = {
  present_section: 'Presente na seção',
  gondola: 'Ponta de Gôndola',
  extra_point: 'Ponto Extra',
}

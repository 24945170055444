import React from 'react'

import { AlertTwoTone, StarFilled, TrophyTwoTone } from '@ant-design/icons'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'

export function columns() {
  function reachedFormsItemsOverallTarget(reached, target) {
    const result = Number(reached) >= Number(target)

    const ReachedFormsItemsOverall = () => (
      <Tooltip placement="top" title={`Meta geral (${target}) foi atingida!`}>
        <StarFilled style={{ color: 'green', marginLeft: 2, marginRight: 2 }} />
      </Tooltip>
    )

    const NotReachedFormsItemsOverall = () => (
      <Tooltip
        placement="top"
        title={`Meta geral (${target}) não foi atingida`}
      >
        <StarFilled style={{ color: 'red', marginLeft: 2, marginRight: 2 }} />
      </Tooltip>
    )

    return result ? (
      <ReachedFormsItemsOverall />
    ) : (
      <NotReachedFormsItemsOverall />
    )
  }

  return [
    {
      title: 'COD.',
      key: 'cod',
      render: (item) => (
        <div>
          {item.first_sale && (
            <Tooltip placement="top" title="Primeira Venda!">
              <TrophyTwoTone twoToneColor="gold" />
            </Tooltip>
          )}{' '}
          {item.has_operator && (
            <Tooltip
              placement="top"
              title="Respostas com operadores de alerta!"
            >
              <AlertTwoTone twoToneColor="red" />
            </Tooltip>
          )}{' '}
          {item.is_warning && (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              {item.mu_key}
            </span>
          )}
          {!item.is_warning && <span>{item.mu_key}</span>}
          {item.forms_items_overall && (
            <span>
              {reachedFormsItemsOverallTarget(
                item.forms_items_overall,
                item.forms_items_overall_target
              )}
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'identifier',
    },
    {
      title: 'Usuário',
      key: 'user',
      dataIndex: 'userName',
      render: (item) => (
        <div>
          <span>{item.split(' ')[0]}</span>
        </div>
      ),
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Missão',
      key: 'mission',
      sorter: true,
      ellipsis: true,
      dataIndex: 'missionName',
    },
    {
      title: 'Sub-categoria',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Última Atualização',
      key: 'updated_at',
      sorter: true,
      dataIndex: 'updated_at',
      render: (date) => dayjs(date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Plataforma',
      key: 'platform',
      dataIndex: 'platform',
      render: (platform) => <>{platform.toUpperCase()}</>,
    },
  ]
}

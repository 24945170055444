import { useCallback, useState } from 'react'

import api from '~/services/api'
import { message } from 'antd'

export default function useSubCategories() {
  const [subcategory, setSubcategory] = useState({})
  const [customFields, setCustomFields] = useState([])

  const getSubCategoryById = useCallback(async id => {
    try {
      const res = await api.get(`missions-main/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      if (res.data.custom_fields) {
        setCustomFields(res.data.custom_fields.fields)
      } else {
        setCustomFields([])
      }

      return setSubcategory(prevState => ({ ...prevState, ...res.data }))
    } catch (error) {
      return message().error(error.message)
    }
  }, [])


  return {
    subcategory,
    getSubCategoryById,
    customFields
  }
}

import styled from 'styled-components'

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.isCollapsable ? 'center' : 'flex-start'};
  margin: 20px;
`

export const Title = styled.span`
  color: ${(props) => props.textColor || '#fff'};
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
`

export const ContainerFooter = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
`

export const LabelVersion = styled.p`
  color: #ffffff;
  font-size: 9px;
  font-weight: 400;
  margin-top: 10px;
`
export const Footer = styled.div`
  position: absolute;
  margin-top: 250px;
  width: 100%;
  padding: 10px;
  background-color: #011529;
`

export const styles = {
  layoutHeight: {
    minHeight: '100vh',
  },
  sideBar: {
    left: 0,
    height: '100vh',
    overflow: 'auto',
    position: 'fixed',
  },
  content: {
    margin: '10px 16px 100px',
    overflow: 'initial',
  },
  layoutMarginLeft: (collapsed) => ({
    marginLeft: collapsed ? 80 : 200,
  }),
  bottom: {
    padding: 5,
    width: '100%',
    marginTop: 10,
    display: 'block',
    textAlign: 'center',
    justifiContent: 'center',
    alignItems: 'center',
  },
}

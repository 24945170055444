import React, { useEffect, useState } from 'react'

import { Radio, Divider } from 'antd'
import t from 'prop-types'

import useFiles from '~/hooks/useFiles'

import { Images } from './Images'
import { PDF } from './PDF'

export function Files({ id, onClickView }) {
  const [file, setFile] = useState('image')
  const [limitFiles, setLimitFiles] = useState(10)

  const { getFiles, files, getImages, images, countImages } = useFiles()

  async function loadingMoreFile() {
    const newLimit = limitFiles + 5
    setLimitFiles(newLimit)

    return getImages(id, { limit: limitFiles })
  }

  useEffect(() => {
    Promise.all([getFiles(id), getImages(id, { limit: limitFiles })])
  }, []) //eslint-disable-line

  return (
    <>
      <Radio.Group
        value={file}
        onChange={({ target }) => setFile(target.value)}
      >
        <Radio.Button value="image">Fotos</Radio.Button>
        <Radio.Button value="pdf">PDF</Radio.Button>
      </Radio.Group>

      <Divider />

      {file === 'image' ? (
        <Images
          files={images}
          onClick={onClickView}
          countImages={countImages}
          loadingMoreFile={() => loadingMoreFile()}
        />
      ) : (
        <PDF files={files} />
      )}
    </>
  )
}

Files.defaultTypes = {
  countImages: 0,
  loadingMoreFile: false,
}

Files.propTypes = {
  id: t.string,
  onClickView: t.func,
}

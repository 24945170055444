import React from 'react'
import { Route, Routes } from 'react-router-dom'

import PrivateLayout from '~/components/templates/PrivateLayout'
import Accounts from '~/pages/Accounts'
import AccountsPrivate from '~/pages/AccountsPrivate'
import Activation from '~/pages/Activation'
import Analytics from '~/pages/Analytics'
import Dashboard from '~/pages/Dashboard'
import PaymentsRequests from '~/pages/Finance/Payments'
import Transactions from '~/pages/Finance/Transactions'
import Forms from '~/pages/Forms'
import FormsItems from '~/pages/Forms/FormsItems'
import LoadingPage from '~/pages/LoadingPage'
import Login from '~/pages/Login'
import Missions from '~/pages/Missions'
import MissionsRequested from '~/pages/Missions/Requested'
import MissionsFeeds from '~/pages/Missions/Feeds'
import MissionsLaunch from '~/pages/Missions/Launch'
import MissionsRegisters from '~/pages/Missions/Registers'
import MissionsSchedules from '~/pages/Missions/Schedule'
import NotFound from '~/pages/NotFound'
import Products from '~/pages/Products'
import ProductsItems from '~/pages/Products/ProductsItems'
import ProductSales from '~/pages/ProductSales'
import Recovery from '~/pages/Recovery'
import Referral from '~/pages/Referral'
import Report from '~/pages/Report'
import Settings from '~/pages/Settings'
import SignUpUser from '~/pages/SignUpUser'
import Webhook from '~/pages/Webhook'
import Workforce from '~/pages/Workforce'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { Stores } from '~/pages/Workforce/Stores'
import { StoresRequests } from '~/pages/Workforce/StoresRequests'

export default function BrowserRoutes() {
  return (
    <Routes>
      <Route
        exact
        name="dashboard"
        path="/dashboard"
        element={
          <PrivateLayout>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        title="BI - Analytics"
        exact
        name="analytics"
        path="/analytics"
        element={
          <PrivateRoute>
            <Analytics />
          </PrivateRoute>
        }
      />
      <Route
        title="Workforce Manager"
        exact
        name="workforce"
        path="/workforce"
        element={
          <PrivateRoute>
            <Workforce />
          </PrivateRoute>
        }
      />
      <Route
        title="Gerenciar Lojas"
        exact
        name="stores"
        path="/workforce/managament/stores"
        element={
          <PrivateRoute>
            <Stores />
          </PrivateRoute>
        }
      />
      <Route
        title="Histórico de demandas"
        exact
        name="stores-requests"
        path="/workforce/managament/stores-requests"
        element={
          <PrivateRoute>
            <StoresRequests />
          </PrivateRoute>
        }
      />
      <Route
        title="Contas Privadas"
        exact
        name="analytics"
        path="/accounts-private"
        element={
          <PrivateRoute>
            <AccountsPrivate />
          </PrivateRoute>
        }
      />
      <Route
        title="Usuários em Campo"
        exact
        name="missions"
        path="/missions/feeds"
        element={
          <PrivateRoute>
            <MissionsFeeds />
          </PrivateRoute>
        }
      />
      <Route
        exact
        title="Agendamentos"
        name="missions"
        path="/missions/schedules"
        element={
          <PrivateRoute>
            <MissionsSchedules />
          </PrivateRoute>
        }
      />
      <Route
        title="Formulário"
        exact
        name="forms"
        path="/forms"
        element={
          <PrivateRoute>
            <Forms />
          </PrivateRoute>
        }
      />
      <Route
        title="Formulário"
        exact
        name="formsItems"
        path="/forms/:id"
        element={
          <PrivateRoute>
            <FormsItems />
          </PrivateRoute>
        }
      />
      <Route
        title="Categorias de Produtos"
        exact
        name="products"
        path="/products"
        element={
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        }
      />
      <Route
        title="Categoria de Produtos / Produtos"
        exact
        name="products"
        path="/products/:id"
        element={
          <PrivateRoute>
            <ProductsItems />
          </PrivateRoute>
        }
      />
      <Route
        title="Vendas Consolidadas"
        exact
        name="sales"
        path="/products-sales"
        element={
          <PrivateRoute>
            <ProductSales />
          </PrivateRoute>
        }
      />
      <Route
        title="Pagamentos"
        exact
        name="payments"
        path="/payments"
        element={
          <PrivateRoute>
            <PaymentsRequests />
          </PrivateRoute>
        }
      />
      <Route
        title="Financeiro"
        exact
        name="payments"
        path="/transactions"
        element={
          <PrivateRoute>
            <Transactions />
          </PrivateRoute>
        }
      />
      <Route
        title="Missões Lançadas"
        exact
        name="missions"
        path="/missions"
        element={
          <PrivateRoute>
            <Missions />
          </PrivateRoute>
        }
      />
      <Route
        title="Solicitações"
        exact
        name="missions"
        path="/missions/requested"
        element={
          <PrivateRoute>
            <MissionsRequested />
          </PrivateRoute>
        }
      />
      <Route
        exact
        name="missions"
        path="/missions/registers"
        element={
          <PrivateRoute>
            <MissionsRegisters />
          </PrivateRoute>
        }
      />
      <Route
        title="Lançar Missão"
        exact
        name="missions"
        path="/missions/launch"
        element={
          <PrivateRoute>
            <MissionsLaunch />
          </PrivateRoute>
        }
      />
      <Route
        title="Exportação"
        exact
        name="reports"
        path="/reports"
        element={
          <PrivateRoute>
            <Report />
          </PrivateRoute>
        }
      />
      <Route
        title="Configurações"
        exact
        name="settings"
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        title="Subcontas"
        exact
        name="accounts"
        path="/accounts"
        element={
          <PrivateRoute>
            <Accounts />
          </PrivateRoute>
        }
      />
      <Route
        title="Webhook"
        exact
        name="webhook"
        path="/webhook"
        element={
          <PrivateRoute>
            <Webhook />
          </PrivateRoute>
        }
      />

      <Route
        path="/"
        element={
          <PublicRoute>
            <LoadingPage />
          </PublicRoute>
        }
      />
      <Route
        exact
        name="login"
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        exact
        name="signup"
        path="/signup"
        element={
          <PublicRoute>
            <SignUpUser />
          </PublicRoute>
        }
      />
      <Route
        exact
        name="referral"
        path="/referral"
        element={
          <PublicRoute>
            <Referral />
          </PublicRoute>
        }
      />
      <Route
        exact
        name="activation"
        path="/activation"
        element={
          <PublicRoute>
            <Activation />
          </PublicRoute>
        }
      />
      <Route
        exact
        name="recovery"
        path="/recovery"
        element={
          <PublicRoute>
            <Recovery />
          </PublicRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useFormsItemsParents() {
  const [parents, setParents] = useState([])

  async function createParent(params) {
    try {
      const res = await api.post('forms-items-parents/customers', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Operação realizada com sucesso')
    } catch (error) {
      return message.error(error.message)
    }
  }

  async function getParents(params) {
    try {
      const res = await api.get('forms-items-parents/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setParents(res.data)
    } catch (error) {
      return message.error(error.message)
    }
  }

  async function removeParents(params) {
    try {
      const res = await api.delete('forms-items-parents/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Item removido com sucesso')
    } catch (error) {
      return message.error(
        error.message || 'Houve um problema para remover o item'
      )
    }
  }

  return {
    createParent,
    getParents,
    parents,
    removeParents,
  }
}

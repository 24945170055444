import React from 'react'

import { Statistic, Row, Col, Card, Skeleton } from 'antd'
import t from 'prop-types'

import colors from '~/styles/colors'

import styles from './styles'

const Count = ({ statistic, loading }) => {
  return (
    <Row gutter={[6, 6]} style={styles.pageContainer}>
      <Col>
        <Card style={styles.container}>
          <Skeleton loading={loading}>
            <Statistic
              prefix="R$"
              title="Saldo Atual"
              valueStyle={{ color: colors.greenSolid }}
              value={Number(statistic.amount) || 0}
            />
          </Skeleton>
        </Card>
      </Col>
      <Col>
        <Card style={styles.container}>
          <Skeleton loading={loading}>
            <Statistic
              prefix="R$"
              title="Total de Pagamento(s)"
              valueStyle={{ color: colors.redStrong }}
              value={Number(statistic.budgetDebit) || 0}
            />
          </Skeleton>
        </Card>
      </Col>
    </Row>
  )
}

Count.defaultTypes = {
  loading: false,
}

Count.propTypes = {
  statistic: t.objectOf(
    t.shape({
      amount: t.string,
      budgetDebit: t.string,
    })
  ).isRequired,
  loading: t.bool.isRequired,
}

export default Count

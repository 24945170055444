import React, { memo, useEffect } from 'react'
import Gallery from 'react-grid-gallery'

import { FileExcelOutlined } from '@ant-design/icons'
import { Divider, Button, Space, Row, Badge } from 'antd'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import useFormsData from '~/hooks/useFormsData'
import useFormsItems from '~/hooks/useFormsItems'

import SectionsAvg from '../SectionsAvg'
import { isOperator } from './helpers/operator'
import { Description, Container, Item } from './styles'

const IS_CAMERA = 16

const RenderGallery = memo(function RenderGallery({ images }) {
  const formattedImages = images?.map((i, index) => ({
    src: i.path,
    thumbnail: i.path,
    caption: `${Date.now()}_${index}`,
    thumbnailWidth: 120,
    thumbnailHeight: 180,
  }))

  return <Gallery maxRows={1} images={formattedImages} />
})

const ImagesComponent = ({ answer }) => (
  <Badge count={JSON.parse(answer).length}>
    <Container>
      <RenderGallery images={JSON.parse(answer)} />
    </Container>
  </Badge>
)

const ConfigResponse = ({ answer }) => {
  try {
    const answerParse = JSON.parse(answer)

    if (answerParse && typeof answerParse === 'object') {
      return <ImagesComponent answer={answer} />
    }

    return answer
  } catch (error) {
    return false
  }
}

export function Answers({ id }) {
  const {
    onExtractLocal,
    loadingExtract,
    getFormsDataByMissionsUsersId,
    formsData,
    loadingFormData,
  } = useFormsData()
  const { getSectionRatio, ratioData, loadingRatio, generalScore } =
    useFormsItems()

  useEffect(() => {
    getFormsDataByMissionsUsersId(id)
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingFormData && formsData.length > 0) {
      getSectionRatio({
        formsId: formsData[0]?.items[0].forms_id,
        missionsUsersId: id,
      })
    }
  }, [formsData.length, loadingFormData]) //eslint-disable-line

  if (loadingFormData) {
    return <Loading />
  }

  return (
    <>
      {generalScore?.totalPotential > 0 ? (
        <SectionsAvg
          ratioData={ratioData}
          loadingRatio={loadingRatio}
          generalScore={generalScore}
        />
      ) : null}

      <Space direction="horizontal" size="large">
        <Button
          shape="round"
          icon={<FileExcelOutlined />}
          color="green"
          loading={loadingExtract}
          onClick={() =>
            onExtractLocal({
              data: formsData,
              fileName: `form_${new Date().getTime()}`,
            })
          }
        >
          Baixar .XLSX
        </Button>
      </Space>
      <Divider />
      <Row>
        {formsData?.map((i, k) => {
          return (
            <Description
              bordered
              key={Number(k) + 1}
              column={1}
              size="small"
              title={`Seção ${i.items[0].section}`}
            >
              {i.items
                .filter((item) => item.answer.trim().length > 0)
                .map((a, index) => {
                  const operatorResult =
                    a?.operator_warning !== null
                      ? isOperator(a.operator_warning, a.answer)
                      : null

                  return (
                    <Item key={Number(index) + 1} label={a.question}>
                      {operatorResult}

                      {a.type === IS_CAMERA ? (
                        <ConfigResponse answer={a.answer} />
                      ) : (
                        a.answer
                      )}
                    </Item>
                  )
                })}
            </Description>
          )
        })}
      </Row>
    </>
  )
}

export default memo(Answers)

Answers.propTypes = {
  id: t.string,
}

ConfigResponse.propTypes = {
  answer: t.string,
}

RenderGallery.propTypes = {
  images: t.arrayOf(t.objectOf({ path: t.string })),
}

ImagesComponent.propTypes = {
  answer: t.string,
}

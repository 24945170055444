import React, { useEffect } from 'react'

import { Button, Alert, Space } from 'antd'
import t from 'prop-types'

import { APPROVATION_BATCH_STATUS, QUEUE_APPROVATION_STATUS } from '../helpers'

export function QueueApprovationsBatch({
  queueApprovationsBatch,
  getApprovationBatch,
  onClick,
  setQueueApprovationsBatch,
}) {
  const INTERVAL_SECONDS_GET_UPDATE = 30000

  const getApprovationsBatch = async () => {
    if (queueApprovationsBatch.length === 0) {
      return
    }

    const itemsInQueueOrProcessing = queueApprovationsBatch.filter(
      (item) => item.status !== QUEUE_APPROVATION_STATUS.DONE
    )

    setQueueApprovationsBatch(itemsInQueueOrProcessing)

    const promisesApprovationsBatch = []

    for (let index = 0; index < itemsInQueueOrProcessing.length; index += 1) {
      promisesApprovationsBatch.push(
        getApprovationBatch(itemsInQueueOrProcessing[index].id, index)
      )
    }

    await Promise.all(promisesApprovationsBatch)
  }

  useEffect(() => {
    const intervalToGetApprovationBatchStatus = setInterval(() => {
      getApprovationsBatch()
    }, INTERVAL_SECONDS_GET_UPDATE)

    return () => clearInterval(intervalToGetApprovationBatchStatus)
  }, [queueApprovationsBatch]) //eslint-disable-line

  if (queueApprovationsBatch.length === 0) {
    return false
  }

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
      {queueApprovationsBatch.map((item) => (
        <Alert
          message={`${item.total_processed}/${
            item.total
          } Registros em Processamento (${
            APPROVATION_BATCH_STATUS[item.status]
          })`}
          type="warning"
          action={
            <Button size="small" type="ghost" onClick={() => onClick(item.id)}>
              Ver Detalhes
            </Button>
          }
          closable
        />
      ))}
    </Space>
  )
}

QueueApprovationsBatch.defaultPros = {
  queueApprovationsBatch: [],
}

QueueApprovationsBatch.propTypes = {
  queueApprovationsBatch: t.arrayOf(
    t.shape({
      total_processed: t.string,
      total: t.string,
      status: t.string,
      id: t.string,
    })
  ),
  getApprovationBatch: t.func,
  onClick: t.func,
  setQueueApprovationsBatch: t.func,
}

/* eslint-disable camelcase */
import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useStores() {
  const [stores, setStores] = useState([])
  const [storesCount, setStoresCount] = useState(0)
  const [loadingStores, setLoadingStores] = useState(true)

  const [isApproveLoading, setApproveLoading] = useState(false)
  const [isRepproveLoading, setRepproveLoading] = useState(false)

  async function getStores(params) {
    try {
      setLoadingStores(true)
      const res = await api.get('workforce-management-store/request', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setStoresCount(res?.data?.length || 0)
      return setStores(res?.data)
    } catch (error) {
      return message.error('Houve um problema no carregamento...')
    } finally {
      setLoadingStores(false)
    }
  }

  async function updateStoresStatus(params) {
    const isApproved = params?.approved_all || params?.status === 'APPROVED'
    const currentLoading = isApproved ? setApproveLoading : setRepproveLoading

    try {
      currentLoading(true)
      const res = await api.post(
        'workforce-management-store/request/manage-status',
        {
          params,
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Status atualizado com sucesso.')
    } catch (error) {
      return message.error('Houve um problema ao atualizar o status...')
    } finally {
      currentLoading(false)
    }
  }

  return {
    stores,
    getStores,
    updateStoresStatus,
    storesCount,
    loadingStores,
    isApproveLoading,
    isRepproveLoading,
  }
}

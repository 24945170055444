import React from 'react'

import {
  CloseCircleFilled,
  PushpinFilled,
  RocketFilled,
  StarFilled,
} from '@ant-design/icons'
import { Popover } from 'antd'
import t from 'prop-types'

const icons = (name, color) => {
  const nameIcons = {
    totalDisponible: <PushpinFilled style={{ color }} />,
    totalInProgress: <RocketFilled style={{ color }} />,
    totalMissed: <CloseCircleFilled style={{ color }} />,
    avgRating: <StarFilled style={{ color }} />,
  }

  return nameIcons[name]
}

export function Counter({ iconName, iconColor, text, description }) {
  return (
    <Popover content={description}>
      {icons(iconName, iconColor)} {text || 0}
    </Popover>
  )
}

Counter.propTypes = {
  iconName: t.string,
  iconColor: t.string,
  text: t.string,
  description: t.string,
}

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

const FORMS_URL = 'forms/customers'

export default function useForms() {
  const [loadingForms, setLoadingForms] = useState(false)
  const [forms, setForms] = useState([])
  const [countForms, setCountForms] = useState(0)

  const getAll = useCallback(async (params) => {
    try {
      setLoadingForms(true)
      const res = await api.get(FORMS_URL, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingForms(false)
      setCountForms(res.count)
      return setForms(res.data)
    } catch (error) {
      setLoadingForms(false)
      return message.error(error.message)
    }
  }, [])

  async function onCreateForm(data) {
    try {
      setLoadingForms(true)
      const res = await api.post(FORMS_URL, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingForms(false)
      return getAll()
    } catch (error) {
      setLoadingForms(false)
      return message.error(error.message)
    }
  }

  async function onRemoveForm(params) {
    try {
      setLoadingForms(true)
      const res = await api.delete(FORMS_URL, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingForms(false)
      return getAll()
    } catch (error) {
      setLoadingForms(false)
      return message.error(error.message)
    }
  }

  async function onEditForm(data) {
    try {
      setLoadingForms(true)
      const res = await api.patch('forms/customers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingForms(false)
      return getAll()
    } catch (error) {
      setLoadingForms(false)
      return message.error(error.message)
    }
  }

  async function onCopyForm(data) {
    try {
      await api.post('forms/customers/copy', data)
      return message.success('Formulário copiado com com sucesso!')
    } catch (error) {
      return message.error(error.message)
    }
  }

  return {
    getAll,
    forms,
    onCopyForm,
    countForms,
    onEditForm,
    onCreateForm,
    onRemoveForm,
    loadingForms,
  }
}

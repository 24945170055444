/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import { Modal, Tag } from 'antd'
import * as t from 'prop-types'

import TableComponent from '~/components/organisms/Table'
import useRegisters from '~/hooks/useRegisters'
import { dateParse } from '~/utils/helpers'

function ExportRegistersList({ open, onCancel }) {
  const {
    getExportRegisters,
    loadingExportRegistersList,
    totalRegistersExportList,
    registersExportList,
  } = useRegisters()

  const [pagination, setPagination] = useState({
    pageSize: 15,
    current: 1,
  })

  function handlePagination(page) {
    const pageLimit = page.current * pagination.pageSize
    const currentOffset =
      page.current === 1 ? 0 : pageLimit - pagination.pageSize

    setPagination((prev) => ({ ...prev, current: page.current }))

    return getExportRegisters({
      limit: pagination.pageSize,
      offset: currentOffset,
    })
  }

  useEffect(() => {
    getExportRegisters(pagination)
  }, [open]) // eslint-disable-line

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={900}
      title="Lista de Registros Exportados"
    >
      <TableComponent
        columns={[
          {
            title: 'STATUS',
            dataIndex: 'status',
            render: (status) => (
              <Tag color={status === 'DONE' ? 'green' : 'yellow'}>
                {status === 'DONE' ? 'Completo' : 'Aguardando'}
              </Tag>
            ),
          },
          {
            title: 'URL',
            dataIndex: 'url',
            render: (url) => (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url ? 'Abrir Arquivo .XLS' : ''}
              </a>
            ),
          },
          {
            title: 'CRIADO EM',
            dataIndex: 'created_at',
            render: (date) => dateParse(date),
          },
          {
            title: 'ATUALIZADO EM',
            dataIndex: 'updated_at',
            render: (date) => dateParse(date),
          },
        ]}
        data={registersExportList}
        loading={loadingExportRegistersList}
        total={totalRegistersExportList}
        current={pagination.current}
        pageSize={pagination.pageSize}
        onChangePageSize={handlePagination}
      />
    </Modal>
  )
}

ExportRegistersList.propTypes = {
  open: t.bool,
  onCancel: t.func,
}

export { ExportRegistersList }

import { useCallback, useState } from 'react'

import { message } from 'antd'
import FileSaver from 'file-saver'

import api from '~/services/api'

function useCustomersAccountsPrivate() {
  const [countData, setCountData] = useState(0)
  const [loadingAccountsPrivate, setLoadingAccountsPrivate] = useState(true)

  const [accountsPrivate, setAccountsPrivate] = useState([])

  const getCustomersAccountsPrivate = useCallback(async (params) => {
    setLoadingAccountsPrivate(true)
    try {
      const res = await api.get('customers/users', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountData(Number(res.data.count))
      return setAccountsPrivate(res.data.users)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingAccountsPrivate(false)
    }
  }, [])

  async function getCustomersAccountsPrivateXls(params) {
    setLoadingAccountsPrivate(true)
    try {
      const res = await api.get('customers/users', {
        params,
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      return FileSaver.saveAs(blob, `users_${Date.now()}`)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingAccountsPrivate(false)
    }
  }

  return {
    countData,
    accountsPrivate,
    loadingAccountsPrivate,
    getCustomersAccountsPrivate,
    getCustomersAccountsPrivateXls,
  }
}

export default useCustomersAccountsPrivate

import React, { useState } from 'react'

import { Modal, Button, Select, Space } from 'antd'
import t from 'prop-types'

export function RegistersActionModal({
  open,
  onCancel,
  options,
  onClick,
  loading,
}) {
  const [value, setValue] = useState(null)

  function onSubmit() {
    return onClick(value)
  }

  return (
    <Modal
      open={open}
      title="Ações do Registro"
      onOk={onClick}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => onSubmit()}
          disabled={value === null || loading}
        >
          Enviar
        </Button>,
      ]}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <Select
          placeholder="Ações..."
          style={{ width: '100%' }}
          onChange={(opt) => setValue(opt)}
          options={options.map((i) => ({
            label: i.description,
            value: i.value,
          }))}
        />
      </Space>
    </Modal>
  )
}

RegistersActionModal.propTypes = {
  open: t.bool,
  onCancel: t.func,
  loading: t.bool,
  onClick: t.func,
  options: t.arrayOf([t.string]),
}

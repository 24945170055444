import React, { useState } from 'react'

import {
  DownOutlined,
  ExportOutlined,
  FunnelPlotOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Popover, Button, Space, Popconfirm, Dropdown } from 'antd'
import t from 'prop-types'

import { PENDING } from '~/utils/statusOptions'

import { FilterPropTypes, StatiticPropTypes } from '../Detail/lib'
import { ActionButtons } from './ActionButtons'
import { CardValues } from './CardValues'
import { Filter } from './Filter'
import { RowContainer } from './styles'

export function Header({
  onChange,
  loading,
  onClickApprove,
  onOpenApprovationBatch,
  filters,
  statistic,
  loadingData,
  currentStatus,
  visibleFilter,
  onClickStatus,
  selectedRowKeys,
  loadingRegister,
  onClickExport,
}) {
  const [open, setOpen] = useState(false)
  const isExecutingApprovationBatch =
    selectedRowKeys.length === 0 && loadingRegister

  const menuItems = () => ({
    onClick: ({ key }) => onClickExport(key),
    items: [
      {
        key: 'export',
        icon: <ExportOutlined />,
        label: 'Exportar Registros',
      },
      {
        key: 'list',
        icon: <UnorderedListOutlined />,
        label: 'Ver Registros Exportados',
      },
    ],
  })

  return (
    <>
      <CardValues
        key={1}
        statistic={statistic}
        loading={loadingData}
        onClick={onClickStatus}
        currentStatus={currentStatus}
      />
      <RowContainer key={2}>
        <Space size="small">
          <Popover
            key={3}
            title="Filtros"
            trigger="click"
            placement="leftTop"
            open={visibleFilter}
            content={
              <Filter
                form={filters.form}
                currentStatus={currentStatus}
                onReset={filters.onReset}
                submitFilter={filters.submitFilter}
              />
            }
            onOpenChange={onChange}
          >
            <Button shape="round" icon={<FunnelPlotOutlined />}>
              Filtrar
            </Button>
          </Popover>
          {currentStatus === PENDING ? (
            <Popconfirm
              title="Atenção. Muito Cuidado!"
              description={`Ao Clicar em Aprovar Missões todas as Missões ${
                selectedRowKeys.length === 0
                  ? 'Pendentes'
                  : `Selecionada(s) (${selectedRowKeys.length})`
              } serão Aprovadas e ficaram processando em Registros em Fila, têm certeza que deseja continuar?`}
              open={open}
              cancelText="Não"
              okText="Sim, quero continuar"
              onConfirm={() => {
                setOpen(false)

                return onClickApprove()
              }}
              okButtonProps={{ loading: isExecutingApprovationBatch }}
              onCancel={() => setOpen(false)}
            >
              <Button
                shape="round"
                type="primary"
                onClick={() => setOpen(true)}
                loading={isExecutingApprovationBatch}
                disabled={isExecutingApprovationBatch}
              >
                {`Aprovar Missões ${
                  selectedRowKeys.length > 0
                    ? `(${selectedRowKeys.length})`
                    : ''
                }`}
              </Button>
            </Popconfirm>
          ) : null}
          <Button shape="round" onClick={onOpenApprovationBatch}>
            Registros em Fila
          </Button>
          <Dropdown menu={menuItems()}>
            <Button shape="round">
              <Space>
                Exportação
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      </RowContainer>
      <ActionButtons
        key={4}
        selectedRowKeys={selectedRowKeys}
        status={currentStatus}
        loading={loading}
        loadingRegister={loadingRegister}
        onClickApprove={onClickApprove}
      />
    </>
  )
}

Header.defaultProps = {
  filters: {},
  selectedRowKeys: [],
}

Header.propTypes = {
  onChange: t.func.isRequired,
  loading: t.bool.isRequired,
  onClickApprove: t.func.isRequired,
  filters: t.objectOf(FilterPropTypes),
  statistic: t.objectOf(StatiticPropTypes).isRequired,
  loadingData: t.bool.isRequired,
  currentStatus: t.number.isRequired,
  visibleFilter: t.bool.isRequired,
  loadingRegister: t.bool,
  onClickStatus: t.func.isRequired,
  onOpenApprovationBatch: t.func,
  selectedRowKeys: t.arrayOf(t.string),
  onClickExport: t.func,
}

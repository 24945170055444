import React from 'react'

import { Form, Drawer, Input, Button } from 'antd'
import t from 'prop-types'

const FormDetail = ({
  form,
  item,
  loading,
  open,
  type,
  onClose,
  handleSubmit,
}) => (
  <Drawer
    width={720}
    visible={open}
    onClose={onClose}
    destroyOnClose="true"
    title="Atualizar Nome do Formulário"
  >
    <Form
      form={form}
      initialValues={item}
      defaultValue={item}
      onFinish={(values) => handleSubmit(values, type)}
    >
      <Form.Item name="name">
        <Input placeholder="Nome do Formulário..." />
      </Form.Item>
      <Form.Item name="link">
        <Input placeholder="https://seulink.com" />
      </Form.Item>
      <Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Enviar
        </Button>
      </Form.Item>
    </Form>
  </Drawer>
)

FormDetail.defaultTypes = {
  open: false,
  loading: false,
}

FormDetail.propTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  open: t.bool.isRequired,
  type: t.string.isRequired,
  onClose: t.func.isRequired,
  handleSubmit: t.func.isRequired,
}

export default FormDetail

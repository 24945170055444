import React, { memo } from 'react'

import t from 'prop-types'

import { Container } from './styles'

function ModalCreateQuestion({ isOpen, closeModal, children }) {
  return (
    <Container
      onCancel={closeModal}
      visible={isOpen}
      footer={null}
      centered
      destroyOnClose
      width="80%"
    >
      {children}
    </Container>
  )
}

ModalCreateQuestion.propTypes = {
  isOpen: t.bool,
  closeModal: t.func,
  children: t.node,
}

export default memo(ModalCreateQuestion)

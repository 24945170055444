import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useWebhook() {
  const [loadingWebhooks, setLoadingWebhooks] = useState(false)
  const [webhooks, setWebhooks] = useState([])
  const [events, setEvents] = useState([])

  async function getWebhooks(params) {
    try {
      setLoadingWebhooks(true)
      const res = await api.get('webhook', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setWebhooks(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingWebhooks(false)
    }
  }

  async function removeWebhook(webhookId) {
    try {
      setLoadingWebhooks(true)

      const res = await api.delete(`webhook/${webhookId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      await getWebhooks()

      return message.success('Webhook removido com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingWebhooks(false)
    }
  }

  async function getEvents(params) {
    try {
      setLoadingWebhooks(true)
      const res = await api.get('webhook/events', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setEvents(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingWebhooks(false)
    }
  }
  async function createWebhook(params) {
    try {
      setLoadingWebhooks(true)
      const res = await api.post('webhook', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      await getWebhooks()

      return message.success('Webhook criado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingWebhooks(false)
    }
  }

  return {
    getWebhooks,
    loadingWebhooks,
    webhooks,
    removeWebhook,
    getEvents,
    events,
    createWebhook,
  }
}

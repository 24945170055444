import { useCallback, useState } from 'react'

import { message } from 'antd'
import axios from 'axios'

const GOOGLE_URL = 'https://maps.google.com/maps/api/geocode/json'

export default function useSearchLocation() {
  const [locations, setLocations] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)

  function onClear() {
    return setLocations([])
  }

  const onSearchAddress = useCallback(async (address) => {
    setLoadingSearch(true)
    try {
      const res = await axios.get(GOOGLE_URL, {
        params: {
          address,
          language: 'pt-BR',
          components: 'country:BR',
          key: process.env.REACT_APP_GOOGLE_KEY,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLocations(res.data.results)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingSearch(false)
    }
  }, [])

  function onGeocodeFormat(unformatted) {
    return new Promise((resolve) => {
      const reducedAddress = unformatted
        .map((component) => {
          if (component.types.includes('street_number')) {
            return {
              number: component.long_name,
            }
          }
          if (component.types.includes('route')) {
            return {
              street: component.long_name,
            }
          }
          if (
            component.types.includes('political') &&
            (component.types.includes('sublocality') ||
              component.types.includes('sublocality_level_1'))
          ) {
            return {
              neighborhood: component.long_name,
            }
          }
          if (
            component.types.includes('administrative_area_level_2') &&
            component.types.includes('political')
          ) {
            return {
              city: component.long_name,
            }
          }
          if (
            component.types.includes('administrative_area_level_1') &&
            component.types.includes('political')
          ) {
            return {
              state: component.short_name,
            }
          }
          if (
            component.types.includes('country') &&
            component.types.includes('political')
          ) {
            return {
              country: component.long_name,
            }
          }

          return {
            postal_code: component.long_name,
          }
        })
        .reduce((accumulator, current) => Object.assign(accumulator, current))

      resolve(reducedAddress)
    })
  }

  return {
    onClear,
    locations,
    loadingSearch,
    onGeocodeFormat,
    onSearchAddress,
  }
}

// NAO MIGRADO ARQUIVOS ANEXOS PDF / PHOTOS
import { useState, useCallback } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useApiLogs() {
  const [logs, setLogs] = useState([])
  const [loadingLogs, setLoading] = useState(true)

  const getLogsById = useCallback(async ({ refferalId, limit }) => {
    try {
      setLoading(true)
      const res = await api.get(
        `webhook/logs?refferalId=${refferalId}&limit=${limit}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLogs(res.data)
    } catch (error) {
      return setLogs([])
    } finally {
      setLoading(false)
    }
  }, [])

  async function onResendLogs({ logId }) {
    try {
      setLoading(true)
      const res = await api.post(
        'webhook/logs-resend',
        { logId },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Log foi reenviado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    logs,
    getLogsById,
    onResendLogs,
    loadingLogs,
  }
}

import React from 'react'

import { Tag } from 'antd'
import dayjs from 'dayjs'

import { TableRowActions } from './TableRowActions'

const STATUS_DESCRIPTION = {
  APPROVED: 'APROVADO',
  PENDING: 'AGUARDANDO',
  REPROVED: 'REJEITADO',
}

const STATUS_VARIANT = {
  APPROVED: 'success',
  PENDING: 'warning',
  REPROVED: 'error',
}

export const columns = [
  {
    title: 'Titulo',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <strong>{text}</strong>,
  },
  {
    title: 'Identificador',
    dataIndex: 'identifier',
    key: 'identifier',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      const status = text

      return (
        <div className="flex space-x-2">
          <Tag color={STATUS_VARIANT[status] || 'outline'}>
            {STATUS_DESCRIPTION[status] || 'NÃO AVALIADA'}
          </Tag>
        </div>
      )
    },
  },
  {
    title: 'Criado Em',
    key: 'created_at',
    dataIndex: 'created_at',
    render: (text) => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '',
    key: 'action',
    render: () => <TableRowActions />,
  },
]

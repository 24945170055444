import React from 'react'

import { Card, Row, Statistic } from 'antd'
import t from 'prop-types'

import { CardStyle, PointsSpan } from './styles'

function SectionsAvg({ ratioData, loadingRatio, generalScore }) {
  return (
    <Row>
      <Card
        key="general"
        loading={loadingRatio}
        hoverable
        style={{ margin: 5 }}
        bodyStyle={CardStyle}
      >
        <Statistic
          title="Nota Geral"
          value={generalScore?.totalPercent}
          precision={0}
          suffix="%"
          style={{ flex: 1 }}
        />
        <PointsSpan>
          Pontos Possíveis: {generalScore?.totalPotential}
        </PointsSpan>

        <PointsSpan>Pontos Obtidos: {generalScore.totalRealised}</PointsSpan>
      </Card>

      {ratioData?.map((data, index) => (
        <Card
          key={String(`key-${index}`)}
          loading={loadingRatio}
          hoverable
          style={{ margin: 5 }}
          bodyStyle={CardStyle}
        >
          <Statistic
            title={data?.name_section || `Seção ${data?.section}`}
            value={data?.rating}
            precision={0}
            suffix="%"
            style={{ flex: 1 }}
          />
          <PointsSpan>
            Pontos Possíveis: {data?.total_potential_score}
          </PointsSpan>
          <PointsSpan>Pontos Obtidos: {data?.score || 0}</PointsSpan>
        </Card>
      ))}
    </Row>
  )
}

SectionsAvg.propTypes = {
  ratioData: t.arrayOf(t.objectOf(t.any)),
  loadingRatio: t.bool,
  generalScore: t.objectOf(t.number),
}

export default SectionsAvg

import React from 'react'

import { SmileOutlined } from '@ant-design/icons'
import { List, Row, Col, Skeleton, Input } from 'antd'
import t from 'prop-types'

import useSearchLocation from '~/hooks/useSearchLocation'

import { ViewportPropTypes, MissionsPropsTypes } from './lib'
import { Maps } from './Maps'

const { Search } = Input

const style = {
  item: {
    cursor: 'pointer',
  },
  container: {
    textAlign: 'center',
  },
  iconSize: {
    fontSize: 20,
  },
}

export function Local({
  mission,
  hasLocation,
  viewport,
  onClick,
  onChangeViewport,
}) {
  const { onSearchAddress, locations, loadingSearch, onClear } =
    useSearchLocation()

  return (
    <>
      {hasLocation && (
        <div style={style.container}>
          <SmileOutlined style={style.iconSize} />
          <p>Essa categoria não é localizada.</p>
        </div>
      )}

      {!hasLocation && (
        <Row gutter={[16, 16]}>
          <Col lg={12} md={12} sm={24}>
            <Search
              allowClear
              enterButton
              loading={loadingSearch}
              onSearch={onSearchAddress}
              defaultValue={mission.place}
              placeholder="Digite Endereço e Pressione 'Enter'"
            />

            <List itemLayout="horizontal" size="small">
              {locations.length > 0 &&
                locations?.map((i) => (
                  <List.Item
                    key={i.formatted_address}
                    style={style.item}
                    onClick={() => {
                      onClick(i)
                      return onClear()
                    }}
                  >
                    <Skeleton loading={loadingSearch} active>
                      <List.Item.Meta title={i.formatted_address} />
                    </Skeleton>
                  </List.Item>
                ))}
            </List>
          </Col>
          <Col lg={12} md={12} sm={24}>
            <Maps
              location={mission}
              viewport={viewport}
              onChange={onChangeViewport}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

Local.defaultTypes = {
  loading: false,
}

Local.propTypes = {
  mission: t.objectOf(MissionsPropsTypes).isRequired,
  viewport: t.objectOf(ViewportPropTypes).isRequired,
  hasLocation: t.bool.isRequired,
  onClick: t.func.isRequired,
  onChangeViewport: t.func.isRequired,
}

import React, { useEffect } from 'react'

import {
  SettingOutlined,
  FileTextOutlined,
  ShoppingOutlined,
  FileSearchOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import { Drawer, Tabs, Space } from 'antd'
import t from 'prop-types'

import useFormsMissions from '~/hooks/useFormsMissions'
import { Forms } from '~/pages/Missions/Launch/components'

import MissionSchedulesList from '../components/MissionSchedulesList'
import ScheduleForm from '../components/ScheduleForm'
import Info from './components/Info'
import Products from './components/Products'
import { UploadFiles } from './components/UploadFiles'

const { TabPane } = Tabs

function Detail({
  form,
  item,
  visible,
  onClose,
  loadingUpdate,
  onChange,
  handleUpdate,
  submitSchedule,
  onChangeAddress,
  customFields,
  customFieldsAnswers,
  handleUpdateCustomFieldAnswers,
  onChangeMissionMainId,
}) {
  const {
    findFormsMissions,
    hasSelectedForm,
    onChangeFormsMissions,
    loadingFormsMissions,
  } = useFormsMissions()

  function onChangeForm(id) {
    return onChangeFormsMissions({ mission_id: item.uid, forms_id: id })
  }

  useEffect(() => {
    if (item?.has_forms) {
      findFormsMissions({ missionId: item.uid })
    }
  }, []) //eslint-disable-line

  return (
    <Drawer
      width={720}
      onClose={onClose}
      open={visible}
      destroyOnClose="true"
      title={`${item.mission_key} - ${item.name}`}
    >
      <Tabs defaultActiveKey="0">
        <TabPane
          key="0"
          tab={
            <span>
              <SettingOutlined />
              Dados
            </span>
          }
        >
          <Info
            onChangeMissionMainId={onChangeMissionMainId}
            handleUpdateCustomFieldAnswers={handleUpdateCustomFieldAnswers}
            customFieldsAnswers={customFieldsAnswers}
            customFields={customFields}
            item={item}
            form={form}
            loading={loadingUpdate}
            onChange={onChange}
            handleUpdate={handleUpdate}
            onChangeAddress={onChangeAddress}
          />
        </TabPane>
        {item.has_forms && (
          <TabPane
            key="1"
            tab={
              <span>
                <FileTextOutlined />
                Formulário
              </span>
            }
          >
            {!loadingFormsMissions && (
              <Forms
                onClick={(id) => onChangeForm(id)}
                isSelected={hasSelectedForm?.forms_id}
              />
            )}
          </TabPane>
        )}
        {item.has_products && (
          <TabPane
            key="2"
            tab={
              <span>
                <ShoppingOutlined />
                Produtos
              </span>
            }
          >
            <Products missionId={item.uid} />
          </TabPane>
        )}
        {item.has_attachment && (
          <TabPane
            key="3"
            tab={
              <span>
                <FileSearchOutlined />
                Documentos
              </span>
            }
          >
            <UploadFiles id={item.uid} />
          </TabPane>
        )}
        <TabPane
          key="4"
          tab={
            <span>
              <CalendarOutlined />
              Agendamentos
            </span>
          }
        >
          <Space direction="vertical" size="large">
            <MissionSchedulesList missionId={item.uid} />

            <ScheduleForm
              customerId={item.customer_id}
              submitSchedule={submitSchedule}
            />
          </Space>
        </TabPane>
      </Tabs>
    </Drawer>
  )
}

Detail.defaultTypes = {
  visible: false,
  loading: false,
}

Detail.propTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  visible: t.bool.isRequired,
  onClose: t.func.isRequired,
  onChangeMissionMainId: t.func.isRequired,
  onChange: t.func.isRequired,
  onChangeAddress: t.func,
  loadingUpdate: t.bool,
  handleUpdate: t.func.isRequired,
  submitSchedule: t.func.isRequired,
  customFields: t.array,
  customFieldsAnswers: t.array,
  handleUpdateCustomFieldAnswers: t.func,
}

export default Detail

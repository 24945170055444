import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useMetrics() {
  const [loadingDashboard, setLoadingDashboard] = useState(false)
  const [loadingMissionsFeed, setLoadingMissionsFeed] = useState(false)
  const [dashboard, setDashboard] = useState(null)
  const [dashboardAccountsPrivate, setDashboardAccountsPrivate] = useState(null)
  const [missionsMain, setMissionsMain] = useState([])
  const [approvedByState, setApprovedByState] = useState([])
  const [launchByState, setLaunchByState] = useState([])
  const [registerDataPeriod, setRegisterDataPeriod] = useState([])

  const getMetricsDashboard = useCallback(async (params) => {
    try {
      const res = await api.get('customers/dashboard', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setDashboard(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingDashboard(false)
    }
  }, [])

  const getMetricsDashboardAccountsPrivate = useCallback(async (params) => {
    setLoadingDashboard(true)
    try {
      const res = await api.get('customers/dashboard-accounts-private', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setDashboardAccountsPrivate(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingDashboard(false)
    }
  }, [])

  async function getAverageMarketShareMissionsMain() {
    try {
      setLoadingDashboard(true)

      const res = await api.get('customers/missions-main', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissionsMain(res.data.missionsMain)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingDashboard(false)
    }
  }

  async function getAverageMarketShareApprovedByState() {
    try {
      setLoadingDashboard(true)

      const res = await api.get('customers/report-state', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setApprovedByState(res.data.approvedByState)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingDashboard(false)
    }
  }

  async function getAverageMarketShareLaunchByState() {
    try {
      setLoadingDashboard(true)

      const res = await api.get('customers/report-state-launchs', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setLaunchByState(res.data.launchByState)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingDashboard(false)
    }
  }

  async function getRegisterDataPeriod(params) {
    try {
      setLoadingMissionsFeed(true)

      const res = await api.get('customers/register-data-period', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setRegisterDataPeriod(res.data.registerDataPeriod)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMissionsFeed(false)
    }
  }

  return {
    loadingDashboard,
    dashboard,
    missionsMain,
    getMetricsDashboard,
    getAverageMarketShareMissionsMain,
    getAverageMarketShareApprovedByState,
    approvedByState,
    getAverageMarketShareLaunchByState,
    launchByState,
    getRegisterDataPeriod,
    registerDataPeriod,
    loadingMissionsFeed,
    dashboardAccountsPrivate,
    getMetricsDashboardAccountsPrivate,
  }
}

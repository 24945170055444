import React from 'react'

import t from 'prop-types'

import ImageSale from '~/assets/images/done.svg'

import { Badge, BadgeTitle } from './styles'

export function SaleBadge({ count }) {
  return (
    <Badge>
      <img src={ImageSale} alt="SaleImage" style={{ height: 25 }} />
      <BadgeTitle>{count}˚ Venda</BadgeTitle>
    </Badge>
  )
}

SaleBadge.defaultTypes = {
  count: 0,
}

SaleBadge.propTypes = {
  count: t.number.isRequired,
}

export default SaleBadge

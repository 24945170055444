import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Form, Button, Select, Space, message } from 'antd'
import t from 'prop-types'

import api from '~/services/api'

import ScheduleInfo from '../ScheduleInfo'

const styles = {
  title: {
    fontSize: '16px',
  },
}

export default function ScheduleForm({ customerId, submitSchedule }) {
  const navigate = useNavigate()
  const [schedulesList, setSchedulesList] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const handleChangeSchedule = (data) => {
    const scheduleData = JSON.parse(data)
    setSelectedSchedule(scheduleData)
  }

  useEffect(() => {
    async function loadSchedule() {
      try {
        const res = await api.get('schedule')
        return setSchedulesList(res.data)
      } catch (error) {
        return message.error(error.message)
      }
    }

    if (customerId) {
      loadSchedule()
    }
  }, []) //eslint-disable-line

  return (
    <Form onFinish={submitSchedule}>
      <Space direction="vertical" size="large">
        <strong style={styles.title}>Adicione um novo agendamento:</strong>
        <Form.Item name="schedule">
          <Select
            placeholder="Agendamento"
            onChange={handleChangeSchedule}
            value={selectedSchedule}
          >
            {schedulesList.map((i) => (
              <Select.Option key={i.uid} value={JSON.stringify(i)}>
                {i.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedSchedule && (
          <ScheduleInfo
            schedule={selectedSchedule}
            onEdit={() =>
              navigate({
                pathname: '/missions/schedules',
                search: `?editScheduleId=${selectedSchedule.uid}`,
              })
            }
          />
        )}

        <Button shape="round" htmlType="submit" type="primary">
          Agendar
        </Button>
      </Space>
    </Form>
  )
}

ScheduleForm.propTypes = {
  customerId: t.string.isRequired,
  submitSchedule: t.func.isRequired,
}

import React from 'react'

import { DownloadOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Popover } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import ExportForm from '../ExportForm'
import { Container, ContainerRow } from './styles'

const { RangePicker } = DatePicker

const Header = ({
  range,
  onChangeFilters,
  loadingFilter,
  onSubmitFilter,
  onExport,
  loadingExport,
  form,
}) => {
  const dateFormat = 'YYYY-MM-DD'

  return (
    <Card style={{ marginBlock: 10 }}>
      <Container>
        <ContainerRow>
          <RangePicker
            defaultValue={[
              dayjs(range.initial, dateFormat),
              dayjs(range.final, dateFormat),
            ]}
            onChange={onChangeFilters}
            format={dateFormat}
          />
          <Button
            shape="round"
            type="primary"
            loading={loadingFilter}
            disabled={loadingFilter}
            onClick={onSubmitFilter}
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>
          <Popover
            title="Filtros"
            trigger="click"
            placement="leftTop"
            content={
              <ExportForm
                form={form}
                submitFilter={onExport}
                loadingButton={loadingExport}
              />
            }
          >
            <Button shape="round" icon={<DownloadOutlined />}>
              Exportar
            </Button>
          </Popover>
        </ContainerRow>
      </Container>
    </Card>
  )
}

Header.defaultTypes = {
  handleSearch: t.func.isRequired,
}

Header.propTypes = {
  loadingFilter: t.bool,
  range: t.objectOf(t.any),
  onSubmitFilter: t.func,
  onChangeFilters: t.func,
  onExport: t.func,
  loadingExport: t.bool,
  form: t.objectOf(t.any),
}

export default Header

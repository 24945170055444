import { List } from 'antd'
import styled from 'styled-components'

export const Container = styled(List)`
  height: 100%;

  .ant-list-item-main {
    min-width: 230px;
    width: 100%;
  }
  .ant-list-item-extra {
    overflow: auto;
    img {
      margin: 5px;
    }
  }

  .ant-list-item-extra {
    margin-left: 25px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 12px;
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;

  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 800;
  }
`

export const ItemContainerImages = styled.div`
  display: block;
  min-height: 1px;
  width: 100%;
  overflow: auto;
`

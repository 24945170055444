import React, { useEffect } from 'react'

import { DeleteTwoTone } from '@ant-design/icons'
import { Divider, Drawer, Form, Input, Button, Table, Popover } from 'antd'
import t from 'prop-types'

import useFormsItemsSections from '~/hooks/useFormsItemsSections'

const SectionName = ({ open, close, formsId }) => {
  const [form] = Form.useForm()

  const {
    loadingSection: loading,
    create,
    getSectionsName,
    sectionsName: sections,
    removeSectionName,
  } = useFormsItemsSections()

  useEffect(() => {
    getSectionsName(formsId)
  }, []) // eslint-disable-line

  const columns = [
    {
      title: 'Seção',
      dataIndex: 'section',
      key: 'section',
    },
    {
      title: 'Nome',
      key: 'name_section',
      render: (item) =>
        item.name_section || (
          <span style={{ color: '#CFCFCF' }}>Não nomeada</span>
        ),
    },
    {
      title: 'Opções',
      key: 'options',
      render: (value) => (
        <Popover trigger="hover" content="Remover">
          <DeleteTwoTone
            twoToneColor="red"
            onClick={() => removeSectionName({ ...value, forms_id: formsId })}
          />
        </Popover>
      ),
    },
  ]

  function resetForm() {
    form.resetFields()
  }

  const handleOnFinish = (values) => {
    create({ ...values, forms_id: formsId })
    resetForm()
  }

  function handleCloseDrawer() {
    close()
    resetForm()
  }

  return (
    <Drawer
      open={open}
      onClose={() => handleCloseDrawer()}
      closable
      title="Nomear Seção"
      placement="right"
      key="right"
    >
      <Form layout="horizontal" form={form} onFinish={handleOnFinish}>
        <Form.Item name="section" label="Seção">
          <Input type="number" placeholder="Digite a seção" />
        </Form.Item>
        <Form.Item name="name" label="Nome">
          <Input type="text" placeholder="Digite o nome da seção" />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Table columns={columns} dataSource={sections} pagination={false} />
    </Drawer>
  )
}

SectionName.propTypes = {
  open: t.bool,
  close: t.func,
  formsId: t.string,
}

export default SectionName

import React, { useEffect } from 'react'

import { List, Skeleton, Button } from 'antd'
import t from 'prop-types'

import useCategories from '~/hooks/useCategories'

export function Categories({ onClick }) {
  const { categories, loadingCategories, getCategories } = useCategories()

  useEffect(() => {
    getCategories()
  }, []) //eslint-disable-line

  return (
    <List itemLayout="horizontal" size="small">
      {!loadingCategories &&
        categories.map((i) => (
          <List.Item
            key={i.title}
            onClick={() => onClick(i)}
            style={{ cursor: 'pointer' }}
          >
            <Skeleton loading={loadingCategories} active avatar>
              <List.Item.Meta
                description={i.category}
                title={`${i.activity_id} - ${i.title}`}
              />
              <Button type="primary">{i.label_value}</Button>
            </Skeleton>
          </List.Item>
        ))}
    </List>
  )
}

Categories.defaultTypes = {
  loading: false,
}

Categories.propTypes = {
  onClick: t.func.isRequired,
}

import styled from 'styled-components'

export const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    > button + button {
      margin-left: 16px;
    }
  }
`

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import { CheckCircleTwoTone, PushpinTwoTone } from '@ant-design/icons'
import { Space } from 'antd'
import t from 'prop-types'

import { MAPBOX_TOKEN } from '~/config/env'
import colors from '~/styles/colors'

const iconStyle = {
  fontSize: 30,
}

export function Maps({ location, viewport, onChange }) {
  return (
    <>
      {location.lat && location.lng && (
        <Space direction="horizontal">
          {location.place || null}
          <CheckCircleTwoTone
            style={iconStyle}
            twoToneColor={colors.greenShock}
          />
        </Space>
      )}
      <AutoSizer>
        {({ width }) => (
          <ReactMapGL
            {...viewport}
            onViewportChange={onChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
            width={width}
            height={400}
          >
            <Marker
              offsetTop={-10}
              offsetLeft={-20}
              latitude={location.lat || viewport.latitude}
              longitude={location.lng || viewport.longitude}
            >
              <PushpinTwoTone
                twoToneColor={colors.redStrong}
                style={iconStyle}
              />
            </Marker>
          </ReactMapGL>
        )}
      </AutoSizer>
    </>
  )
}

Maps.defaultTypes = {}

Maps.propTypes = {
  location: t.objectOf(t.any).isRequired,
  viewport: t.objectOf(t.any).isRequired,
  onChange: t.func.isRequired,
}

/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Form } from 'antd'
import JwtDecode from 'jwt-decode'
import queryString from 'query-string'

import useCustomersUsersTags from '~/hooks/useCustomersUsersTags'
import useSignUpUser from '~/hooks/useSignUpUser'

import ConfirmCode from './ConfirmCode'
import InvalidToken from './InvalidToken'
import Phone from './Phone'
import Success from './Success'
import UserDataForm from './UserData'

export default function SignUpUser() {
  const [form] = Form.useForm()
  const location = useLocation()
  const {
    userStep,
    onSignUp,
    userData,
    loadingSign,
    onConfirmCode,
    onSubmitMessage,
  } = useSignUpUser()
  const { customersUsersTags, getCustomersUsersTagsToken } =
    useCustomersUsersTags()

  const [isValid, setIsValid] = useState(false)
  const [values] = useState(queryString.parse(location.search))

  function handleSubmit(data) {
    const decode = JwtDecode(values.token)

    return onSignUp({ ...data, customer_id: decode.client_id })
  }

  function handlePhone(value) {
    return onSubmitMessage({
      value: `+55${value.phone}`,
      userId: userData?.user?.uid,
    })
  }

  async function handleConfirmCode(value) {
    return onConfirmCode({ value: value.code, userId: userData?.user?.uid })
  }

  useEffect(() => {
    const currentTime = new Date().getTime() / 1000
    const { exp } = JwtDecode(values.token)

    setIsValid(currentTime < exp)
  }, []) //eslint-disable-line

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  useEffect(() => {
    if (values.token) {
      getCustomersUsersTagsToken(values.token)
    }
  }, [values.token]) //eslint-disable-line

  if (!isValid) {
    return <InvalidToken />
  }

  return (
    <div className="content-login">
      {isValid && userStep === 0 && (
        <UserDataForm
          form={form}
          loading={loadingSign}
          tags={customersUsersTags}
          handleSubmit={handleSubmit}
        />
      )}

      {isValid && userStep === 1 && (
        <Phone form={form} loading={loadingSign} handlePhone={handlePhone} />
      )}

      {isValid && userStep === 2 && (
        <ConfirmCode
          form={form}
          loading={loadingSign}
          handleConfirmCode={handleConfirmCode}
        />
      )}

      {isValid && userStep === 3 && <Success />}
    </div>
  )
}

import { responseData } from '~/utils/reducer'

const CUSTOM_STATUS_FETCH = 'CUSTOM_STATUS_FETCH'
const CUSTOM_STATUS_UPDATE = 'CUSTOM_STATUS_UPDATE'

const customStatusFetch = responseData(CUSTOM_STATUS_FETCH, 'array')
const customStatusUpdated = responseData(CUSTOM_STATUS_UPDATE, 'object')

export default {
  customStatusFetch,
  customStatusUpdated,
}

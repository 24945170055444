import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Modal, Empty } from 'antd'

import images from '~/assets/images'
import ListCards from '~/components/organisms/ListCards'
import useForms from '~/hooks/useForms'

import Detail from './components/Detail'
import Header from './components/Header'

const { confirm } = Modal

const IS_UPDATE = 'update'

export default function Forms() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const {
    getAll,
    forms,
    onCopyForm,
    countForms,
    onCreateForm,
    onEditForm,
    onRemoveForm,
    loadingForms,
  } = useForms()
  const [item, setItem] = useState({})
  const [type, setType] = useState('create')
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '' })

  function handleSearch({ target }) {
    setFilters({ ...filters, search: target.value })

    if (target.value.length > 2) {
      setTimeout(() => {
        getAll({ ...filters, search: target.value })
      }, 1000)
    }
  }

  function handlerPagination() {
    const limit = filters.limit + 10
    return getAll({ ...filters, limit })
  }

  function duplicateItemById(formItem) {
    return confirm({
      title: 'Alerta',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja duplicar o formulário?',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        const currentForm = forms.filter((i) => i === formItem)
        const isEmpty = currentForm.length === 0
        if (isEmpty) {
          return
        }

        const formId = currentForm[0].uid

        onCopyForm({ formId })

        getAll({ ...filters })
      },
    })
  }

  function removeItemById(formItem) {
    return confirm({
      title: 'Alerta',
      icon: <ExclamationCircleOutlined />,
      content: 'Você tem certeza que deseja remover essa conta?',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        return onRemoveForm({ formsId: formItem.uid })
      },
    })
  }

  function handleSubmit(values, typeForm) {
    if (typeForm === IS_UPDATE) {
      return onEditForm({ ...values, uid: item.formDetail.uid })
    }

    return onCreateForm(values)
  }

  function handleDetail(formDetail) {
    form.setFieldsValue(formDetail)
    setType(IS_UPDATE)
    setItem((prevState) => ({ ...prevState, formDetail }))
    return setOpen(true)
  }

  function handleCleanForm() {
    form.resetFields(['name', 'link'])
    setType('create')
    setItem({})
    return setOpen(true)
  }

  const onRefresh = useCallback(() => {
    return getAll({ ...filters })
  }, []) //eslint-disable-line

  useEffect(() => {
    onRefresh()
  }, []) //eslint-disable-line

  return (
    <>
      <Header onSearch={handleSearch} handleCleanForm={handleCleanForm} />

      {!loadingForms && forms.length === 0 && (
        <Empty
          image={images.Form}
          description="Nenhum formulário foi adicionado..."
        />
      )}

      {!loadingForms && forms.length > 0 && (
        <ListCards
          hasCopy
          hasEdit
          hasDetail
          hasRemove
          data={forms}
          count={countForms}
          loading={loadingForms}
          onClickEdit={handleDetail}
          onClickDuplicate={duplicateItemById}
          onClickRemove={removeItemById}
          handlePagination={handlerPagination}
          onClickDetail={(formId) => navigate(`/forms/${formId}`)}
        />
      )}

      <Detail
        open={open}
        form={form}
        item={item}
        type={type}
        loading={loadingForms}
        handleSubmit={handleSubmit}
        onClose={() => setOpen((prev) => !prev)}
      />
    </>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
`

export const Logo = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 90px;
  margin-bottom: 2rem;
`

export const LogoImage = styled.img`
  width: 180px;
  margin-bottom: 2rem;
  border-radius: 10px;
`

export const Sidebar = styled.div`
  width: 43%;
  min-height: 100%;
  min-width: 350px;
  position: relative;
  display: flex;
  flex-shrink: 0;

  @media (max-width: 860px) {
    display: none;
  }
`

export const ContainerContent = styled.div`
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`

export const Title = styled.p`
  font-size: 18px;
  font-weight: 900;
  color: ${(props) => props.color};
`

export const Subtitle = styled.p`
  font-size: 16px;
  color: ${(props) => props.color};
`

export const BoxSlides = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Description = styled.div`
  align-items: center;
  text-align: left;
  display: flex;
  justify-content: space-between;
`
export const DescriptionContent = styled.div`
  line-height: 3;
  width: 50%;
  font-size: 1.3rem;
`

export const Highlight = styled.div`
  position: relative;
  padding: 10px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px auto;
  width: 80%;
  text-align: center;
`

export const Text = styled.span`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
`

export const CarouselContent = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  text-align: center;
`

export const Image = styled.img`
  width: 50%;
  margin: 20px auto;
`

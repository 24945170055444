import React from 'react'

import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Card, Col, Row, Skeleton, Avatar } from 'antd'
import t from 'prop-types'

import ImageAvatar from '~/assets/images/rocket.svg'

const { Meta } = Card

const cardWidth = {
  width: 250,
}

const LoadingCards = ({ loading }) => (
  <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
    {loading &&
      [1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
        <Col key={i}>
          <Card
            style={cardWidth}
            loading={loading}
            actions={[<EditOutlined />, <DeleteOutlined />]}
          >
            <Skeleton loading={loading} avatar active>
              <Meta
                avatar={<Avatar src={ImageAvatar} />}
                title="Card title"
                description={`${i}Card title`}
              />
            </Skeleton>
          </Card>
        </Col>
      ))}
  </Row>
)

LoadingCards.defaultTypes = {
  loading: false,
}

LoadingCards.propTypes = {
  loading: t.bool.isRequired,
}

export default LoadingCards

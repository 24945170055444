/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Form, message, Space, Row, Col, Drawer } from 'antd'
import { isEmpty } from 'lodash'

import { Filter } from '~/components/molecules/Filter'
import TableComponent from '~/components/organisms/Table'
import { useAuth } from '~/contexts/auth'
import useMissions from '~/hooks/useMissions'
import useSearchLocation from '~/hooks/useSearchLocation'
import useSubCategories from '~/hooks/useSubCategory'
import api from '~/services/api'
import { getPhoneWithoutCode } from '~/utils/helpers'

import { GroupMissionCreate } from './components/GroupMissionCreate'
import HeaderActions from './components/HeaderActions'
import ScheduleForm from './components/ScheduleForm'
import { UploadXls } from './components/UploadXls'
import Detail from './Detail'
import { columns } from './helpers'
import { styles } from './styles'

export default function Missions() {
  const { data } = useAuth()
  const [form] = Form.useForm()
  const [formFilter] = Form.useForm()

  const {
    missions,
    missionsCount,
    loadingMissions,
    getMissions,
    loadingUpdate,
    onUpdateMission,
    customFieldsAnswers,
    setCustomFieldsAnswers,
    handleUpdateCustomFieldAnswers,
    setMissions,
  } = useMissions()
  const { onGeocodeFormat } = useSearchLocation()
  const customerId = data?.clientId

  const [item, setItem] = useState(null)
  const [items, setItems] = useState([])

  const [visible, setVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  })
  const [isOpenModalSchedule, setIsOpenModalSchedule] = useState(false)

  const [isOpenModalMissionGrouping, setIsOpenModalMissionGrouping] =
    useState(false)

  const { customFields, getSubCategoryById } = useSubCategories()
  const [filtersSelected, setFiltersSelected] = useState({})

  const getQuery = () => {
    const newQuery = {
      offset: 0,
      limit: 20,
      order: 'created_at',
      direction: 'desc',
    }

    return getMissions(newQuery)
  }

  const resetFilters = () => {
    setFiltersSelected({})
    setSelectedRowKeys([])
    setPagination({
      current: 1,
      pageSize: 20,
    })

    setMissions([])
    return getQuery()
  }

  function parseFiltersToRightFormat(filters) {
    if (filters['ufs[]']) {
      filters.ufs = filters['ufs[]']
    }

    if (filters['cities[]']) {
      filters.cities = filters['cities[]']
    }

    delete filters['cities[]']
    delete filters['ufs[]']

    return filters
  }

  function handleTableChange(page, _filter, sorter) {
    const paramsField = {
      username: 'ca.username',
      title: 'mm.title',
      name: 'm.name',
    }
    const order = paramsField[sorter.field]
    const direction = sorter.order === 'ascend' ? 'asc' : 'desc'
    const offset = (page.current - 1) * page.pageSize
    const newQuery = {
      order,
      direction,
      offset,
      limit: page.pageSize,
      ...filtersSelected,
    }
    setPagination((prev) => ({ ...prev, current: page?.current }))
    return getMissions(newQuery)
  }

  function submitFilter(filters) {
    filters = parseFiltersToRightFormat(filters)
    setFiltersSelected(filters)
    return getMissions(filters)
  }

  async function submitSchedule(values) {
    const missionsUids = !isEmpty(item)
      ? [item.uid]
      : selectedRowKeys.map((i) => i.uid)

    const missionSchedule = {
      scheduleId: JSON.parse(values.schedule).uid,
      missionsUids,
    }

    try {
      await api.post('missions-schedule', missionSchedule)

      const newItems = items.map((it) => {
        if (missionsUids.includes(it.uid)) {
          const newItem = { ...it }
          newItem.has_schedule = true
          return newItem
        }
        return it
      })
      setItems(newItems)
      message.success('Agendamento realizado!')
    } catch (error) {
      message.error('Falha ao agendar missões...')
    }
  }

  async function onChangeMissionMainId(missionMainId) {
    setCustomFieldsAnswers([])
    await getSubCategoryById(missionMainId)
    form.setFieldValue('missions_main_id', missionMainId)
  }

  async function onClick(mission, index) {
    if (mission?.checkin_has_sms_confirm) {
      mission.checkin_phone = mission?.checkin_phone
        ? getPhoneWithoutCode(mission?.checkin_phone)
        : null
    }

    await getSubCategoryById(mission.missions_main_id)
    if (mission.custom_fields) {
      setCustomFieldsAnswers(mission.custom_fields.fields)
    }
    form.setFieldsValue(mission)
    setItem({ ...mission, index })
    return setVisible(true)
  }

  function onChange(key, value) {
    return setItem((prev) => ({ ...prev, [key]: value }))
  }

  async function handleUpdate(values) {
    await onUpdateMission({ ...item, ...values })

    return getQuery()
  }

  function openModalSchedule() {
    setIsOpenModalSchedule(true)
  }

  function openModalMissionGrouping() {
    setIsOpenModalMissionGrouping(true)
  }

  function archiveMissions() {
    return selectedRowKeys.forEach((i) => {
      if (i.status || Number(i.has_started) > 0) {
        return message.error(
          `A missão ${i.mission_key} está ativa ou já foi iniciada!`
        )
      }

      if (Number(i.participants) === Number(i.has_finished)) {
        return message.error(`A missão ${i.mission_key} já foi finalizada!`)
      }

      return onUpdateMission({ ...i, deleted: true })
    })
  }

  function start(activeType) {
    return selectedRowKeys.forEach((i) => {
      return onUpdateMission({ ...i, status: activeType })
    })
  }

  function onSelectChange(selectedItems) {
    return setSelectedRowKeys(selectedItems)
  }

  async function onChangeAddress(value) {
    const result = await onGeocodeFormat(value.address_components)

    return setItem((prev) => ({
      ...prev,
      ...result,
      uf: result.state,
      ...value.geometry.location,
      place: value.formatted_address,
    }))
  }

  useEffect(() => {
    getQuery()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingMissions) {
      setPagination((prev) => ({ ...prev, current: pagination.current }))
    }
  }, [loadingMissions, missions]) //eslint-disable-line

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <Filter
        hasOrder
        form={formFilter}
        onSubmit={submitFilter}
        onReset={resetFilters}
      >
        {data?.is_private ? <UploadXls /> : null}
      </Filter>

      {selectedRowKeys.length > 0 ? (
        <PageHeader style={styles.pageHeader}>
          <Row justify="space-between">
            <Space direction="vertical">
              <Col>
                <HeaderActions
                  start={start}
                  loading={loadingMissions}
                  archiveMissions={archiveMissions}
                  selectedRowKeys={selectedRowKeys}
                  openModalSchedule={openModalSchedule}
                  openModalMissionGrouping={openModalMissionGrouping}
                />
              </Col>
            </Space>
          </Row>
        </PageHeader>
      ) : null}

      <TableComponent
        size="small"
        data={missions}
        loading={loadingMissions}
        onClick={onClick}
        columns={columns()}
        total={Number(missionsCount)}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        current={pagination.current}
        pageSize={pagination.pageSize}
        onChangePageSize={handleTableChange}
      />

      {item !== null && (
        <Detail
          onChangeMissionMainId={onChangeMissionMainId}
          handleUpdateCustomFieldAnswers={handleUpdateCustomFieldAnswers}
          customFieldsAnswers={customFieldsAnswers}
          customFields={customFields}
          form={form}
          item={item}
          visible={visible}
          onChange={onChange}
          loading={loadingMissions}
          loadingUpdate={loadingUpdate}
          handleUpdate={handleUpdate}
          submitSchedule={submitSchedule}
          onChangeAddress={onChangeAddress}
          onClose={() => {
            setItem(null)
            form.resetFields(['name', 'identifier', 'missions_main_id'])
            setVisible(false)
          }}
        />
      )}

      <Drawer
        width={720}
        onClose={() => setIsOpenModalSchedule(false)}
        destroyOnClose="true"
        open={isOpenModalSchedule}
        title="Selecione um agendamento"
      >
        <ScheduleForm customerId={customerId} submitSchedule={submitSchedule} />
      </Drawer>

      <GroupMissionCreate
        show={isOpenModalMissionGrouping}
        missionsId={selectedRowKeys.map((i) => i.uid)}
        onCancel={() => setIsOpenModalMissionGrouping(false)}
      />
    </>
  )
}

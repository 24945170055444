import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  width: 100%;
`

export const ContainerLeft = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`

export const ContainerRight = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
`

import React from 'react'

import { Tag } from 'antd'
import { format } from 'date-fns'

const isToday = new Date()
const isNew = (date) => new Date(date) === isToday
const hasExpired = (date) => new Date(date) < isToday

function currentStatus(item) {
  if (!item.status) {
    return <Tag color="red">Inativo</Tag>
  }

  if (hasExpired(item.expired_at)) {
    return <Tag color="red">Expirado</Tag>
  }

  if (isNew(item.created_at)) {
    return <Tag color="blue">Novo</Tag>
  }

  return <Tag color="green">Ativo</Tag>
}

export default [
  {
    title: 'Status',
    render: (item) => currentStatus(item),
  },
  {
    title: 'Nome',
    dataIndex: 'title',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
  },
  {
    title: 'Preço (R$)',
    dataIndex: 'price',
  },
  {
    title: 'Parcelas (x)',
    dataIndex: 'installments',
  },
  {
    title: 'Desconto em Qtd.',
    dataIndex: 'quantity_discount',
  },
  {
    title: 'Desconto (%)',
    dataIndex: 'value_discount',
  },
  {
    title: 'Criado Em',
    dataIndex: 'created_at',
    render: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  {
    title: 'Expira Em',
    dataIndex: 'expired_at',
    render: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  // {
  //   title: 'Ações',
  //   render: (item) => (
  //     <Button type="danger" onClick={() => remove(item.uid)}>
  //       Remover
  //     </Button>
  //   ),
  // },
]

/* eslint-disable camelcase */
import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useMissionsGroups() {
  const [loadingGroups, setLoading] = useState(false)
  const [groups, setGroups] = useState([])

  const getMissionsGroups = useCallback(async () => {
    try {
      setLoading(true)
      const res = await api.get('customers-groups/customer')

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setGroups(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onCreateMissionsGroup(data) {
    try {
      setLoading(true)
      const res = await api.post('customers-groups', {
        description: data.groupName,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getMissionsGroups()

      return message.success('Grupo foi criado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onMergeGroups({ missionsId, customersGroupsId }) {
    try {
      setLoading(true)
      const res = await api.post(
        'missions-customers-groups/create',
        {
          missionsId,
          customersGroupsId,
        },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Grupo Vinculado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    onCreateMissionsGroup,
    onMergeGroups,
    getMissionsGroups,
    groups,
    loadingGroups,
  }
}

import React from 'react'

import { Button } from 'antd'
import t from 'prop-types'

import { Container, ContainerBox } from './styles'

const Header = ({ missionsCount, onClickAdd, onClickMap }) => (
  <Container>
    <ContainerBox>
      {missionsCount > 0
        ? `${missionsCount} Missões Encontradas`
        : 'Nenhuma missão encontrada'}
    </ContainerBox>
    <ContainerBox>
      <Button
        size="middle"
        type="primary"
        onClick={onClickAdd}
        style={{ marginRight: 10 }}
      >
        Adicionar Gráfico
      </Button>
      <Button size="middle" onClick={onClickMap}>
        Mapa de missões
      </Button>
    </ContainerBox>
  </Container>
)

Header.propTypes = {
  onClickAdd: t.func,
  onClickMap: t.func,
  missionsCount: t.number,
}

export default Header

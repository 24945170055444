import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'
import {
  PRODUCTS_IMPORT,
  PRODUCTS_ITEMS_CREATE,
  PRODUCTS_ITEMS_UPDATE,
  PRODUCTS_ITEMS_REMOVE,
  PRODUCTS_ITEMS,
  PRODUCTS_ITEMS_UPDATE_ALL,
} from '~/services/api/endpoints'

function useProductsItems(customerId) {
  const [loadingProductsItems, setLoadingProductsItems] = useState(false)
  const [productsItems, setProductsItems] = useState([])
  const [count, setCount] = useState(0)

  const getProductsItems = useCallback(
    async (query) => {
      try {
        setLoadingProductsItems(true)

        const res = await api.get(PRODUCTS_ITEMS(customerId, query), {
          validateStatus() {
            return true
          },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        setCount(res.data.count)
        setLoadingProductsItems(false)
        return setProductsItems(res.data.data)
      } catch (error) {
        setLoadingProductsItems(false)
        return message.error(error.message)
      }
    },
    [customerId]
  )

  async function createProductItem(data, query) {
    try {
      setLoadingProductsItems(true)

      const res = await api.post(PRODUCTS_ITEMS_CREATE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsItems(false)
      getProductsItems(query)
      return message.success('Produto criado com sucesso!')
    } catch (error) {
      setLoadingProductsItems(false)
      return message.error(error.message)
    }
  }

  async function updateProductItem(data, query) {
    try {
      setLoadingProductsItems(true)
      const res = await api.put(PRODUCTS_ITEMS_UPDATE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsItems(false)
      getProductsItems(query)
      return message.success('Produto atualizado com sucesso!')
    } catch (error) {
      setLoadingProductsItems(false)
      return message.error(error.message)
    }
  }

  async function removeProductItem(data, query) {
    try {
      setLoadingProductsItems(true)

      const res = await api.put(PRODUCTS_ITEMS_REMOVE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsItems(false)
      getProductsItems(query)
      return message.success('Produto removido com sucesso!')
    } catch (error) {
      setLoadingProductsItems(false)
      return message.error(error.message)
    }
  }

  async function importProductItem(data, query) {
    try {
      setLoadingProductsItems(true)

      const res = await api.post(PRODUCTS_IMPORT, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsItems(false)
      getProductsItems(query)

      return message.success('Produtos importados com sucesso!')
    } catch (error) {
      setLoadingProductsItems(false)
      return message.error(error.message)
    }
  }

  async function updateAllStatus(data, query) {
    try {
      setLoadingProductsItems(true)

      const res = await api.put(PRODUCTS_ITEMS_UPDATE_ALL, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsItems(false)
      getProductsItems(query)
      return message.success('Status alterado com sucesso!')
    } catch (error) {
      setLoadingProductsItems(false)
      return message.error(error.message)
    }
  }

  return {
    getProductsItems,
    loadingProductsItems,
    productsItems,
    createProductItem,
    updateProductItem,
    removeProductItem,
    importProductItem,
    updateAllStatus,
    count,
  }
}

export default useProductsItems

import Dash2 from './dash-2.svg'
import Form from './empty-form.svg'
import Schedule from './empty-schedule.svg'
import Products from './products.svg'
import Profile from './profile.svg'
import Rocket from './rocket.svg'

export default {
  Dash2,
  Form,
  Schedule,
  Rocket,
  Profile,
  Products,
}

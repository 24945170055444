import { responseData } from '~/utils/reducer'

const UFS = 'UFS'
const CITIES = 'CITIES'
const UFS_MIS = 'UFS_MIS'
const CITIES_MIS = 'CITIES_MIS'

const ufs = responseData(UFS, 'array')
const cities = responseData(CITIES, 'array')
const ufsMissions = responseData(UFS_MIS, 'array')
const citiesMissions = responseData(CITIES_MIS, 'array')

export default {
  ufs,
  cities,
  ufsMissions,
  citiesMissions,
}

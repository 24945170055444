/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useState } from 'react'

import {
  MoreOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Modal, Space, Spin, message } from 'antd'

import api from '~/services/api'

export function TableRowActions({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  async function onRemove() {
    try {
      setLoading(true)

      const res = await api.delete(`workforce-management-store/${id}`)

      if (res?.status !== 200) {
        throw new Error('Houve um erro no envio do arquivo')
      }

      message.success('Arquivo importado com sucesso!')
    } catch (error) {
      message.error(error.message || error)
    } finally {
      setLoading(false)
    }
  }

  const items = [
    {
      key: '1',
      icon: <DeleteOutlined />,
      onClick: () => setIsModalOpen(true),
      label: 'Remover',
    },
  ]

  return (
    <>
      <Space size="middle" style={{ textAlign: 'right' }}>
        <Dropdown menu={{ items }} placement="bottomLeft">
          <Button>
            <MoreOutlined />
          </Button>
        </Dropdown>
      </Space>
      <Modal
        title="Tem certeza que deseja remover?"
        open={isModalOpen}
        onOk={onRemove}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>Esta ação nao podera ser desfeita.</p>
        {isLoading && (
          <Spin
            style={{ marginTop: '10px' }}
            indicator={<LoadingOutlined spin />}
          />
        )}
      </Modal>
    </>
  )
}

/* eslint-disable no-use-before-define */
import React from 'react'

import { Card, Col } from 'antd'
import t from 'prop-types'

export function Root({ title, extra, children, size }) {
  return (
    <Col span={size === 'full' ? 24 : 12} style={styles.RootStyles}>
      <Card title={title} extra={extra}>
        {children}
      </Card>
    </Col>
  )
}

Root.propTypes = {
  title: t.string,
  extra: t.node,
  size: t.string,
  children: t.node,
}

const styles = {
  RootStyles: {
    height: 400,
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: 10,
    cursor: 'grab',
    backgroundColor: '#ffffff',
  },
}

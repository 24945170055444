export default [
  {
    value: 2,
    label: 'Penalidade',
  },
  {
    value: 3,
    label: 'Bonus',
  },
]

import React from 'react'

import { UserAddOutlined } from '@ant-design/icons'
import { Row, Input, Button } from 'antd'
import t from 'prop-types'

const { Search } = Input

const style = {
  container: {
    marginTop: 10,
    marginBottom: 20,
  },
  searchWidth: {
    width: 250,
  },
}

const Header = ({ handleSearch, openForm }) => (
  <Row justify="space-between" style={style.container}>
    <Search
      style={style.searchWidth}
      placeholder="Buscar contas..."
      onKeyDown={({ target }) => handleSearch(target.value)}
    />
    <Button
      key="0"
      type="primary"
      shape="round"
      onClick={openForm}
      icon={<UserAddOutlined />}
    >
      Adicionar conta
    </Button>
  </Row>
)

Header.defaultTypes = {
  handleSearch: t.func.isRequired,
  openForm: t.func.isRequired,
}

Header.propTypes = {
  handleSearch: t.func.isRequired,
  openForm: t.func.isRequired,
}

export default Header

import dayjs from 'dayjs'

import { dateParse } from '~/utils/helpers'

export function columns() {
  return [
    {
      title: 'Titulo',
      sorter: true,
      dataIndex: 'name',
    },
    {
      title: 'Identificador',
      sorter: true,
      ellipsis: true,
      dataIndex: 'identifier',
    },
    {
      title: 'Qts. Pessoas',
      sorter: true,
      ellipsis: true,
      dataIndex: 'participants',
    },
    {
      title: 'Inicio',
      sorter: true,
      ellipsis: true,
      dataIndex: 'activated_at',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Fim',
      sorter: true,
      ellipsis: true,
      dataIndex: 'expired_at',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      ellipsis: true,
      render: () => 'Pendente',
    },
    {
      title: 'Solicitante',
      sorter: true,
      dataIndex: 'username',
      ellipsis: true,
    },
    {
      title: 'Criado Em',
      sorter: true,
      dataIndex: 'created_at',
      render: (date) => dateParse(date),
    },
  ]
}

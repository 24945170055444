import styled from 'styled-components'

export const HeaderBox = styled.div`
  display: flex;
`

export const HeaderText = styled.div`
  margin-left: 20px;
`

export const Avatar = styled.img`
  border-radius: 100px;
  height: 90px;
  width: 90px;
`

export const SmallLogoMission = styled.img`
  position: absolute;
  left: 80%;
  top: 3%;
  height: 5%;
`

export const Mobile = styled.img`
  rotate: 5deg;
  height: 120px;
`

export const Text = styled.p`
  font-weight: ${(props) => (props.bold ? 'bold' : 'regular')};
  font-size: ${(props) => (props.largeText ? '2rem' : '0.9rem')};
`

export const AvatarBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`

export const FormBox = styled.div`
  text-align: center;
  margin: 20px 0px;
`

/* eslint-disable camelcase */
import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useMissions() {
  const [missions, setMissions] = useState([])
  const [missionsCount, setMissionsCount] = useState(0)
  const [loadingMissions, setLoadingMissions] = useState(true)
  const [loadingMissionCreate, setLoadingMissionCreate] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [activeMissions, setActiveMissions] = useState([])
  const [customFields, setCustomFields] = useState([])
  const [customFieldsAnswers, setCustomFieldsAnswers] = useState([])

  function handleUpdateCustomFieldAnswers(answers) {
    setCustomFieldsAnswers([...answers])
  }

  function onChangeInputValueCustomFields(index, value) {
    const registers = customFields
    registers[index].value = value
    setCustomFields([...registers])
  }

  async function onCreateMission(data) {
    setLoadingMissionCreate(true)
    try {
      data.custom_fields = data.custom_fields
        .filter((item) => {
          return item.value && item.value.length > 0
        })
        .map((item) => {
          return {
            id: item.id,
            value: item.value,
          }
        })

      const res = await api.post('missions/customers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Missão criada com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMissionCreate(false)
    }
  }

  async function onUpdateMission(data) {
    setLoadingUpdate(true)

    const {
      uid,
      lat,
      lng,
      name,
      place,
      status,
      deleted,
      created_at,
      updated_at,
      expired_at,
      checkin_phone,
      identifier,
      description,
      customer_id,
      mission_key,
      custom_distance,
      missions_main_id,
      custom_participants,
    } = data
    try {
      const dataModified = {
        uid,
        lat,
        lng,
        name,
        place,
        status,
        deleted,
        created_at,
        updated_at,
        expired_at,
        identifier,
        description,
        customer_id,
        mission_key,
        custom_distance,
        missions_main_id,
        checkin_phone,
        custom_participants,
      }

      if (customFieldsAnswers && customFieldsAnswers.length > 0) {
        const customFieldsAnswersToSave = customFieldsAnswers
          .filter((item) => item.value.length > 0)
          .map((item) => ({ id: item.id, value: item.value }))

        dataModified.custom_fields = customFieldsAnswersToSave
      }
      const res = await api.put('missions/customers', dataModified, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Missão atualizada com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingUpdate(false)
    }
  }

  async function getMissions(params) {
    try {
      setLoadingMissions(true)
      const res = await api.get('missions/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setMissionsCount(res.data.count)
      return setMissions(res.data.missions)
    } catch (error) {
      return message.error('Houve um problema no carregamento...')
    } finally {
      setLoadingMissions(false)
    }
  }

  async function getActiveMissions() {
    try {
      const res = await api.get('missions/active/customers')

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setActiveMissions(res.data)
    } catch (error) {
      return message.error(error.message)
    }
  }

  return {
    missions,
    getMissions,
    missionsCount,
    loadingMissions,
    onUpdateMission,
    onCreateMission,
    loadingMissionCreate,
    getActiveMissions,
    activeMissions,
    setMissions,
    loadingUpdate,
    customFields,
    setCustomFields,
    onChangeInputValueCustomFields,
    customFieldsAnswers,
    setCustomFieldsAnswers,
    handleUpdateCustomFieldAnswers,
  }
}

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'

import {
  AreaChartOutlined,
  ArrowsAltOutlined,
  EditFilled,
} from '@ant-design/icons'
import { Button, Drawer, Form, Input, List, Select, message } from 'antd'
import t from 'prop-types'
import { v4 as uuid } from 'uuid'

import useForms from '~/hooks/useForms'
import useFormsItems from '~/hooks/useFormsItems'
import api from '~/services/api'

import { chartsTypes, typeValues } from './constants/types-of-charts'

const LIMIT_CHARTS = 5

export function CreateChart({ onRefresh, open, onClose }) {
  const [form] = Form.useForm()
  const [charts, setCharts] = useState([])
  const [loading, setLoading] = useState(false)
  const [chartType, setChartType] = useState(null)

  const { getAll, loadingForms, forms } = useForms()
  const { getFormsItems, formsItems, loadingFormsItems } = useFormsItems()

  async function onIncrementChart(values) {
    if (charts.length >= LIMIT_CHARTS) {
      return message.warning('Por favor, crie no máximo 5 gráficos de uma vez')
    }

    setCharts((prev) => [
      ...prev,
      {
        uid: uuid(),
        order: charts.length + 1,
        data: {
          ...values,
          type: typeValues[values?.type]?.type,
          entity: typeValues[values?.type]?.entity,
        },
      },
    ])

    setChartType(null)
    return form.resetFields()
  }

  async function onSubmit() {
    setLoading(true)

    const res = await api.post(
      'analytics-data',
      { charts },
      {
        validateStatus() {
          return true
        },
      }
    )

    if (res.status !== 201) {
      setLoading(false)
      return message.warning('Houve um erro ao encaminhar')
    }

    form.resetFields()
    message.success('Gráfico criado com sucesso!')
    setLoading(false)
    onRefresh()
    return onClose()
  }

  function onChangeForm(id) {
    getFormsItems({ formId: id, offset: 0, limit: 1000 })
  }

  function onChangeType(value) {
    setChartType(value)
  }

  useEffect(() => {
    getAll()
  }, []) //eslint-disable-line

  const hasForm = ![
    'missions_approved',
    'missions_approved_by_group',
    null,
  ].includes(chartType)

  return (
    <Drawer
      open={open}
      width={600}
      destroyOnClose
      onClose={onClose}
      footer={
        charts.length > 0 ? (
          <Button type="primary" onClick={onSubmit} loading={loading}>
            Enviar Gráficos
          </Button>
        ) : null
      }
    >
      <Form layout="vertical" onFinish={onIncrementChart} form={form}>
        <Form.Item
          label="Tipo de Gráfico"
          name="type"
          rules={[{ required: true, message: 'Escolha um Gráfico' }]}
        >
          <Select
            options={chartsTypes}
            suffixIcon={<AreaChartOutlined />}
            onChange={(val) => onChangeType(val)}
          />
        </Form.Item>
        {hasForm ? (
          <Form.Item label="Escolher Formulário">
            <Select
              loading={loadingForms}
              suffixIcon={<EditFilled />}
              onChange={(val) => onChangeForm(val)}
              options={forms?.map((i) => ({ label: i.name, value: i.uid }))}
            />
          </Form.Item>
        ) : null}
        {hasForm && formsItems.length > 0 ? (
          <Form.Item
            label="Adicione Perguntas"
            name="items"
            rules={[
              {
                required: hasForm,
                message: 'Adicione pelo menos uma pergunta',
              },
            ]}
          >
            <Select
              mode="multiple"
              loading={loadingFormsItems}
              placeholder="Add Perguntas"
              options={formsItems.map((item) => ({
                value: item.uid,
                label: `${item.order} - ${item.question}`,
              }))}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          label="Título"
          name="title"
          rules={[{ required: true, message: 'Adicione um Título' }]}
        >
          <Input placeholder="Título" />
        </Form.Item>
        <Form.Item
          label="Tamanho"
          name="size"
          rules={[{ required: true, message: 'Adicione o tamanho do Card' }]}
        >
          <Select
            title="Tipo"
            suffixIcon={<ArrowsAltOutlined />}
            options={[
              { value: 'full', label: '100%' },
              { value: 'half', label: '50%' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <List>
        {charts?.map((i) => (
          <List.Item key={i.uid}>
            <List.Item.Meta title={i.data?.title} />
            <div>
              <Button
                type="dashed"
                onClick={() => setCharts(charts.filter((c) => c !== i))}
              >
                Remover
              </Button>
            </div>
          </List.Item>
        ))}
      </List>
    </Drawer>
  )
}

CreateChart.propTypes = {
  onClose: t.func,
  onRefresh: t.func,
  open: t.bool,
}

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCustomStatus() {
  const [loadingCustomStatus, setLoading] = useState(false)
  const [customStatus, setCustomStatus] = useState([])

  const getCustomStatus = useCallback(async ({ id, status }) => {
    try {
      setLoading(true)
      const res = await api.get('custom-status/registers', {
        params: {
          missionsMainId: id,
          registerValue: status,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCustomStatus(res.data.customStatus)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onUpdateCustomStatus(data) {
    try {
      setLoading(true)
      const res = await api.put('custom-status/registers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Registro atualizado')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    customStatus,
    getCustomStatus,
    loadingCustomStatus,

    onUpdateCustomStatus,
  }
}

/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { message, Form } from 'antd'
import moment from 'moment'

import Table from '~/components/organisms/Table'
import useProductsItems from '~/hooks/useProductsItems'

import Header from './components/Header'
import ProductForm from './components/ProductForm'
import columns from './helpers/columns'

const UPDATE = 'UPDATE'
const CREATE = 'CREATE'

export default function ProductsDetail() {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { id } = useParams()
  const {
    count,
    getProductsItems,
    loadingProductsItems,
    productsItems,
    createProductItem,
    updateProductItem,
    removeProductItem,
    importProductItem,
    updateAllStatus,
  } = useProductsItems(id)

  const [product, setProduct] = useState({})
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
    page: 1,
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [open, setOpen] = useState({ visible: false, type: CREATE })

  const initialQuery = `limit=${pagination.limit}&offset=${pagination.offset}`

  function handlePagination(page) {
    const offset = (page - 1) * pagination.limit

    setPagination((prevState) => ({
      ...prevState,
      page,
      offset,
    }))

    return getProductsItems(`limit=${pagination.limit}&offset=${offset}`)
  }

  function onSelectChange(selectedItems) {
    return setSelectedRowKeys(selectedItems)
  }

  function removeAll() {
    const productsAll = selectedRowKeys.map((i) => i.uid)
    setSelectedRowKeys([])
    return removeProductItem(productsAll, initialQuery)
  }

  async function updateStatusAll(status) {
    const productsAll = selectedRowKeys.map((i) => i.uid)
    setSelectedRowKeys([])
    return updateAllStatus(
      {
        uids: productsAll,
        status,
      },
      initialQuery
    )
  }

  function handleSubmit(values) {
    const productData = { ...product, ...values }

    if (!productData.title) {
      return message.error('Título não pode estar vazio!')
    }

    if (!productData.description) {
      return message.error('Descrição não pode estar vazio!')
    }

    if (!productData.price) {
      return message.error('Preço não pode estar vazio!')
    }

    if (!productData.installments) {
      return message.error('Número de parcelamento deve ser inserido!')
    }

    if (open.type === UPDATE) {
      setOpen((oldValues) => ({ ...oldValues, visible: false }))
      return updateProductItem(
        {
          ...productData,
          id: product.product_id,
          product_id: product.uid,
        },
        initialQuery
      )
    }

    setOpen((oldValues) => ({ ...oldValues, visible: false }))
    return createProductItem(
      {
        ...productData,
        product_id: id,
      },
      initialQuery
    )
  }

  async function onChange(info) {
    if (info.file.status !== 'uploading') {
      const data = new FormData()
      data.append('file', info.file.originFileObj)
      data.append('info', JSON.stringify({ product_id: id }))
      importProductItem(data, initialQuery)
    }
  }

  function onChangeConditional(key, value) {
    return setProduct((oldValues) => ({ ...oldValues, [key]: value }))
  }

  function handleDetail(value) {
    value.expired_at = moment(value.expired_at)
    form.setFieldsValue(value)
    setProduct((oldValues) => ({ ...oldValues, ...value }))
    return setOpen((oldValues) => ({
      ...oldValues,
      visible: true,
      type: UPDATE,
    }))
  }

  function handleCleanForm() {
    form.resetFields()
    setProduct({})
    setOpen((oldValues) => ({ ...oldValues, visible: true, type: CREATE }))
  }

  useEffect(() => {
    getProductsItems(initialQuery)
  }, []) //eslint-disable-line

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const uploadProps = {
    onChange,
    showUploadList: false,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => onSuccess(`${file} ok`), 0),
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  }

  return (
    <>
      <Header
        removeAll={removeAll}
        loading={loadingProductsItems}
        uploadProps={uploadProps}
        goBack={() => navigate(-1)}
        selectedRowKeys={selectedRowKeys}
        updateStatusAll={updateStatusAll}
        handleCleanForm={handleCleanForm}
      />

      <Table
        data={productsItems}
        count={count}
        loading={loadingProductsItems}
        columns={columns}
        onClick={handleDetail}
        total={count}
        rowSelection={rowSelection}
        current={pagination.page}
        pageSize={pagination.limit}
        handlePagination={handlePagination}
      />

      <ProductForm
        open={open}
        form={form}
        item={product}
        loading={loadingProductsItems}
        handleSubmit={handleSubmit}
        onChange={onChangeConditional}
        onUploadFile={(i) =>
          setProduct((oldValues) => ({ ...oldValues, image_path: i.result }))
        }
        close={() => {
          setProduct({})
          form.resetFields([
            'title',
            'description',
            'has_spotlight',
            'description_spotlight',
          ])
          setOpen((oldValues) => ({ ...oldValues, visible: false }))
        }}
      />
    </>
  )
}

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */

import React, { useRef, useState } from 'react'

import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, Spin, message } from 'antd'

import api from '~/services/api'

export function ImportStore({ onConfirm }) {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const inputRef = useRef(null)

  async function handleChangeFile(e) {
    const file = e.target.files?.[0]
    const formData = new FormData()
    formData.append('file', file)
    setData(formData)
    try {
      setLoading(true)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit() {
    try {
      setLoading(true)

      const res = await api.post(`workforce-management-store/import`, data)

      if (res.status !== 201) {
        throw new Error('Houve um erro no envio do arquivo')
      }
      onConfirm()
      message.success('Arquivo importado com sucesso!')
      setData(null)

      if (inputRef?.current?.value) {
        inputRef.current.value = null
      }
    } catch (error) {
      message.error(error.message || error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <p>Faça upload do arquivo de importação antes de continuar.</p>
      <div style={{ border: 'solid 1px #ddd', borderRadius: 10, padding: 20 }}>
        <input
          hidden
          type="file"
          ref={inputRef}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleChangeFile}
        />

        <span>Arquivo .XLSX</span>
        <div>
          <Button
            variant="outline"
            style={{ marginRight: 10 }}
            disabled={isLoading}
            onClick={() => inputRef?.current?.click()}
          >
            {data ? (
              <>
                <CheckOutlined size={16} style={{ marginRight: 5 }} />
                <span>Arquivo selecionado</span>
              </>
            ) : (
              <>
                <UploadOutlined size={20} style={{ marginRight: 5 }} />
                <span>Selecione um arquivo</span>
              </>
            )}
          </Button>
          {data ? (
            <Button
              size="icon"
              variant={null}
              disabled={isLoading}
              onClick={() => {
                setData(null)
                if (inputRef?.current?.value) {
                  inputRef.current.value = null
                }
              }}
            >
              <CloseOutlined size={20} />
            </Button>
          ) : null}
        </div>
      </div>
      <div style={{ display: 'flex', paddingTop: 20, justifyContent: 'end' }}>
        <Button
          onClick={onConfirm}
          variant="outline"
          style={{ marginRight: 10 }}
        >
          Fechar
        </Button>
        <Button disabled={!data || isLoading} type="primary" onClick={onSubmit}>
          {isLoading ? (
            <>
              <Spin
                style={{ marginRight: 5 }}
                indicator={<LoadingOutlined size={16} spin />}
              />
              <span>Carregando</span>
            </>
          ) : (
            <span>Importar</span>
          )}
        </Button>
      </div>
    </div>
  )
}

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'

import { Modal, Button, Space, Empty, Divider } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import useApiLogs from '~/hooks/useApiLogs'

import 'react-json-pretty/themes/monikai.css'

export function ApiLogsModal({ registerId, open, onClose }) {
  const [limit, setLimit] = useState(10)
  const { logs, loadingLogs, getLogsById, onResendLogs } = useApiLogs()

  function onLoadMore() {
    setLimit((prev) => prev + 20)
    getLogsById({ refferalId: registerId, limit: limit + 20 })
  }

  useEffect(() => {
    getLogsById({ refferalId: registerId, limit: 20 })
  }, []) //eslint-disable-line

  return (
    <Modal
      open={open}
      title="Integração de Logs"
      onCancel={onClose}
      width={1000}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
      ]}
    >
      <Space style={{ width: '100%', marginTop: 20 }} direction="vertical">
        {logs.length === 0 ? (
          <Empty description="Nenhum log foi registrado até o momento" />
        ) : (
          logs.map((log) => (
            <div>
              <JSONPretty
                id="json-pretty"
                data={{
                  method: log?.type?.toUpperCase(),
                  url: log?.url,
                  data: dayjs(log?.timestamp).format('DD/MM/YYYY HH:MM'),
                  responseBody: log?.responseBody,
                }}
                mainStyle="padding:1em; border-radius: 8px"
              />
              <Button
                shape="round"
                disabled={loadingLogs}
                loading={loadingLogs}
                onClick={() => onResendLogs({ logId: log?._id })}
              >
                Reeinviar
              </Button>
              <Divider />
            </div>
          ))
        )}
        {logs.length > 0 ? (
          <Button
            disabled={loadingLogs}
            loading={loadingLogs}
            size="small"
            shape="round"
            onClick={() => onLoadMore()}
          >
            Carregar mais...
          </Button>
        ) : null}
      </Space>
      {loadingLogs ? <Loading /> : null}
    </Modal>
  )
}

ApiLogsModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  registerId: t.string,
}

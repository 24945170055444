import React from 'react'

import { FunnelPlotOutlined } from '@ant-design/icons'
import { Form, Button, Popover, DatePicker } from 'antd'
import t from 'prop-types'

const HeaderProductSales = ({
  form,
  onVisible,
  submitFilter,
  visible,
  onReset,
}) => (
  <Popover
    key="1"
    title="Filtros"
    trigger="click"
    placement="rightTop"
    visible={visible}
    content={
      <Form form={form} onFinish={submitFilter}>
        <Form.Item name="initial">
          <DatePicker placeholder="Data Inicial" />
        </Form.Item>
        <Form.Item name="final">
          <DatePicker placeholder="Data Final" />
        </Form.Item>
        <Form.Item>
          <Button shape="round" htmlType="submit" type="primary">
            Filtrar
          </Button>{' '}
          <Button shape="round" type="default" onClick={onReset}>
            Resetar
          </Button>
        </Form.Item>
      </Form>
    }
    onVisibleChange={onVisible}
  >
    <Button shape="round" icon={<FunnelPlotOutlined />}>
      Filtrar
    </Button>
  </Popover>
)

HeaderProductSales.defaultTypes = {
  visible: false,
}

HeaderProductSales.propTypes = {
  form: t.objectOf(t.any).isRequired,
  onVisible: t.func.isRequired,
  submitFilter: t.func.isRequired,
  visible: t.bool.isRequired,
  onReset: t.func.isRequired,
}

export default HeaderProductSales

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, Space, message } from 'antd'
import t from 'prop-types'

import api from '~/services/api'

import ScheduleInfo from '../ScheduleInfo'

const styles = {
  title: {
    fontSize: 16,
  },
}

export default function MissionSchedulesList({ missionId }) {
  const [missionSchedules, setMissionSchedules] = useState([])
  const [openSchedule, setOpenSchedule] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    async function loadMissionsSchedule() {
      try {
        const res = await api.get('missions-schedule', {
          params: {
            mission_id: missionId,
          },
        })

        return (
          res.data.length !== missionSchedules.length &&
          setMissionSchedules(res.data)
        )
      } catch (error) {
        return message.error(error.message)
      }
    }
    if (missionId) {
      loadMissionsSchedule()
    }
  }, []) // eslint-disable-line

  const handleCollapseSchedule = async (openIdx) => {
    try {
      setOpenSchedule(null)
      const scheduleId = missionSchedules[openIdx]?.schedule_id
      const res = await api.get('missions-schedule', {
        params: {
          schedule_id: scheduleId,
        },
      })

      return setOpenSchedule(res.data[0])
    } catch (error) {
      return message.error(error.message)
    }
  }

  const handleCancelSchedule = async (missionScheduleId) => {
    try {
      await api.delete('missions-schedule', {
        params: {
          missionScheduleId,
        },
      })
      const newMissionSchedules = missionSchedules.filter(
        (ms) => ms.uid !== missionScheduleId
      )
      return setMissionSchedules(newMissionSchedules)
    } catch (error) {
      return message.error(error.response.data.message)
    }
  }

  return (
    <>
      {missionSchedules?.length > 0 && (
        <Space size="large" direction="vertical">
          <strong style={styles.title}>Agendamentos desta missão:</strong>

          <Collapse
            accordion
            onChange={handleCollapseSchedule}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {missionSchedules.map((missionSchedule, idx) => (
              <Collapse.Panel
                header={missionSchedule.schedule_title}
                key={String(idx)}
              >
                <ScheduleInfo
                  schedule={openSchedule}
                  onEdit={() =>
                    navigate({
                      pathname: '/missions/schedules',
                      search: `?editScheduleId=${missionSchedule.schedule_id}`,
                    })
                  }
                  onCancel={() => handleCancelSchedule(missionSchedule.uid)}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </Space>
      )}
    </>
  )
}

MissionSchedulesList.propTypes = {
  missionId: t.string.isRequired,
}

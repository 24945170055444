import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  count: null,
  message: '',
  progress: 0,
  error: false,
  success: false,
  isFetching: false,
}

const typeData = {
  array: assoc('data', [], initialState),
  object: assoc('data', {}, initialState),
  boolean: assoc('data', false, initialState),
}

const responseData =
  (typeEvent, has) =>
  (state = typeData[has], action) => {
    const { data, message, type, progress, count } = action
    const fn = cond([
      [equals(typeEvent), always({ ...state, isFetching: true, progress })],
      [
        equals(`${typeEvent}_OK`),
        always({
          data,
          count,
          error: false,
          success: true,
          progress: 100,
          isFetching: false,
        }),
      ],
      [
        equals(`${typeEvent}_FAIL`),
        always({ isFetching: false, error: true, message, success: false }),
      ],
    ])
    return fn(type) || state
  }

export { responseData }

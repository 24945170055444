import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useExtraCredit() {
  const [loadingCredit, setLoadingCredit] = useState(false)

  async function onSubmitCreditPromocional(userData) {
    setLoadingCredit(true)
    try {
      const res = await api.post(
        'credit-promotional-bonus-transactions',
        userData,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Bônus Promocional adicionado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCredit(false)
    }
  }

  async function onSubmitValuePerPoints(userData) {
    setLoadingCredit(true)
    try {
      const res = await api.post('convert-points-to-money', userData, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Conversão realizada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCredit(false)
    }
  }

  async function onSubmitValuePerPointsAll(data) {
    setLoadingCredit(true)
    try {
      const res = await api.post('convert-points-to-money/all', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Conversão realizada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCredit(false)
    }
  }

  return {
    loadingCredit,
    onSubmitValuePerPoints,
    onSubmitValuePerPointsAll,
    onSubmitCreditPromocional,
  }
}

import React, { useEffect, useState } from 'react'

import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, Modal, Row, Select } from 'antd'
import t from 'prop-types'

import useMissionsGroups from '~/hooks/useMissionsGroups'

export function GroupMissionCreate({ show, onCancel, missionsId }) {
  const [form] = Form.useForm()
  const {
    groups,
    loadingGroups,
    onCreateMissionsGroup,
    getMissionsGroups,
    onMergeGroups,
  } = useMissionsGroups()
  const [customersGroupsId, setSelectedGroup] = useState({})
  const [isFormAddNewGroupVisible, setIsFormAddNewGroupVisible] =
    useState(false)

  useEffect(() => {
    getMissionsGroups()
  }, []) //eslint-disable-line

  return (
    <Modal
      style={{ maxWidth: 300 }}
      title="Selecione um grupo"
      open={show}
      onOk={() => onMergeGroups({ customersGroupsId, missionsId })}
      onCancel={onCancel}
    >
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder="Grupos"
        onChange={(value) => setSelectedGroup(value)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {groups?.map((group) => (
          <Select.Option key={String(group.uid)} value={group.uid}>
            {group.description}
          </Select.Option>
        ))}
      </Select>

      <Divider />

      <Row justify="start">
        <Button
          icon={<PlusOutlined />}
          type="link"
          loading={loadingGroups}
          style={{ padding: 0 }}
          onClick={() => setIsFormAddNewGroupVisible(!isFormAddNewGroupVisible)}
        >
          Criar Grupo
        </Button>
      </Row>

      <Form
        hidden={!isFormAddNewGroupVisible}
        form={form}
        onFinish={onCreateMissionsGroup}
      >
        <Form.Item name="groupName" style={{ marginBottom: 8 }}>
          <Input placeholder="Nome do Grupo" />
        </Form.Item>

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loadingGroups}
            icon={<CheckOutlined />}
            shape="round"
            type="primary"
          >
            Criar
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

GroupMissionCreate.propTypes = {
  show: t.bool,
  onCancel: t.func,
  missionsId: t.arrayOf(t.string),
}

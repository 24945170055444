import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCategories() {
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [categories, setCategories] = useState([])

  const getCategories = useCallback(async () => {
    try {
      const res = await api.get('customers/categories', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCategories(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCategories(false)
    }
  }, [])

  return {
    loadingCategories,
    categories,
    getCategories,
  }
}

import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'

import averageSubcategories from './averageSubcategories/reducer'
import customStatus from './customStatus/reducer'
import { feedsCount } from './feedsCount/reducer'
import files from './files/reducer'
import formsItems from './formsItems/reducer'
import formsItemsMultiples from './formsItemsMultiples/reducer'
import {
  formsMissionsUpdateReducers as formsMissionsUpdate,
  formsMissionsCreateReducers as formsMissionsCreate,
} from './formsMissions/reducer'
import missions from './missions/reducer'
import photos from './photos/reducer'
import places from './places/reducer'
import productsItems from './productsItems/reducer'
import registers from './registers/reducer'
import { reports, reportGenerate } from './reports/reducer'
import sales from './sales/reducer'
import addresses from './searchAddress/reducer'
import updateOrder from './updateOrder/reducer'
import user from './user/reducer'

export const history = createBrowserHistory()

export default combineReducers({
  ...sales,
  ...files,
  ...places,
  ...photos,
  ...missions,
  ...registers,
  ...addresses,
  ...formsItems,
  ...customStatus,
  ...productsItems,
  ...formsItemsMultiples,
  reports,
  reportGenerate,
  feedsCount,
  updateOrder,
  formsMissionsCreate,
  formsMissionsUpdate,
  averageSubcategories,
  user,
  router: connectRouter(history),
})

import axios from 'axios'

import api from '~/services/api'

const putFormData = (params) => async (dispatch) => {
  const { type, data, url } = params
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch({ type, progress: percentCompleted })
      },
      timeout: 100000,
    }

    dispatch({ type })

    const res = await api.put(url, data, config)

    if (res.status !== 200 || res.status === 201) {
      return dispatch({ type: `${type}_FAIL`, message: res.data.message })
    }

    return dispatch({ type: `${type}_OK`, data: res.data.data || res.data })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: `${type}_FAIL`,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: `${type}_FAIL`, message: e.message })
  }
}

const post = (params) => async (dispatch) => {
  const { typeFetch, typeError, typeResponse, data, url } = params
  try {
    dispatch({ type: typeFetch })
    const res = await api.post(url, data, { timeout: 20000 })

    if (res.status !== 200) {
      return dispatch({ type: typeError, message: res.data.message })
    }

    return dispatch({ type: typeResponse, data: res.data.data || res.data })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: typeError,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: typeError, message: e.message })
  }
}

const remove = (params) => async (dispatch) => {
  const { typeFetch, typeError, typeResponse, url, data } = params
  try {
    dispatch({ type: typeFetch })

    const res = await api.delete(url, data || null, { timeout: 5000 })

    if (res.status !== 200) {
      return dispatch({ type: typeError, message: res.data.message })
    }

    return dispatch({ type: typeResponse, data: res.data.data || res.data })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: typeError,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: typeError, message: e.message })
  }
}

const get = (params) => async (dispatch) => {
  const { url, typeError, typeFetch, typeResponse } = params
  try {
    dispatch({ type: typeFetch })

    const res = await api.get(url, { timeout: 15000 })

    if (res.status !== 200) {
      throw new Error(res.data.message)
    }

    return dispatch({
      type: typeResponse,
      data: res.data.data || res.data,
      count: res.data.count || null,
    })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: typeError,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: typeError, message: e.message })
  }
}

const getAdress = (params) => async (dispatch) => {
  const { typeFetch, typeError, typeResponse, url } = params
  try {
    dispatch({ type: typeFetch })

    const res = await axios.get(url)

    if (res.status !== 200) {
      throw new Error(res.data.message)
    }

    return dispatch({
      type: typeResponse,
      data: res.data.results || res.data.data,
    })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: typeError,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: typeError, message: e.message })
  }
}

const put = (params) => async (dispatch) => {
  const { typeFetch, typeError, typeResponse, url, data } = params
  try {
    dispatch({ type: typeFetch })

    const res = await api.put(url, data, { timeout: 20000 })

    if (res.status !== 200 && res.status !== 201) {
      return dispatch({ type: typeError, message: res.data.message })
    }

    return dispatch({ type: typeResponse, data: res.data.data || res.data })
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      return dispatch({
        type: typeError,
        message:
          'Erro: Tempo excedido, verique sua conexão e tente novamente...',
      })
    }

    return dispatch({ type: typeError, message: e.message })
  }
}

export { get, put, post, remove, getAdress, putFormData }

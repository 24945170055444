import React from 'react'

import { BarcodeOutlined, BankOutlined } from '@ant-design/icons'
import { Form, Input, Button, Space, Select } from 'antd'
import t from 'prop-types'

const { Option } = Select

const options = [
  { key: '0', text: 'Boleto', value: 'BOLETO', icon: <BarcodeOutlined /> },
  {
    key: '1',
    value: 'TED',
    text: 'Transfêrencia Bancária',
    icon: <BankOutlined />,
  },
]

export function RequestForm({ form, submit, onClose, loadingButton }) {
  return (
    <Form form={form} onFinish={submit}>
      <Form.Item name="value">
        <Input prefix="R$" type="number" />
      </Form.Item>
      <Form.Item name="paymentType">
        <Select placeholder="Selecione o arquivo" optionFilterProp="children">
          {options.map((i) => (
            <Option key={i.key} value={i.value}>
              {i.icon} {i.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button shape="round" type="danger" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loadingButton}
            disabled={loadingButton}
          >
            Prosseguir
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

RequestForm.defaultProps = {
  loadingButton: false,
}

RequestForm.propTypes = {
  submit: t.func.isRequired,
  onClose: t.func.isRequired,
  loadingButton: t.bool,
  form: t.objectOf({ value: t.number, payment_type: t.number }).isRequired,
}

import { createStore, compose, applyMiddleware } from 'redux'

import { ENVIRONMENT } from '~/config/env'

export default (reducers, middlewares) => {
  const enhancer =
    ENVIRONMENT === 'development'
      ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares)
  return createStore(reducers, enhancer)
}

import React from 'react'
import { Modal, Button, Input } from 'antd'
import t from 'prop-types'

const ModalBonusPoints = ({
  open,
  onSubmit,
  onCancel,
  loading,
  onChange,
  bonusPoints,
}) => (
  <Modal
    visible={open}
    title="Bonificação de Pontos"
    onOk={onSubmit}
    onCancel={onCancel}
    footer={[
      <Button key="back" onClick={onCancel}>
        Cancelar
      </Button>,
      <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
        Enviar
      </Button>,
    ]}
  >
    <Input
      onChange={(event) => onChange(event.target.value)}
      value={bonusPoints}
      placeholder="Valor em pontos(Ex: 10)"
    />
  </Modal>
)

ModalBonusPoints.propTypes = {
  open: t.bool,
  onSubmit: t.func,
  onCancel: t.func,
  loading: t.bool,
  onChange: t.func,
  bonusPoints: t.number,
}

export default ModalBonusPoints

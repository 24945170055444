import React, { useEffect } from 'react'

import { Badge, Drawer } from 'antd'
import t from 'prop-types'

import Table from '~/components/organisms/Table'
import useRegisters from '~/hooks/useRegisters'

import { INIT_PAGE } from '../helpers'

export function ApprovationBatchDetail({ onClose, open, id }) {
  const {
    pagination,
    loadingRegister,
    registersApprovationBatch,
    getRegistersApprovationBatch,
  } = useRegisters()

  const REGISTER_STATUS = {
    0: 'Pendente',
    1: 'Aprovado',
    2: 'Reprovado',
    3: 'Em análise',
  }

  const BADGE_STATUS = {
    0: 'processing',
    1: 'success',
    2: 'error',
    3: 'warning',
  }
  const COLUMNS = [
    {
      title: 'Cod',
      key: 'mu_key',
      dataIndex: 'mu_key',
    },
    {
      title: 'Usuário',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Missão',
      key: 'mission_name',
      dataIndex: 'mission_name',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Badge status={BADGE_STATUS[status]} text={REGISTER_STATUS[status]} />
      ),
    },
  ]

  function getRegisters(page) {
    return getRegistersApprovationBatch(id, page)
  }

  useEffect(() => {
    getRegisters(INIT_PAGE)
  }, []) // eslint-disable-line

  return (
    <Drawer
      title="Registros em Processamento"
      placement="right"
      closable={false}
      onClose={onClose}
      width={720}
      open={open}
      key="approvation-batch-detail"
    >
      <Table
        key="table"
        size="small"
        data={registersApprovationBatch}
        columns={COLUMNS}
        total={pagination?.total}
        loading={loadingRegister}
        current={pagination?.current}
        pageSize={pagination?.pageSize}
        handlePagination={(page) => getRegisters(page)}
      />
    </Drawer>
  )
}

ApprovationBatchDetail.propTypes = {
  onClose: t.func,
  open: t.bool,
  id: t.number,
}

import React from 'react'

import { List, Button, Skeleton, Card, Avatar, Tag } from 'antd'
import t from 'prop-types'

import Profile from '~/assets/images/profile.svg'
import { checkDayRange } from '~/utils/helpers'

const WaitingReview = ({ count, loading, limit, items, onClick, loadMore }) => (
  <Card
    loading={loading}
    className="card-content"
    title={`${count || 0} Aguardando Aprovação`}
  >
    <List
      loading={loading}
      dataSource={items}
      loadMore={
        Number(count) >= limit && (
          <Button
            size="small"
            shape="round"
            onClick={loadMore}
            disabled={loading}
            loading={loading}
          >
            Carregar mais
          </Button>
        )
      }
      itemLayout="horizontal"
      renderItem={(item, index) => {
        const countDay = checkDayRange(item.updated_at || item.created_at)
        const isUrgent = countDay >= item.time_to_appreciate / 24

        return (
          <List.Item
            onClick={() => onClick(item, index)}
            style={{ cursor: 'pointer' }}
          >
            <Skeleton loading={loading} active avatar>
              <List.Item.Meta
                description={item.missionName}
                title={item.userName.split(' ')[0].toUpperCase()}
                avatar={<Avatar src={item.userAvatar || Profile} />}
              />
              {isUrgent ? (
                <Tag color="error">Urgente! Aguardando há {countDay} dias</Tag>
              ) : (
                <Tag color="default">
                  Aguardando há {countDay} {countDay <= 1 ? 'dia' : 'dias'}
                </Tag>
              )}
            </Skeleton>
          </List.Item>
        )
      }}
    />
  </Card>
)

WaitingReview.defaultTypes = {
  count: 0,
  limit: 0,
  loading: false,
}

WaitingReview.propTypes = {
  count: t.number.isRequired,
  loading: t.bool.isRequired,
  limit: t.number.isRequired,
  items: t.arrayOf(t.any).isRequired,
  onClick: t.func.isRequired,
  loadMore: t.func.isRequired,
}

export default WaitingReview

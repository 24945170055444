import React from 'react'

import { List } from 'antd'
import t from 'prop-types'

import {
  actionLabel,
  actionColor,
} from '~/pages/Missions/Registers/helpers/activities'
import { dateParse } from '~/utils/helpers'

import { Container } from './styles'

const Items = ({ activities, onClick }) => (
  <Container>
    <List
      size="small"
      style={{
        fontWeight: 9,
        maxWidth: 300,
        maxHeight: 300,
        zIndex: 999999,
        overflowY: 'auto',
      }}
      loading={false}
      header="Atividades"
      bordered
      dataSource={activities}
      itemLayout="horizontal"
      renderItem={(item, index) => (
        <List.Item
          key={String(index)}
          onClick={() => onClick(item, index)}
          style={{ fontSize: 10, cursor: 'pointer' }}
        >
          <List.Item.Meta
            style={{ fontSize: 9 }}
            description={dateParse(item.created_at)}
            title={
              <span style={{ color: actionColor[item.action] }}>
                {actionLabel[item.action]}
              </span>
            }
          />
        </List.Item>
      )}
    />
  </Container>
)

Items.propTypes = {
  activities: t.arrayOf(t.any),
  onClick: t.func,
}

export default Items

/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Form, Space, Row, Col } from 'antd'

import TableComponent from '~/components/organisms/Table'
import useStores from '~/hooks/useStores'

import { styles } from '../styles'
import { Filter } from './components/Filter'
import HeaderActions from './components/HeaderActions'
import { columns } from './helpers'

export default function MissionsRequested() {
  const [formFilter] = Form.useForm()

  const {
    stores,
    storesCount,
    loadingStores,
    updateStoresStatus,
    getStores,
    isApproveLoading,
    isRepproveLoading,
  } = useStores()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  })
  const [filtersSelected, setFiltersSelected] = useState({})

  const getQuery = () => {
    const newQuery = {
      offset: 0,
      limit: 20,
      status: 'PENDING',
      order: 'created_at',
      direction: 'desc',
    }

    return getStores(newQuery)
  }

  const resetFilters = () => {
    setFiltersSelected({})
    setSelectedRowKeys([])
    setPagination({
      current: 1,
      pageSize: 20,
    })

    return getQuery()
  }

  function handleTableChange(page, _filter) {
    const offset = (page.current - 1) * page.pageSize
    const newQuery = {
      offset,
      limit: page.pageSize,
      ...filtersSelected,
    }
    setPagination((prev) => ({ ...prev, current: page?.current }))
    return getStores(newQuery)
  }

  function submitFilter(filters) {
    setFiltersSelected(filters)
    return getStores(filters)
  }

  function onSelectChange(selectedItems) {
    return setSelectedRowKeys(selectedItems)
  }

  useEffect(() => {
    getQuery()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingStores) {
      setPagination((prev) => ({ ...prev, current: pagination.current }))
    }
  }, [loadingStores, stores]) //eslint-disable-line

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <Filter
        form={formFilter}
        onSubmit={submitFilter}
        onReset={resetFilters}
      />

      {selectedRowKeys.length > 0 ? (
        <PageHeader style={styles.pageHeader}>
          <Row justify="space-between">
            <Space direction="vertical">
              <Col>
                <HeaderActions
                  updateStoresStatus={updateStoresStatus}
                  isStoresLoading={loadingStores}
                  isApproveLoading={isApproveLoading}
                  isRepproveLoading={isRepproveLoading}
                  selectedRowKeys={selectedRowKeys}
                  itemsCount={storesCount}
                  resetFilters={resetFilters}
                  allRowsSelected={stores.length === selectedRowKeys.length}
                />
              </Col>
            </Space>
          </Row>
        </PageHeader>
      ) : null}

      <TableComponent
        size="small"
        data={stores}
        loading={loadingStores}
        columns={columns()}
        total={Number(storesCount)}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        current={pagination.current}
        pageSize={pagination.pageSize}
        onChangePageSize={handleTableChange}
      />
    </>
  )
}

import { Descriptions } from 'antd'
import styled from 'styled-components'

export const Description = styled(Descriptions)`
  width: 100%;

  .ant-descriptions-view {
    overflow: visible;
  }
`

export const Item = styled(Descriptions.Item)`
  max-height: 185px !important;
  overflow-x: auto;

  .ant-descriptions-item-content {
    max-height: 185px !important;
    overflow-x: auto;
  }
`

export const Container = styled.div`
  #ReactGridGallery {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    min-width: 300px;
  }
`

import styled from 'styled-components'

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 0px 4px 0px;
  border-radius: 30px;
  background-color: #f1f1f1;
  width: 142px;
`

export const BadgeTitle = styled.span`
  color: #54aa84;
  font-size: 15px;
  margin-left: 5px;
  font-weight: bold;
`

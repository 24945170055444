import React from 'react'

import { ScheduleTwoTone } from '@ant-design/icons'
import { Tag, Badge } from 'antd'
import { isNil } from 'ramda'

import { dateParse } from '~/utils/helpers'

const styles = {
  colorRed: {
    color: 'red',
  },
  lineCentered: {
    display: 'flex',
    alignItems: 'bottom',
  },
  icon: {
    fontSize: '18px',
    marginLeft: '5px',
  },
}

export function columns() {
  return [
    {
      title: 'COD.',
      dataIndex: '',
      render: (item) => (
        <span style={styles.lineCentered}>
          <Badge status={item.status ? 'success' : 'error'} />{' '}
          {item.mission_key}{' '}
          {item.has_schedule && <ScheduleTwoTone style={styles.icon} />}
        </span>
      ),
    },
    {
      title: 'Progresso',
      dataIndex: '',
      render: (item) => (
        <div>
          {Number(item.participants) === Number(item.has_finished) && (
            <Tag color="green" ribbon="true">
              Finalizado
            </Tag>
          )}

          {Number(item.has_started) > 0 && <Tag color="blue">Iniciada</Tag>}

          {Number(item.has_finished) === 0 &&
            Number(item.has_started) === 0 && (
              <Tag color="yellow">Aguardando</Tag>
            )}
        </div>
      ),
    },
    {
      title: 'Grupo',
      dataIndex: '',
      render: (item) => (
        <Tag color={item.group_name ? 'blue' : 'warning'} ribbon="true">
          {item.group_name || 'Sem Grupo'}
        </Tag>
      ),
    },
    {
      title: 'ID.',
      sorter: true,
      ellipsis: true,
      dataIndex: 'identifier',
    },
    {
      title: 'Categoria',
      sorter: true,
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: 'Missão',
      sorter: true,
      dataIndex: 'name',
    },
    {
      title: 'Criado Por',
      sorter: true,
      dataIndex: 'username',
      render: (user) => (isNil(user) ? 'MASTER' : user),
    },
    {
      title: 'Criado Em',
      sorter: true,
      dataIndex: 'created_at',
      render: (date) => dateParse(date),
    },
    {
      sorter: true,
      title: 'Expira Em',
      dataIndex: 'expired_at',
      render: (date) => <span style={styles.colorRed}>{dateParse(date)}</span>,
    },
  ]
}

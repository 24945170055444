export const chartsTypes = [
  {
    value: 'barra',
    label: 'Barra',
  },
  {
    value: 'linear',
    label: 'Linear',
  },
  {
    value: 'bar_avg',
    label: 'Barra Percentual',
  },
  {
    value: 'doughnut',
    label: 'Doughnut Percentual',
  },
  {
    value: 'missions_approved',
    label: 'Missões por UF',
  },
  {
    value: 'missions_approved_by_group',
    label: 'Missões por Grupos',
  },
]

// export const labelByEntity = {
//   barra: { entity: 'forms_data', type: 'bar_count' },
//   linear: { entity: 'forms_data', type: 'linear' },
//   bar_avg: { entity: 'forms_data', type: 'bar_avg' },
//   doughnut: { entity: 'forms_data', type: 'doughnut' },
//   missions_approved: { entity: 'missions_approved', type: 'bar_count' },
//   missions_approved_by_group: {
//     entity: 'missions_approved_by_group',
//     type: 'bar_count',
//   },
// }

export const typeValues = {
  barra: { entity: 'forms_data', type: 'bar_count' },
  linear: { entity: 'forms_data', type: 'linear' },
  bar_avg: { entity: 'forms_data', type: 'bar_avg' },
  doughnut: { entity: 'forms_data', type: 'doughnut' },
  missions_approved: { entity: 'missions_approved', type: 'bar_count' },
  missions_approved_by_group: {
    entity: 'missions_approved_by_group',
    type: 'bar_count',
  },
}

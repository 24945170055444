import { responseData } from '~/utils/reducer'

import {
  WALLET_CONSOLIDATE,
  WALLET_LIST_CONSOLIDATED,
  WALLET_FETCH_CONSOLIDATED,
  WALLET_TRANSACTION,
} from './types'

const sales = responseData(WALLET_FETCH_CONSOLIDATED, 'array')
const getSale = responseData(WALLET_LIST_CONSOLIDATED, 'array')
const consolidateWallet = responseData(WALLET_CONSOLIDATE, 'object')
const walletTransactioned = responseData(WALLET_TRANSACTION, 'object')

export default {
  sales,
  getSale,
  consolidateWallet,
  walletTransactioned,
}

import React from 'react'

import dayjs from 'dayjs'

import { TableRowActions } from './TableRowActions'

export const columns = [
  {
    title: 'Titulo',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <strong>{text}</strong>,
  },
  {
    title: 'Identificador',
    dataIndex: 'identifier',
    key: 'identifier',
  },
  {
    title: 'Client ID',
    dataIndex: 'customers_accounts_id',
    key: 'customers_accounts_id',
  },
  {
    title: 'Criado Em',
    key: 'created_at',
    dataIndex: 'created_at',
    render: (text) => dayjs(text).format('YYYY-MM-DD'),
  },
  {
    title: '',
    key: 'action',
    render: (a) => <TableRowActions id={a.id} />,
  },
]

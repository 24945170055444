import { useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useFormsItemsQuestionsMultiples() {
  const [loadingMultiples, setLoadingMultiples] = useState(true)
  const [questionsMultiples, setQuestionsMultiples] = useState([])

  async function getFormsItemsQuestionsMultiples(formsItemsId) {
    try {
      setLoadingMultiples(true)

      const res = await api.get('/forms-items-multiples/customers', {
        params: { formsItemsId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setQuestionsMultiples(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  async function createFormsItemsQuestionsMultiples(params) {
    try {
      setLoadingMultiples(true)

      const res = await api.post('/forms-items-multiples/customers', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setQuestionsMultiples((prevState) => [...prevState, ...res.data])

      return message.success('Item criado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  async function deleteFormsItemsQuestionsMultiples(params) {
    try {
      setLoadingMultiples(true)

      const res = await api.delete('/forms-items-multiples/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setQuestionsMultiples((prevState) =>
        prevState.filter((value) => value.uid !== res.data[0].uid)
      )

      return message.success('Item removido com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  async function changeOrderQuestionsMultiples(params) {
    try {
      setLoadingMultiples(true)

      const res = await api.put(
        '/forms-items-multiples/customers/change-order',
        params,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Ordem alterada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  async function updateFormsItemsQuestionsMultiples(params) {
    try {
      setLoadingMultiples(true)

      const res = await api.put('/forms-items-multiples/customers', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return message.success('Item alterado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  async function uploadImageQuestionsMultiples(params) {
    try {
      setLoadingMultiples(true)

      const formData = new FormData()

      formData.append('image', params.file.originFileObj)
      formData.append('uid', params.fiqmId)

      const res = await api.post(
        '/forms-items-multiples/customers/image',
        formData,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setQuestionsMultiples((prevState) =>
        prevState.map((prev) => {
          if (prev.uid === params.fiqmId) {
            return {
              ...prev,
              url_image: res.data[0].url_image,
            }
          }
          return prev
        })
      )

      return message.success('Imagem enviada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingMultiples(false)
    }
  }

  return {
    getFormsItemsQuestionsMultiples,
    questionsMultiples,
    loadingMultiples,
    createFormsItemsQuestionsMultiples,
    deleteFormsItemsQuestionsMultiples,
    changeOrderQuestionsMultiples,
    updateFormsItemsQuestionsMultiples,
    uploadImageQuestionsMultiples,
  }
}

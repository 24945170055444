import React from 'react'
import { pdfjs } from 'react-pdf'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './config/ReactotronConfig'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import dotenv from 'dotenv'

import Routes from '~/routes'
import GlobalStyles from '~/styles/global'

import 'antd/dist/reset.css'

import AppProvider from './contexts'
import { store } from './store'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

dotenv.config()

export default () => (
  <>
    <BrowserRouter>
      <Provider store={store}>
        <AppProvider>
          <Routes />
          <GlobalStyles />
        </AppProvider>
      </Provider>
    </BrowserRouter>
  </>
)

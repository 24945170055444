import { useCallback, useState } from 'react'

import { message } from 'antd'
import Axios from 'axios'

import { API_BASE_URL } from '~/config/env'
import api from '~/services/api'

export default function useCustomersUsersTags() {
  const [loadingTags, setLoadingTags] = useState(true)
  const [customersUsersTags, setCustomersUsersTags] = useState([])
  const [customersUsersTagsName, setCustomersUsersTagsName] = useState('')
  const [customerUserTag, setCustomerUserTag] = useState(null)

  const getCustomersUsersTags = async () => {
    try {
      const res = await api.get('customers-users-tags', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCustomersUsersTagsName(res.data ? res.data?.name : 'Sem Tags')

      setCustomerUserTag(res.data)
      return setCustomersUsersTags(res.data ? res.data?.tags?.items : [])
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTags(false)
    }
  }

  const getCustomersUsersTagsToken = useCallback(async (token) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const res = await Axios.get(
        `${API_BASE_URL}/v1/customers-users-tags`,
        config,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCustomersUsersTags(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTags(false)
    }
  }, [])

  async function onSubmitCustomersUsersTags(data) {
    try {
      setLoadingTags(true)
      const res = await api.post('customers-users-tags', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Campo adicionado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      getCustomersUsersTags()
      setLoadingTags(false)
    }
  }

  async function onUpdateCustomersUsersTags(data) {
    try {
      setLoadingTags(true)
      data.uid = customerUserTag.uid
      data.name = customerUserTag.name
      const res = await api.put('customers-users-tags', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      await getCustomersUsersTags()
      return message.success('Campo atualizado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTags(false)
    }
  }

  return {
    loadingTags,
    customersUsersTags,
    customersUsersTagsName,
    getCustomersUsersTags,
    onUpdateCustomersUsersTags,
    onSubmitCustomersUsersTags,
    getCustomersUsersTagsToken,
  }
}

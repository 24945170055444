import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Row, Button } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import t from 'prop-types'

const styles = {
  marginBottom: {
    marginBottom: 10,
  },
}

const Header = ({ openForm }) => (
  <PageHeader style={styles.marginBottom}>
    <Row>
      <Button
        key="0"
        type="primary"
        shape="round"
        onClick={openForm}
        icon={<PlusOutlined />}
      >
        Novo Agendamento
      </Button>
    </Row>
  </PageHeader>
)

Header.defaultTypes = {}

Header.propTypes = {
  openForm: t.func.isRequired,
}

export default Header

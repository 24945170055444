import { message } from 'antd'
import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '~/services/api'
import { CUSTOMERS_UPDATE } from '~/services/api/endpoints'

import { updateProfileSuccess } from './actions'

export function* updateProfileRequest({ payload }) {
  try {
    const profile = payload.data

    const res = yield call(api.put, CUSTOMERS_UPDATE, profile)

    if (res.status === 500) {
      throw new Error(res.data.data.message)
    }

    yield put(updateProfileSuccess(res.data.data))

    return message.success('Perfil atualizado!')
  } catch (error) {
    return message.error(
      error.response.data.data || 'Houve um erro no servidor!'
    )
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfileRequest),
])

import React from 'react'

import { Modal, Alert } from 'antd'
import t from 'prop-types'

const ZipLinkModal = ({
  isOpenZipLinkModal,
  closeZipLinkModal,
  reportSelected,
}) => {
  return (
    <Modal
      visible={isOpenZipLinkModal}
      title="Arquivos zip"
      open={isOpenZipLinkModal}
      cancelText="fechar"
      onOk={() => closeZipLinkModal()}
      onCancel={() => closeZipLinkModal()}
    >
      <Alert
        message="Alerta"
        description="Cada arquivo zip pode ter até 1.000 imagens dentro."
        type="info"
        showIcon
      />
      {reportSelected &&
        reportSelected.urls.map((link, index) => {
          const position = index + 1
          return (
            <>
              <br />
              <a href={link} target="_blank" rel="noopener noreferrer">
                Clique no {position}º link para baixar
              </a>
            </>
          )
        })}
    </Modal>
  )
}

ZipLinkModal.propTypes = {
  isOpenZipLinkModal: t.bool,
  closeZipLinkModal: t.func,
  reportSelected: t.objectOf(t.any),
}

export default ZipLinkModal

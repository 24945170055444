import React, { useState, useEffect } from 'react'
import { FlyToInterpolator } from 'react-map-gl'

import * as d3 from 'd3'
import t from 'prop-types'

import MapFeeds from '~/components/organisms/Maps/Feeds'
import Items from '~/components/organisms/Maps/Feeds/Items'
import Popup from '~/components/organisms/Maps/Popup'
import useMissionsFeeds from '~/hooks/useMissionsFeeds'

import { actionLabel } from '../../../../helpers/activities'

const position = {
  latitude: -8.269214,
  longitude: -55.441382,
}

const animationViewport = {
  zoom: 14,
  transitionDuration: 5000,
  transitionEasing: d3.easeCubic,
  transitionInterpolator: new FlyToInterpolator(),
}

export function Feeds({ register }) {
  const [selected, setSelected] = useState(0)
  const [viewport, setViewport] = useState({
    ...animationViewport,
    latitude: position.latitude,
    longitude: position.longitude,
  })

  const { missionsFeeds, loadingFeeds, getMissionsFeedsByMissionsUsersId } =
    useMissionsFeeds()

  function onClick(item, index) {
    setSelected(index)

    return setViewport({
      ...viewport,
      latitude: item?.lat || position.latitude,
      longitude: item?.lng || position.longitude,
      ...animationViewport,
    })
  }

  useEffect(() => {
    getMissionsFeedsByMissionsUsersId(register.missions_users_id)
  }, []) //eslint-disable-line

  return (
    <div style={{ height: '100vh' }}>
      {!loadingFeeds && missionsFeeds.length > 0 && (
        <MapFeeds
          markers={missionsFeeds}
          selected={selected}
          viewport={viewport}
          currentMarker={{
            lat: register?.lat || position.latitude,
            lng: register?.lng || position.longitude,
            place: register?.place || 'Não Localizada',
          }}
          renderPopUp={(item) => (
            <Popup
              lat={item?.lat}
              lng={item?.lng}
              place={actionLabel[item?.action]}
            />
          )}
          onChange={(item) => setViewport(item)}
        >
          <Items activities={missionsFeeds} onClick={onClick} />
        </MapFeeds>
      )}
    </div>
  )
}

Feeds.propTypes = {
  register: t.objectOf(t.any),
}

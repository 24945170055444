import React from 'react'

import {
  EditOutlined,
  DeleteOutlined,
  OrderedListOutlined,
  StopOutlined,
  CheckOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { Space, Card, Button, Row, Col, Skeleton, Tooltip } from 'antd'
import t from 'prop-types'

import LoadingCards from '~/components/molecules/LoadingCards'

import styles from './styles'

const { Meta } = Card

const ListCards = ({
  data,
  loading,
  count,
  hasCopy,
  hasEdit,
  hasDetail,
  hasRemove,
  hasTurnOnOff,
  onClickEdit,
  onClickDetail,
  onClickRemove,
  onClickDuplicate,
  onClickTurnOnOff,
  handlePagination,
}) => {
  function hasButtons(i) {
    return [
      {
        hasContent: hasCopy,
        content: (
          <Tooltip placement="top" title="Copiar">
            <CopyOutlined onClick={() => onClickDuplicate(i)} />
          </Tooltip>
        ),
      },
      {
        hasContent: hasEdit,
        content: (
          <Tooltip placement="top" title="Editar">
            <EditOutlined onClick={() => onClickEdit(i, 'update')} />
          </Tooltip>
        ),
      },
      {
        hasContent: hasDetail,
        content: (
          <Tooltip placement="top" title="Lista de Itens">
            <OrderedListOutlined onClick={() => onClickDetail(i.uid)} />
          </Tooltip>
        ),
      },
      {
        hasContent: hasRemove,
        content: (
          <Tooltip placement="top" title="Remover">
            <DeleteOutlined onClick={() => onClickRemove(i)} />
          </Tooltip>
        ),
      },
      {
        hasContent: hasTurnOnOff,
        content: (
          <Tooltip placement="top" title={i.status ? 'Inativar' : 'Ativar'}>
            {i.status ? (
              <StopOutlined onClick={() => onClickTurnOnOff(i)} />
            ) : (
              <CheckOutlined onClick={() => onClickTurnOnOff(i)} />
            )}
          </Tooltip>
        ),
      },
    ]
  }

  return (
    <>
      <LoadingCards loading={loading} />

      <Space direction="vertical">
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {data?.map((i) => (
            <Col key={i.id}>
              <Card
                loading={loading}
                style={styles.cardWidth}
                actions={hasButtons(i)
                  .filter((item) => item.hasContent)
                  .map((c) => c.content)}
              >
                <Skeleton loading={loading} avatar active>
                  <Meta
                    title={i?.title || `${i?.id}# ${i?.name}`}
                    style={styles.metaContent}
                    description={i?.description || null}
                  />
                  <br />
                  {i.content}
                </Skeleton>
              </Card>
            </Col>
          ))}
        </Row>

        {count > data.length && !loading && (
          <Row justify="center">
            <Button
              shape="round"
              loading={loading}
              disabled={loading}
              onClick={handlePagination}
            >
              Carregar Mais
            </Button>
          </Row>
        )}
      </Space>
    </>
  )
}

ListCards.defaultTypes = {
  loading: false,
  hasEdit: false,
  hasDetail: false,
  hasRemove: false,
  hasTurnOnOff: false,
}

ListCards.propTypes = {
  data: t.arrayOf(t.any).isRequired,
  loading: t.bool.isRequired,
  count: t.number.isRequired,
  hasCopy: t.bool.isRequired,
  hasEdit: t.bool.isRequired,
  hasDetail: t.bool.isRequired,
  hasRemove: t.bool.isRequired,
  hasTurnOnOff: t.bool.isRequired,
  onClickEdit: t.func.isRequired,
  onClickDetail: t.func.isRequired,
  onClickRemove: t.func.isRequired,
  onClickTurnOnOff: t.func.isRequired,
  onClickDuplicate: t.func.isRequired,
  handlePagination: t.func.isRequired,
}

export default ListCards

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

const isArrayFilters = ['ufs[]', 'cities[]']

export default function useQueryFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  const getUrlQuery = useCallback(() => {
    return Object.fromEntries([...searchParams])
  }, [searchParams])

  async function onUpdateQuery(key, value) {
    return setSearchParams((params) => {
      if (isArrayFilters.includes(key)) {
        params.append(key, value)
      } else {
        params.set(key, value)
      }

      return params
    })
  }

  function onResetQuery() {
    return setSearchParams('')
  }

  return { onUpdateQuery, onResetQuery, getUrlQuery, searchParams }
}

import React from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Form, Space, Popover, Button, InputNumber, Upload } from 'antd'
import t from 'prop-types'

import styles from './styles'

const ContentPop = ({ form, submitOrder, loading }) => (
  <Space direction="horizontal" size="small">
    <Form form={form} onFinish={submitOrder} style={styles.widthFlex}>
      <Form.Item name="to" style={styles.widthFlex}>
        <InputNumber placeholder="De" />
      </Form.Item>
      <Form.Item name="start" style={styles.widthFlex}>
        <InputNumber placeholder="Para" />
      </Form.Item>
      <Form.Item>
        <Button
          shape="round"
          loading={loading}
          disabled={loading}
          htmlType="submit"
          style={styles.widthFlex}
        >
          Ordenar
        </Button>
      </Form.Item>
    </Form>
  </Space>
)

const ContentTemplateActions = ({
  handleExportFormsItemsTemplate,
  handleImportFormsItemsTemplate,
  loadingActionsTemplate,
}) => (
  <Space direction="vertical" size="small">
    <Button
      shape="round"
      onClick={handleExportFormsItemsTemplate}
      loading={loadingActionsTemplate}
      disabled={loadingActionsTemplate}
    >
      Exportar
    </Button>

    <Upload
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      showUploadList={false}
      onChange={handleImportFormsItemsTemplate}
      customRequest={() => setTimeout(() => {}, 0)}
    >
      <Button
        shape="round"
        loading={loadingActionsTemplate}
        disabled={loadingActionsTemplate}
      >
        Importar
      </Button>
    </Upload>
  </Space>
)

ContentTemplateActions.propTypes = {
  handleExportFormsItemsTemplate: t.func,
  handleImportFormsItemsTemplate: t.func,
  loadingActionsTemplate: t.bool,
}

ContentPop.propTypes = {
  form: t.objectOf(t.any).isRequired,
  submitOrder: t.func.isRequired,
  loading: t.bool.isRequired,
}

const PopoverForm = ({
  form,
  submitOrder,
  loading,
  visible,
  handleVisibleChange,
  setIsOpenModalCreateQuestion,
  openSectionName,
  handleExportFormsItemsTemplate,
  handleImportFormsItemsTemplate,
  visibleActionsTemplate,
  handleVisibleChangeActionsTemplate,
  loadingActionsTemplate,
}) => {
  function handleOpenModal() {
    setIsOpenModalCreateQuestion(true)
  }

  return (
    <>
      <Button onClick={handleOpenModal} shape="round">
        Criar Questão
      </Button>
      <Button
        onClick={openSectionName}
        shape="round"
        style={styles.marginLeftButton}
      >
        Nomear Seção
      </Button>
      <Popover
        key="reorder"
        title="Reordenar"
        trigger="click"
        open={visible}
        placement="leftTop"
        onOpenChange={handleVisibleChange}
        content={() => (
          <ContentPop form={form} submitOrder={submitOrder} loading={loading} />
        )}
      >
        <Button shape="round" style={styles.marginLeftButton}>
          Alterar Ordenação
        </Button>
      </Popover>

      <Popover
        key="template"
        title="Ações disponíveis"
        trigger="click"
        placement="leftTop"
        open={visibleActionsTemplate}
        onOpenChange={handleVisibleChangeActionsTemplate}
        content={() => (
          <ContentTemplateActions
            handleExportFormsItemsTemplate={handleExportFormsItemsTemplate}
            handleImportFormsItemsTemplate={handleImportFormsItemsTemplate}
            loadingActionsTemplate={loadingActionsTemplate}
          />
        )}
      >
        <Button shape="round" style={styles.marginLeftButton}>
          Ações de Template
        </Button>
      </Popover>
    </>
  )
}

PopoverForm.propTypes = {
  form: t.objectOf(t.any).isRequired,
  submitOrder: t.func.isRequired,
  loading: t.bool.isRequired,
  visible: t.bool.isRequired,
  handleVisibleChange: t.func.isRequired,
  setIsOpenModalCreateQuestion: t.func.isRequired,
  openSectionName: t.func,
  handleExportFormsItemsTemplate: t.func,
  handleImportFormsItemsTemplate: t.func,
  visibleActionsTemplate: t.bool,
  handleVisibleChangeActionsTemplate: t.func,
  loadingActionsTemplate: t.bool,
}

const Header = ({
  form,
  loading,
  title,
  goBack,
  visible,
  background,
  submitOrder,
  handleVisibleChange,
  setIsOpenModalCreateQuestion,
  openSectionName,
  handleExportFormsItemsTemplate,
  handleImportFormsItemsTemplate,
  visibleActionsTemplate,
  handleVisibleChangeActionsTemplate,
  loadingActionsTemplate,
}) => (
  <PageHeader
    title={title}
    onBack={goBack}
    style={{ backgroundColor: background }}
    extra={[
      <PopoverForm
        key="pop-form"
        form={form}
        loading={loading}
        visible={visible}
        submitOrder={submitOrder}
        handleVisibleChange={handleVisibleChange}
        setIsOpenModalCreateQuestion={setIsOpenModalCreateQuestion}
        openSectionName={openSectionName}
        handleExportFormsItemsTemplate={handleExportFormsItemsTemplate}
        handleImportFormsItemsTemplate={handleImportFormsItemsTemplate}
        visibleActionsTemplate={visibleActionsTemplate}
        handleVisibleChangeActionsTemplate={handleVisibleChangeActionsTemplate}
        loadingActionsTemplate={loadingActionsTemplate}
      />,
    ]}
  />
)

Header.propTypes = {
  loading: false,
}

Header.propTypes = {
  form: t.objectOf(t.any).isRequired,
  loading: t.bool.isRequired,
  title: t.string.isRequired,
  goBack: t.func.isRequired,
  visible: t.bool.isRequired,
  submitOrder: t.func.isRequired,
  background: t.string,
  handleVisibleChange: t.func.isRequired,
  setIsOpenModalCreateQuestion: t.func.isRequired,
  openSectionName: t.func,
  handleExportFormsItemsTemplate: t.func,
  handleImportFormsItemsTemplate: t.func,
  visibleActionsTemplate: t.bool,
  handleVisibleChangeActionsTemplate: t.func,
  loadingActionsTemplate: t.bool,
}

export default Header

import React, { useEffect } from 'react'

import { Form, Select } from 'antd'
import t from 'prop-types'

import useCustomersUsersTags from '~/hooks/useCustomersUsersTags'

export function Tags({ onChange }) {
  const {
    getCustomersUsersTags,
    loadingTags,
    customersUsersTags,
    customersUsersTagsName,
  } = useCustomersUsersTags()

  useEffect(() => {
    getCustomersUsersTags()
  }, []) //eslint-disable-line

  return (
    <Form.Item name="tags">
      <Select
        placeholder={customersUsersTagsName}
        mode="multiple"
        loading={loadingTags}
        onChange={(values) => onChange('tags', values)}
        options={customersUsersTags?.map((i) => ({
          label: i.value,
          value: i.value,
        }))}
      />
    </Form.Item>
  )
}

Tags.propTypes = {
  onChange: t.func,
}

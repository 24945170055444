import React from 'react'

import { Input, Form, Drawer, Skeleton, Button, Checkbox, Row, Col } from 'antd'
import t from 'prop-types'

import { useAuth } from '~/contexts/auth'

import { items } from './permissions'

const AccountsDetail = ({ form, item, open, close, loading, handleSubmit }) => {
  const { data } = useAuth()

  return (
    <Drawer
      width={720}
      visible={open}
      onClose={close}
      destroyOnClose="true"
      title="Detalhe da Conta"
    >
      <Skeleton loading={loading}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item label="Nome" name="username">
            <Input defaultValue={item?.username || ''} />
          </Form.Item>
          <Form.Item label="E-mail" name="email">
            <Input defaultValue={item?.email || ''} />
          </Form.Item>
          <Form.Item label="Permissões" name="permissions">
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {items.map((i) => (
                  <Col span={8}>
                    <Checkbox value={i.value}>{i.text}</Checkbox>
                  </Col>
                ))}
                {data?.is_private && (
                  <Col span={8}>
                    <Checkbox value="private_accounts">
                      Contas Privadas
                    </Checkbox>
                  </Col>
                )}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button shape="round" type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  )
}

AccountsDetail.defaultTypes = {
  form: {},
  item: { username: '', password: '', confirm: '' },
  open: false,
  close: false,
  loading: false,
}

AccountsDetail.propTypes = {
  item: t.objectOf({
    username: t.string,
    password: t.string,
    confirm: t.string,
  }),
  form: t.objectOf({}),
  open: t.bool.isRequired,
  close: t.func.isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
}

export default AccountsDetail

import React from 'react'

import { Form, Input } from 'antd'
import t from 'prop-types'

const CustomFields = ({ data, onChange }) => {
  function isNotEmpty() {
    return data.length > 0
  }

  return (
    <>
      {isNotEmpty() &&
        data.map((item, index) => {
          return (
            <Form.Item key={item.id} label={`${item.name} (Opcional)`}>
              <Input
                onChange={(event) => onChange(index, event.target.value)}
                value={item.value || ''}
              />
            </Form.Item>
          )
        })}
    </>
  )
}

CustomFields.propTypes = {
  onChange: t.func.isRequired,
  data: t.arrayOf(t.objectOf(t.any)),
}

export default CustomFields

/* eslint-disable array-callback-return */
import React, { useState } from 'react'

import {
  InboxOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Upload, message } from 'antd'
import t from 'prop-types'

export function Attachment({ onUploadFile }) {
  const { Dragger } = Upload
  const [files, setFiles] = useState([])

  function beforeUpload(file) {
    const is10MB = file.size / 1024 / 1024 < 10

    if (!is10MB) {
      message.error('Arquivo deve ter no máximo 10MB')
    }

    return is10MB
  }

  function getBase64(file, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }

  function onChange(info) {
    const { fileList } = info

    fileList.map((item) => {
      const { status, name, size, type, originFileObj } = item
      if (status !== 'uploading') {
        message.info(`Adicionando arquivo (${name})...`)
      }

      if (status === 'done') {
        getBase64(originFileObj, (base64) => {
          const newFile = { name, size, type, base64 }

          setFiles((prev) => [...prev, newFile])
          onUploadFile([...files, newFile])
        })

        message.success(`${name} encaminhado com sucesso`)
      } else if (status === 'error') {
        message.error(`${name} falha no envio do arquivo!`)
      }
    })
  }

  const draggerProps = {
    name: 'file',
    onChange,
    beforeUpload,
    multiple: true,
    defaultFileList: files,
    accept: '.pdf',
    onRemove: (e) => files.filter((i) => i !== e),
    customRequest: ({ file, onSuccess }) => {
      return setTimeout(() => onSuccess(`${file} ok`), 0)
    },
    showUploadList: {
      showRemoveIcon: true,
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined />,
      removeIcon: <DeleteOutlined onClick={() => true} />,
    },
    onDrop(e) {
      setFiles(e.dataTransfer.files)
    },
  }

  return (
    <div className="clearfix">
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou arraste o arquivo para fazer o upload.
        </p>
        <p className="ant-upload-hint">
          Arquivo deve ser menor ou igual a 3 MB!
        </p>
      </Dragger>
    </div>
  )
}

Attachment.propTypes = {
  onUploadFile: t.func,
}

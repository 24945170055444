import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCustomersRegistersActions() {
  const [loadingActions, setLoadingActions] = useState(true)
  const [customersRegistersActions, setCustomersRegistersActions] = useState([])

  const getCustomersRegistersActions = useCallback(async (registerStatusId) => {
    try {
      setLoadingActions(true)
      const res = await api.get('customers-registers-actions', {
        params: {
          registerStatusId,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCustomersRegistersActions(res.data)

      return res.data
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingActions(false)
    }
  }, [])

  async function onSubmitAction(params) {
    try {
      setLoadingActions(true)
      const res = await api.post('customers-registers-actions', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Ação encaminhada com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingActions(false)
    }
  }

  return {
    loadingActions,
    onSubmitAction,
    customersRegistersActions,
    getCustomersRegistersActions,
  }
}

import { responseData } from '~/utils/reducer'

const MISSIONS = 'MISSIONS'
const MISSIONS_CREATE = 'MISSIONS_CREATE'
const UPDATE_MISSIONS = 'UPDATE_MISSIONS'
const MISSIONS_PARTICIPANTS = 'MISSIONS_PARTICIPANTS'
const MISSIONS_REPORT_STATE = 'MISSIONS_REPORT_STATE'
const UPDATE_MISSIONS_STATUS = 'UPDATE_MISSIONS_STATUS'

const missions = responseData(MISSIONS, 'array')
const missionCreate = responseData(MISSIONS_CREATE, 'object')
const missionUpdate = responseData(UPDATE_MISSIONS, 'object')
const missionStatus = responseData(UPDATE_MISSIONS_STATUS, 'object')
const missionParticipants = responseData(MISSIONS_PARTICIPANTS, 'array')
const missionsReportState = responseData(MISSIONS_REPORT_STATE, 'array')

export default {
  missions,
  missionCreate,
  missionStatus,
  missionUpdate,
  missionParticipants,
  missionsReportState,
}

import React from 'react'

import { Statistic, Row, Col, Card, Skeleton } from 'antd'
import t from 'prop-types'

import { StatiticPropTypes } from '~/pages/Missions/Registers/components/Detail/lib'
import colors from '~/styles/colors'

import styles from './styles'

const Count = ({ statistic, loading }) => (
  <Row gutter={[6, 6]} style={{ padding: 0 }}>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Criadas"
            valueStyle={{ color: colors.grey }}
            value={Number(statistic?.missions_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Ativadas"
            valueStyle={{ color: colors.greenLight }}
            value={Number(statistic?.missions_actived_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Em Aberto"
            valueStyle={{ color: colors.redStrong }}
            value={Number(statistic?.missions_open) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Iniciadas"
            valueStyle={{ color: colors.blue }}
            value={Number(statistic?.missions_started) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Finalizadas"
            valueStyle={{ color: colors.grey }}
            value={Number(statistic?.registers_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Aguardando Avaliação"
            valueStyle={{ color: colors.yellow }}
            value={Number(statistic?.registers_pendent_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Aprovadas"
            valueStyle={{ color: colors.greenLightSecondary }}
            value={Number(statistic?.registers_approved_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    <Col>
      <Card hoverable style={styles.container}>
        <Skeleton loading={loading}>
          <Statistic
            title="Reprovadas"
            valueStyle={{ color: colors.redLight }}
            value={Number(statistic?.registers_reproved_count) || 0}
          />
        </Skeleton>
      </Card>
    </Col>
    {statistic?.custom_status !== null
      ? statistic?.custom_status?.map((item) => (
          <Col key={item.description}>
            <Card hoverable style={styles.container}>
              <Skeleton loading={loading}>
                <Statistic
                  title={item.description}
                  value={Number(item.count)}
                />
              </Skeleton>
            </Card>
          </Col>
        ))
      : null}
  </Row>
)

Count.defaultTypes = {
  loading: false,
}

Count.propTypes = {
  statistic: t.objectOf(StatiticPropTypes).isRequired,
  loading: t.bool.isRequired,
}

export default Count

import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Row, Button } from 'antd'
import t from 'prop-types'

const style = {
  container: {
    marginTop: 10,
    marginBottom: 20,
  },
  searchWidth: {
    width: 250,
  },
}

const Header = ({ openForm }) => (
  <Row justify="space-between" style={style.container}>
    <Button
      key="0"
      type="primary"
      shape="round"
      onClick={openForm}
      icon={<PlusOutlined />}
    >
      Nova Categoria Produto
    </Button>
  </Row>
)

Header.propTypes = {
  openForm: t.func.isRequired,
}

export default Header

import React, { useEffect, useState } from 'react'

import { DeleteFilled } from '@ant-design/icons'
import { Badge, Button, Popconfirm, Popover, Space } from 'antd'
import dayjs from 'dayjs'

import TableComponent from '~/components/organisms/Table'
import useWebhook from '~/hooks/useWebhook'

import CreateWebhook from './Create'

export default function Webhook() {
  const [openCreate, setOpenCreate] = useState(false)

  const {
    getWebhooks,
    loadingWebhooks,
    webhooks,
    removeWebhook,
    getEvents,
    events,
    createWebhook,
  } = useWebhook()

  const columns = [
    {
      title: 'Callback URL',
      dataIndex: 'callback_url',
      key: 'callback_url',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge
          status={status ? 'success' : 'error'}
          text={status ? 'Ativo' : 'Inativo'}
        />
      ),
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (item) => (
        <Space>
          <Popover content="Remover">
            <Popconfirm
              title="Remoção de Webhook"
              description="Você tem certeza que deseja remover esse Webhook? Essa ação não pode ser revertida."
              onConfirm={() => removeWebhook(item.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Button icon={<DeleteFilled />} danger />
            </Popconfirm>
          </Popover>
        </Space>
      ),
    },
  ]

  const handleOnSubmitCreate = (values) => {
    createWebhook(values)
  }

  useEffect(() => {
    Promise.all([getWebhooks(), getEvents()])
  }, []) // eslint-disable-line

  return (
    <>
      <Button type="primary" shape="round" onClick={() => setOpenCreate(true)}>
        Novo Webhook
      </Button>

      <TableComponent
        size="small"
        data={webhooks}
        columns={columns}
        loading={loadingWebhooks}
        total={Number(webhooks.length)}
      />

      <CreateWebhook
        open={openCreate}
        onCancel={() => setOpenCreate(false)}
        events={events}
        onSubmitCreate={handleOnSubmitCreate}
      />
    </>
  )
}

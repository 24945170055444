import React, { useState } from 'react'

import { Drawer, Tabs, List } from 'antd'
import { format } from 'date-fns'
import t from 'prop-types'

import useTransactions from '~/hooks/useTransactions'

const { TabPane } = Tabs

const Extract = ({ open, close, userId, accountsPrivate }) => {
  const [userName, setUserName] = useState('')
  const dateForList = (date) => format(new Date(date), 'dd/MM/yyyy HH:mm')

  const {
    getUsersPointsTransactions,
    getUsersTransactions,
    pointsTransactions,
    transactions,
    loadingTransaction,
  } = useTransactions()

  function afterOpenDrawer() {
    getUsersTransactions({ userId })
    setUserName(accountsPrivate.find((item) => item.uid === userId).name)
  }

  function onChange(keyTab) {
    if (keyTab === '1') {
      getUsersTransactions({ userId })
    }

    if (keyTab === '2') {
      getUsersPointsTransactions({ userId })
    }
  }

  return (
    <Drawer
      size="large"
      destroyOnClose
      visible={open}
      onClose={close}
      afterVisibleChange={afterOpenDrawer}
      title={`Extrato do Usuário - ${userName}`}
    >
      <Tabs
        defaultActiveKey="1"
        onChange={(keyTab) => onChange(keyTab)}
        centered
      >
        <TabPane tab="Em Dinheiro" key="1">
          <List
            loading={loadingTransaction}
            bordered
            size="small"
            dataSource={transactions}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={`${item.mission_name} (${item.status || 'Aprovado'})`}
                  description={`R$ ${item.mission_value} - ${dateForList(
                    item.created_at
                  )}`}
                />
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="Em Pontos" key="2">
          <List
            loading={loadingTransaction}
            bordered
            size="small"
            dataSource={pointsTransactions}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={`${item.description || 'Pts'}  (${item.status})`}
                  description={`${item.value} Pontos - ${dateForList(
                    item.created_at
                  )}`}
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  )
}

Extract.propTypes = {
  open: t.bool.isRequired,
  close: t.func.isRequired,
  userId: t.string.isRequired,
  accountsPrivate: t.instanceOf(Array),
}

export default Extract

import React from 'react'
import { Popup, Marker } from 'react-map-gl'

import { format } from 'date-fns'
import t from 'prop-types'

import { feedLabel, feedIcon, feedColor } from '~/utils/feedsActions'

const GOOGLE_MAPS_URL = 'https://www.google.com/maps/?q='

const markerStyles = {
  width: 50,
  height: 50,
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  borderEndEndRadius: 10,
  borderTopLeftRadius: 25,
  borderTopRightRadius: 25,
  borderBottomLeftRadius: 25,
}

const FeedsItem = ({ item, index }) => {
  const { selected, lat, lng, action } = item
  const label = `(${lat}, ${lng})`
  const url = `${GOOGLE_MAPS_URL}${lat}, ${lng}`

  return (
    <div key={`key-${index}`}>
      {selected && (
        <Popup
          anchor="top"
          tipSize={10}
          offsetTop={35}
          latitude={lat}
          longitude={lng}
          offsetLeft={17}
          closeButton
          closeOnClick={false}
          onClose={() => selected !== index}
        >
          <div style={{ fontSize: 13, textAlign: 'center' }}>
            <h5>{feedLabel(action)}</h5>
            <p> {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')} </p>
            <p>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {label}
              </a>
            </p>
          </div>
        </Popup>
      )}
      <Marker
        offsetTop={0}
        offsetLeft={0}
        latitude={lat}
        longitude={lng}
        key={Number(index)}
        className="marker"
      >
        <div style={{ backgroundColor: feedColor[action], ...markerStyles }}>
          {feedIcon[action]}
        </div>
      </Marker>
    </div>
  )
}

FeedsItem.propTypes = {
  item: t.objectOf(t.any).isRequired,
  index: t.string.isRequired,
}

export default FeedsItem

import React from 'react'

import { Form, Select } from 'antd'
import t from 'prop-types'

export function OrderBy({ onChange }) {
  return (
    <Form.Item name="order">
      <Select
        placeholder="Ordenar Por"
        onChange={(value) => onChange('order', value)}
        options={[
          {
            label: 'Criado Em',
            value: 'created_at',
          },
          {
            label: 'Expirado Em',
            value: 'expired_at',
          },
        ]}
      />
    </Form.Item>
  )
}

OrderBy.propTypes = {
  onChange: t.func,
}

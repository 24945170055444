import React from 'react'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Row, Space, Button } from 'antd'
import t from 'prop-types'

const HeaderActions = ({
  updateStoresStatus,
  isApproveLoading,
  isRepproveLoading,
  isStoresLoading,
  selectedRowKeys,
  itemsCount,
  resetFilters,
  allRowsSelected,
}) => {
  async function approveAll() {
    await updateStoresStatus({
      approved_all: true,
    })

    return resetFilters()
  }

  async function reproveAll() {
    await updateStoresStatus({
      reproved_all: true,
    })

    return resetFilters()
  }

  async function approveSelected() {
    await updateStoresStatus({
      workforce_stores_requests_ids: selectedRowKeys,
      status: 'APPROVED',
    })

    return resetFilters()
  }

  async function reproveSelected() {
    await updateStoresStatus({
      workforce_stores_requests_ids: selectedRowKeys,
      status: 'REPROVED',
    })

    return resetFilters()
  }

  return (
    <Row justify="space-between">
      <div>
        {selectedRowKeys.length > 0 && (
          <Space>
            <Button
              icon={<CheckOutlined />}
              shape="round"
              type="primary"
              onClick={allRowsSelected ? approveAll : approveSelected}
              loading={isApproveLoading}
              disabled={
                isApproveLoading || isRepproveLoading || isStoresLoading
              }
            >
              Aprovar {allRowsSelected ? 'todos' : 'selecionados'}
            </Button>
            <Button
              icon={<CloseOutlined />}
              shape="round"
              type="danger"
              onClick={allRowsSelected ? reproveAll : reproveSelected}
              loading={isRepproveLoading}
              disabled={
                isRepproveLoading || isApproveLoading || isStoresLoading
              }
            >
              Reprovar {allRowsSelected ? 'todos' : 'selecionados'}
            </Button>
          </Space>
        )}
        <span style={{ marginLeft: 8 }}>
          {selectedRowKeys.length > 0 &&
            `Itens selecionados ${
              allRowsSelected ? itemsCount : selectedRowKeys.length
            }`}
        </span>
      </div>
    </Row>
  )
}

HeaderActions.propTypes = {
  updateStoresStatus: t.func,
  isApproveLoading: t.bool,
  isRepproveLoading: t.bool,
  isStoresLoading: t.bool,
  selectedRowKeys: t.arrayOf(),
  itemsCount: t.number,
  resetFilters: t.func,
  allRowsSelected: t.func,
}

export default HeaderActions

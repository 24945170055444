/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'

import { CreditCardOutlined } from '@ant-design/icons'
import { Form, Row, Modal, Button, message, Popover } from 'antd'
import { isNil } from 'ramda'

import TableComponent from '~/components/organisms/Table'
import useBudget from '~/hooks/useBudget'
import useCreditRequests from '~/hooks/useCreditRequests'

import { RequestForm } from './components/RequestForm'
import columns from './helpers/columns'

const { confirm } = Modal

function PaymentsRequests() {
  const [form] = Form.useForm()
  const { budget, loadingBudget, getBudget } = useBudget()
  const {
    creditRequests,
    loadingCredit,
    creditRequestsCount,
    getCreditRequests,
    onCreatePaymentRequest,
  } = useCreditRequests()

  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
    count: null,
    search: '',
    activePage: 1,
    totalCount: 0,
  })
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
  })

  const [open, setOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)

  function handlePagination(page) {
    const pageLimit = page.current * filters.limit
    const currentOffset = page.current === 1 ? 0 : pageLimit - filters.limit

    setFilters((prev) => ({ ...prev, offset: currentOffset }))
    setPagination((prev) => ({ ...prev, current: page.current }))

    return getCreditRequests({ limit: filters.limit, offset: currentOffset })
  }

  function onConfirmPayment(item) {
    if (isNil(item.value)) {
      return message.error('Valor não pode estar vazio!')
    }

    if (Number(item.value) < 100) {
      return message.error('Sua solicitação deve ser maior que R$ 100')
    }

    setOpen(false)
    setLoadingButton(true)

    return confirm({
      okType: 'primary',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      title: 'Gostaria de confirmar a sua solicitação de crédito?',
      content: `Valor Total R$ ${item.value} (${item.paymentType})`,
      onOk() {
        setLoadingButton(false)
        onCreatePaymentRequest(item)
        message.success('Enviando sua solicitacão...')
      },
      onCancel() {
        setLoadingButton(false)
        message.error('Procedimento cancelado!')
      },
    })
  }

  useEffect(() => {
    const unsubscribe = Promise.all([
      getCreditRequests({ limit: filters.limit, offset: 0 }),
      getBudget(),
    ])

    return () => unsubscribe
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingBudget) {
      const pageLimit = filters.offset * filters.limit
      setPagination({
        total: Number(creditRequestsCount),
        pageSize: creditRequests?.length,
        current: pagination.current > 0 ? pageLimit : 1,
      })
    }
  }, [loadingBudget]) //eslint-disable-line

  return (
    <>
      <Row justify="space-between">
        {(budget?.customer_payment_type === 3 ||
          budget?.customer_payment_type === 4) && (
          <Popover
            open={open}
            trigger="click"
            placement="leftTop"
            title="Dados do Pagamento"
            content={
              <RequestForm
                form={form}
                loadingButton={loadingButton}
                onClose={() => setOpen(false)}
                submit={(item) => onConfirmPayment(item)}
              />
            }
          >
            <Button
              shape="round"
              type="primary"
              icon={<CreditCardOutlined />}
              onClick={() => setOpen(true)}
            >
              Solicitar Pagamento
            </Button>
          </Popover>
        )}
      </Row>

      {creditRequests.length > 0 && (
        <TableComponent
          columns={columns}
          data={creditRequests}
          loading={loadingCredit}
          total={Number(creditRequestsCount)}
          current={pagination.current}
          pageSize={pagination.pageSize}
          onChangePageSize={handlePagination}
        />
      )}
    </>
  )
}

export default PaymentsRequests

import React, { useEffect } from 'react'

import { FilePdfOutlined } from '@ant-design/icons'
import { Descriptions, Divider, Button } from 'antd'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import useInvoices from '~/hooks/useInvoices'

export function Invoice({ id }) {
  const { loadingInvoices, getInvoiceByMissionsUsersId, invoices } =
    useInvoices()

  function onClick() {
    return window.open(invoices[0]?.invoice_pdf_path, '_self')
  }

  useEffect(() => {
    getInvoiceByMissionsUsersId(id)
  }, []) //eslint-disable-line

  if (loadingInvoices) {
    return <Loading />
  }

  return (
    <>
      <Button
        shape="round"
        icon={<FilePdfOutlined />}
        onClick={() => onClick()}
      >
        Baixar .PDF
      </Button>
      <Divider />
      {invoices[0]?.invoice_products?.invoice.map((i) => (
        <Descriptions title={i.category} bordered size="small" column={4}>
          {i.products.map((product) => (
            <>
              <Descriptions.Item label={product.title}>
                R${' '}
                {Number(
                  Number(product.total).toFixed(2) /
                    Number(product.installments || 1)
                ).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Qtd">
                {product.quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Parcelas">
                {product.installments || 1}
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                R$ {product.total}
              </Descriptions.Item>
            </>
          ))}
        </Descriptions>
      ))}
    </>
  )
}

Invoice.defaultTypes = {
  invoice: {},
}

Invoice.propTypes = {
  id: t.string.isRequired,
  onClick: t.func.isRequired,
}

import React from 'react'

import { Space, Button } from 'antd'
import t from 'prop-types'

export function Footer({
  current,
  setCurrent,
  items,
  loading,
  onCreate,
  isDisable,
  onInitial,
}) {
  return (
    <Space direction="horizontal">
      {current > 0 && (
        <Button
          style={{ margin: 8 }}
          onClick={() => {
            if (current === 1) {
              onInitial()
            }

            setCurrent(current - 1)
          }}
        >
          Anterior
        </Button>
      )}
      {current < items.length - 1 && (
        <Button
          type="primary"
          onClick={() => setCurrent(current + 1)}
          disabled={isDisable}
        >
          Próximo
        </Button>
      )}
      {current === items.length - 1 && (
        <Button
          type="primary"
          loading={loading}
          disable={loading}
          onClick={onCreate}
        >
          Finalizar
        </Button>
      )}
    </Space>
  )
}

Footer.propTypes = {
  current: t.number,
  setCurrent: t.func,
  loading: t.bool,
  onCreate: t.func,
  isDisable: t.bool,
  onInitial: t.func,
  items: t.arrayOf(
    t.shape({ title: t.string, description: t.string, key: t.string })
  ),
}

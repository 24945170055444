import React from 'react'
import { FixedSizeList as List } from 'react-window'

import { Carousel, Card, Progress, Row, Col } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import CartItem from '../CardItem'
import { Counter } from '../Counter'
import { ContainerHeader, HeaderText, styleDateCard } from './styles'

function CardDays({ usersData, dateList, adherence, totalRegisters, ratings }) {
  const getWidth = () => {
    if (window.screen.width <= 1360) {
      return '245px'
    }
    return '100%'
  }

  return (
    <Carousel draggable dots={false} infinite={false} slidesPerRow={4}>
      {dateList.map((date) => (
        <Card hoverable bodyStyle={styleDateCard} key={date}>
          <ContainerHeader>
            <HeaderText>{dayjs(date).format('DD/MM/YYYY')}</HeaderText>
            {adherence && (
              <Progress
                percent={adherence[date]}
                status="active"
                strokeColor={{ from: '#108ee9', to: '#3ad089' }}
              />
            )}
            <Row>
              <Col span={6}>
                <Counter
                  description="Missões Disponíveis"
                  iconName="totalDisponible"
                  iconColor="grey"
                  text={totalRegisters?.totalRegistersPerDay[date]}
                />
              </Col>
              <Col span={6}>
                <Counter
                  description="Missões em Progresso"
                  iconName="totalInProgress"
                  iconColor="blue"
                  text={totalRegisters?.totalRegistersInProgressPerDay[date]}
                />
              </Col>
              <Col span={6}>
                <Counter
                  description="Missões com Faltas"
                  iconName="totalMissed"
                  iconColor="red"
                  text={totalRegisters?.totalRegistersMissedPerDay[date]}
                />
              </Col>
              <Col span={6}>
                <Counter
                  description="Média das Avaliações"
                  iconName="avgRating"
                  iconColor="#F3D500"
                  text={ratings?.ratingsPerDay[date]}
                />
              </Col>
            </Row>
          </ContainerHeader>

          <List
            height={500}
            itemCount={usersData[date]?.length}
            itemSize={150}
            width={getWidth()}
          >
            {(props) =>
              CartItem({ ...props, users: usersData[date], date, ratings })
            }
          </List>
        </Card>
      ))}
    </Carousel>
  )
}

CardDays.propTypes = {
  usersData: t.objectOf(t.string),
  dateList: t.arrayOf(t.string),
  adherence: t.objectOf(t.string),
  totalRegisters: t.objectOf(t.string),
  ratings: t.objectOf(t.string),
}

export default CardDays

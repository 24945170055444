/* eslint-disable no-nested-ternary */
import React from 'react'
import { Bar } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import t from 'prop-types'

import { backgroundColors } from '../Colors'
import { Container } from './styles'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export function BarAvg({ data }) {
  const labels = Object.keys(data)
    .flatMap((i) => data[i])
    .map((i) => i.answer)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  }

  const items = Object.keys(data).flatMap((i) => data[i])

  const datasets = items.map((b, idx) => ({
    data: items.map((i) => (i.answer === b.answer ? b.percent : null)),
    label: b.answer,
    backgroundColor: backgroundColors[idx],
    datalabels: {
      color: 'white',
      labels: {
        title: {
          font: {
            size: 18,
            weight: 'bold',
          },
        },
      },
      formatter(value) {
        return value === null ? '' : `${value}%`
      },
    },
  }))

  const dataOptions = {
    labels,
    datasets,
  }

  return (
    <Container>
      <Bar height="300px" options={options} data={dataOptions} />
    </Container>
  )
}

BarAvg.propTypes = {
  data: t.arrayOf([]),
}

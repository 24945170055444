/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { ExcelRenderer } from 'react-excel-renderer'

import { DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'

import useImport from '~/hooks/useImport'
import { cleanString, mapRowsToObject } from '~/utils/functions'

export function UploadXls() {
  const { onSubmitImport, loadingImport } = useImport()

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]

      ExcelRenderer(file, (err, resp) => {
        if (err) {
          return message.error(err)
        }

        if (resp.rows.length > 4000) {
          return message.error('Arquivo não pode ter mais de 4000 linhas')
        }

        const rows = mapRowsToObject(resp.rows).filter(
          (i) => i.activity !== undefined
        )

        onSubmitImport({ rows, filename: cleanString(file?.name) })
      })
    },
    [onSubmitImport] //eslint-disable-line
  )

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onDrop(acceptedFiles)
    }
  }, [acceptedFiles]) //eslint-disable-line

  return (
    <div {...getRootProps()}>
      <input
        {...getInputProps()}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Button
        onClick={open}
        loading={loadingImport}
        disabled={loadingImport}
        icon={<DownloadOutlined />}
      >
        Importar
      </Button>
    </div>
  )
}

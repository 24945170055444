import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'
import {
  REPORTS_REGISTERS_DOSSIE,
  REPORTS_REGISTERS_MAIL,
} from '~/services/api/endpoints'

function useExportRegister() {
  const [loadingExportRegister, setLoadingExportRegister] = useState(false)
  const [loadingMailRegister, setLoadingMailRegister] = useState(false)
  const [hasGenerated, setHasGenerated] = useState(null)

  const onSubmitExportRegister = useCallback(async (item) => {
    message.info('Aguarde, O dossiê está sendo gerado e pode levar de 10-60s')
    setLoadingExportRegister(true)
    try {
      const res = await api.get(`${REPORTS_REGISTERS_DOSSIE}${item.uid}`, {
        params: {
          missionsUsersId: item.missions_users_id,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status === 500) {
        throw new Error(res.data.message)
      }

      setHasGenerated(res.data.register.url_mission_report)

      window.open(res.data.register.url_mission_report, '_blank')
      return setLoadingExportRegister(false)
    } catch (error) {
      setLoadingExportRegister(false)
      return message.error(error.message)
    }
  }, [])

  const onSubmitMailRegister = useCallback(async ({ registerId, to }) => {
    setLoadingMailRegister(true)
    try {
      const res = await api.post(
        REPORTS_REGISTERS_MAIL,
        { registerId, to },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status === 500) {
        throw new Error(res.data.message)
      }

      message.success(res.data.message)
      return setLoadingMailRegister(false)
    } catch (error) {
      setLoadingMailRegister(false)
      return message.error(error.message)
    }
  }, [])

  return {
    hasGenerated,
    loadingMailRegister,
    loadingExportRegister,
    onSubmitMailRegister,
    onSubmitExportRegister,
  }
}

export default useExportRegister

/* eslint-disable no-nested-ternary */
import React from 'react'
import { Bar } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import t from 'prop-types'

import { backgroundColors } from '../Colors'
import { Container } from './styles'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export function BarCount({ data }) {
  const labelInfo = (label) =>
    label?.uf || label?.group_name || label?.answer || 'Não Localizada'

  const labels = data?.map((i) =>
    labelInfo(i).length > 50
      ? `${labelInfo(i).substring(0, 50)}...`
      : labelInfo(i)
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    skipNull: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  const datasets = data?.map((i, idx) => ({
    data: data.map((d) =>
      labelInfo(d) === labelInfo(data[idx]) ? d.count : null
    ),
    backgroundColor: backgroundColors[idx],
    label: `${
      labelInfo(i).length > 50
        ? `${labelInfo(i).substring(0, 50)}...`
        : labelInfo(i)
    }`,
    datalabels: {
      color: 'white',
      labels: {
        title: {
          font: {
            size: 18,
            weight: 'bold',
          },
        },
      },
    },
  }))

  return (
    <Container>
      <Bar options={options} height={400} data={{ labels, datasets }} />
    </Container>
  )
}

BarCount.propTypes = {
  data: t.arrayOf([]),
}

import React from 'react'
import ReactQuill from 'react-quill'

import {
  Input,
  Row,
  Col,
  Form,
  Drawer,
  Skeleton,
  Button,
  DatePicker,
} from 'antd'
import t from 'prop-types'
import { omit } from 'ramda'

import UploadImage from '~/components/molecules/UploadImage'

function ProductForm({
  form,
  item,
  open,
  close,
  loading,
  onChange,
  handleSubmit,
  onUploadFile,
}) {
  return (
    <Drawer
      width={720}
      onClose={close}
      destroyOnClose="true"
      visible={open.visible}
      title={item?.title || 'Categoria de Produto'}
    >
      <Skeleton loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={omit(['expired_at'], item)}
          onFinish={(values) => handleSubmit(values)}
        >
          <Row gutter={[8, 8]}>
            <Col span={5}>
              <UploadImage
                id={item.uid}
                loading={false}
                imagePath={item.image_path}
                onUploadFile={onUploadFile}
              />
            </Col>
            <Col span={19}>
              <Form.Item label="Nome do Produto" name="title">
                <Input />
              </Form.Item>
              <Form.Item label="Descrição" name="description">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Preço" name="price">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Parcelas (x)" name="installments">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Expira Em" name="expired_at">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Desconto a partir de" name="quantity_discount">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Valor do desconto" name="value_discount">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Condições Especiais">
                {!loading && (
                  <ReactQuill
                    defaultValue={item.special_conditions}
                    onChange={(value) => onChange('special_conditions', value)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button shape="round" type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  )
}

ProductForm.defaultTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  open: t.objectOf(t.any).isRequired,
  close: t.func.isRequired,
  onChange: t.func.isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
  onUploadFile: t.func.isRequired,
}

ProductForm.propTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  open: t.objectOf(t.any).isRequired,
  close: t.func.isRequired,
  onChange: t.func.isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
  onUploadFile: t.func.isRequired,
}

export default ProductForm

import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import { ENVIRONMENT } from '~/config/env'

import createStore from './createStore'
import rootReducer from './ducks/rootReducer'
import rootSaga from './ducks/rootSaga'
import persistReducers from './persistReducers'

const sagaMonitor =
  ENVIRONMENT === 'development' ? console.tron.createSagaMonitor() : null
const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

const middlewares = [thunk, sagaMiddleware]

const store = createStore(persistReducers(rootReducer), middlewares)
const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }

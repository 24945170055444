/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react'

import { Form } from 'antd'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'

import TableComponent from '~/components/organisms/Table'
import useBudget from '~/hooks/useBudget'
import useCustomersTransactions from '~/hooks/useCustomersTransactions'
import useMetrics from '~/hooks/useMetrics'
import useReceiptMission from '~/hooks/useReceiptMission'
import useTransactions from '~/hooks/useTransactions'

import Header from './components/Header'
import Statistics from './components/Statistics'
import columns from './helpers/columns'

dayjs.extend(weekday)
dayjs.extend(localeData)

export default function Transactions() {
  const [form] = Form.useForm()

  const { loadingReceipt, generateCustomerReceipt } = useReceiptMission()
  const { dashboard, getMetricsDashboard } = useMetrics()
  const {
    transactions,
    getTransactions,
    loadingTransactions,
    transactionsCount,
  } = useCustomersTransactions()
  const { budget, loadingBudget, getBudget } = useBudget()

  const { getReport, loadingTransaction } = useTransactions()

  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
    count: null,
    search: '',
    activePage: 1,
    totalCount: 0,
  })
  const [range, setRange] = useState({
    initial: dayjs().startOf('month').format('YYYY-MM-DD'),
    final: dayjs().endOf('month').format('YYYY-MM-DD'),
  })
  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 20,
    current: 1,
  })

  const onRefresh = useCallback(() => {
    getTransactions({ ...filters, ...range })
  }, [filters, range]) //eslint-disable-line

  function handleUserReceipt(id) {
    return generateCustomerReceipt(id)
  }

  function onChangePage(page) {
    const pageLimit = page.current * filters.limit
    const currentOffset = page.current === 1 ? 0 : pageLimit - filters.limit

    setFilters((prev) => ({ ...prev, offset: currentOffset }))
    setPagination((prev) => ({ ...prev, current: page.current }))

    return getTransactions({
      ...filters,
      ...range,
      limit: filters.limit,
      offset: currentOffset,
    })
  }

  function onExportTransactions(params) {
    if (params.initial && params.final) {
      params.initial = dayjs(new Date(params.initial)).format('YYYY-MM-DD')
      params.final = dayjs(new Date(params.final)).format('YYYY-MM-DD')
    }

    return getReport(params)
  }

  function onSubmitFilter() {
    setRange((prev) => ({ ...prev, ...range }))

    return getTransactions({ ...filters, ...range })
  }

  useEffect(() => {
    const unsubsribe = Promise.all([
      getMetricsDashboard(range),
      getBudget(),
      onRefresh(),
    ])

    return () => unsubsribe
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!loadingTransactions) {
      setPagination({
        total: Number(transactionsCount),
        pageSize: filters.limit,
      })
    }
  }, [loadingTransactions]) //eslint-disable-line

  const dataInfo = {
    limit: budget?.limit,
    amount: budget?.amount,
    type: budget?.payment_type,
    budgetDebit: dashboard?.budget_debit,
    budgetCredit: dashboard?.budget_credit,
  }

  return (
    <>
      <Statistics statistic={dataInfo} loading={loadingBudget} />

      <Header
        range={range}
        form={form}
        loadingExport={loadingTransaction}
        loadingFilter={loadingTransactions}
        onExport={(values) => onExportTransactions(values)}
        onSubmitFilter={() => onSubmitFilter()}
        onChangeFilters={(value) => {
          setRange((prev) => ({
            ...prev,
            initial: dayjs(value[0]?.$d).format('YYYY-MM-DD'),
            final: dayjs(value[1]?.$d).format('YYYY-MM-DD'),
          }))
        }}
      />

      <TableComponent
        data={transactions}
        total={Number(transactionsCount)}
        current={pagination.current}
        pageSize={pagination.pageSize}
        columns={columns({ handleUserReceipt })}
        onChangePageSize={onChangePage}
        loading={loadingTransactions || loadingReceipt}
      />
    </>
  )
}

import { useState } from 'react'

import { message } from 'antd'
import * as FilerSaver from 'file-saver'

import api from '~/services/api'
import { FORMS_ITEMS_MULTIPLES } from '~/services/api/endpoints'

export default function useFormsItems() {
  const [formsItems, setFormsItems] = useState([])
  const [loadingFormsItems, setLoadingForms] = useState(false)
  const [loadingMultiples, setLoadingMultiples] = useState(false)
  const [loadingRatio, setLoadingRatio] = useState(true)
  const [avgData, setAvgData] = useState([])
  const [ratioData, setRatioData] = useState([])
  const [urlImage, setUrlImage] = useState('')
  const [generalScore, setGeneralScore] = useState({})
  const [loadingImport, setLoadingImport] = useState(false)

  async function onRemoveMultiple(id) {
    try {
      setLoadingMultiples(true)

      const res = await api.delete(`${FORMS_ITEMS_MULTIPLES}${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      message.success('Item removido com sucesso!')

      setLoadingMultiples(false)

      return res.data
    } catch (error) {
      setLoadingMultiples(false)
      return message.error(error.message)
    }
  }

  async function getFormsItems(params) {
    try {
      setLoadingForms(true)
      const res = await api.get('forms-items/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFormsItems(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function getSectionAvg(formsId) {
    try {
      const res = await api.get('forms-items/avg', {
        params: formsId,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setAvgData(res.data.avgScoreWithSectionName)
    } catch (error) {
      return message.error(error.message)
    }
  }

  async function getSectionRatio(params) {
    try {
      setLoadingRatio(true)
      const res = await api.get('forms-items/customers/rating-sections', {
        params: {
          formsId: params.formsId,
          missionsUsersId: params.missionsUsersId,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setGeneralScore(res.data.totalGeneral)

      return setRatioData(res.data.scoreUser)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingRatio(false)
    }
  }

  async function exportFormsItemsTemplate(formsId) {
    try {
      setLoadingForms(true)
      const res = await api.get('/forms-items/customers/export', {
        params: { formsId },
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })
      if (res.status !== 200) {
        throw new Error(res.data.message)
      }
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })
      FilerSaver.saveAs(blob, `forms-items-template-${Date.now()}`)
      return message.success('Exportação realizada com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function importFormsItemsTemplate(params) {
    try {
      setLoadingImport(true)

      const data = new FormData()

      data.append('file', params.file.originFileObj)
      data.append('formsId', params.id)

      const res = await api.post('/forms-items/customers/import', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      await getFormsItems({ formId: params.id })

      return message.success('Formulário importado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingImport(false)
    }
  }

  async function createFormsItems(params) {
    try {
      setLoadingForms(true)

      const res = await api.post('/forms-items/customers', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFormsItems((prev) => [...prev, res.data[0]])

      return message.success('Item de formulário criado com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingForms(false)
    }
  }

  async function changeOrder(params) {
    try {
      setLoadingForms(true)

      const items = params.map((item, index) => ({
        ...item,
        order: index + 1,
      }))

      const res = await api.put(
        'forms-items/customers/change-order',
        { items },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setFormsItems(items)
    } catch (error) {
      return message.error(
        error.message || 'Houve um problema para alterar a ordem dos itens'
      )
    } finally {
      setLoadingForms(false)
    }
  }

  async function removeFormsItems(params) {
    try {
      setLoadingForms(true)

      const res = await api.delete('forms-items/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const newFormsItemsList = formsItems.filter(
        (formItem) => formItem.uid !== res.data[0].uid
      )

      setFormsItems(newFormsItemsList)

      return message.success('Item removido com sucesso')
    } catch (error) {
      return message.error(
        error.message || 'Houve um problema para remover o item'
      )
    } finally {
      setLoadingForms(false)
    }
  }

  async function uploadImage(params) {
    try {
      setLoadingForms(true)

      const formData = new FormData()

      formData.append('file', params.file.originFileObj)
      formData.append('formsItemsId', params.formsItemsId)

      const res = await api.post(
        'forms-items/customers/upload-image',
        formData,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFormsItems((prev) =>
        prev.map((item) => {
          if (item.uid === params.formsItemsId) {
            return {
              ...item,
              example: {
                ...item.example,
                image: res.data.url,
              },
            }
          }
          return item
        })
      )

      return setUrlImage(res.data.url)
    } catch (error) {
      return message.error(
        error.message || 'Houve um erro para enviar a imagem'
      )
    } finally {
      setLoadingForms(false)
    }
  }

  async function updateFormsItems(params) {
    try {
      setLoadingForms(true)

      const res = await api.put('forms-items/customers', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFormsItems((prev) =>
        prev.map((prev) => {
          if (prev.uid === res.data[0].uid) {
            return res.data[0]
          }
          return prev
        })
      )

      return message.success('Atualização registrada com sucesso')
    } catch (error) {
      return message.error(
        error.message || 'Houve um erro na atualização do item'
      )
    } finally {
      setLoadingForms(false)
    }
  }

  return {
    onRemoveMultiple,
    loadingFormsItems,
    loadingMultiples,
    getFormsItems,
    formsItems,
    getSectionAvg,
    avgData,
    getSectionRatio,
    ratioData,
    exportFormsItemsTemplate,
    importFormsItemsTemplate,
    loadingRatio,
    createFormsItems,
    changeOrder,
    removeFormsItems,
    updateFormsItems,
    uploadImage,
    urlImage,
    generalScore,
    loadingImport,
  }
}

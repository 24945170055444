/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react'

import { Button, Form, Input, List, Select, Skeleton, message } from 'antd'

import useSearchLocation from '~/hooks/useSearchLocation'
import api from '~/services/api'

const { Search } = Input

export function StoreForm({ onConfirm }) {
  const [isLoading, setLoading] = useState(false)
  const [address, setAddress] = useState(null)

  const [customers, setCustomers] = useState(null)
  const [loadingCustomers, setLoadingCustomers] = useState(false)
  const { locations, loadingSearch, onSearchAddress, onClear } =
    useSearchLocation()

  const getCustomers = useCallback(async () => {
    setLoadingCustomers(true)

    try {
      const res = await api.get(`restricted-subcategories/customers`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCustomers(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCustomers(false)
    }
  }, [])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const [form] = Form.useForm()

  async function handleSubmit() {
    setLoading(true)
    try {
      const res = await api.post('workforce-management-store', {
        name: form.getFieldValue('name'),
        identifier: form.getFieldValue('identifier'),
        missions_main_id: form.getFieldValue('category'),
        place: address.formatted_address,
        uf: address.address_components[4].short_name,
        city: address.address_components[3].long_name,
        lat: address.geometry.location.lat,
        lng: address.geometry.location.lng,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      message.success('Loja criada com sucesso!')
      onConfirm()
      return null
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label="Nome">
        <Input
          name="name"
          onChange={(e) => form.setFieldValue('name', e.target.value)}
          placeholder="Nome da Loja"
        />
      </Form.Item>
      <Form.Item label="Identificador">
        <Input
          name="identifier"
          onChange={(e) => form.setFieldValue('identifier', e.target.value)}
          placeholder="Ex: Loja-123"
        />
      </Form.Item>
      <Form.Item label="Categoria">
        <Select
          showSearch
          name="category"
          onChange={(e) => form.setFieldValue('category', e)}
          placeholder="Selecione uma categoria"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={customers?.map((item) => ({
            value: item.uid,
            label: item.title,
          }))}
        />
      </Form.Item>
      <Form.Item label="Endereço">
        {address ? (
          <Search
            allowClear
            loading={loadingSearch}
            value={address?.formatted_address}
            onSearch={() => setAddress(null)}
            placeholder="Digite Endereço e Pressione 'Enter'"
          />
        ) : (
          <Search
            allowClear
            loading={loadingSearch}
            onSearch={onSearchAddress}
            placeholder="Digite Endereço e Pressione 'Enter'"
          />
        )}
        {locations.length > 0 && (
          <List itemLayout="horizontal" size="small">
            {locations?.map((i) => (
              <List.Item
                key={i.place_id}
                onClick={() => {
                  setAddress(i)
                  form.setFieldValue('address', i.formatted_address)
                  onClear()
                  // return setChangeLocal(false)
                }}
              >
                <Skeleton loading={loadingSearch} active>
                  <List.Item.Meta title={i.formatted_address} />
                </Skeleton>
              </List.Item>
            ))}
          </List>
        )}
      </Form.Item>
      <Form.Item>
        <Button onClick={handleSubmit} type="primary">
          Criar
        </Button>
      </Form.Item>
    </Form>
  )
}

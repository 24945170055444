import React, { useEffect } from 'react'

import { Modal, Button, Space, Timeline, Empty } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import useLogs from '~/hooks/useLogs'

import { OPERATION_LABEL, STATUS_COLOR, STATUS_LABEL } from '../../../helpers'

export function LogsModal({ registerId, open, onClose }) {
  const { logs, loadingLogs, getLogsById } = useLogs()

  useEffect(() => {
    getLogsById(registerId)
  }, []) //eslint-disable-line

  return (
    <Modal
      open={open}
      title="Logs do Registro"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
      ]}
    >
      {!loadingLogs ? (
        <Space style={{ width: '100%', marginTop: 20 }} direction="vertical">
          {logs.length === 0 ? (
            <Empty description="Nenhum log foi registrado até o momento" />
          ) : (
            <Timeline
              items={logs.map((log) => {
                const label = log?.data?.custom_status_id
                  ? log?.data?.custom_status_label
                  : STATUS_LABEL[log?.data?.status]

                return {
                  color: STATUS_COLOR[log?.data?.status] || '#737373',
                  children: (
                    <span>
                      O registro foi <b>{OPERATION_LABEL[log?.operation]}</b>{' '}
                      para <b>{label || 'Status Não Encontrado'}</b> às{' '}
                      <b>{dayjs(log?.updatedAt).format('DD/MM/YYYY HH:mm')}</b>
                    </span>
                  ),
                }
              })}
            />
          )}
        </Space>
      ) : (
        <Loading />
      )}
    </Modal>
  )
}

LogsModal.propTypes = {
  open: t.bool,
  onClose: t.func,
  registerId: t.string,
}

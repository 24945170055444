import React from 'react'

import { Button, Form, Input } from 'antd'
import t from 'prop-types'

import LogoMission from '~/assets/images/logo.png'
import {
  HeaderBox,
  HeaderText,
  Avatar,
  SmallLogoMission,
  Mobile,
  Text,
  AvatarBox,
  FormBox,
} from '~/pages/Referral/UserData/styles'

const UserData = ({ form, loading, handleSubmit, userData }) => (
  <>
    <HeaderBox>
      <Mobile
        src="https://www.missionbrasil.com.br/wp-content/uploads/2021/01/Passo-03-1.png"
        alt="cel"
      />
      <HeaderText style={{ marginLeft: '20px' }}>
        <Text bold largeText>
          Faça parte!
        </Text>
        <Text>
          A Mission Brasil é a maior plataforma do Brasil com o conceito{' '}
          <strong>JOB ON DEMAND</strong>.
        </Text>
        <Text>
          Somos o <strong>TRABALHO do futuro!</strong>
        </Text>
      </HeaderText>
      <SmallLogoMission
        src="https://www.missionbrasil.com.br/wp-content/uploads/2021/01/logo-mission.png"
        alt="logo"
      />
    </HeaderBox>
    <AvatarBox>
      <Avatar src={userData?.avatar} alt="logo-user" />
      <Avatar src={LogoMission} alt="logo" />
    </AvatarBox>
    <FormBox>A {userData?.name} é parceira da Mission Brasil</FormBox>

    <FormBox>
      <h1>CADASTRE-SE</h1>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Nome Completo"
          name="name"
          rules={[
            {
              required: true,
              message: 'Digite seu nome completo',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Digite seu email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nova Senha"
          name="password"
          rules={[
            {
              required: true,
              message: 'Digite sua senha',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmar Senha"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Digite sua senha',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(
                  new Error('As senhas devem estar iguais!')
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Cadastre-se
          </Button>
        </Form.Item>
      </Form>
    </FormBox>
  </>
)

UserData.propTypes = {
  form: t.objectOf(t.any),
  loading: t.bool,
  handleSubmit: t.func,
  userData: t.objectOf(t.any),
}

export default UserData

/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react'

import { MoreOutlined, PlusOutlined, MonitorOutlined } from '@ant-design/icons'
import { Dropdown, Empty, Tag } from 'antd'
import dayjs from 'dayjs'
import debounce from 'lodash.debounce'
import moment from 'moment'

import Images from '~/assets/images'
import Table from '~/components/organisms/Table'
import useBonusPoints from '~/hooks/useBonusPoints'
import useCategories from '~/hooks/useCategories'
import useCustomersAccountsPrivate from '~/hooks/useCustomersAccountsPrivate'
import useCustomersUsersTags from '~/hooks/useCustomersUsersTags'
import useExtraCredit from '~/hooks/useExtraCredit'
import useMetrics from '~/hooks/useMetrics'

import Extract from './components/Extract'
import Header from './components/Header'
import ModalBonusPoints from './components/ModalBonusPoints'
import ModalConvertPoints from './components/ModalConvertPoints'
import ModalPromotionalBonus from './components/ModalPromotionalBonus'
import SelectedActions from './components/SelectedActions'
import { Statistics } from './components/Statistics'

export default function AccountsPrivate() {
  const { getCategories, categories } = useCategories()
  const {
    countData,
    accountsPrivate,
    loadingAccountsPrivate,
    getCustomersAccountsPrivate,
    getCustomersAccountsPrivateXls,
  } = useCustomersAccountsPrivate()
  const {
    loadingCredit,
    onSubmitCreditPromocional,
    onSubmitValuePerPoints,
    onSubmitValuePerPointsAll,
  } = useExtraCredit()
  const {
    loadingTags,
    customersUsersTags,
    getCustomersUsersTags,
    customersUsersTagsName,
    onUpdateCustomersUsersTags,
    onSubmitCustomersUsersTags,
  } = useCustomersUsersTags()
  const {
    onSubmitBonusPoints,
    bonusPoints,
    setBonusPoints,
    loadingBonusPoints,
  } = useBonusPoints()
  const { getMetricsDashboardAccountsPrivate, dashboardAccountsPrivate } =
    useMetrics()

  const [openExtract, setOpenExtract] = useState(false)
  const [valuePerPoints, setValuePerPoints] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [openModalCredit, setOpenModalCredit] = useState(false)
  const [openModalConvertPoints, setOpenModalConvertPoints] = useState({
    all: false,
    open: false,
  })
  const [filters, setFilters] = useState({
    limit: 50,
    offset: 0,
    search: '',
    order: 'desc',
    hasBonus: false,
    missionsMainId: null,
    column: 'total_points',
    start: moment().startOf('month').format('YYYY-MM-DD'),
    final: moment().endOf('month').format('YYYY-MM-DD'),
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: filters.limit,
  })
  const [openModalBonusPoints, setOpenModalBonusPoints] = useState({
    userId: null,
    open: false,
  })
  const [userDataCredit, setUserDataCredit] = useState({
    userId: null,
    value: 0,
  })
  const orderFilter = { desc: 'descend', asc: 'ascend' }

  function handleSearch(value) {
    setFilters((prev) => ({ ...prev, search: value }))

    return getCustomersAccountsPrivate({ ...filters, search: value })
  }

  async function onSubmitFilter() {
    await getMetricsDashboardAccountsPrivate({
      initial: filters.start,
      final: filters.final,
    })

    return getCustomersAccountsPrivate(filters)
  }

  function onSubmitExport() {
    return getCustomersAccountsPrivateXls({
      ...filters,
      data: 'xls',
      limit: countData,
    })
  }

  function onChangeCreditPromotionalBonus(value) {
    return setUserDataCredit((prev) => ({
      ...prev,
      value: value.floatValue,
    }))
  }

  function onChangeBonusPoints(value) {
    return setBonusPoints(value)
  }

  function onChangeConvertPointsValues(value) {
    return setValuePerPoints(value.floatValue)
  }

  function handlePagination(page, _filter, sorter) {
    const pageLimit = page.current * filters.limit
    const currentOffset = page.current === 1 ? 0 : pageLimit - filters.limit
    const direction = sorter.order === 'ascend' ? 'asc' : 'desc'

    setFilters((prev) => ({ ...prev, offset: currentOffset }))
    setPagination((prev) => ({ ...prev, current: page?.current }))

    return getCustomersAccountsPrivate({
      ...filters,
      order: direction,
      offset: currentOffset,
    })
  }

  function handleMenuClick(item, key) {
    setUserDataCredit((prev) => ({ ...prev, userId: item.uid }))

    if (key === '1') {
      return setOpenModalBonusPoints({ userId: item.uid, open: true })
    }

    if (key === '2') {
      return setOpenModalCredit(true)
    }

    return setOpenExtract(true)
  }

  async function onSubmit() {
    await onSubmitCreditPromocional(userDataCredit)

    setUserDataCredit((prev) => ({ ...prev, userId: null, value: 0 }))

    await getCustomersAccountsPrivate(filters)

    return setOpenModalCredit(false)
  }

  async function onSubmitBonus() {
    await onSubmitBonusPoints({ userIds: [openModalBonusPoints.userId] })

    await getCustomersAccountsPrivate(filters)

    return setOpenModalBonusPoints((prev) => ({
      ...prev,
      open: false,
    }))
  }

  async function onRefreshConvertPoints() {
    setValuePerPoints(0)
    setSelectedRowKeys([])

    await getCustomersAccountsPrivate(filters)

    await getMetricsDashboardAccountsPrivate({
      initial: filters.start,
      final: filters.final,
    })

    return setOpenModalConvertPoints((prev) => ({
      ...prev,
      open: false,
    }))
  }

  async function onSubmitConvertPoints() {
    if (openModalConvertPoints.all) {
      await onSubmitValuePerPointsAll({
        valuePerPoints,
        start: filters.start,
        final: filters.final,
        hasBonus: filters.hasBonus,
        missionsMainId: filters.missionsMainId,
      })

      return onRefreshConvertPoints()
    }

    await onSubmitValuePerPoints({
      valuePerPoints,
      start: filters.start,
      final: filters.final,
      hasBonus: filters.hasBonus,
      missionsMainId: filters.missionsMainId,
      userIds: selectedRowKeys.map((i) => i.uid),
    })

    return onRefreshConvertPoints()
  }

  function onChangeFilters(values) {
    return setFilters((prev) => ({ ...prev, ...values }))
  }

  const menuItems = (item) => ({
    onClick: ({ key }) => handleMenuClick(item, key),
    items: [
      {
        key: 1,
        icon: <PlusOutlined />,
        label: 'Bônus em Pontos',
      },
      {
        key: 2,
        icon: <PlusOutlined />,
        label: 'Bônus em R$',
      },
      {
        key: 3,
        icon: <MonitorOutlined />,
        label: 'Visualizar Extrato',
      },
    ],
  })

  const debounceSearch = useMemo(() => {
    return debounce(handleSearch, 1000)
  }, []) //eslint-disable-line

  const rowSelection = {
    selectedRowKeys,
    onChange: (items) => setSelectedRowKeys(items),
  }

  useEffect(() => {
    Promise.all([
      getCustomersAccountsPrivate(filters),
      getCustomersUsersTags(),
      getCategories(),
      getMetricsDashboardAccountsPrivate({
        initial: '2016-01-01',
        final: dayjs().format('YYYY-MM-DD'),
      }),
    ])
  }, []) //eslint-disable-line

  return (
    <>
      <Statistics
        totalUsers={dashboardAccountsPrivate?.total_users || 0}
        totalPoints={dashboardAccountsPrivate?.total_points || 0}
      />

      <Header
        filters={filters}
        categories={categories}
        loadingTags={loadingTags}
        handleSearch={debounceSearch}
        onSubmitExport={() => onSubmitExport()}
        onSubmitFilter={() => onSubmitFilter()}
        onChangeFilters={(values) => onChangeFilters(values)}
        loadingFilter={loadingAccountsPrivate}
        customersUsersTagsName={customersUsersTagsName}
        customersUsersTags={customersUsersTags}
        getCustomersUsersTags={getCustomersUsersTags}
        onUpdateCustomersUsersTags={onUpdateCustomersUsersTags}
        onSubmitCustomersUsersTags={onSubmitCustomersUsersTags}
        onOpen={() => setOpenModalConvertPoints({ all: true, open: true })}
      />

      {selectedRowKeys.length > 0 && (
        <SelectedActions
          selectedKeys={selectedRowKeys}
          onOpenModal={() =>
            setOpenModalConvertPoints({ all: false, open: true })
          }
        />
      )}

      {accountsPrivate?.length > 0 && (
        <Table
          data={accountsPrivate}
          rowSelection={rowSelection}
          loading={loadingAccountsPrivate}
          columns={[
            {
              key: 1,
              title: 'Nome',
              dataIndex: 'name',
              sorter: true,
              columnKey: 'u.name',
              sortOrder:
                filters.column === 'u.name' ? orderFilter[filters.order] : null,
            },
            {
              key: 2,
              title: 'E-mail',
              dataIndex: 'email',
              sorter: true,
              columnKey: 'u.email',
              sortOrder:
                filters.column === 'u.email'
                  ? orderFilter[filters.order]
                  : null,
            },
            {
              key: 3,
              title: 'Status',
              dataIndex: 'status',
              render: (item) => (
                <Tag color={!item ? 'yellow' : 'green'}>
                  {!item ? 'Pendente' : 'Ativo'}
                </Tag>
              ),
            },
            {
              key: 4,
              title: 'Balanço Ponto(s)',
              dataIndex: 'current_total_points',
              render: (item) => item || 0,
              sorter: true,
              columnKey: 'current_total_points',
              sortOrder:
                filters.column === 'current_total_points'
                  ? orderFilter[filters.order]
                  : null,
            },
            {
              key: 5,
              title: 'Total de Ponto(s)',
              dataIndex: 'total_points',
              render: (item) => item || 0,
              sorter: true,
              columnKey: 'total_points',
              sortOrder:
                filters.column === 'total_points'
                  ? orderFilter[filters.order]
                  : null,
            },
            {
              key: 6,
              title: 'Total de Bônus',
              dataIndex: 'bonus',
              render: (item) => item || 0,
              sorter: true,
              columnKey: 'bonus',
              sortOrder:
                filters.column === 'bonus' ? orderFilter[filters.order] : null,
            },
            {
              key: 7,
              title:
                customersUsersTags === null
                  ? 'Campo Adicional'
                  : customersUsersTags?.name,
              dataIndex: 'customers_users_tags_id',
              sorter: true,
              columnKey: 'u.customers_users_tags_id',
              sortOrder:
                filters.column === 'u.customers_users_tags_id'
                  ? orderFilter[filters.order]
                  : null,
              render: (item) => {
                const tag = customersUsersTags?.find((i) => i.id === item)
                return (
                  <Tag color={!item ? 'red' : 'blue'}>
                    {tag?.value || 'Sem Atribuição'}
                  </Tag>
                )
              },
            },
            {
              key: 9,
              title: 'Criado Em',
              dataIndex: 'created_at',
              render: (item) => dayjs(item).format('DD/MM/YYYY HH:mm'),
              sorter: true,
              columnKey: 'u.created_at',
              sortOrder:
                filters.column === 'u.created_at'
                  ? orderFilter[filters.order]
                  : null,
            },
            {
              key: 10,
              title: 'Atualizado Em',
              dataIndex: 'updated_at',
              render: (item) =>
                item ? dayjs(item).format('DD/MM/YYYY HH:mm') : '',
              sorter: true,
              columnKey: 'u.updated_at',
              sortOrder:
                filters.column === 'u.updated_at'
                  ? orderFilter[filters.order]
                  : null,
            },
            {
              key: 11,
              render: (item) => (
                <Dropdown.Button
                  menu={menuItems(item)}
                  placement="bottomCenter"
                  icon={<MoreOutlined />}
                  buttonsRender={([leftButton, rightButton]) => [
                    leftButton,
                    React.cloneElement(rightButton, {
                      loading: loadingCredit,
                    }),
                  ]}
                >
                  Ações
                </Dropdown.Button>
              ),
            },
          ]}
          total={countData}
          current={pagination.current}
          pageSize={pagination.pageSize}
          onChangePageSize={handlePagination}
        />
      )}

      <ModalPromotionalBonus
        onSubmit={onSubmit}
        open={openModalCredit}
        loading={loadingCredit}
        onCancel={() => setOpenModalCredit(false)}
        onChange={onChangeCreditPromotionalBonus}
      />

      <ModalConvertPoints
        loading={loadingCredit}
        valuePerPoints={valuePerPoints}
        onSubmit={onSubmitConvertPoints}
        open={openModalConvertPoints.open}
        onCancel={() => setOpenModalConvertPoints(false)}
        onChange={onChangeConvertPointsValues}
        onChangeBonus={onChangeFilters}
      />

      <ModalBonusPoints
        bonusPoints={bonusPoints}
        open={openModalBonusPoints.open}
        loading={loadingBonusPoints}
        onChange={onChangeBonusPoints}
        onCancel={() =>
          setOpenModalBonusPoints((prev) => ({
            ...prev,
            open: false,
          }))
        }
        onSubmit={onSubmitBonus}
      />

      {!loadingAccountsPrivate && accountsPrivate.length === 0 && (
        <Empty
          image={Images.Profile}
          description="Nenhuma conta até o momento..."
        />
      )}

      <Extract
        open={openExtract}
        close={() => setOpenExtract(false)}
        userId={userDataCredit.userId}
        accountsPrivate={accountsPrivate}
      />
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { Form, Select } from 'antd'
import t from 'prop-types'

import useForms from '~/hooks/useForms'

export function Forms({ onChange }) {
  const { getAll, forms } = useForms()
  const params = new URLSearchParams(document.location.search)
  const formId = params.get('forms_id')

  useEffect(() => {
    getAll()
  }, [])

  return (
    <Form.Item name="form_id">
      <Select
        disabled={forms.length === 0}
        placeholder="Formulários"
        defaultValue={formId || null}
        onChange={(values) => onChange('forms_id', values)}
        options={forms.map((i) => ({ label: i.name, value: i.uid }))}
      />
    </Form.Item>
  )
}

Forms.propTypes = {
  onChange: t.func,
}

import React from 'react'

import { Button } from 'antd'
import t from 'prop-types'

const SelectedActions = ({ selectedKeys, onOpenModal }) => (
  <div>
    <Button shape="round" color="orange" onClick={onOpenModal}>
      Aplicar Conversão de Pontos ({selectedKeys.length || 0})
    </Button>
  </div>
)

SelectedActions.propTypes = {
  onOpenModal: t.func,
  selectedKeys: t.arrayOf(t.any),
}

export default SelectedActions

import React from 'react'

import { Button, Tag } from 'antd'
import { cond, equals, always } from 'ramda'

import { dateParse } from '~/utils/helpers'

const statusLabel = cond([
  [equals('CREDIT'), always('CREDITO')],
  [equals('DEBIT'), always('DEBITO')],
  [equals('BONUS'), always('BÔNUS')],
])
const statusColor = cond([
  [equals('CREDIT'), always('green')],
  [equals('DEBIT'), always('red')],
  [equals('BONUS'), always('orange')],
])
const statusTagColor = {
  Pendente: 'yellow',
  Aprovado: 'green',
  Reprovado: 'red',
}

export default ({ handleUserReceipt }) => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
  },
  {
    title: 'Criado Em',
    dataIndex: 'created_at',
    render: (date) => dateParse(date),
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    render: (type) => (
      <Tag color={statusColor(type)}> {statusLabel(type)} </Tag>
    ),
  },
  {
    title: 'Agendado Em',
    dataIndex: 'scheduled_at',
    render: (date) => (date ? dateParse(date) : null),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => (
      <Tag color={statusTagColor[status || 'Aprovado']}>
        {' '}
        {status || 'Aprovado'}{' '}
      </Tag>
    ),
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    render: (value) => <span> R$ {value} </span>,
  },
  {
    key: 'x',
    title: 'Ações',
    dataIndex: '',
    render: (i) =>
      i.type === 'DEBIT' && (
        <Button
          shape="round"
          onClick={() => handleUserReceipt(i.missions_users_id)}
        >
          Recibo{' '}
        </Button>
      ),
  },
]

import { useCallback, useState } from 'react'

import { message } from 'antd'
import { format } from 'date-fns'

import api from '~/services/api'

const PATH = '/products-customers'

function useProductsCustomers() {
  const [loadingProductsCustomers, setLoadingProductsCustomers] =
    useState(false)
  const [productsCustomers, setProductsCustomers] = useState([])
  const [itemsList, setItemsList] = useState([])
  const [countProducts, setCountProducts] = useState(0)

  const getProducts = useCallback(async () => {
    setLoadingProductsCustomers(true)
    try {
      const res = await api.get(PATH, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setItemsList(
        res.data.products.map((i) => ({
          ...i,
          description: i.description || 'Sem descrição',
          content: `Expira em ${format(new Date(i.created_at), 'dd/MM/yyyy')}`,
        }))
      )

      return setProductsCustomers(res.data.products)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingProductsCustomers(false)
    }
  }, [])

  async function createProduct(data) {
    try {
      setLoadingProductsCustomers(true)
      const res = await api.post(PATH, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsCustomers(false)
      getProducts()
      setCountProducts(productsCustomers.length)

      return message.success('Produto criado com sucesso!')
    } catch (error) {
      setLoadingProductsCustomers(false)
      return message.error(error.message)
    }
  }

  async function updateProduct(data) {
    try {
      setLoadingProductsCustomers(true)
      const res = await api.put(PATH, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsCustomers(false)
      getProducts()
      return message.success('Produto atualizado com sucesso!')
    } catch (error) {
      setLoadingProductsCustomers(false)
      return message.error(error.message)
    }
  }

  async function removeProduct(data) {
    try {
      setLoadingProductsCustomers(true)
      const res = await api.delete(`${PATH}/${data}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setLoadingProductsCustomers(false)
      getProducts()
      return message.success('Produto removido com sucesso!')
    } catch (error) {
      setLoadingProductsCustomers(false)
      return message.error(error.message)
    }
  }

  return {
    getProducts,
    productsCustomers,
    loadingProductsCustomers,
    createProduct,
    itemsList,
    updateProduct,
    removeProduct,
    countProducts,
  }
}

export default useProductsCustomers

/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'

import useCustomersAccountsPrivate from '~/hooks/useCustomersAccountsPrivate'

import { DebounceSelect } from '../DebounceSelect'

export function Licenses({ onChange }) {
  const [users, setUsers] = useState([])
  const { getCustomersAccountsPrivate, accountsPrivate } =
    useCustomersAccountsPrivate()

  async function fetchUserList(search) {
    await getCustomersAccountsPrivate({
      search,
      limit: 5,
      start: dayjs().subtract(3, 'y').format('YYYY-MM-DD'),
      final: dayjs().add(1, 'y').format('YYYY-MM-DD'),
    })

    return accountsPrivate.map((i) => ({
      label: `${i.name}`,
      value: i.uid,
    }))
  }

  return (
    <DebounceSelect
      value={users}
      mode="multiple"
      style={{ width: '100%' }}
      fetchOptions={fetchUserList}
      placeholder="Selecione Usuários"
      onChange={(newValue) => {
        onChange(
          'usersId',
          newValue.map((i) => i.value)
        )

        return setUsers(newValue)
      }}
    />
  )
}

Licenses.propTypes = {
  onChange: t.func,
}

import React, { useEffect } from 'react'

import { Empty, Form } from 'antd'
import dayjs from 'dayjs'

import Loading from '~/components/atoms/Loading'
import useCategories from '~/hooks/useCategories'
import useMissions from '~/hooks/useMissions'
import useMonitoring from '~/hooks/useWorkforce'

import CardDays from './components/CardDays'
import Charts from './components/Charts'
import Filter from './components/Filter'
import { MainContainer } from './styles'

function Workforce() {
  const [form] = Form.useForm()
  const { getActiveMissions, activeMissions } = useMissions()
  const {
    getUsers,
    exportReport,
    users,
    dateList,
    adherence,
    adherenceGraphicBar,
    adherenceGraphicPizza,
    loadingWorkforce,
    totalRegisters,
    ratings,
  } = useMonitoring()
  const { getCategories, categories } = useCategories()

  const today = dayjs().format('YYYY-MM-DD')
  const initialFilter = {
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    finalDate: today,
  }

  function getFilters() {
    const filledValues = form.getFieldValue()
    const { period, customDate, ...submitValues } = filledValues

    if ('period' in filledValues) {
      if (period !== 'custom') {
        submitValues.startDate = dayjs()
          .subtract(period, 'day')
          .format('YYYY-MM-DD')
        submitValues.finalDate = today
      } else {
        submitValues.startDate = dayjs(customDate[0]).format('YYYY-MM-DD')
        submitValues.finalDate = dayjs(customDate[1]).format('YYYY-MM-DD')
      }
    } else {
      submitValues.startDate = initialFilter.startDate
      submitValues.finalDate = initialFilter.finalDate
    }

    if (filledValues.cities) {
      submitValues.cities = filledValues.cities.map(
        (city) => city.split(' - ')[0]
      )
    }

    return submitValues
  }

  function handleExportReport() {
    const submitValues = getFilters()
    submitValues.typeExportation = 'xls'
    return exportReport(submitValues)
  }

  function handleSubmit() {
    const submitValues = getFilters()
    return getUsers(submitValues)
  }

  useEffect(() => {
    const init = Promise.all([
      getCategories(),
      getActiveMissions(),
      getUsers(initialFilter),
    ])

    return () => init
  }, []) // eslint-disable-line

  return (
    <MainContainer>
      <Filter
        form={form}
        categories={categories}
        onSubmit={() => handleSubmit()}
        onExportReport={() => handleExportReport()}
        activeMissions={activeMissions}
      />
      {loadingWorkforce ? <Loading /> : null}

      {!loadingWorkforce && dateList.length === 0 ? (
        <Empty description="Nenhum dado foi encontrado para exibição" />
      ) : null}

      {!loadingWorkforce && dateList.length > 0 ? (
        <>
          <CardDays
            usersData={users}
            dateList={dateList}
            adherence={adherence}
            totalRegisters={totalRegisters}
            ratings={ratings}
          />

          <Charts
            adherenceGraphicBar={adherenceGraphicBar}
            adherenceGraphicPizza={adherenceGraphicPizza}
          />
        </>
      ) : null}
    </MainContainer>
  )
}

export default Workforce

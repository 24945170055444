import React from 'react'

import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

const { RangePicker } = DatePicker

export function RangeDates({ onChange }) {
  return (
    <Form.Item name="initial">
      <RangePicker
        onChange={(dates) => {
          onChange('start', dayjs(dates[0]).format('YYYY-MM-DD'))
          onChange('final', dayjs(dates[1]).format('YYYY-MM-DD'))
        }}
        dateFormat="DD/MM/YYYY"
      />
    </Form.Item>
  )
}

RangeDates.propTypes = {
  onChange: t.func,
}

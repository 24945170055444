/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Form, message, Col, Row } from 'antd'
import { format } from 'date-fns'
import queryString from 'query-string'

import Table from '~/components/organisms/Table'
import useCustomersRegistersActions from '~/hooks/useCustomersRegistersActions'
import useCustomStatus from '~/hooks/useCustomStatus'
import useMetrics from '~/hooks/useMetrics'
import useRateUser from '~/hooks/useRateUser'
import useRegisters from '~/hooks/useRegisters'
import { CORRECTION } from '~/utils/statusOptions'

import {
  Header,
  Detail,
  UserRateModal,
  ApprovationsBatch,
  ApprovationBatchDetail,
  QueueApprovationsBatch,
  RegistersActionModal,
} from './components'
import { ApiLogsModal } from './components/Detail/components/ApiLogsModal'
import { LogsModal } from './components/Detail/components/LogsModal'
import { ExportRegistersList } from './components/ExportRegistersList'
import { INIT_PAGE, QUEUE_APPROVATION_STATUS } from './helpers'
import { columns } from './helpers/columns'

const statuses = [
  { label: 'Aguardando Avaliação', value: 0 },
  { label: 'Aprovado', value: 1 },
  { label: 'Reprovado', value: 2 },
]

const initialFilter = {
  status: ['0'],
  limit: 20,
  offset: 0,
  order: 'updated_at',
  direction: 'asc',
}

const INITIAL_DATE = '01-01-2020'

export default function Registers() {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const { onUpdateCustomStatus } = useCustomStatus()
  const { onSubmitRate, loadingRate } = useRateUser()
  const { dashboard, getMetricsDashboard, loadingDashboard } = useMetrics()
  const {
    register,
    missions,
    registersCount,
    getMissions,
    loadingMissions,
    getRegisterById,
    onSubmitRegister,
    onSubmitApprovals,
    loadingRegister,
    getQueueApprovationsBatch,
    getQueueApprovationBatchById,
    queueApprovationsBatch,
    setQueueApprovationsBatch,
    submitExport,
  } = useRegisters()
  const {
    customersRegistersActions,
    getCustomersRegistersActions,
    onSubmitAction,
    loadingActions,
  } = useCustomersRegistersActions()
  const [status, setStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState(initialFilter)
  const [score, setScore] = useState({ rate: 3, message: '' })
  const [registersBatchId, setRegistersBatchId] = useState(null)
  const [approveDescription, setApproveDescription] = useState(null)
  const [reproveDescription, setReproveDescription] = useState(null)
  const [modalAction, setModalAction] = useState({ item: null, open: false })
  const [modalRate, setModalRate] = useState({ item: null, open: false })
  const [openModal, setOpenModal] = useState({
    visible: false,
    currentItem: null,
    currentStatus: null,
  })
  const [openModalLogs, setOpenModalLogs] = useState(false)
  const [openModalApiLogs, setOpenModalApiLogs] = useState(false)
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [openApprovationsBatch, setOpenApprovationsBatch] = useState(false)
  const [openApprovationsDetail, setOpenApprovationsDetail] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 })
  const [openExportList, setOpenExportList] = useState(false)

  function onClick(mission) {
    setSelectedRowKeys([mission])
    return getRegisterById(mission.uid)
  }

  function handleFilters(newQuery) {
    navigate({
      search: queryString.stringify(newQuery),
      pathname: location.pathname,
    })

    return getMissions(newQuery)
  }

  function onRefresh() {
    getMetricsDashboard({
      initial: INITIAL_DATE,
      final: format(new Date(), 'yyyy-MM-dd'),
    })
    handleFilters(query)
  }

  function onSelectChange(selectedItems) {
    return setSelectedRowKeys(selectedItems)
  }

  async function onClickApprove() {
    if (selectedRowKeys.length > 0) {
      await onSubmitApprovals({
        registersIds: selectedRowKeys.map((i) => i.uid),
      })

      setSelectedRowKeys([])
      return onRefresh()
    }

    await onSubmitApprovals()

    setSelectedRowKeys([])
    return onRefresh()
  }

  function submitFilter(values) {
    const newFilters = {}

    if (values.search) {
      newFilters.search = values.search
    }

    if (values.order) {
      newFilters.order = values.order
    }

    if (values.missionsMainId) {
      newFilters.missionsMainId = values.missionsMainId
    }

    if (values.ufsList && values.ufsList.length > 0) {
      newFilters.uf = values.ufsList.map((i) => i).join(', ')
    }

    if (values.citiesList && values.citiesList.length > 0) {
      newFilters.city = values.citiesList.map((i) => i).join(', ')
    }

    if (values.customStatusId) {
      newFilters.customStatusId = values.customStatusId
    }

    if (values.initial && values.final) {
      if (values.initial > values.final) {
        return message.error('Data inicial deve ser maior que final!')
      }

      newFilters.initial = format(new Date(values.initial), 'yyyy-MM-dd')
      newFilters.final = format(new Date(values.final), 'yyyy-MM-dd')
    }

    setLoading(true)
    setVisibleFilter(false)
    setQuery({ ...query, ...newFilters })
    return handleFilters({ ...query, ...newFilters })
  }

  function handleTableChange(page, _filters, sorter) {
    const pageLimit = page.current * query.limit
    const currentOffset = page.current === 1 ? 0 : pageLimit - query.limit
    const direction = sorter.order === 'ascend' ? 'asc' : 'desc'
    const params = {
      missionName: 'mission_name',
      updated_at: 'updated_at',
      userName: 'user_name',
    }
    const order = params[sorter.field]
    const newQuery = {
      ...query,
      order,
      direction,
      offset: currentOffset,
    }

    setQuery(newQuery)
    setPagination((prev) => ({ ...prev, current: page?.current }))
    return handleFilters(newQuery)
  }

  function onClickWindowOpen(path) {
    return window.open(path, '_self')
  }

  async function onCompleteRegister() {
    const registerId = openModal.currentItem.uid
    const registerStatus = openModal.currentStatus

    if (registerStatus !== CORRECTION) {
      await onSubmitApprovals({
        registersIds: [registerId],
        has_approved: approveDescription,
        isOnly1Mission: true,
      })
    } else {
      await onSubmitRegister({
        registerId,
        status: registerStatus,
        has_approved: approveDescription,
        has_reproved: reproveDescription,
      })
    }

    setModalRate((prev) => ({
      ...prev,
      item: openModal.currentItem,
      open: true,
    }))

    return onRefresh()
  }

  async function submitRegister() {
    const registerStatus = openModal.currentStatus

    if (registerStatus === CORRECTION && reproveDescription === null) {
      return message.error('Escolha uma opção ou escreva um motivo!')
    }

    setOpenModal((prev) => ({ ...prev, visible: false }))

    const items = await getCustomersRegistersActions(registerStatus)

    if (items.length > 0) {
      return setModalAction({ item: openModal.currentItem, open: true })
    }

    return onCompleteRegister()
  }

  function onChangeRate(key, value) {
    return setScore((prev) => ({ ...prev, [key]: value }))
  }

  async function onClickRate() {
    const { item } = modalRate

    await onSubmitRate({
      ...score,
      user_id: item.user_id,
      missions_users_id: item.missions_users_id,
    })

    setModalRate((prev) => ({ ...prev, item, open: false }))

    return onRefresh()
  }

  function onClickStatus(currentStatus) {
    setLoading(true)

    if (currentStatus !== 0) {
      setSelectedRowKeys([])
    }

    const params = {
      ...initialFilter,
      status:
        currentStatus === 'custom_status'
          ? 'custom_status'
          : [`${currentStatus}`],
      custom_status_description: '',
      direction: currentStatus === 0 ? 'asc' : 'desc',
    }

    setStatus(currentStatus)
    setQuery(params)

    return handleFilters(params)
  }

  function onChangeConfirmDescription(key, value) {
    if (key === 'aproveDescription') {
      return setApproveDescription(value)
    }

    return setReproveDescription(value)
  }

  function onClickModal({ currentStatus, currentItem }) {
    return setOpenModal({ currentItem, currentStatus, visible: true })
  }

  function onClickLogs() {
    return setOpenModalLogs(true)
  }

  async function onClickCustomStatus(value) {
    setLoading(true)

    await onUpdateCustomStatus(value)

    return setTimeout(() => onRefresh(), 500)
  }

  function openModalRate(item) {
    return setModalRate((prev) => ({ ...prev, item, open: !prev.open }))
  }

  function onSubmitRegisterAction(value) {
    onSubmitAction({
      actionValue: value,
      missionsUsersId: modalAction?.item?.missions_users_id,
    })

    setModalAction((prev) => ({ ...prev, open: false }))

    return onCompleteRegister()
  }

  function handleOnClickExport(key) {
    if (key === 'export') {
      submitExport({ ...query, xlsx: true })
    }

    if (key === 'list') {
      setOpenExportList(true)
    }
  }

  useEffect(() => {
    const unsub = onRefresh()

    return () => unsub
  }, []) //eslint-disable-line

  useEffect(() => {
    const sub = () =>
      getQueueApprovationsBatch(
        [QUEUE_APPROVATION_STATUS.QUEUED, QUEUE_APPROVATION_STATUS.PROCESSING],
        INIT_PAGE,
        4
      )

    return () => sub()
  }, []) //eslint-disable-line

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const filters = {
    form,
    statuses,
    submitFilter,
    onReset: () => {
      form.resetFields(['search', 'citiesList', 'ufsList', 'initial', 'final'])
      setVisibleFilter(false)
    },
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col lg={missions.length > 0 ? 12 : 24}>
          <Header
            key="header"
            filters={filters}
            loading={loading}
            statistic={dashboard}
            currentStatus={status}
            visibleFilter={visibleFilter}
            loadingData={loadingDashboard}
            loadingRegister={loadingRegister}
            selectedRowKeys={selectedRowKeys}
            onClickApprove={() => onClickApprove()}
            onChange={() => setVisibleFilter((prev) => !prev)}
            onClickCustomStatus={(value) => onClickCustomStatus(value)}
            onOpenApprovationBatch={() => setOpenApprovationsBatch(true)}
            onClickStatus={(currentStatus) => onClickStatus(currentStatus)}
            onClickExport={handleOnClickExport}
          />

          {queueApprovationsBatch.length > 0 ? (
            <QueueApprovationsBatch
              onClick={(id) => {
                setRegistersBatchId(id)

                return setOpenApprovationsDetail(true)
              }}
              getApprovationBatch={getQueueApprovationBatchById}
              queueApprovationsBatch={queueApprovationsBatch}
              setQueueApprovationsBatch={setQueueApprovationsBatch}
            />
          ) : null}

          <ApprovationsBatch
            open={openApprovationsBatch}
            onClose={() => setOpenApprovationsBatch(false)}
          />

          {registersBatchId !== null ? (
            <ApprovationBatchDetail
              id={registersBatchId}
              onClose={() => setOpenApprovationsDetail(false)}
              open={openApprovationsDetail}
            />
          ) : null}

          <Table
            key="table"
            size="small"
            data={missions}
            columns={columns()}
            total={registersCount}
            loading={loadingMissions}
            rowSelection={rowSelection}
            onClick={(mission) => onClick(mission)}
            onChangePageSize={handleTableChange}
            current={pagination.current}
            pageSize={pagination.pageSize}
          />
        </Col>
        <Col lg={12}>
          {!loadingRegister && register && (
            <Detail
              item={register}
              score={score}
              statistic={dashboard}
              loading={loadingRegister}
              openModal={openModal}
              modalRate={modalRate}
              handleOk={() => submitRegister()}
              onClickRate={() => onClickRate()}
              onChangeRate={() => onChangeRate()}
              onClickLogs={() => onClickLogs()}
              onClickApiLogs={() => setOpenModalApiLogs(true)}
              onClickModal={({ currentStatus }) =>
                onClickModal({ currentStatus, currentItem: register })
              }
              openModalRate={() => openModalRate(register)}
              approveDescription={approveDescription}
              reproveDescription={reproveDescription}
              onClickWindowOpen={() => onClickWindowOpen()}
              onClickCustomStatus={onClickCustomStatus}
              onCloseModalRate={() =>
                setModalRate((prev) => ({ ...prev, open: false }))
              }
              onChangeConfirmDescription={(key, { target }) =>
                onChangeConfirmDescription(key, target.value)
              }
              onCancel={() =>
                setOpenModal((prev) => ({ ...prev, visible: false }))
              }
            />
          )}
        </Col>
      </Row>
      {openModalLogs ? (
        <LogsModal
          open={openModalLogs}
          registerId={register?.uid}
          onClose={() => setOpenModalLogs((prev) => !prev)}
        />
      ) : null}
      {openModalApiLogs ? (
        <ApiLogsModal
          open={openModalApiLogs}
          registerId={register?.identifier}
          onClose={() => setOpenModalApiLogs((prev) => !prev)}
        />
      ) : null}
      <UserRateModal
        open={modalRate.open}
        value={score.rate}
        loading={loadingRate}
        onClick={() => onClickRate()}
        onChange={(value) => onChangeRate('rate', value)}
        onCancel={() => setModalRate((prev) => ({ ...prev, open: false }))}
      />
      <RegistersActionModal
        open={modalAction.open}
        loading={loadingActions}
        onClick={onSubmitRegisterAction}
        options={customersRegistersActions}
        onCancel={() => setModalAction((prev) => ({ ...prev, open: false }))}
      />
      <ExportRegistersList
        open={openExportList}
        onCancel={() => setOpenExportList(false)}
      />
    </>
  )
}

import React from 'react'
import { Navigate } from 'react-router-dom'

export default function LoadingPage() {
  const token = localStorage.getItem('@Mission:token')

  return token === null ? (
    <Navigate to="/login" />
  ) : (
    <Navigate to="/dashboard" />
  )
}

export default [
  {
    id: 1,
    value: 'Não se deslocou',
  },
  {
    id: 2,
    value: 'Formulário Incompleto',
  },
  {
    id: 3,
    value: 'Foto Incorreta',
  },
  {
    id: 4,
    value: 'Foto Ilegível',
  },
  {
    id: 5,
    value: 'Incompleto',
  },
]

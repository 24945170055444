const initialState = {
  data: [],
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const AVERAGE = 'AVERAGE'
const AVERAGE_OK = 'AVERAGE_OK'
const AVERAGE_FAIL = 'AVERAGE_FAIL'

export default function averageReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case AVERAGE:
      return { isFetching: true }

    case AVERAGE_OK:
      return { isFetching: false, data, success: true, error: false }

    case AVERAGE_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}

import React from 'react'

import { Modal, Button, Rate, Typography, Image } from 'antd'
import t from 'prop-types'

import ReviewImage from '~/assets/images/review.svg'

import { ContainerModal } from './styles'

const { Title } = Typography

const modalValues = ['Muito Ruim', 'Ruim', 'Bom', 'Muito Bom', 'Excelente']

export function UserRateModal({
  open,
  onCancel,
  value,
  onClick,
  onChange,
  loading,
}) {
  return (
    <Modal
      open={open}
      title="Avaliação"
      onOk={onClick}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onClick}>
          Enviar
        </Button>,
      ]}
    >
      <ContainerModal>
        <Image preview={false} src={ReviewImage} width={100} />
        <br />
        <Title level={5}> Avalie a qualidade da entrega.</Title>
        <br />
        <Rate tooltips={modalValues} defaultValue={value} onChange={onChange} />
      </ContainerModal>
    </Modal>
  )
}

UserRateModal.defaultTypes = {
  form: {},
  open: false,
  loading: false,
}

UserRateModal.propTypes = {
  open: t.bool.isRequired,
  onCancel: t.func.isRequired,
  value: t.number.isRequired,
  onClick: t.func.isRequired,
  onChange: t.func.isRequired,
  loading: t.bool.isRequired,
}

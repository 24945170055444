import React from 'react'

import { Tag } from 'antd'
import { format } from 'date-fns'

const statusColor = {
  0: 'yellow',
  1: 'green',
  2: 'red',
}

const statusLabel = {
  0: 'AGUARDANDO APROVAÇÃO',
  1: 'APROVADO',
  2: 'CANCELADO',
}

export default [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Tipo de Pagamento',
    dataIndex: 'payment_type',
    render: (type) => <span>{type.toUpperCase()}</span>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => (
      <Tag color={statusColor[status]}>{statusLabel[status]}</Tag>
    ),
  },
  {
    title: 'Criado Em',
    dataIndex: 'created_at',
    render: (date) => format(new Date(date), 'dd/MM/yyyy HH:mm'),
  },
  {
    title: 'Atualizado Em',
    dataIndex: 'updated_at',
    render: (date) =>
      date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : null,
  },
  {
    title: 'Valor (R$)',
    dataIndex: 'value',
    render: (value) => <span>R${value}</span>,
  },
]

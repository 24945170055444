import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function usePointOfSales() {
  const [pointOfSale, setPointOfSale] = useState(null)
  const [loadingPos, setLoading] = useState(false)

  const getPointOfSales = useCallback(async (missionsUsersId) => {
    setLoading(true)
    try {
      const res = await api.get(`point-of-sale/sent/${missionsUsersId}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setPointOfSale(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loadingPos,
    pointOfSale,
    getPointOfSales,
  }
}

import React from 'react'

import { Button, Col, Input, Row } from 'antd'
import t from 'prop-types'

export function DynamicHeaderOptions({
  headers,
  addHeader,
  handleOnChange,
  loadingWebhook,
}) {
  return (
    <>
      {headers.map((item, idx) => {
        const headerKeys = Object.keys(item)
        return headerKeys.map((itemHeader) => (
          <Row>
            <Col span={12}>
              <Input
                placeholder="header"
                value={itemHeader}
                onChange={({ target }) =>
                  handleOnChange('header', target.value, idx)
                }
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="value"
                value={item[itemHeader]}
                onChange={({ target }) =>
                  handleOnChange('value', target.value, idx)
                }
              />
            </Col>
          </Row>
        ))
      })}
      <Row>
        <Col>
          <Button type="primary" onClick={addHeader} disabled={loadingWebhook}>
            Adicionar Novo Header
          </Button>
        </Col>
      </Row>
    </>
  )
}

DynamicHeaderOptions.propTypes = {
  headers: t.arrayOf(t.string),
  addHeader: t.func,
  handleOnChange: t.func,
  loadingWebhook: t.bool,
}

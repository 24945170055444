import React from 'react'
import { Line } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import dayjs from 'dayjs'
import t from 'prop-types'
import { ResponsiveContainer } from 'recharts'

import { Container } from './styles'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export function LinearCount({ data }) {
  const labels = data.map((i) => {
    const date = i.day.split('-')
    return dayjs(`${date[2]}-${date[1]}-${date[0]}`).format('DD/MM/YYYY')
  })

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
  }

  const dataValues = {
    labels,
    datasets: [
      {
        label: '',
        data: data.map((i) =>
          parseFloat(i.answer.replace(',', '.')).toFixed(2)
        ),
      },
    ],
  }

  return (
    <Container>
      <ResponsiveContainer width="100%" height={300}>
        <Line options={options} data={dataValues} />
      </ResponsiveContainer>
    </Container>
  )
}

LinearCount.propTypes = {
  data: t.oneOfType([t.arrayOf(t.string), t.object]),
}

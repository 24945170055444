import React, { useEffect, useState } from 'react'

import { FileTextOutlined, MailOutlined, SendOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Card,
  Dropdown,
  Modal,
  Select,
  Space,
  message,
  Avatar,
  Alert,
  Divider,
} from 'antd'
import t from 'prop-types'

import useCustomersRegistersActions from '~/hooks/useCustomersRegistersActions'
import useExportRegister from '~/hooks/useExportRegister'
import useReceiptMission from '~/hooks/useReceiptMission'
import { APPROVED } from '~/utils/statusOptions'

import { RegisterPropTypes } from '../lib'
import { ActionButtons } from './ActionButtons'
import { UserRate } from './UserRate'

const { Meta } = Card

export function Header({
  item,
  userId,
  onClickModal,
  onClickCustomStatus,
  onClickLogs,
}) {
  const {
    hasGenerated,
    loadingExportRegister,
    onSubmitExportRegister,
    loadingMailRegister,
    onSubmitMailRegister,
  } = useExportRegister()
  const { generateReceipt } = useReceiptMission()
  const {
    getCustomersRegistersActions,
    loadingActions,
    onSubmitAction,
    customersRegistersActions,
  } = useCustomersRegistersActions()
  const [modal, setModal] = useState(false)
  const [mails, setMails] = useState([])

  function onChange(values) {
    return setMails([...mails, ...values])
  }

  function onSubmitMails() {
    if (mails.length === 0) {
      return message.error('Encaminhe pelo menos 1 e-mail!')
    }

    setModal(false)
    return onSubmitMailRegister({ registerId: item.uid, to: mails })
  }

  function onClick(option) {
    if (option.key === 'receipt') {
      return generateReceipt(item.missions_users_id)
    }

    if (option.key === 'generate') {
      return item.url_mission_report !== null || hasGenerated !== null
        ? window.open(item.url_mission_report || hasGenerated, '_blank')
        : onSubmitExportRegister(item)
    }

    if (option.key === 'send_email') {
      return setModal(true)
    }

    return onSubmitExportRegister(item)
  }

  const menuItens = {
    items: [
      {
        key: 'receipt',
        label: 'Recibo',
      },
      {
        key: 'generate',
        label:
          item.url_mission_report !== null || hasGenerated !== null
            ? 'Baixar Dossiê'
            : 'Gerar PDF',
      },
      {
        key: 'new_dossie',
        label: 'Gerar Novo Dossie',
      },
      {
        key: 'send_email',
        label: 'Enviar por E-mail',
      },
    ],
    onClick,
  }

  function onClickAction(action) {
    return onSubmitAction({
      actionValue: action?.key,
      missionsUsersId: item?.missions_users_id,
    })
  }

  useEffect(() => {
    getCustomersRegistersActions(item.status)
  }, []) //eslint-disable-line

  return (
    <Row justify="space-between">
      <Col lg={12}>
        <Meta
          title={item?.userName.split(' ')[0] || item?.userName}
          avatar={<Avatar src={item.userAvatar} />}
          description={
            <Space direction="vertical">
              <span>{item.place}</span>
              {item.status === APPROVED && (
                <Dropdown.Button
                  type="default"
                  buttonsRender={([leftButton, rightButton]) => [
                    leftButton,
                    React.cloneElement(rightButton, {
                      loading: loadingExportRegister || loadingMailRegister,
                    }),
                  ]}
                  menu={menuItens}
                  icon={<FileTextOutlined />}
                >
                  Documentos
                </Dropdown.Button>
              )}
              {customersRegistersActions.length > 0 ? (
                <Dropdown.Button
                  type="default"
                  buttonsRender={([leftButton, rightButton]) => [
                    leftButton,
                    React.cloneElement(rightButton, {
                      loading: loadingActions,
                    }),
                  ]}
                  menu={{
                    items: customersRegistersActions.map((i) => ({
                      key: i.value,
                      label: i.description,
                    })),
                    onClick: onClickAction,
                  }}
                  icon={<SendOutlined />}
                >
                  Ações do Registro
                </Dropdown.Button>
              ) : null}
            </Space>
          }
        />
      </Col>
      <Col lg={12}>
        <UserRate userId={userId} />
      </Col>

      {item.status === 2 && (
        <Col lg={24}>
          <Alert
            showIcon
            type="error"
            message="Motivo Reprovação"
            style={{ marginTop: 10 }}
            description={item.has_reproved || 'Sem justificativa'}
          />
        </Col>
      )}

      {item.status === 0 && (
        <Col lg={24}>
          <Divider />
          <ActionButtons
            item={item}
            onClickLogs={onClickLogs}
            onClickModal={onClickModal}
            onClickCustomStatus={onClickCustomStatus}
          />
        </Col>
      )}
      <Modal
        open={modal}
        style={{ top: 20 }}
        title="Encaminhe o Dossiê"
        onOk={() => onSubmitMails()}
        onCancel={() => setModal(false)}
      >
        <p>
          <Select
            mode="tags"
            showSearch={false}
            tokenSeparators={[',']}
            prefix={<MailOutlined />}
            style={{ width: '100%' }}
            onChange={(values) => onChange(values)}
            placeholder="Digite os emails separados por vírgula ','"
          />
        </p>
      </Modal>
    </Row>
  )
}

Header.propTypes = {
  item: t.objectOf(RegisterPropTypes).isRequired,
  userId: t.string,
  onClickLogs: t.func,
  onClickModal: t.func.isRequired,
  onClickCustomStatus: t.func,
}

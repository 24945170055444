import styled from 'styled-components'

export const StyledBox = styled.div`
  padding: 15px 10px;
  background-color: #140432;
  color: #fff;
  margin: 20px 0px;
  border-radius: 40px;
  width: 60vw;
  text-align: center;
`
export const Title = styled.h1`
  color: #565556;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 1px white;
`

export const SubTitle = styled.h3`
  color: #565556;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 1px white;
`

export const Space = styled.div`
  margin-bottom: 80px;
`

export const HeaderBox = styled.div`
  line-height: 20px;
`

import React, { useState } from 'react'

import { Input, Checkbox, Form, Drawer, Skeleton, Button } from 'antd'
import t from 'prop-types'

const ProductForm = ({ form, item, open, close, loading, handleSubmit }) => {
  const [showDesc, setShowDesc] = useState(false)
  return (
    <Drawer
      width={720}
      onClose={close}
      destroyOnClose="true"
      visible={open.visible}
      title={item?.title || 'Categoria de Produto'}
    >
      <Skeleton loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={item}
          onFinish={(values) => handleSubmit(values, open.type)}
        >
          <Form.Item label="Nome da Categoria" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="description">
            <Input />
          </Form.Item>
          <Form.Item name="is_service" valuePropName="checked">
            <Checkbox>Assinale caso for um serviço</Checkbox>
          </Form.Item>
          <Form.Item name="has_spotlight" valuePropName="checked">
            <Checkbox onClick={(e) => setShowDesc(e.target.checked)}>
              Assinale caso for uma categoria destaque
            </Checkbox>
          </Form.Item>
          {(showDesc || item.description_spotlight) && (
            <Form.Item label="Descreva o Destaque" name="description_spotlight">
              <Input />
            </Form.Item>
          )}
          <Form.Item>
            <Button shape="round" type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Drawer>
  )
}

ProductForm.propTypes = {
  form: t.objectOf(t.any).isRequired,
  item: t.objectOf(t.any).isRequired,
  open: t.objectOf(t.any).isRequired,
  close: t.func.isRequired,
  loading: t.bool.isRequired,
  handleSubmit: t.func.isRequired,
}

export default ProductForm

import { useState } from 'react'

import { message } from 'antd'
import { saveAs } from 'file-saver'
import * as Yup from 'yup'

import api from '~/services/api'

function useTransactions() {
  const [loadingTransaction, setLoadingTransaction] = useState(false)
  const [pointsTransactions, setPointsTransactions] = useState([])
  const [transactions, setTransactions] = useState([])

  async function getUsersPointsTransactions(params) {
    try {
      setLoadingTransaction(true)
      const res = await api.get('users-points-transactions/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      return setPointsTransactions(res.data.transactions)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTransaction(false)
    }
  }

  async function getUsersTransactions(params) {
    try {
      setLoadingTransaction(true)
      const res = await api.get('users-transactions/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      return setTransactions(res.data.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTransaction(false)
    }
  }

  async function getReport(params) {
    try {
      setLoadingTransaction(true)

      const schema = Yup.object().shape({
        type_report: Yup.array(
          Yup.string().equals(
            ['DEBIT', 'CREDIT'],
            ({ value }) => `A opção ${value} é inválida`
          )
        ).required(
          'Selecione ao menos uma opção (Débito/Crédito) para gerar o relatório'
        ),
        type: Yup.string().required('O tipo de relatório é obrigatório'),
        final: Yup.string().required('A data final é obrigatória'),
        initial: Yup.string().required('A data inicial é obrigatória'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.get('customers-transactions/report', {
        params,
        responseType: 'blob',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error('Não existem transações no periodo.')
      }

      const processFile = (type) => {
        const blob = new Blob([res.data], { type: `application/${type}` })
        saveAs(blob, `customers_transactions_${new Date().getTime()}.${type}`)
      }

      if (params.type === 'pdf') {
        processFile('pdf')
      }

      if (params.type === 'xls') {
        processFile('xls')
      }

      return message.success('Relatório gerado com sucesso.')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingTransaction(false)
    }
  }

  return {
    getUsersPointsTransactions,
    getUsersTransactions,
    pointsTransactions,
    transactions,
    loadingTransaction,
    getReport,
  }
}

export default useTransactions

import React, { useEffect } from 'react'

import { Form, Button, Select, Input, DatePicker } from 'antd'
import t from 'prop-types'

import useCategories from '~/hooks/useCategories'
import useCustomStatus from '~/hooks/useCustomStatus'
import useGeocode from '~/hooks/useGeocode'

const { Option } = Select

export function Filter({ form, onReset, currentStatus, submitFilter }) {
  const { getCategories, categories } = useCategories()
  const { ufsOptions, citiesOptions, onChangeUFs } = useGeocode()
  const { getCustomStatus, customStatus, loadingCustomStatus } =
    useCustomStatus()

  function onChangeCategory(value) {
    return getCustomStatus({ status: currentStatus, id: value })
  }

  useEffect(() => {
    getCategories()
  }, []) //eslint-disable-line

  return (
    <Form form={form} onFinish={submitFilter}>
      <Form.Item name="search">
        <Input placeholder="Nome / COD. / ID" />
      </Form.Item>
      <Form.Item name="missionsMainId">
        <Select
          placeholder="Categorias"
          onChange={(_, { value }) => onChangeCategory(value)}
        >
          {categories?.map((i) => (
            <Option key={i.title} value={i.mission_main_id}>
              {i.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!loadingCustomStatus && customStatus?.length > 0 && (
        <Form.Item name="customStatusId">
          <Select placeholder="Sub-status">
            {customStatus?.map((i) => (
              <Option key={String(i.order)} value={i.uid}>
                {i.order} - {i.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item name="ufsList">
        <Select
          placeholder="UF"
          mode="multiple"
          onChange={onChangeUFs}
          options={ufsOptions}
        />
      </Form.Item>
      {citiesOptions.length > 0 && (
        <Form.Item name="citiesList">
          <Select
            placeholder="Cidades"
            mode="multiple"
            options={citiesOptions}
          />
        </Form.Item>
      )}
      <Form.Item name="initial">
        <DatePicker placeholder="Data Inicial" />
      </Form.Item>
      <Form.Item name="final">
        <DatePicker placeholder="Data Final" />
      </Form.Item>
      <Form.Item>
        <Button shape="round" htmlType="submit" type="primary">
          Filtrar
        </Button>{' '}
        <Button shape="round" type="default" onClick={onReset}>
          Resetar
        </Button>
      </Form.Item>
    </Form>
  )
}

Filter.propTypes = {
  form: t.objectOf({}).isRequired,
  onReset: t.func.isRequired,
  currentStatus: t.number,
  submitFilter: t.func.isRequired,
}

import React from 'react'
import { Popup } from 'react-map-gl'

import t from 'prop-types'

const GOOGLE_MAPS_URL = 'https://www.google.com/maps/?q='
const LAT_DEFAULT = -8.269214
const LNG_DEFAULT = -55.441382

const FeedsPopup = ({ lat, lng }) => {
  const label = `(${lat}, ${lng})`
  const url = `${GOOGLE_MAPS_URL}${lat}, ${lng}`
  return (
    <Popup
      anchor="top"
      tipSize={10}
      offsetTop={40}
      offsetLeft={18}
      closeButton={false}
      latitude={lat || LAT_DEFAULT}
      longitude={lng || LNG_DEFAULT}
    >
      <div>
        <p>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {label}
          </a>
        </p>
      </div>
    </Popup>
  )
}

FeedsPopup.defaultTypes = {
  lat: 0,
  lng: 0,
}

FeedsPopup.propTypes = {
  lat: t.number.isRequired,
  lng: t.number.isRequired,
}

export default FeedsPopup

import styled from 'styled-components'

export const Container = styled.div`
  max-height: 525px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 85%;

  button {
    position: ${({ isActive }) =>
      !isActive ? 'absolute' : 'unset'} !important;
    background-color: ${({ isActive }) =>
      !isActive ? '#1AAE9F' : '#D3455B'} !important;
  }
`

export const CustomTooltip = styled.div`
  background: #fbfbfb;
  padding: 4px 12px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.33);
  border: 0.5px solid ${({ color }) => color}55;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  strong,
  b {
    font-size: 10px;
  }

  span {
    font-size: 12px;
  }

  :before {
    content: '';
    display: block;
    height: 0;
    left: 50%;
    bottom: -20%;
    position: absolute;
    transform: translate3d(-50%, 25%, 0);
    border-color: #fbfbfb transparent transparent transparent;
    border-style: solid;
    border-width: 10px;
  }

  :after {
    content: '';
    display: block;
  }
`

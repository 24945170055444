import React from 'react'

import { Button, Form, Input } from 'antd'

import useCustomer from '~/hooks/useCustomer'

export default function RecoveryAccount() {
  const { onRecovery, loadingCustomer } = useCustomer()

  const [form] = Form.useForm()

  async function handleSubmit(data) {
    return onRecovery({ email: data?.email })
  }

  return (
    <div className="content-login">
      <div className="content-login-header">
        <h3>Recuperar Senha</h3>
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Digite seu email por favor!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={loadingCustomer}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

import { createGlobalStyle } from 'styled-components'

import LogoWhite from '~/assets/images/logo-name-color.png'
import LogoLabel from '~/assets/images/logo-name.svg'

import '../assets/fonts/font.css'

export default createGlobalStyle`
  .bold {
    font-family: 'Gotham Pro Bold', Arial, serif !important;
  }

  body {
    background-color: #f4f7fa !important;
    font-family: inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .ant-checkbox-group-item {
    display: flex !important;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #002e5d !important;
  }

  .header-no-margin > .ant-page-header-content{
    padding-top: 0 !important;
  }

  .ant-popover-inner-content {
    max-width: 300px;
  }

  .trigger {
    font-size: 14px;
    line-height: 40px;
    left: 0;
    color: 'white';
    padding: 0px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: white;
  }

  .upload-image > .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 150px;
    background-color: #f1f2f3;
    border: 1px dashed gray;
    text-align: center;
  }

  .ant-layout-sider-children {
    height: 100%;
    margin-top: -.1px;
    padding-top: .1px;
  }

  .ant-pro-sider-menu-logo img {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }

  .ant-pro-sider-menu-logo h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    font-family: Gotham Pro;
    vertical-align: middle;
  }

  .ant-pro-sider-menu-logo {
    position: relative;
    height: 80px;
    padding-left: 24px;
    overflow: hidden;
    line-height: 80px;
    transition: all .3s;
  }

  .loading {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .ant-layout-header {
    padding: 0 10px !important;
  }

  .header-button {
    margin: 0 !important;
    padding: 0 !important;
    height: 60px !important;
    border: 0 !important;
    line-height: 60px !important;
    background-color: #fff !important;
  }

  .center-image-header {
    position: absolute !important;
    text-align: center;
    margin: 0 auto;
    width: 100% !important;
  }

  .logo {
    width: 100px;
    height: 100px;
  }

  .page-login {
    display: flex;
    height: 100vh;
    width: 100%;
    min-height: 100%;
  }

  .card-checked {
    border: '1px solid #24d2ab' !important
  }

  .page-login-container {
    width: auto;
    flex: 1 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .content-login {
    width: 580px;
    min-height: 500px;
    margin: 0 auto;
    padding: 40px;
  }

  .content-login-header {
    font: 400 24px/50px "Gotham Pro", "Proxima Nova", Arial, serif;
    margin: 0 0 15px;
    color: #343434;
  }

  .blue-radial {
    background-color: #033259;
    background-image: radial-gradient(65% at 0 0, circle farthest-corner, #0b66b1, #033259);
    background-image: -webkit-radial-gradient(65% at 0 0, circle farthest-corner, #0b66b1, #033259);
  }

  .blue-radial-right {
    background-image: radial-gradient(25% 25% at 0 0, circle, #0b66b1, #033259);
    /* background-image: radial-gradient(70% 45% at 0 0, circle farthest-corner, #0b66b1, #033259); */
    background-image: -webkit-radial-gradient(70% 45% at 0 0, circle farthest-corner, #0b66b1, #033259);
  }

  .logo-container {
    width: 200px;
    height: 58px;
    position: absolute;
    top: 25px;
    left: 30px;
    background: url(${LogoLabel}) top center/contain no-repeat;
  }

  .image-container {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    z-index: 2;
  }

  .image-container-header {
    font-size: 21px;
  }

  .image-container-width {
    width: 300px;
  }

  .image-container-width-content {
    margin: 100px 0 130px;
    width: 350px;
  }

  .image-container-header-content {
    color: #fff;
    font: 400 18px/24px "Gotham Pro", "Proxima Nova", Arial, serif;
    margin-bottom: 15px;
  }

  .image-container-content {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .header-drop {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {

    .page-side-bar {
      display: none;
    }

    .page-login-container {
      justify-content: flex-start;
    }

    .content-login {
      padding: 15px;
      width: 100%;
      max-width: 530px;
    }

    .content-login::before {
      content: '';
      display: block;
      width: 230px;
      height: 65px;
      background: url(${LogoWhite}) center/contain no-repeat;
      margin: 35px auto 35px;
    }
  }


  .logo-header {
    width: 100%;
    padding: 28px;
  }

  .logo-header-collapsed {
    width: 100%;
    padding: 5px;
  }

  #components-layout-demo-side .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout-background {
    background: #fff;
  }

  .header-title {
    font-size: 1.2em;
  }

  .marker {
    width: 100px !important;
    height: 100px !important;
  }

  #root {
    height: 100% !important;
  }

  .App-header {
    margin: 0 auto;
    width: 100%;
  }

  .App {
    background-color: #033259;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 150px;
    margin-top: 120px;
  }

  .ui.vertical.menu .item>i.icon {
    width: 1.18em;
    float: left !important;
    margin: 0px 11px 0px 0em !important;
  }

  .react-datepicker-popper {
    z-index: 99999 !important;
  }

  .ui.inverted.pointing.menu .active.item:after {
    background: #2a5071 !important;
  }

  .login-form {
    margin: 0 auto;
    width: 300px;
    text-align: center;
  }

  .App-title {
    font-size: 1em;
    color: white;
  }

  .App-intro {
    font-size: large;
  }

  .card-custom {
    height: 347px;
    overflow-y: auto;
  }

  .card-content {
    height: 490px;
    min-height: 450px;
    overflow-x: auto;
    max-height: 500px !important;
  }

  .ant-layout-sider .ant-layout-sider-dark,
  .ant-layout-sider .ant-layout-sider-dark {
    background: #2a5071 !important;
  }

  .ant-empty {
    margin: 80px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ui-box ui-menu__item__wrapper::before {
    background-color: #2a5071 !important;
  }

  .sidebar {
    height: 100vh;
    width: 200px;
    position: fixed;
    background-color: #033259;
    color: white;
  }

  .menu-bar {
    width: 200px !important;
    background-color: #033259 !important;
    color: white !important;
    border: transparent !important;
  }

  .menu-bar > li > a {
    border-right: 1px solid #033259 !important;
  }

  .menu-bar > li > a:hover {
    background-color: #2a5071 !important;
  }

  .content {
    margin-left: 200px;
  }

  .menu-button {
    display: none !important;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }

  .list-card .ant-card-body {
    min-height: 115px !important;
  }

  #filter .ant-form-item{
    margin-bottom: 0 !important;
  }


  .image-view {
    text-align: center;
    height: 600px;
    line-height: 600px;
    background: #364d79;
    overflow-y: hidden;
  }

  .button-link {
    background-color: transparent;
    border: none;
  }
`

export const items = [
  {
    text: 'Dashboard',
    value: 'dashboard',
  },
  {
    text: 'BI - Analytics',
    value: 'analytics',
  },
  {
    text: 'Transações',
    value: 'transactions',
  },
  {
    text: 'Pagamentos',
    value: 'payments',
  },
  {
    text: 'Missões',
    value: 'missions',
  },
  {
    text: 'Exportação',
    value: 'reports',
  },
  {
    text: 'Produtos',
    value: 'products',
  },
  {
    text: 'Formulários',
    value: 'forms',
  },
  {
    text: 'Sub-contas',
    value: 'accounts',
  },
  {
    text: 'Workforce',
    value: 'workforce',
  },
  {
    notifications: 'Notificações',
    value: 'notifications',
  },
]

export const permissionsLabels = {
  dashboard: 'Dashboard',
  analytics: 'BI - Analytics',
  transactions: 'Transações',
  payments: 'Pagamentos',
  missions: 'Missões',
  missions_feeds: 'Atividades Missões',
  missions_schedules: 'Agendamentos Missões',
  missions_launch: 'Missões Lançadas',
  missions_registers: 'Missões Finalizadas',
  reports: 'Exportação',
  products: 'Produtos',
  forms: 'Formulários',
  accounts: 'Sub-contas',
  private_accounts: 'Contas Privadas',
  workforce: 'Workforce',
  notifications: 'Notificações',
}

import t from 'prop-types'

export const MissionsPropsTypes = {
  custom_distance: t.oneOfType([t.string]),
  description: t.string,
  expired_at: t.oneOfType([t.string]),
  identifier: t.oneOfType([t.string]),
  lat: t.oneOfType([t.number]),
  lng: t.oneOfType([t.number]),
  missions_main_id: t.oneOfType([t.string]),
  name: t.oneOfType([t.string]),
  place: t.oneOfType([t.string]),
}

export const ViewportPropTypes = {
  zoom: t.number,
  height: t.number,
  latitude: t.number,
  longitude: t.number,
  width: t.number,
}

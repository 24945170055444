import React from 'react'
import ReactQuill from 'react-quill'

import { Form, Input } from 'antd'
import t from 'prop-types'

import CustomFields from '../../components/CustomFields'
import { MissionsPropsTypes } from './lib'

export function Info({
  customFields,
  form,
  mission,
  onChange,
  onChangeDescription,
  onChangeInputValueCustomFields,
}) {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={mission}
      onFieldsChange={onChange}
    >
      <Form.Item label="Nome da Missão" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="ID" name="identifier">
        <Input />
      </Form.Item>

      <CustomFields
        data={customFields}
        onChange={onChangeInputValueCustomFields}
      />
      <Form.Item label="Descrição">
        <ReactQuill
          onChange={onChangeDescription}
          defaultValue={mission.description}
        />
      </Form.Item>
    </Form>
  )
}

Info.propTypes = {
  onChange: t.func.isRequired,
  form: t.objectOf({}),
  mission: t.objectOf(MissionsPropsTypes).isRequired,
  onChangeDescription: t.func.isRequired,
  onChangeInputValueCustomFields: t.func,
  customFields: t.arrayOf(t.objectOf(t.any)),
}

export default Info

import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const styles = {
  iconSize: {
    fontSize: 24,
  },
}

export default function Loading() {
  return (
    <div className="loading">
      <Spin indicator={<LoadingOutlined style={styles.iconSize} spin />} />
    </div>
  )
}

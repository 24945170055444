import React from 'react'

import { Tag } from 'antd'

export default [
  {
    title: 'Nome',
    render: (item) => <span>{item?.name.split(' ')[0]}</span>,
  },
  {
    title: 'Valor Consolidado (R$)',
    dataIndex: 'total',
  },
  {
    title: 'Status',
    render: (item) => (
      <Tag color={item.is_consolidated ? 'green' : 'yellow'}>
        {item.is_consolidated ? 'Consolidado' : 'Aberto'}
      </Tag>
    ),
  },
]

/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'

import {
  FilterOutlined,
  CopyOutlined,
  PlusOutlined,
  EditOutlined,
  FileExcelOutlined,
  CarryOutOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import {
  Select,
  Input,
  Button,
  message,
  Card,
  DatePicker,
  Dropdown,
  Col,
  Row,
} from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'
import { v4 } from 'uuid'

import { ModalCustomersUsersTags } from '../ModalCustomersUsersTags'

const { Search } = Input
const { RangePicker } = DatePicker

const Header = ({
  filters,
  handleSearch,
  onChangeFilters,
  onSubmitExport,
  loadingTags,
  loadingFilter,
  onSubmitFilter,
  onOpen,
  customersUsersTags,
  customersUsersTagsName,
  onUpdateCustomersUsersTags,
  onSubmitCustomersUsersTags,
  categories,
}) => {
  const token = localStorage.getItem('@Mission:token')
  const url = `https://app.missionbrasil.com.br/signup?token=${token}`

  const [openModalTags, setOpenModalTags] = useState(false)
  const [userTags, setUserTags] = useState({ name: null, tags: { items: [] } })

  function onChangeTags(key, value) {
    return setUserTags({ ...userTags, [key]: value })
  }

  const getTags = () => {
    if (!userTags) {
      return []
    }

    if (Array.isArray(userTags)) {
      return userTags
    }

    return userTags?.tags.items || []
  }

  function onAddItem(value) {
    return setUserTags({
      ...userTags,
      tags: { items: [...getTags(), { id: v4(), value }] },
    })
  }

  function onRemoveItem(id) {
    const items = getTags().filter((tag) => tag.id !== id)

    return setUserTags((prev) => ({ ...prev, tags: { items } }))
  }

  function onCreate() {
    setOpenModalTags(false)
    return onSubmitCustomersUsersTags(userTags)
  }

  function onUpdate() {
    setOpenModalTags(false)
    return onUpdateCustomersUsersTags(userTags)
  }

  function onSubmit() {
    const isCreate =
      customersUsersTags === null || customersUsersTags.length === 0

    if (isCreate) {
      return onCreate()
    }

    return onUpdate()
  }

  function handleMenuClick(key) {
    if (key === '1') {
      return setOpenModalTags(true)
    }

    message.success('Cópia do Link realizada com sucesso!')
    return navigator.clipboard.writeText(url)
  }

  useEffect(() => {
    if (customersUsersTags !== null) {
      setUserTags(customersUsersTags)
    }
  }, [loadingTags]) //eslint-disable-line

  const dateFormat = 'YYYY-MM-DD'

  const menuItems = {
    onClick: ({ key }) => handleMenuClick(key),
    items: [
      {
        key: 1,
        icon: customersUsersTags !== null ? <EditOutlined /> : <PlusOutlined />,
        label: `${
          customersUsersTags !== null ? 'Atualizar' : 'Adicionar'
        } Campo de
            Cadastro`,
      },
      {
        key: 2,
        icon: <CopyOutlined />,
        label: 'Copiar Link de Cadastro',
      },
    ],
  }

  return (
    <Card style={{ marginBlock: 10 }}>
      <Row gutter={24}>
        <Col span={6}>
          <Search
            placeholder="Buscar contas..."
            onChange={({ target }) => handleSearch(target.value)}
          />
        </Col>
        <Col span={6}>
          <RangePicker
            style={{ width: '100%' }}
            defaultValue={[
              dayjs(filters.start, dateFormat),
              dayjs(filters.final, dateFormat),
            ]}
            onChange={(value) => {
              onChangeFilters({
                start: dayjs(value[0]?.$d).format('YYYY-MM-DD'),
                final: dayjs(value[1]?.$d).format('YYYY-MM-DD'),
              })
            }}
            format={dateFormat}
          />
        </Col>
        <Col span={6}>
          <Select
            mode="multiple"
            showSearch
            style={{ width: '100%' }}
            disabled={
              customersUsersTags === null || customersUsersTags.length === 0
            }
            onChange={(values) => onChangeFilters({ tags: values })}
            placeholder={customersUsersTagsName || 'Carregando...'}
            filterOption={(input, option) =>
              (option?.label ?? '').includes(input)
            }
            filterSort={(optionA, optionB) => {
              return optionA.label.localeCompare(optionB.label, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            }}
            options={customersUsersTags?.map((i) => ({
              value: i.id,
              label: i.value,
            }))}
          />
        </Col>
        <Col span={6}>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Categorias"
            disabled={categories.length === 0}
            onChange={(value) => onChangeFilters({ missionsMainId: value })}
            options={categories.map((item) => ({
              value: item.uid,
              label: item.title,
            }))}
          />
        </Col>
      </Row>
      <Row gutter={10} style={{ marginTop: 15 }}>
        <Col>
          <Button
            size="small"
            type="primary"
            loading={loadingFilter}
            disabled={loadingFilter}
            onClick={onSubmitFilter}
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            loading={loadingFilter}
            disabled={loadingFilter}
            onClick={onSubmitExport}
            icon={<FileExcelOutlined />}
          >
            Exportar
          </Button>
        </Col>
        <Col>
          <Button size="small" icon={<CarryOutOutlined />} onClick={onOpen}>
            Conversão de Pontos para Todos
          </Button>
        </Col>
        <Col>
          <Dropdown.Button
            menu={menuItems}
            size="small"
            placement="bottomCenter"
            icon={<MoreOutlined />}
            buttonsRender={([leftButton, rightButton]) => [
              leftButton,
              React.cloneElement(rightButton),
            ]}
          >
            Outras Opções
          </Dropdown.Button>
        </Col>
      </Row>

      <ModalCustomersUsersTags
        userTags={getTags()}
        open={openModalTags}
        loading={loadingTags}
        onAddItem={onAddItem}
        onChange={onChangeTags}
        onRemoveItem={onRemoveItem}
        onCancel={() => setOpenModalTags(false)}
        onSubmit={() => onSubmit()}
      />
    </Card>
  )
}

Header.defaultTypes = {
  handleSearch: t.func.isRequired,
}

Header.propTypes = {
  onOpen: t.func,
  loadingTags: t.bool,
  loadingFilter: t.bool,
  onSubmitFilter: t.func,
  onSubmitExport: t.func,
  onChangeFilters: t.func,
  customersUsersTagsName: t.string,
  filters: t.objectOf(t.any),
  handleSearch: t.func.isRequired,
  customersUsersTags: t.objectOf(t.any),
  onUpdateCustomersUsersTags: t.func,
  onSubmitCustomersUsersTags: t.func,
  categories: t.arrayOf(t.objectOf(t.string)),
}

export default Header

import React, { useState } from 'react'

import { Modal, Button, Input, Form, Tag } from 'antd'
import t from 'prop-types'

export function ModalCustomersUsersTags({
  open,
  userTags,
  loading,
  onSubmit,
  onCancel,
  onChange,
  onAddItem,
  onRemoveItem,
}) {
  const [value, setValue] = useState(null)

  return (
    <Modal
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      title="Adicionar Campo de Seleção"
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Enviar
        </Button>,
      ]}
    >
      <Form>
        <Form.Item>
          <Input
            placeholder="Nome do Campo"
            value={userTags.name || 'Nome do Campo'}
            onChange={({ target }) => onChange('name', target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input.Group compact>
            <Input
              value={value}
              placeholder="Valor do Campo"
              style={{ width: 'calc(100% - 144px)' }}
              onChange={({ target }) => setValue(target.value)}
            />
            <Button
              type="primary"
              disabled={value === null}
              onClick={() => {
                setValue(null)
                return onAddItem(value)
              }}
            >
              Adicionar Valores
            </Button>
          </Input.Group>
        </Form.Item>
        <Form.Item>
          {userTags.map((i) => (
            <Tag key={i.id} closable onClose={() => onRemoveItem(i.id)}>
              {i.value}
            </Tag>
          ))}
        </Form.Item>
      </Form>
    </Modal>
  )
}

ModalCustomersUsersTags.propTypes = {
  open: t.bool,
  onSubmit: t.func,
  onCancel: t.func,
  loading: t.bool,
  onChange: t.func,
  onAddItem: t.func,
  onRemoveItem: t.func,
  userTags: t.arrayOf([t.string]),
}

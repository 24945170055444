import React, { useEffect } from 'react'

import { Empty, Checkbox } from 'antd'
import t from 'prop-types'

import useProductsCustomers from '~/hooks/useProductsCustomers'

export function Products({ onClick }) {
  const { productsCustomers, loadingProductsCustomers, getProducts } =
    useProductsCustomers()

  useEffect(() => {
    getProducts()
  }, []) //eslint-disable-line

  return (
    <>
      {!loadingProductsCustomers && productsCustomers.length === 0 && (
        <Empty description="Nenhum Produto foi Cadastrado" />
      )}

      {!loadingProductsCustomers && productsCustomers.length > 0 && (
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={onClick}
          options={productsCustomers.map((i) => ({
            label: i.title,
            value: i.uid,
          }))}
        />
      )}
    </>
  )
}

Products.propTypes = {
  onClick: t.func.isRequired,
}

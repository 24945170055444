import styled from 'styled-components'

export const ContainerHeader = styled('div')`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  margin: auto;
  padding: 12px 0px;
  border-bottom: 1px solid #f1f2f3;
  margin-bottom: 20px;
`

export const DividerCustom = styled.div`
  height: 0.5px;
  width: 100%;
  margin: 6px auto;
  background-color: #f1f2f3;
`

export const HeaderText = styled('h1')`
  font-size: 2em;
  font-weight: bold;
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 90%;
  align-items: flex-start;
  justify-content: flex-start;
`

export const UserLabels = styled.div`
  display: flex;
  flwrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 6px;
`

export const FlexContainerWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ContainerRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 20%;
`

export const StatusLabel = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => (props.isChecked ? '#3ad089' : '#fe5565')};
`

export const Title = styled.span`
  font-size: 1rem;
  display: block;
  font-weight: 700;
`

export const Subtitle = styled.span`
  max-width: 150px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
  font-size: 0.7rem;
  font-weight: 600;
`

export const CheckLabel = styled.span`
  font-size: 0.65rem;
  color: #666;
  font-weight: 600;
`

export const styleDateCard = {
  height: '100vh',
  maxHeight: 800,
  overflowY: 'auto',
  overflowX: 'hidden',
  textAlign: 'center',
  paddingTop: 0,
}

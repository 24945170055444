import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

function useReceiptMission() {
  const [loadingReceipt, setLoadingReceipt] = useState(false)

  const generateReceipt = useCallback(async (id) => {
    message.info({
      duration: 5,
      content: 'Aguarde um instante, o arquivo está sendo enviado...',
    })
    setLoadingReceipt(true)
    try {
      const res = await api.put(`reports/registers-user-receipt/${id}`, null, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      message.success({
        duration: 5,
        content: 'Recibo foi gerado com sucesso!',
      })

      window.open(res.data.register.url_user_mission_receipt, '_self')
      return setLoadingReceipt(false)
    } catch (error) {
      setLoadingReceipt(false)
      return message.error(error.message)
    }
  }, [])

  const generateCustomerReceipt = useCallback(async (id) => {
    message.info({
      duration: 5,
      content: 'Aguarde um instante, o arquivo está sendo enviado...',
    })
    setLoadingReceipt(true)
    try {
      const res = await api.put(
        `reports/registers-customer-receipt/${id}`,
        null,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      message.success({
        duration: 5,
        content: 'Recibo foi gerado com sucesso!',
      })

      window.open(res.data.register.url_customer_mission_receipt, '_self')
      return setLoadingReceipt(false)
    } catch (error) {
      setLoadingReceipt(false)
      return message.error(error.message)
    }
  }, [])

  return {
    loadingReceipt,
    generateReceipt,
    generateCustomerReceipt,
  }
}

export default useReceiptMission

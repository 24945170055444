import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useAnalytics() {
  const [loadingAnalytics, setLoadingAnalytics] = useState(true)
  const [loadingChart, setLoading] = useState(false)

  const [chartsData, setChartsData] = useState([])
  const [missionsUsersIds, setMissions] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [totalCharts, setTotalCharts] = useState(0)
  const [charts, setCharts] = useState([])

  const getMissionsUsersIds = useCallback(async (params) => {
    try {
      setLoadingAnalytics(true)
      const res = await api.get('missions-users/customers/explore', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissions(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingAnalytics(false)
    }
  }, [])

  async function onDeleteChart(id) {
    setLoading(true)
    try {
      const res = await api.delete('/analytics-data', {
        params: {
          chartId: id,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setChartsData(chartsData.filter((c) => c.config.id !== id))

      return message.success('Chart removido com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getUserCharts = useCallback(
    async ({ params }) => {
      setLoading(true)
      try {
        const res = await api.get('analytics-data', {
          params,
          validateStatus() {
            return true
          },
        })

        if (res.status !== 200) {
          throw new Error(res.data.message)
        }

        setTotalCharts(res.data.charts.length)

        if (res.data.charts.length > 0) {
          setTotalCount(Number(res.data.charts[0].total_rows))
        }

        return setCharts(res.data.charts)
      } catch (error) {
        return message.error(error.message)
      } finally {
        setLoading(false)
      }
    },
    [] //eslint-disable-line
  )

  async function onChangeChart(params) {
    api.put('analytics-data', params)
  }

  async function onReorderChart(params) {
    api.put('analytics-data/reorder', params)
  }

  return {
    charts,
    onChangeChart,
    onReorderChart,
    totalCharts,
    chartsData,
    totalCount,
    getUserCharts,
    onDeleteChart,
    loadingChart,
    loadingAnalytics,
    missionsUsersIds,
    setChartsData,
    getMissionsUsersIds,
  }
}

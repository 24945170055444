import React, { useEffect } from 'react'

import {
  InboxOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Upload, message, Skeleton } from 'antd'
import t from 'prop-types'

import useAttachments from '~/hooks/useAttachments'

const { Dragger } = Upload

export function UploadFiles({ id }) {
  const {
    onUploadData,
    onRemoveFile,
    loadingFile,
    getFilesByMissionId,
    files,
  } = useAttachments()

  function getBase64(file, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  }

  function beforeUpload(file) {
    const is10MB = file.size / 1024 / 1024 < 10

    if (!is10MB) {
      message.error('Arquivo deve ter no máximo 10MB')
    }

    return is10MB
  }

  async function onChange(info) {
    const { file } = info
    const { status, name, size, type, originFileObj } = file

    if (status !== 'uploading') {
      message.info(`Arquivo ${name} está sendo enviando...`)
    }

    if (status === 'done') {
      getBase64(originFileObj, async (base64) => {
        await onUploadData({
          uid: id,
          file: { name, size, type, base64 },
        })

        getFilesByMissionId(id)
      })
      message.success(`Arquivo ${name} enviado com sucesso`)
    } else if (status === 'error') {
      message.error(`${name} falha no envio do arquivo!`)
    }
  }

  useEffect(() => {
    getFilesByMissionId(id)
  }, []) //eslint-disable-line

  const draggerProps = {
    multiple: true,
    defaultFileList: files,
    accept: 'application/pdf',
    beforeUpload,
    onRemove: (e) => onRemoveFile(e.uid),
    onChange: (info) => onChange(info, onUploadData, id),
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => onSuccess(`${file} ok`), 0),
    showUploadList: {
      showRemoveIcon: true,
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined />,
      removeIcon: <DeleteOutlined onClick={() => true} />,
    },
  }

  return (
    <div className="clearfix">
      {loadingFile && <Skeleton active />}

      {!loadingFile && files && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste o arquivo para fazer o upload.
          </p>
          <p className="ant-upload-hint">
            Arquivo deve ser menor ou igual a 3 MB!
          </p>
        </Dragger>
      )}
    </div>
  )
}

UploadFiles.propTypes = {
  id: t.string.isRequired,
}

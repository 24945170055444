import React from 'react'

import t from 'prop-types'

import { AuthProvider } from './auth'

function AppProvider({ children }) {
  return <AuthProvider>{children}</AuthProvider>
}

AppProvider.propTypes = {
  children: t.node.isRequired,
}

export default AppProvider

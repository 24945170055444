import React from 'react'

import { Form, Row, Col, Button, Card } from 'antd'
import t from 'prop-types'

import { Categories } from './Categories'
import { Forms } from './Forms'
import { Groups } from './Groups'
import { Licenses } from './Licenses'
import { OrderBy } from './OrderBy'
import { RangeDates } from './RangeDates'
import { Search } from './Search'
import { Tags } from './Tags'
import { UfsAndCities } from './UfsAndCities'
import useQueryFilters from './useQueryFilters'

const ColDefault = ({ children }) => (
  <Col xs={24} xl={4} sm={12} md={6}>
    {children}
  </Col>
)

export function Filter({
  form,
  isPrivate,
  onSubmit,
  hasOrder,
  loading,
  onReset,
  children,
}) {
  const { getUrlQuery, onUpdateQuery, onResetQuery } = useQueryFilters()

  return (
    <Card size="small" id="filter">
      <Form
        form={form}
        size="small"
        layout="vertical"
        onFinish={(_) => onSubmit(getUrlQuery())}
      >
        <Row gutter={[8, 8]}>
          <ColDefault>
            <Search onChange={onUpdateQuery} />
          </ColDefault>
          <ColDefault>
            <Categories onChange={onUpdateQuery} />
          </ColDefault>
          <UfsAndCities onChange={onUpdateQuery} />
          {hasOrder && (
            <ColDefault>
              <OrderBy onChange={onUpdateQuery} />
            </ColDefault>
          )}
          <ColDefault>
            <RangeDates onChange={onUpdateQuery} />
          </ColDefault>
          <ColDefault>
            <Groups onChange={onUpdateQuery} />
          </ColDefault>
          <ColDefault>
            <Forms onChange={onUpdateQuery} />
          </ColDefault>
          {isPrivate ? (
            <>
              <ColDefault>
                <Tags onChange={onUpdateQuery} />
              </ColDefault>
              <ColDefault>
                <Licenses onChange={onUpdateQuery} />
              </ColDefault>
            </>
          ) : null}
        </Row>
        <Row style={{ marginTop: 10, gap: 10 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Filtrar
          </Button>
          <Button
            type="default"
            htmlType="reset"
            onClick={(event) => {
              onResetQuery(event)
              onReset(event)
            }}
          >
            Resetar
          </Button>
          {children}
        </Row>
      </Form>
    </Card>
  )
}

Filter.propTypes = {
  form: t.objectOf(),
  onReset: t.func,
  onSubmit: t.func,
  hasOrder: t.bool,
  loading: t.bool,
  isPrivate: t.bool,
  children: t.element,
}

ColDefault.propTypes = {
  children: t.element,
}

import React from 'react'

import { Button, Form, Input } from 'antd'
import t from 'prop-types'

const Phone = ({ form, loading, handlePhone }) => (
  <>
    <div className="content-login-header">
      <h3>Seu Telefone</h3>
    </div>

    <Form form={form} layout="vertical" onFinish={handlePhone}>
      <Form.Item
        label="Seu celular. Ex: 4899999999"
        name="phone"
        rules={[
          {
            required: true,
            message: 'Digite seu nome completo',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Confirmar
        </Button>
      </Form.Item>
    </Form>
  </>
)

Phone.propTypes = {
  form: t.objectOf(t.any),
  loading: t.bool,
  handlePhone: t.func,
}

export default Phone

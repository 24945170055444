import { format, subHours, differenceInDays } from 'date-fns'

import { SUMMER_TIME } from '~/config/env'

export function dateParse(dateData) {
  if (SUMMER_TIME === 'true') {
    const subDate = subHours(new Date(dateData), 1)
    return format(subDate, 'dd/MM/yyyy HH:mm')
  }

  return format(new Date(dateData), 'dd/MM/yyyy HH:mm')
}

export function checkDayRange(pastDate) {
  const today = new Date()
  const past = new Date(pastDate)

  return differenceInDays(today, past)
}

export function onConvertArrayToObject(array, key, value) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[value],
    }
  }, initialValue)
}

export function getPhoneRaw(phone) {
  return phone
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace('-', '')
}

export function getPhoneWithoutCode(phone) {
  return phone.replace('+55', '')
}

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useFormsMissions() {
  const [loadingFormsMissions, setLoadingFormsMissions] = useState(false)
  const [hasSelectedForm, setHasSelectedForm] = useState(null)

  const findFormsMissions = useCallback(async (params) => {
    setLoadingFormsMissions(true)
    try {
      const res = await api.get('form-missions/customers', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setHasSelectedForm(res.data.formsMissions)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFormsMissions(false)
    }
  }, [])

  async function onChangeFormsMissions(data) {
    setLoadingFormsMissions(true)
    try {
      const res = await api.put('form-missions/customers', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      await findFormsMissions({ missionId: data.mission_id })

      return message.success('Alteração realizada com sucesso.')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingFormsMissions(false)
    }
  }

  return {
    hasSelectedForm,
    findFormsMissions,
    loadingFormsMissions,
    onChangeFormsMissions,
  }
}

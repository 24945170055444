import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ContainerChart = styled.div`
  width: 100%;
  height: 300px;
`

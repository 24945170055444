import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ShoppingOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Drawer, Modal } from 'antd'

import { ImportStore } from './importStore'
import { StoreForm } from './StoreForm'

export function HeaderActions() {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, display: 'flex' }}>
          <Button
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => navigate('/workforce/managament/stores')}
          >
            <ShoppingOutlined />
            Minhas Lojas
          </Button>
          <Button
            htmlType="button"
            type="text"
            style={{ marginRight: 8 }}
            onClick={() => navigate('/workforce/managament/stores-requests')}
          >
            <ShoppingOutlined />
            Histórico de Demandas
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => setIsDrawerOpen(true)}
          >
            <PlusOutlined />
            Criar Loja
          </Button>
          <Button
            htmlType="button"
            style={{ marginRight: 8 }}
            onClick={() => setIsModalOpen(true)}
          >
            <UploadOutlined />
            Importação
          </Button>
        </div>
      </div>
      <Modal
        title="Importar Demanda(s)"
        open={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsModalOpen(false)}
      >
        <ImportStore onConfirm={() => setIsModalOpen(false)} />
      </Modal>
      <Drawer
        title="Criar Loja"
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <StoreForm onConfirm={() => setIsDrawerOpen(false)} />
      </Drawer>
    </>
  )
}

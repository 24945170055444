import React from 'react'

import { Modal, Input, Radio, Space } from 'antd'
import t from 'prop-types'

import options from '../../../helpers/reprovedOptions'

const { TextArea } = Input

export function ModalDescription({
  handleOk,
  onCancel,
  visible,
  status,
  loading,
  onChange,
  approveDescription,
  reproveDescription,
}) {
  return (
    <Modal
      destroyOnClose
      onOk={handleOk}
      open={visible}
      okText="Confirmar"
      onCancel={onCancel}
      closable={loading}
      confirmLoading={loading}
      cancelText="Cancelar"
      title={status === 1 ? 'Aprovar' : 'Motivo'}
    >
      {status === 1 ? (
        <TextArea
          rows={4}
          onChange={(value) => onChange('aproveDescription', value)}
          value={approveDescription}
          placeholder="Deseja atribuir alguma observação?"
        />
      ) : (
        <>
          <Radio.Group
            onChange={(event) => onChange(null, event)}
            value={reproveDescription}
          >
            <Space direction="vertical">
              {options.map((i) => (
                <Radio value={i.value} key={i.id}>
                  {i.value}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <TextArea
            rows={4}
            style={{ marginTop: 10 }}
            placeholder="Caso seja por outro motivo, descreva os itens necessários..."
            onChange={(event) => onChange(null, event)}
          />
        </>
      )}
    </Modal>
  )
}

ModalDescription.defaultTypes = {
  status: false,
  visible: false,
}

ModalDescription.propTypes = {
  handleOk: t.func.isRequired,
  onCancel: t.func.isRequired,
  visible: t.bool.isRequired,
  status: t.bool,
  loading: t.bool,
  onChange: t.func.isRequired,
  approveDescription: t.string,
  reproveDescription: t.string,
}

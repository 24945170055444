import { GOOGLE_API_KEY } from '~/config/env'

// DASHBOARD
export const CUSTOMERS = 'customers/'
export const DASHBOARD = 'customers/dashboard'
export const CUSTOMER_LOGIN = 'customers/login'
export const CUSTOMERS_FORGOT = 'customers/recovery'
export const CUSTOMERS_UPDATE = 'master/customers/update'
export const CUSTOMERS_REPORT = 'customers/register-filter/'
export const DASHBOARD_FETCH_COORDINATES = (id) =>
  `customers/report-state-launchs/${id}`

// BUDGET

export const BUDGET_REQUEST = 'credit-request/create'
export const TRANSACTIONS_FILTER = 'customers/transactions-report/'
export const AVERAGE_CATEGORIES = 'customers/missions-main/'
export const REPORT_TRANSACTIONS = 'customers/'

// MISSIONS
export const MISSIONS = 'customers/missions/find'
export const MISSIONS_SEARCH = 'missions-search'

export const MISSIONS_CREATE = 'customers/missions-create'
export const MISSIONS_UPDATE = 'customers/missions/update/'
export const MISSIONS_UPDATE_STATUS = 'customers/missions-update-status'
export const MISSIONS_REPORT_STATE = 'customers/report-state/'
export const MISSION_FEEDS_BY_USER = 'missions-feeds-count-by-user'

export const PHOTOS = 'photos'
export const PHOTOS_COUNT = 'photos/customers/count/'
export const PHOTOS_ORDER = 'photos/customers/reorder'

export const MISSIONS_MAIN_ALL = 'missions-main/customers'
export const MISSIONS_FEEDS = 'customers/missions-feeds/'
export const MISSIONS_PLACES = 'missions-places'
export const MISSIONS_PARTICIPANTS = 'missions/participants/'

export const ATTACHMENTS = 'attachments'
export const ATTACHMENTS_UPLOAD = 'attachments'
export const ATTACHMENTS_REMOVE = 'attachments'

export const POINT_OF_SALE = (id) => `point-of-sale/sent/${id}`

// REGISTERS
// export const REGISTERS_CUSTOMERS = 'customers/registers'
export const REGISTERS_FEEDS = 'registers/missions-feeds/'
export const REGISTERS_DETAIL = (registerId) =>
  `registers/customers/${registerId}`
export const REGISTERS_UPDATE = 'registers/customers'
export const REGISTERS_FILTER = 'customers/register-data-period/'
export const REGISTERS_UPDATE_ALL = 'customers/registers/update-list'

export const REGISTERS_ZIP = 'customers/extract-files/'

export const REPORTS_REGISTERS_DOSSIE = 'reports/registers-dossie/'
export const REPORTS_REGISTERS_MAIL = 'reports/mail-dossie'

// CUSTOM STATUS
export const CUSTOM_STATUS_FETCH = 'custom-status/registers'
export const CUSTOM_STATUS_UPDATE = 'custom-status/registers'

// INVOICE
export const INVOICE_FETCH = (id) => `invoices/${id}`

// CITIES
export const COUNTIES = (address) =>
  `missions/counties/fetch?address=${address}`
export const GEOCODE = (address) =>
  `https://maps.google.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=${address}&language=pt-BR&components=country:BR`

export const UFS = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
export const CITIES = (cities) =>
  `http://servicodados.ibge.gov.br/api/v1/localidades/estados/${cities}/municipios`

export const UFS_MIS = 'geocode/customers/search'
export const CITIES_MIS = 'geocode/customers/search'

// FORMULARIO

export const FORMS_ITEMS_LIST = 'customers/forms-items/'
export const FORMS_ITEMS_CREATE = 'customers/forms-items/create'
export const FORMS_ITEMS_REMOVE = 'customers/forms-items-remove/'
export const FORMS_ITEMS_UPDATE = 'customers/forms-items/update'
export const FORMS_ITEMS_UPDATE_ORDER = 'customers/forms-items/change-order'
export const FORMS_ITEMS_UPLOAD_IMAGE = (id) =>
  `customers/forms-items-questions-multiples/${id}/update-image`
export const FORMS_HAS_MISSIONS = 'customers/has-forms-missions/'
export const FORMS_MISSIONS_CREATE = 'customers/forms-missions/create'
export const FORMS_MISSIONS_UPDATE = 'customers/forms-missions/update'
export const FORMS_ITEMS_MULTIPLES = 'forms-items/multiple/'
export const FORMS_ITEMS_MULTIPLES_CREATE =
  'customers/forms-items-questions-multiples/create'
export const FORMS_ITEMS_MULTIPLES_REMOVE =
  'customers/forms-items-questions-multiples-remove/'
export const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER =
  'customers/forms-items-questions-multiples/change-order'
export const FORMS_ITEMS_OPTIONAL_GET = 'customers/forms-items/parents/get'
export const FORMS_ITEMS_OPTIONAL_CREATE =
  'customers/forms-items/parents/insert'
export const FORMS_ITEMS_OPTIONAL_REMOVE = ({ formsItemsId, fiqmParentId }) =>
  `customers/forms-items/parents/${formsItemsId}/forms-items-id/${fiqmParentId}/fiqm-parent-id/remove`

export const FORMS_DATA = 'customers/forms-data-by-mission/'
export const EXPORT_FORMS_DATA = (customerId, missionsId) =>
  `customers/forms-data/customer/${customerId}/mission/${missionsId}/export-to-excel`

// PRODUCTS ITEMS
export const PRODUCTS_IMPORT = 'products-items/imports'
export const PRODUCTS_ITEMS_CREATE = 'products-items/create'
export const PRODUCTS_ITEMS_UPDATE = 'products-items/update'
export const PRODUCTS_ITEMS_REMOVE = 'products-items/remove'
export const PRODUCTS_ITEMS = (id, query) => `products-items/${id}?${query}`
export const PRODUCTS_ITEMS_UPDATE_ALL = 'products-items/update-all-status'

// PRODUCTS MISSIONS
export const PRODUCTS_MISSIONS_CREATE = 'products-missions/create'
export const PRODUCTS_MISSIONS_REMOVE = (id) => `products-missions/${id}`
export const PRODUCTS_MISSIONS = (id) => `products-missions/available/${id}`

export const HEAT_MAP = 'customers/heat-map/'
export const FORMS_ITEMS_TYPES = 'forms-items-types'
export const APP_VERSIONS_ALL = 'customers/versions/all'
export const PHOTO_UPDATE_ORDER = 'missions-photos/change-order'
export const PHOTO_DOWNLOAD_ZIP = 'customers/extract-files/'
// FILES
export const MISSIONS_FILES_GET = (id) => `missions/${id}/files/fetch`
export const MISSIONS_FILES_POST = (id) => `missions/${id}/files/upload`
export const MISSIONS_FILES_DEL = (id) => `missions/${id}/file/delete`

// WALLET

export const WALLET_FETCH_CONSOLIDATED = 'user/wallet/consolidated/fetch'
export const WALLET_TRANSACTION = (id) => `user/wallet/${id}/temporary/insert`
export const WALLET_LIST_CONSOLIDATED = 'user/wallet/temporary/fetch'
export const WALLET_CONSOLIDATE = (id) => `user/wallet/${id}/consolidate`
export const WALLET_CONSOLIDATE_UPDATE = (id) => `user/wallet/${id}/update`

// ANALYTICS

export const ANALYTICS_MISSIONS = '/missions-users/customers/explore'
export const ANALYTICS_RUPTURA = '/point-of-sale/analytics/ruptura'
export const ANALYTICS_RUPTURA_AVG = '/point-of-sale/analytics/ruptura-avg'
export const ANALYTICS_RUPTURA_LIST = '/point-of-sale/analytics/ruptura-list'
export const ANALYTICS_PRICES = '/point-of-sale/analytics/prices'
export const ANALYTICS_PRICES_AVG = '/point-of-sale/analytics/prices-avg'
export const ANALYTICS_EXTRA_POINT = '/point-of-sale/analytics/extra-point'
export const ANALYTICS_VISIBILITY = '/point-of-sale/analytics/visibility'
export const ANALYTICS_GONDOLA = '/point-of-sale/analytics/gondola'
export const ANALYTICS_REPORTS = (label) => `/point-of-sale/analytics/${label}`
export const ANALYTICS_CREATE_CHART = '/analytics-data'

export const LIST_PRODUCTS = (customerId) => `/import-products/${customerId}`

export const LIST_APPROVATIONS_BATCH = `/missions/registers/approvations-batch`

export const LIST_APPROVATION_REGISTERS_BATCH = (id) =>
  `/missions/registers/approvation-batch/${id}`

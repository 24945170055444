import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Form } from 'antd'
import queryString from 'query-string'

import useSignUpUser from '~/hooks/useSignUpUser'
import InvalidReferer from '~/pages/Referral/InvalidReferer'
import ModalMobile from '~/pages/Referral/ModalMobile'
import UserData from '~/pages/Referral/UserData'
import ConfirmCode from '~/pages/SignUpUser/ConfirmCode'
import Phone from '~/pages/SignUpUser/Phone'
import Success from '~/pages/SignUpUser/Success'

function Referral() {
  const location = useLocation()
  const [form] = Form.useForm()
  const [values] = useState(queryString.parse(location.search))
  const [isValid, setIsValid] = useState(false)

  const {
    userStep,
    onSignUp,
    userData,
    loadingSign,
    onConfirmCode,
    onSubmitMessage,
    getUserByHostHash,
    userDataByHostHash,
  } = useSignUpUser()

  const handleSubmit = (data) => {
    onSignUp({ ...data, ...values })
  }

  const handlePhone = (value) => {
    onSubmitMessage({
      value: `+55${value.phone}`,
      userId: userData?.user?.uid,
    })
  }

  const handleConfirmCode = async (value) => {
    onConfirmCode({ value: value.code, userId: userData?.user?.uid })
  }

  useEffect(() => {
    getUserByHostHash(values?.referer)
  }, []) // eslint-disable-line

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  useEffect(() => {
    if (values.referer) {
      setIsValid(true)
    }
  }, [values])

  const [open, setOpen] = useState(true)
  const [widthScreen, setWidthScreen] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => setWidthScreen(window.innerWidth))

    if (widthScreen <= 860) {
      setOpen(true)
    }
  }, [widthScreen])

  if (!isValid || !userDataByHostHash) {
    return <InvalidReferer />
  }

  return (
    <div style={{ margin: '0 auto', padding: '40px 20px' }}>
      {widthScreen <= 860 && (
        <ModalMobile
          userDataByHostHash={userDataByHostHash}
          open={open}
          setOpen={setOpen}
        />
      )}
      {userStep === 0 && (
        <UserData
          form={form}
          handleSubmit={handleSubmit}
          loading={loadingSign}
          tags={null}
          userData={userDataByHostHash}
        />
      )}

      {userStep === 1 && (
        <Phone form={form} loading={loadingSign} handlePhone={handlePhone} />
      )}

      {userStep === 2 && (
        <ConfirmCode
          form={form}
          loading={loadingSign}
          handleConfirmCode={handleConfirmCode}
        />
      )}

      {userStep === 3 && <Success />}
    </div>
  )
}

export default Referral

import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useBudget() {
  const [budget, setBudget] = useState(null)
  const [loadingBudget, setLoadingBudget] = useState(false)

  const getBudget = useCallback(async () => {
    setLoadingBudget(true)

    try {
      const res = await api.get('customers-budget', {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setBudget(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingBudget(false)
    }
  }, [])

  return {
    budget,
    getBudget,
    loadingBudget,
  }
}

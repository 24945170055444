import { useCallback, useState } from 'react'

import { message } from 'antd'

import api from '~/services/api'

export default function useCreditRequests() {
  const [creditRequests, setCreditRequests] = useState(true)
  const [creditRequestsCount, setCreditRequestsCount] = useState(0)
  const [loadingCredit, setLoadingCredit] = useState(false)

  const getCreditRequests = useCallback(async (params) => {
    try {
      setLoadingCredit(true)
      const res = await api.get('credit-request', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCreditRequestsCount(res.data.count)
      return setCreditRequests(res.data.creditRequests)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingCredit(false)
    }
  }, [])

  async function onCreatePaymentRequest(data) {
    try {
      setLoadingCredit(true)
      const res = await api.post('credit-request', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return message.success('Crédito solicitado com sucesso!')
    } catch (error) {
      return message.error(error.message)
    } finally {
      getCreditRequests()
      setLoadingCredit(false)
    }
  }

  return {
    loadingCredit,
    creditRequests,
    creditRequestsCount,
    getCreditRequests,
    onCreatePaymentRequest,
  }
}

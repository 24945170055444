import React from 'react'

import { Descriptions, Button, Badge, Spin } from 'antd'
import moment from 'moment'
import t from 'prop-types'

const daysPTBR = {
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
  sunday: 'Domingo',
}

export default function ScheduleInfo({ schedule, onEdit, onCancel }) {
  if (!schedule) {
    return <Spin />
  }

  return (
    <Descriptions title="Informações do agendamento" bordered>
      <Descriptions.Item label="Título" span={3}>
        {schedule.title}
      </Descriptions.Item>
      <Descriptions.Item label="Descrição" span={3}>
        {schedule.description}
      </Descriptions.Item>
      <Descriptions.Item label="Ação" span={3}>
        Ativar missões apenas nos horários escolhidos
      </Descriptions.Item>
      <Descriptions.Item label="Status" span={3}>
        <Badge
          status={schedule.status ? 'success' : 'error'}
          text={schedule.status ? 'Ativo' : 'Inativo'}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Dias/Horários" span={3}>
        <ul style={{ margin: 0 }}>
          {schedule.config?.days?.map((day, idx) => (
            <li key={String(idx)}>
              <b>{daysPTBR[day.dayOfTheWeek]}</b> - {day.startTime} até{' '}
              {day.endTime}
              <br />
            </li>
          ))}
        </ul>
      </Descriptions.Item>
      <Descriptions.Item label="Início deste agendamento">
        {schedule.started_at &&
          moment(schedule.started_at).format('DD/MM/YYYY HH:mm:ss')}
      </Descriptions.Item>
      <Descriptions.Item label="Fim deste agendamento" span={2}>
        {schedule.finished_at &&
          moment(schedule.finished_at).format('DD/MM/YYYY HH:mm:ss')}
      </Descriptions.Item>

      <Descriptions.Item label="Opções" span={3}>
        <Button type="primary" onClick={onEdit}>
          Editar
        </Button>{' '}
        {onCancel && (
          <Button type="danger" onClick={onCancel}>
            Cancelar
          </Button>
        )}
      </Descriptions.Item>
    </Descriptions>
  )
}

ScheduleInfo.defaultTypes = {}

ScheduleInfo.propTypes = {
  schedule: t.objectOf(t.any).isRequired,
  onEdit: t.func.isRequired,
  onCancel: t.func.isRequired,
}

import React, { useCallback, useEffect, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { message, Table, Spin } from 'antd'

import api from '~/services/api'

import { columns } from './components/columns'
import { HeaderActions } from './components/HeaderActions'

export function Stores() {
  const [stores, setStores] = useState(null)
  const [loadingStores, setLoadingStores] = useState(false)

  const getStores = useCallback(async () => {
    setLoadingStores(true)

    try {
      const res = await api.get(`workforce-management-store`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setStores(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingStores(false)
    }
  }, [])

  useEffect(() => {
    getStores()
  }, [getStores])

  return (
    <div style={{ marginTop: '10px' }}>
      <HeaderActions />
      {loadingStores ? (
        <Spin
          style={{ marginTop: '10px' }}
          indicator={<LoadingOutlined spin />}
        />
      ) : (
        <div style={{ borderTop: 'solid 1px #ddd', marginTop: '20px' }}>
          <Table columns={columns} dataSource={stores} />
        </div>
      )}
    </div>
  )
}
